/*
 *   File : reset-password.js
 *   Author : https://evoqins.com
 *   Description : Password reset modal component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useState } from 'react';

// Custom components
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { CustomPasswordInput } from '../FormElements';

const ResetPasswordModal = (props) => {

    const [apiLoader, setApiLoader] = useState(false);

    const [currentPassword, setCurrentPassword] = useState("");
    const [currentPasswordError, setCurrentPasswordError] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");


    useEffect(() => {
        var reset_modal = document.getElementById("reset-password-modal");
        reset_modal.addEventListener("hidden.bs.modal", () => {
            props.closeModal()
            setCurrentPassword("")
            setConfirmPassword("");
            setNewPassword("");
            setCurrentPasswordError("");
            setConfirmPasswordError("");
            setNewPasswordError("");
        });
    }, []);

    useEffect(() => {
        setCurrentPasswordError("");
    }, [currentPassword]);

    useEffect(() => {
        setNewPasswordError("");
    }, [newPassword]);

    useEffect(() => {
        setConfirmPasswordError("");
    }, [confirmPassword]);

    useEffect(() => {
        setApiLoader(props.loading);
    }, [props.loading]);

    // handle reset password 
    async function _resetPassword() {
        try {
            const result = await props.reset(newPassword, currentPassword);
            if (result === true) {
                _handleCloseModal();
            }
        } catch (error) {

        }
    }

    // handle modal close
    const _handleCloseModal = () => {
        var button = document.getElementById("reset-pw-modal-close");
        button.click();
    };

    // Confirm new password
    const _handleConfirm = () => {
        let is_valid = true;

        if (props.type !== 1) {
            if (currentPassword.trim().length === 0) {
                setCurrentPasswordError("Enter your current password");
                is_valid = false;
            }
        }

        if (newPassword.trim().length === 0) {
            setNewPasswordError("Enter a new password");
            is_valid = false;
        }
        if (confirmPassword.trim().length === 0) {
            setConfirmPasswordError("Enter confirm password");
            is_valid = false;
        } else if (newPassword !== confirmPassword) {
            setConfirmPasswordError("Password does not match");
            is_valid = false;
        }

        if (is_valid === true) {
            _resetPassword();
        }
    };

    return (
        <div className="modal fade" id="reset-password-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="resetPasswordModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered e-border-radius-24">
                <div className="modal-content e-border-radius-16 w-412px mx-auto">
                    <div className="modal-header px-4 pt-4 pb-2 border-0">
                        <h4 className="mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-600 line-height-28px">
                            {props.type === 1 ? "Reset password" : "Change password"}
                        </h4>
                    </div>
                    <img src={require("../../Assets/Image/CountryAdmin/close-icon.svg").default}
                        draggable={false}
                        id='reset-pw-modal-close'
                        className='d-none '
                        alt='close'
                        data-bs-dismiss="modal" />
                    <div className="modal-body px-4 pt-0 pb-4">
                        <div className="padding-20px-top">
                            {/* Current password */}
                            {props.type !== 1 &&
                                <CustomPasswordInput label="Enter current password"
                                    placeholder="*********"
                                    require
                                    autoFocus={true}
                                    value={currentPassword}
                                    error={currentPasswordError}
                                    className="w-100"
                                    labelStyle="e-font-weight-400 e-text-raisin-black line-height-18px padding-6px-bottom"
                                    onKeyPress={_handleConfirm}
                                    handleChange={(e) => setCurrentPassword(e)} />
                            }

                            {/* New password */}
                            <CustomPasswordInput label="Enter new password"
                                placeholder="*********"
                                require
                                value={newPassword}
                                error={newPasswordError}
                                className="w-100"
                                labelStyle="e-font-weight-400 e-text-raisin-black line-height-18px padding-6px-bottom"
                                onKeyPress={_handleConfirm}
                                handleChange={(e) => setNewPassword(e)} />

                            {/* Confirm password */}
                            <CustomPasswordInput label="Confirm new password"
                                placeholder="*********"
                                require
                                value={confirmPassword}
                                error={confirmPasswordError}
                                className="w-100"
                                labelStyle="e-font-weight-400 e-text-raisin-black line-height-18px padding-6px-bottom"
                                onKeyPress={_handleConfirm}
                                handleChange={(e) => setConfirmPassword(e)} />

                            <div className='row'>
                                <div className='offset-4 col-8'>
                                    <div className='row text-end pt-4 mt-1'>
                                        <div className='col-6'>
                                            <SecondaryButton name="Cancel"
                                                className="px-4 padding-12px-tb w-100"
                                                onPress={_handleCloseModal} />
                                        </div>
                                        <div className='col-6'>
                                            <PrimaryButton name="Confirm"
                                                status={apiLoader}
                                                className={`padding-12px-tb px-4 w-100`}
                                                onPress={_handleConfirm} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

ResetPasswordModal.defaultProps = {
    closeModal: () => { }
}

export default memo(ResetPasswordModal);