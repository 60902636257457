/*
 *   File : learning-resources.js
 *   Author : https://evoqins.com
 *   Description : Manage-resources page
 *   Integrations : react-toastify,js-cookie
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from "bootstrap";
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

// Custom components
import { PrimaryButton } from '../../../Component/Buttons';
import { CustomSelectBox } from '../../../Component/FormElements';
import { FlashQuizCard, ResourceCard, VideoCard } from '../../../Component/Cards';
import { APIService } from '../../../Service';
import { Pagination } from '../../../Component/pagination';
import { ConfirmDeleteModal, FlashQuizDetailModal, VideoDetailModal } from '../../../Component/Modal';
import { Loader } from '../../../Component/Others';
import { EmptyScreen } from "../../../Component/EmptyComponent";

const CATEGORY_TAB = [
    {
        id: 1,
        name: "Courses"
    },
    {
        id: 2,
        name: "Videos"
    },
    {
        id: 3,
        name: "Flash quiz"
    },
];

const CATEGORY = [
    {
        value: 0,
        label: 'All',
    },
    {
        value: 1,
        label: 'Products'
    },
    {
        value: 2,
        label: 'Skills & Services'
    },
];

const ITEMS_PER_PAGE = 16;

const LearningResources = () => {

    const navigate = useNavigate();
    const { state } = useLocation();

    const [adminType, setAdminType] = useState(Cookies.get("admin_type") === "1" ? "super_admin" : "country_admin");
    const [tabIndex, setTabIndex] = useState(0);
    const [toggleFilterCourse, setToggleFilterCourse] = useState(false);
    const [toggleFilterVideo, setToggleFilterVideo] = useState(false);
    const [toggleFilterFlashQuiz, setToggleFilterFlashQuiz] = useState(false);


    const [selected, setSelected] = useState(CATEGORY[0]);

    const [resourceData, setResourceData] = useState({});
    const [loading, setLoading] = useState(true);

    const [totalPageCount, setTotalPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [flashQuizDetailData, setFlashQuizDetailData] = useState({});
    const [flashQuizId, setFlashQuizId] = useState(null);
    const [flashQuizDeleteResponse, setFlashQuizDeleteResponse] = useState(false);
    const [flashQuizLoading, setFlashQuizLoading] = useState(true);

    const [deleteStatus, setDeleteStatus] = useState(null);

    const [videoId, setVideoId] = useState(null);
    const [videoDetailData, setVideoDetailData] = useState({});
    const [videoDataLoading, setVideoDataLoading] = useState(true);
    const [videoDeleteResponse, setVideoDeleteResponse] = useState(false);

    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [countrySelect, setCountrySelect] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });

        if (adminType === "super_admin") {
            _getCountries();
        }
    }, [adminType]);

    useEffect(() => {
        const opened_modal = document.querySelector('.modal.show');
        if (opened_modal) {
            const modal = Modal.getInstance(opened_modal);
            modal.hide();

            setFlashQuizId(null);
            setVideoId(null);
        }
    }, [state]);

    useEffect(() => {
        if (state !== null) {
            setTabIndex(state);
        } else {
            setTabIndex(1);
        }
    }, [state]);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth'
        });
        if (tabIndex !== 0) {
            _getResourceList();
        }
    }, [tabIndex, currentPage, selected, selectedCountry]);

    useEffect(() => {
        if (videoId !== null) {
            _getVideoDetail();

            const detail_modal = new Modal(document.getElementById('video-detail-modal'));
            detail_modal.show();
        }
    }, [videoId]);

    useEffect(() => {
        if (flashQuizId !== null) {
            _getFlashQuizDetail();

            const detail_modal = new Modal(document.getElementById('flash-quiz-detail-modal'));
            detail_modal.show();
        }
    }, [flashQuizId]);

    useEffect(() => {
        if (deleteStatus !== null) {
            const delete_modal = new Modal(document.getElementById('confirm-delete-modal'));
            delete_modal.show();
        }
    }, [deleteStatus]);

    useEffect(() => {
        setSelected(CATEGORY[0]);
        if (countrySelect === true) {
            setSelectedCountry(countries[0]);
        }
    }, [tabIndex]);

    // handle tab change 
    const _handleTabChange = (id) => {
        navigate("/manage-resource", {
            state: id
        });
        setLoading(true);
        setCurrentPage(1);
        setToggleFilterCourse(false);
        setToggleFilterVideo(false);
        setToggleFilterFlashQuiz(false);
    };

    // handle toggle filter 
    const _handleToggleFilter = (type) => {
        if (type === "course") {
            setToggleFilterCourse(!toggleFilterCourse);
        } else if (type === "video") {
            setToggleFilterVideo(!toggleFilterVideo);
        } else {
            setToggleFilterFlashQuiz(!toggleFilterFlashQuiz);
        }
    };

    //handle pagination
    const _handlePagination = (new_start_limit) => {
        const selected_page = Math.floor(new_start_limit / ITEMS_PER_PAGE) + 1;
        setCurrentPage(selected_page);
    }

    // handle delete 
    const _handleViewFlashQuizDetail = (id) => {
        setFlashQuizId(id);
        setFlashQuizDeleteResponse(false);
    };

    // handle delete flash quiz
    const _handleDeleteFlashQuiz = (quiz_id) => {
        setDeleteStatus(1);
        setFlashQuizId(quiz_id);
        setFlashQuizDeleteResponse(false);
    };

    // handle view video detail modal 
    const _handleViewVideoDetail = (vide_id) => {
        setVideoId(vide_id);
        setVideoDeleteResponse(false);

    };

    // handle delete video
    const _handleDeleteVideo = (vid_id) => {
        setDeleteStatus(2);
        setVideoId(vid_id);
        setVideoDeleteResponse(false);
    };

    // handle navigate add course
    const _handleManageCourse = () => {
        navigate("manage-course", {
            state: {
                type: "",
                course_id: "",
            }
        });
    };

    // handle manage video 
    const _handleManageVideo = () => {
        navigate('manage-video', {
            state: {
                data: "",
                type: ""
            }
        });
    };

    //handle manage flash quiz
    const _handleManageFlashQuiz = () => {
        navigate('manage-flash-quiz', {
            state: {
                data: "",
                type: ""
            }
        });
    };

    // handle filter category 
    const _handleSelectCategory = (value) => {
        setSelected(value);
        setLoading(true);
        setCurrentPage(1);
    };

    // handle filter country 
    const _handleSelectCountry = (value) => {
        setSelectedCountry(value);
        setLoading(true);
        setCurrentPage(1);
    };

    // API - Get resource list 
    const _getResourceList = () => {
        let is_post = true;
        let url = adminType === "super_admin" ? 'course/resource/list' : 'CA/resource/list';
        let data = {
            "page_num": currentPage,
            "filter_type": tabIndex,
            "filter_item": selected.value === 0 ? null : selected.value,
            "country_id": selectedCountry.value
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setResourceData(response.data);
                setTotalPageCount(Math.ceil(response.data.total_count / ITEMS_PER_PAGE));
            } else {
            }
            setLoading(false);
        });
    };

    // API - Flash quiz detail
    const _getFlashQuizDetail = () => {
        let is_post = true;
        let url = adminType === "super_admin" ? 'flash-quiz/detail' : 'CA/flash-quiz/detail';
        let data = {
            "quiz_id": flashQuizId
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setFlashQuizDetailData(response.data);
                setFlashQuizLoading(false);
            } else {
            }
        });
    };

    // API - Delete flash quiz 
    const _deleteFlashQuiz = () => {
        let is_post = true;
        let url = adminType === "super_admin" ? 'flash-quiz/delete' : 'CA/flash-quiz/delete';
        let data = {
            "flash_quiz_id": flashQuizId
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setFlashQuizDeleteResponse(true);
            } else {
                setFlashQuizDeleteResponse(false);
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        });
    };

    // API - Delete video
    const _deleteVideo = () => {
        let is_post = true;
        let url = adminType === "super_admin" ? 'video/delete' : 'CA/video/delete';
        let data = {
            "video_id": videoId
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setVideoDeleteResponse(true);
            } else {
                setVideoDeleteResponse(false);
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        });
    };

    // API - Video detail
    const _getVideoDetail = () => {
        let is_post = true;
        let url = adminType === "super_admin" ? 'video/detail' : 'CA/video/detail';
        let data = {
            "video_id": videoId
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setVideoDetailData(response.data);
                setVideoDataLoading(false);
            } else {
            }
        });
    };

    // API - Get countries
    const _getCountries = () => {

        const country_options = [];

        let is_post = false;
        let url = 'general/list-countries';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                country_options.push(
                    {
                        value: null,
                        label: "All"
                    }
                );

                response.data.map((country) => (
                    country_options.push(
                        {
                            value: country.value,
                            label: country.label
                        }
                    )
                ));

                setSelectedCountry(country_options[0]);
                setCountries(country_options);
                setCountrySelect(true);
            } else {
                setCountrySelect(false);
            }
        });
    };

    return (
        <section className='pb-5 '>
            <div className={`${adminType === 'super_admin' ? 'e-bg-azureish-white' : 'e-bg-lavender-blush-light'} pt-3 padding-52px-lr`}>
                <h1 className='mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-raisin-black e-font-weight-600'>
                    Resource management
                </h1>

                {/* category tab */}
                <div className='d-flex flex-wrap padding-40px-top padding-20px-bottom justify-content-space-between align-items-center'>
                    <div className='d-flex gap-0-16 overflow-auto pb-xl-0 pb-lg-0 pb-md-0 pb-1 e-tab-scroll'>
                        {CATEGORY_TAB.map((data, key) => (
                            <div key={key} className={`${tabIndex === data.id ? 'e-bg-lust e-text-white border-transparent pe-none' : 'e-text-rich-black e-border-1px-cool-grey'} transition e-border-radius-24 cursor-pointer`}
                                onClick={() => _handleTabChange(data.id)} >
                                <p className='mb-0 w-max-content px-4 padding-12px-tb e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-14px'>
                                    {data.name}
                                </p>
                            </div>
                        ))}
                    </div>

                    {/* Custom buttons */}
                    {tabIndex === 1 ?
                        // add new resource button
                        <div className='pt-xl-0 pt-lg-0 pt-md-0 pt-2'>

                            <div className='d-flex align-items-center'>
                                <PrimaryButton name="Add new course"
                                    className="me-3 e-border-radius-16 padding-14px-tb line-height-12px e-font-16"
                                    onPress={_handleManageCourse} />
                                <img src={toggleFilterCourse ? require("../../../Assets/Image/filter-icon-white.svg").default :
                                    require("../../../Assets/Image/filter-icon.svg").default}
                                    draggable={false}
                                    className={`${toggleFilterCourse ? `e-bg-indigo` : ``} transition e-border-1px-cool-grey e-border-radius-8 p-2 cursor-pointer`}
                                    alt='filter'
                                    onClick={() => _handleToggleFilter("course")} />
                            </div>
                        </div>
                        :
                        // add new video button
                        tabIndex === 2 ?
                            <div className='d-flex align-items-center pt-xl-0 pt-lg-0 pt-md-0 pt-2'>
                                <PrimaryButton name="Add new video"
                                    className="me-3 e-border-radius-16 padding-14px-tb line-height-12px e-font-16"
                                    onPress={_handleManageVideo} />
                                <img src={toggleFilterVideo ? require("../../../Assets/Image/filter-icon-white.svg").default :
                                    require("../../../Assets/Image/filter-icon.svg").default}
                                    draggable={false}
                                    className={`${toggleFilterVideo ? `e-bg-indigo` : ``} transition e-border-1px-cool-grey  e-border-radius-8 p-2 cursor-pointer`}
                                    alt='filter'
                                    onClick={() => _handleToggleFilter("video")} />
                            </div>
                            :
                            // add new flash quiz
                            <div className='pt-xl-0 pt-lg-0 pt-md-0 pt-2'>
                                <PrimaryButton name="Add new flash quiz"
                                    className="me-3 e-border-radius-16 padding-14px-tb line-height-12px e-font-16"
                                    onPress={_handleManageFlashQuiz} />

                                {countrySelect === true &&
                                    <img src={toggleFilterFlashQuiz ? require("../../../Assets/Image/filter-icon-white.svg").default :
                                        require("../../../Assets/Image/filter-icon.svg").default}
                                        draggable={false}
                                        className={`${toggleFilterFlashQuiz ? `e-bg-indigo` : ``} transition e-border-1px-cool-grey  e-border-radius-8 p-2 cursor-pointer`}
                                        alt='filter'
                                        onClick={() => _handleToggleFilter("flash-quiz")} />
                                }
                            </div>
                    }
                </div>
            </div>

            <div className='padding-52px-lr'>
                {/* filter */}
                {toggleFilterCourse && tabIndex === 1 ?
                    <div className='e-bg-anti-flash-white-dark py-3 px-4'>
                        <div className='row'>
                            {/* Category  */}
                            <div className='col-xl-2 col-lg-3 col-md-3 col-sm-4 col-8 pe-0'>
                                <CustomSelectBox label="Category"
                                    value={selected}
                                    options={CATEGORY}
                                    type={2}
                                    className="e-bg-white e-border-radius-8 e-table-select "
                                    labelClass="e-font-weight-600"
                                    isSearchable={false}
                                    selectChange={(temp) => _handleSelectCategory(temp)}
                                />
                            </div>
                            {/* Country select */}
                            {countrySelect === true &&
                                <div className='col-xl-2 col-lg-3 col-md-3 col-sm-4 col-8 pe-0'>
                                    <CustomSelectBox label="Country"
                                        value={selectedCountry}
                                        options={countries}
                                        type={2}
                                        className="e-bg-white e-border-radius-8 e-table-select "
                                        labelClass="e-font-weight-600"
                                        isSearchable={false}
                                        selectChange={(temp) => { _handleSelectCountry(temp); }}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    :
                    // filter video
                    toggleFilterVideo && tabIndex === 2 ?
                        <div className='e-bg-anti-flash-white-dark py-3 px-4'>
                            <div className='row'>
                                <div className='col-xl-2 col-lg-3 col-md-3 col-sm-4 col-8 pe-0'>
                                    <CustomSelectBox label="Category"
                                        value={selected}
                                        options={CATEGORY}
                                        type={2}
                                        className="e-bg-white e-border-radius-8 e-table-select "
                                        labelClass="e-font-weight-600"
                                        isSearchable={false}
                                        selectChange={(temp) => { setSelected(temp); }}
                                    />
                                </div>
                                {/* Country select */}
                                {countrySelect === true &&
                                    <div className='col-xl-2 col-lg-3 col-md-3 col-sm-4 col-8 pe-0'>
                                        <CustomSelectBox label="Country"
                                            value={selectedCountry}
                                            options={countries}
                                            type={2}
                                            className="e-bg-white e-border-radius-8 e-table-select "
                                            labelClass="e-font-weight-600"
                                            isSearchable={false}
                                            selectChange={(temp) => { _handleSelectCountry(temp); }}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        :
                        // filter flash quiz
                        toggleFilterFlashQuiz && tabIndex === 3 ?
                            <div className='e-bg-anti-flash-white-dark py-3 px-4'>
                                <div className='row'>
                                    {/* Country select */}
                                    {countrySelect === true &&
                                        <div className='col-xl-2 col-lg-3 col-md-3 col-sm-4 col-8 pe-0'>
                                            <CustomSelectBox label="Country"
                                                value={selectedCountry}
                                                options={countries}
                                                type={2}
                                                className="e-bg-white e-border-radius-8 e-table-select "
                                                labelClass="e-font-weight-600"
                                                isSearchable={false}
                                                selectChange={(temp) => { _handleSelectCountry(temp); }}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                            :
                            null
                }

                {loading ?
                    // loader 
                    <Loader />
                    :
                    <>
                        {resourceData.total_count === 0 ?
                            // empty screen
                            <div className='h-100vh pt-5 mt-5'>
                                <EmptyScreen message="No data found" />
                            </div>
                            :
                            <>
                                {/* Resource card */}
                                {tabIndex === 1 ?
                                    <div className='row row-gap-3 pt-4 mt-2'>
                                        {resourceData.data.map((data, key) => (
                                            <div key={key} className='col-xl-6 col-lg-6 col-md-6 col-12'>
                                                <ResourceCard course={data} />
                                            </div>
                                        ))}
                                    </div>
                                    :
                                    tabIndex === 2 ?
                                        <div className='row row-gap-3 pt-4 mt-2 pb-5'>
                                            {resourceData.data.map((data, key) => (
                                                <div key={key} className='col-xl-6 col-lg-6 col-md-6 col-12'>
                                                    <VideoCard video={data}
                                                        handleView={_handleViewVideoDetail} />
                                                </div>
                                            ))}
                                        </div>
                                        :
                                        // flash quiz card
                                        <div className='row row-gap-3 pt-4 mt-2 pb-5'>
                                            {resourceData.data.map((data, key) => (
                                                <div key={key} className='col-xl-6 col-lg-6 col-md-6 col-12'>
                                                    <FlashQuizCard flashQuiz={data}
                                                        handleView={_handleViewFlashQuizDetail} />
                                                </div>
                                            ))}
                                        </div>
                                }

                                {/* pagination */}
                                {totalPageCount > 1 &&
                                    <div className="d-flex justify-content-center e-gap-0-16 mt-4 pt-3">
                                        <Pagination itemsPerPage={ITEMS_PER_PAGE}
                                            totalPage={totalPageCount}
                                            showFirstLastPages={true}
                                            onChange={_handlePagination} />
                                    </div>
                                }
                            </>
                        }
                    </>
                }

            </div>

            {/* Video detail modal */}
            {videoId !== null &&
                <VideoDetailModal video={videoDetailData}
                    response={videoDeleteResponse}
                    loading={videoDataLoading}
                    handleClose={() => setVideoId(null)}
                    handleDelete={_handleDeleteVideo} />
            }

            {/* flash quiz detail modal */}
            {flashQuizId !== null &&
                <FlashQuizDetailModal flashQuiz={flashQuizDetailData}
                    response={flashQuizDeleteResponse}
                    loading={flashQuizLoading}
                    handleClose={() => setFlashQuizId(null)}
                    handleDelete={_handleDeleteFlashQuiz} />
            }

            {/* confirm delete modal */}
            {deleteStatus !== null &&
                <ConfirmDeleteModal label={deleteStatus === 1 ? "flash quiz" : "video"}
                    deleteId={deleteStatus === 1 ? flashQuizId : videoId}
                    response={deleteStatus === 1 ? flashQuizDeleteResponse : videoDeleteResponse}
                    delete={() => {
                        if (deleteStatus === 1) {
                            _deleteFlashQuiz();
                        } else {
                            _deleteVideo();
                        }
                    }}
                    handleClose={() => setDeleteStatus(null)}
                    success={() => {
                        _getResourceList();
                        setFlashQuizDeleteResponse(false);
                        setVideoDeleteResponse(false);
                    }} />
            }
        </section>
    )
}

export default LearningResources;