/*
 *   File : time-picker.js
 *   Author : https://evoqins.com
 *   Description : Time picker component
 *   Integrations : rc-time-picker
 *   Version : 1.0.0
*/

import React, { memo } from 'react';
import TimePicker from 'rc-time-picker';

//Custom component
import { PrimaryButton } from '../Buttons';
import { Icon } from '../Icon';

const CustomTimePicker = (props) => {
    const format = 'h:mm a';

    // handle submit 
    const _handleSubmit = () => {
        props.handleClose();
    };

    return (
        <div>
            {props.label &&
                <label className={`${props.labelClass} padding-6px-bottom e-text-black e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-20px`}>
                    {props.label}
                    {!props.optional && <span className='e-text-lust'>*</span>}
                </label>
            }
            <div className='h-62px'>
                <TimePicker value={props.value}
                    showSecond={false}
                    placeholder={props.placeholder}
                    inputReadOnly
                    open={props.open}
                    className={`${props.open ? `e-border-1px-indigo` : `e-border-1px-platinum-light`} e-time-picker e-border-radius-8`}
                    format={format}
                    use12Hours
                    clearIcon={
                        <Icon icon="close-icon"
                            className='cursor-pointer e-close'
                            size="20px" />
                    }
                    inputIcon={props.value !== null ? null : (
                        <img src={require("../../Assets/Image/CountryAdmin/arrow-down.svg").default}
                            draggable={false}
                            className={props.value ? `transform-rotate-180 transition` : `transition`}
                            alt='arrow' />
                    )}
                    addon={() => (
                        <PrimaryButton name="Ok"
                            className="py-1 e-border-radius-8 position-absolute bottom-6px right-6px"
                            onPress={_handleSubmit} />
                    )}
                    placement='bottomLeft'
                    onClose={props.handleClose}
                    onOpen={props.handleOpen}
                    onChange={props.handleChange} />

                {props.error &&
                    <p className='mb-0 e-error-message e-alt-font-noto-sans e-font-12 line-height-12px pt-1'>
                        {props.error}
                    </p>
                }
            </div>

        </div>
    )
}

export default memo(CustomTimePicker);