/*
 *   File : confirm-delete.js
 *   Author : https://evoqins.com
 *   Description :Delete confirm modal
 *   Integrations : js-cookie
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useState } from 'react'

// Custom components
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { Icon } from '../Icon';

const ConfirmDeleteModal = (props) => {

    const [deleteStatus, setDeleteStatus] = useState(false);

    useEffect(() => {
        var delete_modal = document.getElementById("confirm-delete-modal");
        delete_modal.addEventListener("hidden.bs.modal", () => {
            setDeleteStatus(false);
        });
    }, []);

    useEffect(() => {
        if (props.response === true) {
            setDeleteStatus(true);
        }
    }, [props.response]);

    // handle close Modal 
    const _handleCloseModal = () => {
        var button = document.getElementById("confirm-delete-modal-close");
        button.click();
        setDeleteStatus(false);
        props.handleClose();
    };

    // handle confirm delete 
    const _handleDeleteConfirm = (delete_id) => {
        props.delete(delete_id);
    };

    // handle success
    const _handleSuccess = () => {
        var button = document.getElementById("confirm-delete-modal-close");
        button.click();
        setDeleteStatus(false);
        props.handleClose();
        props.success();
    }

    return (
        <div className="modal fade" id="confirm-delete-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="confirmDeleteModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content e-border-radius-12 w-400px mx-auto ">
                    <img src={require("../../Assets/Image/CountryAdmin/close-icon.svg").default}
                        draggable={false}
                        className='d-none'
                        id='confirm-delete-modal-close'
                        alt='close'
                        data-bs-dismiss="modal" />
                    <div className="modal-header px-4 pt-4 pb-3 border-0 align-items-start">
                        <img src={require("../../Assets/Image/CountryAdmin/delete-icon.svg").default}
                            draggable={false}
                            alt='delete' />
                        {!deleteStatus &&
                            <Icon icon="close-icon"
                                className='cursor-pointer'
                                size="24px"
                                onPress={_handleCloseModal} />
                        }

                    </div>
                    <div className="modal-body px-4 pt-1 pb-4">
                        {!deleteStatus ?
                            <>
                                <h4 className='mb-2 e-primary-font-playfair e-text-raisin-black e-font-18 e-font-weight-500 line-height-28px'>
                                    Delete {props.label}
                                </h4>
                                <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                    Are you sure you want to delete this {props.label}
                                </p>

                                {/* Custom button  */}
                                <div className='row row-cols-2 pt-4 mt-2'>
                                    <div className='col'>
                                        <SecondaryButton name="Cancel"
                                            className="w-100"
                                            onPress={_handleCloseModal} />
                                    </div>
                                    <div className='col'>
                                        <PrimaryButton name="Delete"
                                            className="w-100"
                                            status={props.response}
                                            onPress={() => _handleDeleteConfirm(props.deleteId)} />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <h4 className='mb-2 e-primary-font-playfair e-text-raisin-black e-font-18 e-font-weight-500 line-height-28px'>
                                    Deleted successfully
                                </h4>
                                <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                    Your {props.label} has been deleted successfully.
                                </p>

                                {/* Custom button  */}
                                <div className='pt-4 mt-2'>
                                    <PrimaryButton name="Ok"
                                        className="w-100"
                                        onPress={_handleSuccess} />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}

ConfirmDeleteModal.defaultProps = {
    delete: () => { },
    handleClose: () => { }
}


export default memo(ConfirmDeleteModal);