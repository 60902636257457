/*
 *   File : competition.js
 *   Author : https://evoqins.com
 *   Description : Competition card component
 *   Integrations : NA
 *   Version : 1.0.0
*/

//Custom components
import { PrimaryButton, SecondaryButton, TextButton } from "../Buttons";

// styles
import styles from '../../Style/Component/competition.module.scss';

const CompetitionCard = (props) => {
    const { data } = props;

    return (
        <div className="w-100 d-flex flex-column justify-content-between bg-white e-border-radius-16 e-border-1px-platinum-light overflow-hidden position-relative">
            <span className={`${data.status === "Active" ? 'e-bg-philippine-green' : 'e-bg-lust'} position-absolute z-index-2 top-24px left-24px e-border-radius-40 p-1 e-alt-font-noto-sans e-text-white e-font-10 e-font-weight-400 line-height-10px`}>
                {data.status}
            </span>
            <div>
                <div className={styles.e_img_wrapper}>
                    <img src={data.image_url}
                        draggable={false}
                        className="object-fit-cover w-100 h-100 overflow-hidden"
                        alt="banner"
                    />
                </div>

                <div className="px-3 pt-3">
                    <div className="row mb-3">
                        <div className="col-xl-9">
                            <h6 className={`${props.detail ? 'e-ellipse h-48px' : 'text-truncate'} e-font-18 e-alt-font-noto-sans e-font-weight-500 mb-0 line-height-24px e-text-raisin-black`}>
                                {data.title}
                            </h6>
                        </div>
                        <div className="col-xl-3">
                            <h5 className="e-font-24 e-alt-font-noto-sans e-font-weight-600 mb-0 line-height-24px e-text-raisin-black d-flex align-items-center justify-content-end">
                                <img src={require("../../Assets/Image/CountryAdmin/ManageCompetition/streak.svg").default}
                                    alt="streak" />
                                {data.points}
                            </h5>
                        </div>
                    </div>
                    <p className={`${props.detail ? '' : 'e-ellipse-3-line '} e-font-14 e-alt-font-noto-sans e-font-weight-400 mb-3 line-height-22px e-text-raisin-black`}>
                        {data.description}
                    </p>

                    {props.detail ?
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-2 col-sm-3 col-12 pe-0">
                                <p className="mb-1 e-font-12 e-alt-font-noto-sans e-font-weight-400 mb-0 line-height-22px e-text-raisin-black">
                                    Min. sales target
                                </p>
                                {data.target.map((target, key) => (
                                    <p key={key} className="mb-1 e-font-14 e-alt-font-noto-sans e-font-weight-700 mb-0 line-height-22px e-text-raisin-black letter-spacing-005em ">
                                        {target.sales_target}
                                    </p>
                                ))}
                            </div>
                            <div className="col-xl-9 col-lg-8 col-md-10 col-sm-9 col-12">
                                <p className="e-font-12 e-alt-font-noto-sans e-font-weight-400 mb-0 line-height-22px e-text-raisin-black">
                                    Target product
                                </p>
                                {data.target.map((target, key) => (
                                    <p key={key} className="mb-1 e-font-14 e-alt-font-noto-sans e-font-weight-700 mb-0 line-height-22px e-text-raisin-black letter-spacing-005em  ">
                                        {target.target_product}
                                    </p>
                                ))}
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-2 col-sm-3 col-12 pe-0">
                                <p className="mb-1 e-font-12 e-alt-font-noto-sans e-font-weight-400 mb-0 line-height-22px e-text-raisin-black">
                                    Min. sales target
                                </p>
                                <p className="mb-1 e-font-14 e-alt-font-noto-sans e-font-weight-700 mb-0 line-height-22px e-text-raisin-black letter-spacing-005em ">
                                    {props.data.target[0]?.sales_target}
                                </p>
                            </div>
                            <div className="col-xl-9 col-lg-8 col-md-10 col-sm-9 col-12">
                                <p className="e-font-12 e-alt-font-noto-sans e-font-weight-400 mb-0 line-height-22px e-text-raisin-black">
                                    Target product
                                </p>
                                <p className="mb-1 e-font-14 e-alt-font-noto-sans e-font-weight-700 mb-0 line-height-22px e-text-raisin-black letter-spacing-005em  ">
                                    {props.data.target[0]?.target_product}
                                </p>
                            </div>
                            {data.target.length > 1 &&
                                <TextButton name="View more"
                                    className="w-max-content"
                                    onPress={props.handleNavigate} />
                            }
                        </div>
                    }

                    <div className="d-flex gap-4 pt-3 mb-2">
                        <div className="d-block">
                            <p className="e-font-12 e-alt-font-noto-sans e-font-weight-400 mb-0 line-height-22px e-text-raisin-black">
                                Start date
                            </p>
                            <span className="e-font-14 e-alt-font-noto-sans e-font-weight-700 mb-0 line-height-22px e-text-raisin-black letter-spacing-005em ">
                                {data.start_date}
                            </span>
                        </div>
                        <div className="d-block">
                            <p className="e-font-12 e-alt-font-noto-sans e-font-weight-400 mb-0 line-height-22px e-text-raisin-black">
                                End date
                            </p>
                            <span className="e-font-14 e-alt-font-noto-sans e-font-weight-700 mb-0 line-height-22px e-text-raisin-black letter-spacing-005em ">
                                {data.end_date}
                            </span>
                        </div>
                    </div>
                    <span className="e-font-12 e-alt-font-noto-sans e-font-weight-400 mb-0 line-height-22px e-text-raisin-black">
                        <img src={require("../../Assets/Image/CountryAdmin/ManageCompetition/judge.svg").default}
                            alt="judge"
                            draggable={false}
                            className="me-1" />
                        Rules
                    </span>
                    {props.detail ?
                        <ol className='ps-3 mt-1 e-font-14 e-alt-font-noto-sans e-font-weight-400 mb-0 line-height-22px e-text-raisin-black'>
                            {
                                data.rules.map((item, key) => (
                                    <li key={key} className="mb-2 ms-1">{item}</li>
                                ))
                            }
                        </ol>
                        :
                        <div className="d-flex flex-column justify-content-between">
                            <div className="d-flex align-items-start">
                                <p className="mb-0 e-font-14 e-alt-font-noto-sans e-font-weight-400 mb-0 line-height-22px e-text-raisin-black">1.</p>
                                <p className='ps-1 mb-1 e-font-14 e-alt-font-noto-sans e-font-weight-400 mb-0 line-height-22px e-text-raisin-black e-ellipse'>
                                    {data.rules[0]}
                                </p>
                            </div>
                            {data.rules.length > 1 &&
                                <TextButton name="View more"
                                    className="w-max-content"
                                    onPress={props.handleNavigate} />
                            }
                        </div>
                    }
                </div>
            </div>

            <div className="px-3 pb-3 mt-2">
                {props.detail ?
                    <div className='d-flex justify-content-end mt-2 ms-auto'>
                        <TextButton name="Edit"
                            className="p-2"
                            onPress={props.handleEdit} />
                        <TextButton name="Delete"
                            className="p-2 e-text-lust"
                            onPress={props.handleConfirm} />
                    </div>
                    :
                    <div className="d-flex gap-3 flex-wrap justify-content-between align-items-center">
                        <h6 className="e-font-14 e-alt-font-noto-sans e-font-weight-600 mb-0 line-height-22px e-text-raisin-black e-letter-spacing-005em">
                            Total agents completed: {data.completed_agent_count}
                        </h6>
                        <div>
                            <div className="d-flex justify-content-end">
                                <SecondaryButton name="Send notification"
                                    status={props.loader}
                                    className={`${props.loader ? 'padding-6px-tb' : 'py-2'}  text-nowrap me-2 e-border-radius-16 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0 mt-2 w-148px`}
                                    onPress={props.sendNotification} />
                                <PrimaryButton name="View overview"
                                    className="text-nowrap py-2 e-border-radius-16 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0 mt-2"
                                    onPress={props.handleNavigate} />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div >
    )
}

CompetitionCard.defaultProps = {
    handleNavigate: () => { }
}
export default CompetitionCard