/*
 *   File : configure-alarm.js
 *   Author : https://evoqins.com
 *   Description :  Configure jotun alarm
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { Modal } from 'bootstrap';

// Custom components
import { CustomDatePicker, CustomFileUpload, CustomSelectBox, CustomTextInput, TextArea } from '../../../../Component/FormElements';
import { PrimaryButton, TextButton } from '../../../../Component/Buttons';
import { APIService, AWSService } from '../../../../Service';
import { Loader, OverLay } from '../../../../Component/Others';
import { Icon } from '../../../../Component/Icon';
import { ScheduledNotifications } from '../../../../Component/Cards';
import { EmptyScreen } from '../../../../Component/EmptyComponent';
import { ConfirmDeleteModal } from '../../../../Component/Modal';
import { Pagination } from '../../../../Component/pagination';

// Style
import styles from "../../../../Style/Component/notification.module.scss";

const ITEMS_PER_PAGE = 12;

const ConfigureAlarm = () => {

    const errorRef = useRef({});
    const cardRef = useRef(null);

    const [title, setTitle] = useState({ en: "", ar: "" });
    const [titleError, setTitleError] = useState({ en: "", ar: "" });
    const [content, setContent] = useState({ en: "", ar: "" });
    const [contentError, setContentError] = useState({ en: "", ar: "" });
    const [image, setImage] = useState(null);
    const [imageError, setImageError] = useState("");
    const [imageLoader, setImageLoader] = useState(true);

    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [startDateError, setStartDateError] = useState("");
    const [startDateToggle, setStartDateToggle] = useState(false);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [endDateError, setEndDateError] = useState("");
    const [endDateToggle, setEndDateToggle] = useState(false);

    const [selectedResource, setSelectedResource] = useState(null);
    const [selectedResourceError, setSelectedResourceError] = useState("");
    const [courseData, setCourseData] = useState([]);
    const [courseLoading, setCourseLoading] = useState(true);
    const [createLoader, setCreateLoader] = useState(true);

    const [alarmHistory, setAlarmHistory] = useState({});
    const [historyLoading, setHistoryLoading] = useState(true);

    const [deleteId, setDeleteId] = useState(null);
    const [alarmDeleteResponse, setAlarmDeleteResponse] = useState(false);

    const [totalPageCount, setTotalPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        setCreateLoader(false);
        setImageLoader(false);
        _getCourseList();
    }, []);

    useEffect(() => {
        _getAlarmHistory();
    }, [currentPage]);

    useEffect(() => {
        setImageError("");
    }, [image]);

    useEffect(() => {
        setStartDateError("");
    }, [selectedStartDate]);

    useEffect(() => {
        setEndDateError("");
    }, [selectedEndDate]);

    useEffect(() => {
        setSelectedResourceError("");
    }, [selectedResource]);

    useEffect(() => {
        if (deleteId !== null) {
            const delete_modal = new Modal(document.getElementById('confirm-delete-modal'));
            delete_modal.show();
        }
    }, [deleteId]);

    // handle title change 
    const _handleChangeTitle = (lang, value) => {
        setTitle(prev => ({ ...prev, [lang]: value }));
        setTitleError(prev => ({ ...prev, [lang]: "" }));
    };

    // handle content change 
    const _handleChangeContent = (lang, value) => {
        setContent(prev => ({ ...prev, [lang]: value }));
        setContentError(prev => ({ ...prev, [lang]: "" }));
    };

    // handle upload image
    const _uploadedImage = (url) => {
        setImageLoader(true);
        _getPresignedUrl(url);
    };

    // start date toggle show an hide
    const _handleStartDateToggle = () => {
        setStartDateToggle(!startDateToggle);
    };

    // handle select start date
    const _handleSelectedStartDate = (day) => {
        setSelectedStartDate(day);
        setStartDateToggle(false);
    };

    // handle clear start date 
    const _handleClearStartDate = () => {
        setSelectedStartDate(null);
        setSelectedEndDate(null);
    };

    // end date toggle show an hide
    const _handleEndDateToggle = () => {
        setEndDateToggle(!endDateToggle);
    };

    // handle select end date
    const _handleSelectedEndDate = (day) => {
        setSelectedEndDate(day);
        setEndDateToggle(false);
    };

    // handle clear end date 
    const _handleClearEndDate = () => {
        setSelectedEndDate(null);
    };

    //handle select linked resource
    const _handleSelectLinkedResource = (id) => {
        setSelectedResource(id);
    };

    // handle focus error
    const _focusError = (field) => {
        if (errorRef.current[field]) {
            errorRef.current[field].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    // handle send alarm
    const _handleSend = () => {

        let is_valid = true;
        let error_field = [];

        if (title.en.trim().length === 0) {
            setTitleError(prev => ({ ...prev, en: "Title english is required" }));
            is_valid = false;
            error_field.push('title');
        }

        if (title.ar.trim().length === 0) {
            setTitleError(prev => ({ ...prev, ar: "Title arabic is required" }));
            is_valid = false;
            error_field.push('title');
        }

        if (content.en.trim().length === 0) {
            setContentError(prev => ({ ...prev, en: "Message english is required" }));
            is_valid = false;
            error_field.push('content');
        }

        if (content.ar.trim().length === 0) {
            setContentError(prev => ({ ...prev, ar: "Message arabic is required" }));
            is_valid = false;
            error_field.push('content');
        }

        if (image === null) {
            setImageError("Image is required");
            is_valid = false;
            error_field.push('image');
        }

        if (selectedStartDate === null) {
            setStartDateError("Select start date");
            is_valid = false;
            error_field.push('start-date');
        }

        if (selectedStartDate !== null && selectedEndDate === null) {
            setEndDateError("Select end date");
            is_valid = false;
            error_field.push('end-date');
        }

        if (selectedResource === null) {
            setSelectedResourceError("Select resource");
            is_valid = false;
            error_field.push('resource');
        }

        if (is_valid === true) {
            setCreateLoader(true);
            _createAlarm();
        } else {
            if (error_field.length > 0) {
                _focusError(error_field[0]);
            }
        }
    };

    // handle delete alarm
    const _handleDeleteAlarm = (id) => {
        setDeleteId(id);
        setAlarmDeleteResponse(false);
    };

    //handle pagination
    const _handlePagination = (new_start_limit) => {
        const selected_page = Math.floor(new_start_limit / ITEMS_PER_PAGE) + 1;
        setCurrentPage(selected_page);

        if (cardRef.current) {
            cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    // get presigned url
    async function _getPresignedUrl(file) {
        let is_post = true;
        let url = 'general/file-upload';
        let data = {
            "file": file.name,
            "is_public": true
        };
        try {
            let response = await APIService(is_post, url, data);
            if (response.status_code === 200) {
                let aws_fields = response.data.upload_info.fields;
                let aws_url = response.data.upload_info.url;

                try {
                    let aws_response = await AWSService(aws_fields, file, aws_url);
                    if (aws_response.status === 'ok') {
                        setImage(response.data.view_info);
                    } else {
                        toast.dismiss();
                        toast.error(aws_response.message, {
                            type: "error",
                            className: "e-toast",
                        });
                    }
                } catch (err) {
                    console.log("error")
                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        } catch (err) {
        }
        setImageLoader(false);
    };


    // API - Create jotun alarm
    const _createAlarm = () => {
        let is_post = true;
        let url = 'CA/notifications/create-alarm';
        let data = {
            "title": title.en,
            "title_arabic": title.ar,
            "description": content.en,
            "description_arabic": content.ar,
            "image_url": image,
            "linked_resource": selectedResource.value,
            "resource_id": 2,
            "start_date": format(selectedStartDate, 'yyyy-MM-dd'),
            "end_date": format(selectedEndDate, 'yyyy-MM-dd'),
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                toast.dismiss();
                toast.success("Alarm configured successfully", {
                    type: "success",
                    className: "e-toast",
                });

                setTitle({ en: "", ar: "" });
                setContent({ en: "", ar: "" });
                setImage(null);
                setSelectedStartDate(null);
                setSelectedEndDate(null);
                setSelectedResource(null);

                setHistoryLoading(true);
                _getAlarmHistory(); //API

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                })
            }
            setCreateLoader(false);
        });
    };

    // API - Course list 
    const _getCourseList = () => {
        let is_post = false;
        let url = 'general/course/list';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCourseData(response.data);
            } else { }
            setCourseLoading(false);
        });
    };

    //API - Get jotun alarm history
    const _getAlarmHistory = () => {
        let is_post = true;
        let url = 'CA/notifications/list-alarm';
        let data = {
            "page_num": currentPage
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setAlarmHistory(response.data);
                setTotalPageCount(Math.ceil(response.data.total_record / ITEMS_PER_PAGE));
            } else { }
            setHistoryLoading(false);
        });
    };

    //API - Delete jotun alarm
    const _deleteAlarm = () => {
        let is_post = true;
        let url = 'CA/notifications/delete-alarm';
        let data = {
            "alarm_id": deleteId
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setAlarmDeleteResponse(true);
            } else {
                setAlarmDeleteResponse(false);
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        });
    };

    return (
        <div className='row'>
            <div className='col-xl-6 col-lg-7 col-md-8 col-sm-12 col-12'>
                <div className='e-border-1px-platinum-light py-4 ps-2 pe-2 e-bg-white e-admin-box-shadow e-border-radius-16'>

                    <p className="ps-3 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-28px mb-0 ">
                        Send reminders
                    </p>
                    <div className={`${styles.e_schedule}  ${startDateToggle === true || endDateToggle === true ? 'h-422px overflow-y-hidden ' : 'h-422px overflow-y-auto '} ${menuOpen ? 'overflow-hidden' : ''} ps-3 pt-3 pe-2 `}>
                        <div >
                            <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                Sent reminders and notify goals
                            </p>
                            {/* title */}
                            {courseLoading ?
                                <Loader className="h-30vh" />  //loader
                                :
                                <div className='pt-3 pe-2'>
                                    {/* Title  */}
                                    <div ref={(ref) => errorRef.current['title'] = ref}>
                                        <CustomTextInput label="Title (English)"
                                            placeholder="Complete the course now"
                                            type="text"
                                            require
                                            value={title.en}
                                            error={titleError.en}
                                            className="w-100"
                                            labelStyle="e-font-weight-500 e-text-raisin-black"
                                            handleChange={(e) => _handleChangeTitle("en", e)} />

                                        {/* Title arabic */}
                                        <CustomTextInput label="Title (Arabic)"
                                            placeholder="Complete the course now"
                                            type="text"
                                            require
                                            value={title.ar}
                                            error={titleError.ar}
                                            className="w-100"
                                            labelStyle="e-font-weight-500 e-text-raisin-black"
                                            handleChange={(e) => _handleChangeTitle("ar", e)} />
                                    </div>

                                    {/* content */}
                                    <div ref={(ref) => errorRef.current['content'] = ref}>
                                        <TextArea label="Content (English)"
                                            placeholder="Enter your text..."
                                            type="text"
                                            rows="3"
                                            require
                                            value={content.en}
                                            error={contentError.en}
                                            className=" px-3 padding-12px-tb w-100"
                                            handleChange={(e) => _handleChangeContent("en", e)} />

                                        {/* Content arabic */}
                                        <TextArea label="Content (Arabic)"
                                            placeholder="Enter your text..."
                                            type="text"
                                            rows="3"
                                            require
                                            value={content.ar}
                                            error={contentError.ar}
                                            className=" px-3 padding-12px-tb w-100"
                                            handleChange={(e) => _handleChangeContent("ar", e)} />
                                    </div>

                                    {/* Image upload */}
                                    <div ref={(ref) => errorRef.current['image'] = ref}>
                                        <CustomFileUpload label="Upload image"
                                            placeholder="Drop your image here"
                                            require
                                            file={image}
                                            fileType={["JPG", "PNG", "JPEG"]}
                                            error={imageError}
                                            loader={imageLoader}
                                            handleChange={_uploadedImage} />
                                        {/* change thumbnail button */}
                                        {image !== null &&
                                            <div className='mb-2 margin-neg-20px-top'>
                                                <TextButton name="Change thumbnail"
                                                    onPress={() => setImage(null)} />
                                            </div>
                                        }
                                    </div>

                                    {/* start and end date selector */}
                                    <div className='row'>
                                        <div ref={(ref) => errorRef.current['start-date'] = ref} className='col-6 pe-2'>
                                            <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                                Start date<span className='e-text-lust'>*</span>
                                            </p>
                                            {/* Pick date  */}
                                            <div className='pt-2 h-70px position-relative'>
                                                <div className={`${startDateToggle ? 'e-border-1px-indigo' : 'e-border-1px-platinum-light'} e-display-flex gap-0-4px e-border-radius-6 padding-14px-tb px-2 cursor-pointer`}
                                                    onClick={_handleStartDateToggle}>
                                                    <img src={require("../../../../Assets/Image/CountryAdmin/Notification/calendar-icon.svg").default}
                                                        draggable={false}
                                                        alt='calender-icon' />
                                                    {selectedStartDate !== null ?
                                                        <>
                                                            <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-16px'>
                                                                {format(selectedStartDate, 'PP')}
                                                            </p>

                                                        </>
                                                        :
                                                        <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                                                            Choose start date
                                                        </p>
                                                    }

                                                </div>
                                                <p className='mb-0 e-error-message e-alt-font-noto-sans e-font-12 line-height-12px padding-2-top'>
                                                    {startDateError}
                                                </p>

                                                {/* close icon */}
                                                {selectedStartDate !== null &&
                                                    <Icon icon="close-icon"
                                                        className='position-absolute top-20px right-8px cursor-pointer'
                                                        size="20px"
                                                        onPress={_handleClearStartDate} />
                                                }

                                                {/* date picker */}
                                                {startDateToggle &&
                                                    <>
                                                        <OverLay className="e-bg-overlay" isOpen={startDateToggle} onClose={_handleStartDateToggle} />
                                                        <CustomDatePicker selected={selectedStartDate}
                                                            type="alarm"
                                                            className="bottom-8px"
                                                            onSelect={_handleSelectedStartDate} />

                                                    </>
                                                }

                                            </div>
                                        </div>

                                        {/* end date selector */}
                                        <div ref={(ref) => errorRef.current['end-date'] = ref} className='col-6 ps-2'>
                                            <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                                End date<span className='e-text-lust'>*</span>
                                            </p>
                                            <div className='pt-2 h-70px position-relative'>
                                                <div className={`${endDateToggle ? 'e-border-1px-indigo' : 'e-border-1px-platinum-light'} ${selectedStartDate === null ? 'e-disable' : ''} e-display-flex gap-0-4px e-border-radius-6 padding-14px-tb px-2 cursor-pointer`}
                                                    onClick={_handleEndDateToggle}>
                                                    <img src={require("../../../../Assets/Image/CountryAdmin/Notification/calendar-icon.svg").default}
                                                        draggable={false}
                                                        alt='calender-icon' />
                                                    {selectedEndDate !== null ?
                                                        <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-16px'>
                                                            {format(selectedEndDate, 'PP')}
                                                        </p>
                                                        :
                                                        <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                                                            Choose end date
                                                        </p>
                                                    }
                                                </div>
                                                <p className='mb-0 e-error-message e-alt-font-noto-sans e-font-12 line-height-12px padding-2-top'>
                                                    {endDateError}
                                                </p>

                                                {/* clear icon */}
                                                {selectedEndDate !== null &&
                                                    <Icon icon="close-icon"
                                                        className='position-absolute top-20px right-8px cursor-pointer'
                                                        size="20px"
                                                        onPress={_handleClearEndDate} />
                                                }

                                                {/* date picker */}
                                                {endDateToggle &&
                                                    <>
                                                        <OverLay className="e-bg-overlay" isOpen={endDateToggle} onClose={_handleEndDateToggle} />
                                                        <CustomDatePicker selected={selectedEndDate}
                                                            className='right-0px bottom-8px'
                                                            type="alarm"
                                                            minDate={selectedStartDate}
                                                            onSelect={_handleSelectedEndDate} />
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    {/* select linked resource */}
                                    <div ref={(ref) => errorRef.current['resource'] = ref}>
                                        <CustomSelectBox label="Select linked resources"
                                            value={selectedResource}
                                            options={courseData}
                                            error={selectedResourceError}
                                            require
                                            menuIsOpen={menuOpen}
                                            menuPortalTarget={document.body}
                                            className="e-modal-select"
                                            isSearchable={true}
                                            onMenuOpen={() => setMenuOpen(true)}
                                            onMenuClose={() => setMenuOpen(false)}
                                            selectChange={(id) => _handleSelectLinkedResource(id)} />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {/* send button */}
                    <div className='pt-4 mt-2 px-3'>
                        <PrimaryButton name="Configure alarm"
                            className="w-100"
                            status={createLoader}
                            onPress={_handleSend} />
                    </div>
                </div>
            </div>

            {/* Alarm notification history */}
            <div ref={cardRef} className='pt-4'>
                <h4 className='mb-0 e-text-dark-jungle-green e-primary-font-playfair e-font-18 e-font-weight-500 line-height-28px'>
                    Alarm notification scheduled
                </h4>

                {historyLoading ?
                    <Loader className="h-30vh" />  //loader
                    :
                    <>
                        {alarmHistory.data.length !== 0 ?
                            <div className='pt-2'>
                                {alarmHistory.data.map((data, key) => (
                                    <ScheduledNotifications key={key}
                                        data={data}
                                        type={2}
                                        handleDelete={_handleDeleteAlarm} />
                                ))}

                                {/* pagination */}
                                {totalPageCount > 1 &&
                                    <div className="d-flex justify-content-center e-gap-0-16 mt-4 pt-3">
                                        <Pagination itemsPerPage={ITEMS_PER_PAGE}
                                            totalPage={totalPageCount}
                                            showFirstLastPages={true}
                                            onChange={_handlePagination} />
                                    </div>
                                }
                            </div>
                            :
                            <div className='min-h-30vh pt-5'>
                                {/* empty screen */}
                                <EmptyScreen message="No alarms found" />
                            </div>
                        }
                    </>
                }
            </div>

            {/* delete modal */}
            {deleteId !== null &&
                <ConfirmDeleteModal label="alarm"
                    deleteId={deleteId}
                    response={alarmDeleteResponse}
                    delete={() => _deleteAlarm()}
                    handleClose={() => setDeleteId(null)}
                    success={() => {
                        _getAlarmHistory();
                        setHistoryLoading(true);
                    }} />
            }
        </div>
    )
}

export default memo(ConfigureAlarm);