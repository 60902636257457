/*
 *   File : text-area.js
 *   Author : https://evoqins.com
 *   Description : Text area component
 *   Integrations : NA
 *   Version : 1.0.0
*/


import React, { memo } from 'react';

// Custom style
import styles from '../../Style/Component/input.module.scss';

const TextArea = (props) => {

    // handle change
    const _handleChange = (e) => {
        let value = e.target.value;
        props.handleChange(value);
    };

    return (
        <div className={`${styles.e_textarea_wrap} ${props.height ? props.height : "h-144px"} `}>
            {props.label &&
                <label className={`${props.labelStyle} e-text-rich-black e-alt-font-noto-sans e-font-weight-500 line-height-18px e-font-14 pb-2`}>
                    {props.label}
                    {props.require &&
                        <span className='e-text-lust'>*</span>
                    }
                </label>
            }
            <div>
                <textarea id={props.id}
                    value={props.value}
                    type={props.type}
                    autoFocus={props.autoFocus}
                    className={`${props.className} e-border-1px-platinum-light e-border-radius-8 shadow-none resize-none`}
                    placeholder={props.placeholder}
                    required={props.required}
                    rows={props.rows}
                    readOnly={props.readOnly}
                    cols={props.cols}
                    onChange={(e) => _handleChange(e)}
                />
                {props.error &&
                    <p className="mb-0 e-error-message e-alt-font-noto-sans e-font-12 line-height-12px margin-neg-2px-top">
                        {props.error}
                    </p>
                }
            </div>
        </div>
    );
}

TextArea.defaultProps = {
    handleChange: () => { },
    className: null,
    error: "",
}

export default memo(TextArea);


