/*
 *   File : login.js
 *   Author : https://evoqins.com
 *   Description : Login page
 *   Integrations : js-cookie,react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

//Custom components
import { CustomPasswordInput, CustomTextInput, OTPInput } from '../../Component/FormElements';
import { PrimaryButton } from '../../Component/Buttons';
import { APIService } from "../../Service"

const Login = () => {

    const inputRef = useRef(null);

    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [loading, setLoading] = useState(false);
    const [loginStep, setLoginStep] = useState(1);

    const [otpValue, setOtpValue] = useState("");
    const [focusedInput, setFocusedInput] = useState(2);
    const [confirmOTP, setConfirmOTP] = useState([]);
    const [otpError, setOtpError] = useState("");

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    }, []);

    useEffect(() => {
        setNameError("");
    }, [name]);

    useEffect(() => {
        setPasswordError("");
    }, [password]);

    useEffect(() => {
        setOtpError("");
    }, [otpValue]);

    //handle login
    const _handleLogin = () => {
        let is_valid = true;
        if (name.trim().length === 0) {
            setNameError("Username cannot be empty");
            is_valid = false;
        }
        if (password.trim().length === 0) {
            setPasswordError("Password is required");
            is_valid = false;
        }

        if (is_valid === true) {
            _login();
        }
    };

    // handle OTP 
    const _handleOTP = (e) => {
        if (loading !== true) {
            const input_value = e.target.value;
            if (input_value.length <= 4) {
                setOtpValue(input_value);
                const characters = [];

                for (let i = 0; i < input_value.length; i++) {
                    const character = input_value.charAt(i);
                    characters.push(character);
                }
                setConfirmOTP(characters)
            }
        }
    };

    // handle resend OTP
    const _handleResendOTP = () => {
        setOtpValue("");
        setConfirmOTP([]);

        _login();
    };

    // handle back to login 
    const _handleBack = () => {
        setOtpValue("");
        setConfirmOTP([]);
        setLoginStep(1);
    };

    // handle submit OTP 
    const _handleSubmitOTP = () => {
        if (otpValue.length < 4) {
            setOtpError("Enter received OTP");
        }

        if (otpValue.length === 4) {
            _verifyOTP();
        }
    };

    // API - login 
    const _login = () => {
        setLoading(true);
        let is_post = true;
        let url = 'auth/login';
        let data = {
            "username": name,
            "password": password
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setLoginStep(2);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setLoading(false);
        });
    };

    // API - Verify OTP 
    const _verifyOTP = () => {
        let is_post = true;
        let url = 'auth/verify-otp';
        let data = {
            "username": name,
            "otp": otpValue,
            "type": "LOGIN"
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                setOtpValue("");
                setConfirmOTP([]);

                Cookies.set('access_token', response.data.access_token, { domain: process.env.REACT_APP_DOMAIN, path: '/' });
                Cookies.set("admin_type", response.data.admin_type.id, { domain: process.env.REACT_APP_DOMAIN, path: '/' });
                window.location.href = "/";
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        });
    };

    return (
        <>
            <div className=' e-bg-indigo padding-32px-top'>
                <div className='container '>
                    <img src={require("../../Assets/jotun-icon.svg").default}
                        draggable={false}
                        alt='jotun-icon' />

                    {/* Login section */}
                    <div className='row padding-40px-top padding-200px-bottom'>
                        <div className='offset-xl-4 offset-lg-3 offset-md-2 offset-sm-1 col-xl-4 col-lg-6 col-md-8 col-sm-10 '>
                            <div className='p-4 e-bg-white e-border-radius-16'>
                                {loginStep === 1 ?
                                    // first step
                                    <>
                                        <h3 className='mb-4 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                                            Admin Login
                                        </h3>

                                        {/* user name */}
                                        <CustomTextInput label="Username"
                                            placeholder="username"
                                            type="text"
                                            require
                                            value={name}
                                            error={nameError}
                                            autoFocus={true}
                                            className="w-100"
                                            labelStyle="e-font-weight-500"
                                            onKeyPress={_handleLogin}
                                            handleChange={(e) => setName(e)} />

                                        {/* password */}
                                        <CustomPasswordInput label="Password"
                                            placeholder="********"
                                            require
                                            value={password}
                                            error={passwordError}
                                            className="w-100"
                                            labelStyle="e-font-weight-500"
                                            handleChange={(e) => setPassword(e)}
                                            onKeyPress={_handleLogin} />

                                        {/* forgot pw button */}
                                        <div className='text-end'>
                                            <span className='cursor-pointer text-decoration-hover-underline mb-0 text-end e-font-14 e-alt-font-noto-sans line-height-22px e-text-rich-black e-font-weight-500 letter-spacing-02em'
                                                onClick={() => navigate("/forgot-password")}>
                                                Forgot password?
                                            </span>
                                        </div>

                                        {/* login button */}
                                        <PrimaryButton name="Login"
                                            className="e-bg-indigo"
                                            status={loading}
                                            onPress={_handleLogin} />
                                    </>
                                    :

                                    // otp step
                                    <>
                                        <img src={require("../../Assets/Image/left-back-arrow.svg").default}
                                            draggable={false}
                                            className='cursor-pointer'
                                            alt='back-arrow'
                                            onClick={_handleBack} />
                                        <h3 className='padding-18px-top mb-4 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                                            User authentication
                                        </h3>
                                        {/* OTP Input */}
                                        <OTPInput label="Enter the otp sent to the registered email"
                                            ref={inputRef}
                                            id="otp"
                                            value={otpValue}
                                            spellcheck="false"
                                            error={otpError}
                                            focused_input={focusedInput}
                                            confirm_pin={confirmOTP}
                                            onFocus={() => setFocusedInput(2)}
                                            onBlur={() => setFocusedInput(0)}
                                            onKeyPress={_handleSubmitOTP}
                                            onChange={_handleOTP} />
                                        <p className=' mb-0 e-text-rich-black e-alt-font-noto-sans e-font-weight-500 line-height-18px e-font-14'>
                                            Don’t receive the otp?
                                            <span className='ps-1 e-text-lust cursor-pointer text-decoration-hover-underline'
                                                onClick={_handleResendOTP} >
                                                Resend now
                                            </span>
                                        </p>

                                        <div className='pt-4'>
                                            <PrimaryButton name="Confirm"
                                                className="e-bg-indigo"
                                                onPress={_handleSubmitOTP} />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Login;