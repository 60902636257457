/*
 *   File : secondary.js
 *   Author : https://evoqins.com
 *   Description : CTA component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo } from 'react';

// Assets 
import Loader from "../../Assets/loader-dark.gif";

const SecondaryButton = (props) => {
    return (
        <button className={`${props.className} ${props.status ? `px-4 padding-12px-tb` : `px-3 padding-12px-tb`} e-border-radius-12 e-font-14 e-bg-transparent e-border-1px-platinum-light e-alt-font-noto-sans e-text-raisin-black e-font-weight-500 line-height-20px`}
            data-bs-toggle={props.toggle}
            data-bs-target={props.target}
            data-bs-cancel={props.cancel}
            data-bs-dismiss={props.dismiss}
            disabled={props.disabled}
            id={props.id}
            onClick={(e) => props.onPress(e)}>

            {props.status ?
                <img src={Loader}
                    className={`${props.loaderClass} w-18px h-18px`}
                    alt='loader' />
                :
                <span>{props.name}</span>
            }
        </button>
    )
}

SecondaryButton.defaultProps = {
    onPress: () => { },
    className: null,
    id: null,
    name: null,
    icon: null
}

export default memo(SecondaryButton);