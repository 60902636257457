/*
 *   File : add-milestone.js
 *   Author : https://evoqins.com
 *   Description : Add milestone modal 
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useState } from 'react';

// Custom component
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { CustomSelectBox, CustomTextInput } from '../FormElements';
import { Icon } from '../Icon';
import { APIService } from '../../Service';

const MILESTONE_TYPE = [
    {
        value: 1,
        label: 'Birthday',
    },
    {
        value: 2,
        label: 'Anniversary'
    },
    {
        value: 3,
        label: 'Gift'
    },
];

const AddMilestoneModal = (props) => {

    const [rewardPoint, setRewardPoint] = useState('');
    const [rewardPointError, setRewardPointError] = useState("");

    const [selected, setSelected] = useState(null);
    const [toggleSuccess, setToggleSuccess] = useState(false);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.award.length !== 0) {
            setSelected(props.award[0]);
        }
    }, [props.award]);

    useEffect(() => {
        setRewardPointError("");
    }, [rewardPoint]);

    // handle close modal
    const _handleCloseModal = () => {
        var button = document.getElementById("add-milestone-close");
        button.click();

        setRewardPointError("");
        setRewardPoint("");
        setToggleSuccess(false);
        props.handleClose();
    };

    // handle submit
    const _handleSubmit = () => {
        let is_valid = true;
        if (rewardPoint.length === 0) {
            setRewardPointError("Reward point is required");
            is_valid = false;
        }

        if (is_valid === true) {
            setLoading(true);
            _addAward();
        }
    };

    // API - Add award
    const _addAward = () => {
        let is_post = true;
        let url = "CA/sales-specialist/add-award";
        let data = {
            "customer_id": props.customerId,
            "award_id": selected.value,
            "points": rewardPoint
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setToggleSuccess(true);
            } else {
                setToggleSuccess(false);
            }
            setLoading(false);
        });
    };

    return (
        <div className="modal fade" id="add-milestone-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="addMilestoneModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered e-border-radius-24">
                <div className="modal-content e-border-radius-12 w-480px mx-auto">
                    <img src={require("../../Assets/Image/CountryAdmin/close-icon.svg").default}
                        draggable={false}
                        id='add-milestone-close'
                        className='d-none '
                        alt='close'
                        data-bs-dismiss="modal" />
                    {!toggleSuccess ?
                        <>
                            <div className="modal-header px-4 pt-4 pb-2 border-0">
                                <h4 className="mb-0 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px">
                                    Add awards & rewards
                                </h4>
                                <Icon icon="close-icon"
                                    className='cursor-pointer'
                                    size="24px"
                                    onPress={_handleCloseModal} />

                            </div>
                            <div className="modal-body px-4 pt-0 pb-4">
                                <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                    Add a new item by selecting the type of award or reward.
                                </p>
                                <div className="padding-20px-top">
                                    {/* Milestone type */}
                                    <div className='position-relative'>
                                        <CustomSelectBox label="Select type"
                                            value={selected}
                                            options={props.award}
                                            className="e-modal-select"
                                            isSearchable={false}
                                            selectChange={(id) => { setSelected(id); }}
                                        />
                                    </div>

                                    {/* Reward point */}
                                    <CustomTextInput label="Reward point"
                                        placeholder="1500"
                                        type="number"
                                        require
                                        value={rewardPoint}
                                        error={rewardPointError}
                                        className="w-100"
                                        labelStyle="e-font-weight-500 e-text-raisin-black padding-20px-top"
                                        handleChange={(e) => setRewardPoint(e)}
                                        onKeyPress={_handleSubmit} />

                                    {/* Custom button  */}
                                    <div className='row row-cols-2 padding-100px-top'>
                                        <div className='col'>
                                            <SecondaryButton name="Cancel"
                                                className="w-100"
                                                onPress={_handleCloseModal} />
                                        </div>
                                        <div className='col'>
                                            <PrimaryButton name="Submit"
                                                className="w-100"
                                                status={loading}
                                                onPress={_handleSubmit} />
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </>
                        :
                        <>
                            <div className="modal-header px-4 pt-4 pb-2 border-0 align-items-start">
                                <img src={require("../../Assets/Image/CountryAdmin/success-icon.svg").default}
                                    draggable={false}
                                    alt='icon' />
                                <Icon icon="close-icon"
                                    className='cursor-pointer'
                                    size="24px"
                                    onPress={_handleCloseModal} />
                                <button type="button" className="btn-close shadow-none d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body px-4 pb-4 pt-2">
                                <h6 className='mb-2 e-primary-font-playfair e-font-18 e-text-raisin-black e-font-weight-500 line-height-28px'>
                                    Reward added successfully
                                </h6>
                                <p className='mb-0 e-text-davys-gray e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                    The reward has been added successfully. You can view it in the rewards section.
                                </p>
                                <div className='pt-4 mt-2'>
                                    <PrimaryButton name="Ok"
                                        className="w-100"
                                        onPress={_handleCloseModal} />
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default memo(AddMilestoneModal);