/*
 *   File : shop.js
 *   Author : https://evoqins.com
 *   Description : Shops and sales component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useRef, useState } from 'react';

//Custom component
import { DataTableComponent } from '../../../Component/DataTable';
import { SummaryCard } from '../../../Component/Cards';
import { APIService } from '../../../Service';
import { EmptyScreen } from '../../../Component/EmptyComponent';
import { Loader } from '../../../Component/Others';

const ITEMS_PER_PAGE = 15;

const Shop = (props) => {

    const tableRef = useRef(null);

    const [shopData, setShopData] = useState({});
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        _getShopData();
    }, [currentPage]);

    // handle page change of table
    const _handlePageChange = (page_num) => {
        setCurrentPage(page_num);

        if (tableRef.current) {
            tableRef.current.scrollIntoView({ behavior: 'smooth', block: "start" });
        }
    };

    const TABLE_COLUMNS = [
        {
            name: 'Shop name',
            selector: row => row.shop,
            width: "260px"
        },
        {
            name: 'Sales Specialist',
            selector: row => row.sale_specialist,
            width: "160px"
        },
        {
            name: 'Total sales',
            selector: row => row.total_sales,
            width: "160px"
        },
        {
            name: 'Premium sales',
            selector: row => row.premium,
            width: "160px"
        },
        {
            name: 'Painters visited',
            selector: row => row.painter_visited === null ?
                "-"
                :
                row.painter_visited,
            width: "160px"

        },

    ];

    // API - Get shop and sale data
    const _getShopData = () => {
        let is_post = true;
        let url = 'country-admin/shops-sale';
        let data = {
            "admin_id": props.adminId,
            "page_num": currentPage,
            "limit": ITEMS_PER_PAGE
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setShopData(response.data);
            } else { }
            setLoading(false);
        });
    };

    return (
        <>
            {loading ?
                <div className='margin-20px-top e-bg-white e-admin-box-shadow py-4 e-border-radius-16 '>
                    {/* loader */}
                    <Loader />
                </div>
                :
                <>
                    {/* Summary  */}
                    <div className='e-admin-box-shadow p-4 e-bg-white e-border-radius-16'>
                        <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                            Summary
                        </h4>
                        <div className='row row-gap-3 row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-3 row-cols-2 padding-10px-top'>

                            <div className='col'>
                                <SummaryCard title="Total shops"
                                    quantity={shopData.total_store} />
                            </div>

                            <div className='col'>
                                <SummaryCard title="Total sales"
                                    quantity={shopData.total_sales} />
                            </div>
                            <div className='col'>
                                <SummaryCard title="Premium sales"
                                    quantity={shopData.premium_sales} />
                            </div>
                            {/* <div className='col'>
                        <SummaryCard title="Painters visited"
                            quantity="10" />
                    </div> */}
                        </div>
                    </div>

                    {/* Shops and sales table */}
                    <div ref={tableRef} className='margin-20px-top e-bg-white e-admin-box-shadow py-4 e-border-radius-16 '>
                        <div className='px-4'>
                            <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-600 line-height-28px'>
                                Shops and sales
                            </h4>
                        </div>

                        {/* Data table */}
                        {shopData.data.length !== 0 ?
                            <div className='e-table'>
                                <DataTableComponent columns={TABLE_COLUMNS}
                                    pagination={true}
                                    data={shopData.data}
                                    paginationServer={true}
                                    paginationTotalRows={shopData.total_count}
                                    onChangePage={_handlePageChange} />
                            </div>
                            :
                            // empty screen
                            <div className='h-100vh pt-5 mt-5'>
                                <EmptyScreen message="No data found" />
                            </div>
                        }

                    </div>
                </>
            }
        </>
    )
}

export default Shop;