/*
 *   File : icon.js
 *   Author : https://evoqins.com
 *   Description : Icon component
 *   Integrations : icomoon-react
 *   Version : 1.0.0
*/

import { memo } from "react";

import IcomoonReact from "icomoon-react";
const iconSet = require("../../Assets/Font/selection.json");

const Icon = ({ onPress, ...props }) =>
    <span onClick={onPress}>
        <IcomoonReact iconSet={iconSet} {...props} />
    </span>

export default memo(Icon);