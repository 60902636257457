/*
 *   File : checkbox.js
 *   Author : https://evoqins.com
 *   Description : Custom checkbox
 *   Integrations : NA
 *   Version : 1.0.0
*/


import React, { memo } from 'react';

const CustomCheckbox = (props) => {

    //input handle change
    const _handleChange = (e) => {
        let value = e.target.checked;
        props.handleChange(value);
    };

    return (
        props.type !== 2 ?
            (
                <div className='text-center'>
                    <label className={`${props.labelStyle} checkbox-container e-text-rich-black e-alt-font-noto-sans e-font-weight-500 line-height-18px e-font-14 `}>
                        {props.label}
                        {props.require && props.label &&
                            <span className='e-text-lust'>*</span>
                        }
                        <input
                            tabIndex={props.tabIndex}
                            type="checkbox"
                            checked={props.checked}
                            disabled={props.disabled}
                            required={props.required}
                            className={`${props.className} `}
                            onChange={_handleChange} />
                        <span className={`${props.label ? 'top-40px ' : 'top-12px'} custom-checkbox`}></span>
                    </label>
                </div >
            ) : (
                <div className='form-check d-flex align-items-center gap-1 mb-0 h-auto'>
                    <input
                        id='checkbox-outline'
                        tabIndex={props.tabIndex}
                        type="checkbox"
                        checked={props.checked}
                        disabled={props.disabled}
                        required={props.required}
                        className={`${props.className}  form-check-input shadow-none`}
                        onChange={_handleChange} />
                    <label className={`${props.labelStyle} mt-1 e-text-raisin-black e-alt-font-noto-sans e-font-weight-400 line-height-20px e-font-14 `}
                        htmlFor='checkbox-outline'>
                        {props.label}
                    </label>
                </div >
            )
    )
}

export default memo(CustomCheckbox);