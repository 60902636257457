/*
 *   File : reward-point.js
 *   Author : https://evoqins.com
 *   Description : Reward Point component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useRef, useState } from 'react';


//Custom component
import { DataTableComponent } from '../../../Component/DataTable';
import { APIService } from '../../../Service';
import { EmptyScreen } from '../../../Component/EmptyComponent';
import { Loader } from '../../../Component/Others';

const ITEMS_PER_PAGE = 15;

const RewardPoint = () => {

    const tableRef = useRef(null);

    const [salesData, setSalesData] = useState({});
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        _getSalesData();
    }, [currentPage]);

    // handle page change of table
    const _handlePageChange = (page_num) => {
        setCurrentPage(page_num);

        if (tableRef.current) {
            tableRef.current.scrollIntoView({ behavior: 'smooth', block: "start" });
        }
    };

    // sales and reward table
    const TABLE_COLUMNS = [
        {
            name: 'Total unit sales',
            selector: row => row.total_unit,
            width: "160px"
        },
        {
            name: 'Category',
            selector: row => row.category,
            width: "160px"
        },
        {
            name: 'Linked products',
            selector: row => row.product,
            width: "280px"
        },
        {
            name: "Reward points",
            selector: row => row.point === null ?
                "-"
                :
                row.point
        }
    ];

    // API - Get sales and reward data
    const _getSalesData = () => {
        let is_post = true;
        let url = 'country-admin/sales';
        let data = {
            "page_num": currentPage,
            "limit": ITEMS_PER_PAGE
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setSalesData(response.data);
            } else { }
            setLoading(false);
        });
    };

    return (
        <>
            {/* Reward points/ sales table */}
            <div ref={tableRef} className='e-bg-white e-admin-box-shadow py-4 e-border-radius-16 '>
                <div className='px-4'>
                    <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-600 line-height-28px'>
                        Reward points/ sales
                    </h4>
                </div>

                {loading ?
                    // loader 
                    <Loader />
                    :
                    <>
                        {/* Data table */}
                        {salesData.data.length !== 0 ?
                            <div className='e-table'>
                                <DataTableComponent columns={TABLE_COLUMNS}
                                    pagination={true}
                                    data={salesData.data}
                                    paginationServer={true}
                                    paginationTotalRows={salesData.total_count}
                                    onChangePage={_handlePageChange} />
                            </div>
                            :
                            // empty screen
                            <div className='h-100vh pt-5 mt-5'>
                                <EmptyScreen message="No data found" />
                            </div>
                        }
                    </>
                }
            </div>
        </>
    )
}

export default RewardPoint;