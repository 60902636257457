/*
 *   File : custom-select.js
 *   Author : https://evoqins.com
 *   Description : Custom select component
 *   Integrations : react-select
 *   Version : 1.0.0
*/

import React, { memo } from 'react';
import Select from 'react-select';

const CustomSelectBox = (props) => {

    // handle select value 
    const valueChange = (e) => {
        props.selectChange(e);
    };


    return (
        <div>
            {props.label &&
                <label className={`${props.labelClass} padding-6px-bottom e-text-black e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-20px`}>
                    {props.label}
                    {!props.optional && <span className='e-text-lust'>*</span>}
                </label>
            }
            <div className={props.type === 1 ? '' : 'h-64px'}>
                <Select isSearchable={props.isSearchable}
                    isClearable={props.isClearable}
                    className={`e-select-box ${props.className}`}
                    isDisabled={props.isDisabled}
                    options={props.options}
                    value={props.value ? props.value : ""}
                    isMulti={props.selectType}
                    placeholder={props.placeholder}
                    defaultValue={props.defaultValue}
                    menuIsOpen={props.menuIsOpen}
                    styles={{
                        dropdownIndicator: (base, state) => ({
                            ...base,
                            transition: "all .2s ease",
                            transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
                        }),
                        control: (styles, state) => ({
                            ...styles,
                            borderColor: state.menuIsOpen ? '#213366' : props.borderColor ? props.borderColor : '#E3E5E5',
                            boxShadow: state.menuIsOpen ? '#213366' : 'none',
                            '&:hover': {
                                borderColor: '##213366',
                            },
                            borderRadius: props.type === 1 ? "0.75rem" : props.type === 2 ? "0.25rem" : "0.5rem",
                            cursor: "pointer",
                            background: "transparent",
                            width: props.width ? props.width : ""
                        }),
                        menuList: (styles) => ({
                            ...styles,
                            maxHeight: '180px',
                            '&::-webkit-scrollbar-thumb': {
                                background: "#213366",
                                borderRadius: "3rem",
                            },

                            '&::-webkit-scrollbar': {
                                width: "0.125rem",
                                display: "block",
                            }
                        }),
                        menuPortal: (base) => ({ ...base, zIndex: 9999 })
                    }}

                    menuPortalTarget={props.menuPortalTarget}
                    onMenuOpen={props.onMenuOpen}
                    onMenuClose={props.onMenuClose}
                    onChange={(e) => { valueChange(e) }}

                    {...props} />

                {props.error &&
                    <p className='mb-0 e-error-message e-alt-font-noto-sans e-font-12 line-height-12px pt-1'>
                        {props.error}
                    </p>
                }
            </div>
        </div>
    )
}

export default memo(CustomSelectBox);