/*
 *   File : resource.js
 *   Author : https://evoqins.com
 *   Description : Resource card component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';

//Custom component
import { TextButton } from '../Buttons';

const ResourceCard = (props) => {

    const navigate = useNavigate();

    // handle navigate course detail
    const _handleNavigateDetail = () => {
        navigate("course-detail", {
            state: {
                course_id: props.course.course_id
            }
        });
    };

    return (
        <div className='e-admin-box-shadow e-border-radius-16 py-3 ps-3 pe-4 e-bg-white cursor-pointer'
            onClick={_handleNavigateDetail}>
            <div className='d-flex gap-0-16'>
                <div className='position-relative'>
                    <img src={props.course.image}
                        draggable={false}
                        className='e-border-radius-8'
                        height={154}
                        width={146}
                        alt='family' />
                    {/* <span className='top-4px left-4px position-absolute e-bg-spanish-yellow px-3 py-1 e-border-radius-16 left-0 e-alt-font-noto-sans e-text-white e-font-12 e-font-weight-400 line-height-16px'>
                        {props.course.tag}
                    </span> */}
                </div>
                <div className='d-flex flex-column justify-content-between'>
                    <div>
                        <h6 className='mb-2 e-primary-font-playfair e-text-charleston-green e-font-16 e-font-weight-600 line-height-22px e-ellipse'>
                            {props.course.title}
                        </h6>
                        <p className='mb-2 e-alt-font-noto-sans e-text-davys-grey e-font-14 e-font-weight-400 line-height-22px e-ellipse-3-line'>
                            {props.course.description}
                        </p>
                    </div>

                    <p className='e-inline-flex mb-0 e-alt-font-noto-sans e-text-davys-grey e-font-12 e-font-weight-500 line-height-14px'>
                        {props.course.no_of_modules} {props.course.no_of_modules === 1 ? 'Module' : 'Modules'}
                        <span className='px-1 e-font-16 '>•</span>
                        {props.course.course_duration}
                    </p>
                </div>
            </div>
            {/* Button view detail page */}
            <div className='text-end margin-top-neg-22px'>
                <TextButton name="View details" />
            </div>
        </div>
    )
}

export default memo(ResourceCard);