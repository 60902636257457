/*
 *   File : left-navigation.js
 *   Author : https://evoqins.com
 *   Description : Left navigation component
 *   Integrations : NA
 *   Version : 1.0.0
*/


import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

//Custom style
import styles from "../../Style/Component/left.module.scss";

//Custom component
import { Icon } from '../Icon';

const LEFT_ITEMS = [
    {
        image_active: "home-inactive-icon",
        image_inactive: "home-icon",
        label: "Dashboard",
        path: "/",
        child_path: [],
    },
    {
        image_active: "admin-inactive-icon",
        image_inactive: "admin-icon",
        label: "Country admins",
        path: "/country-admin",
        child_path: ["/country-admin"],
    },
    {
        image_active: "resource-active",
        image_inactive: "resource",
        label: "Manage resource",
        path: "/manage-resource",
        child_path: ["/manage-resource"],
    },
    {
        image_active: "news-active",
        image_inactive: "news",
        label: "Manage News",
        path: "/manage-news",
        child_path: [],
    },
];

const LEFT_ITEMS_COUNTRY = [
    {
        image_active: "home-inactive-icon",
        image_inactive: "home-icon",
        label: "Dashboard",
        path: "/",
        child_path: [],
    },
    {
        image_active: "sales-specialist-active",
        image_inactive: "sales-specialist",
        label: "Sales specialists",
        path: "/sales-specialist",
        child_path: ["/sales-specialist"],

    },
    {
        image_active: "new-request-active",
        image_inactive: "new-request",
        label: "New requests",
        path: "/pending-request",
        child_path: ["/pending-request", "/request-history"],
    },
    {
        image_active: "store-detail-active",
        image_inactive: "store-detail",
        label: "Store details",
        path: "/store-listing",
        child_path: ["/store-listing"],
    },
    {
        image_active: "resource-active",
        image_inactive: "resource",
        label: "Manage resource",
        path: "/manage-resource",
        child_path: ["/manage-resource"],
    },
    {
        image_active: "reward-active",
        image_inactive: "reward",
        label: "Manage reward points",
        path: "/reward-point",
        child_path: [],
    },
    {
        image_active: "notification-active",
        image_inactive: "notification",
        label: "Manage notification",
        path: "/manage-notification",
        child_path: [],
    },
    {
        image_active: "redeem-active",
        image_inactive: "redeem",
        label: "Manage redeem offers",
        path: "/redeem-offer",
        child_path: ["/redemption-history"],
    },
    {
        image_active: "competition-active",
        image_inactive: "competition",
        label: "Manage competition",
        path: "/competition",
        child_path: ["/competition-detail"],
    },
    {
        image_active: "news-active",
        image_inactive: "news",
        label: "Manage News",
        path: "/manage-news",
        child_path: [],
    },
    {
        image_active: "banner-active",
        image_inactive: "banner",
        label: "Manage banners",
        path: "/banner-list",
        child_path: [],
    }
];

const LeftNavigationWidget = (props) => {

    const navigate = useNavigate();
    const location = useLocation();

    const [hover, setHover] = useState(null);

    // handle tab 
    const _handleTabChange = (data) => {
        navigate(data.path);

        if (props.type === "offcanvas") {
            props.handleCloseOffCanvas();
        }
    }

    return (
        <div className='e-bg-white position-sticky top-0 left-0 min-h-100vh transition'>
            <p className='mb-0 text-end pt-3 '>
                {props.token === 'super_admin' ?
                    <span className="e-bg-azureish-white cursor-pointer px-2 py-1 e-border-radius-2px-0-0-2px e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px e-text-black">
                        Super admin
                    </span>
                    :
                    <span className="e-bg-lavender-blush-light cursor-pointer px-2 py-1 e-border-radius-2px-0-0-2px e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px e-text-black">
                        Country admin
                    </span>
                }
            </p>
            <div className='text-center padding-14px-top pb-3'>
                <img src={require("../../Assets/Image/LeftNavigation/jotun.svg").default}
                    draggable={false}
                    className={`${props.toggle === false ? 'w-70px' : ''} cursor-pointer e-jotun-logo transition`}
                    alt='logo'
                    onClick={() => {
                        navigate("/")
                        if (props.type === "offcanvas") {
                            props.handleCloseOffCanvas();
                        }
                    }} />
            </div>
            <div className={`pt-4 pe-3 ps-3 transition max-h-zoom-100vh overflow-scroll ${styles.e_left_nav_scroll} ${!props.toggle && 'text-center'}`}>
                {props.token === 'super_admin' ?
                    <>
                        {/* Navigation list for super admin */}
                        {LEFT_ITEMS.map((data, key) => (
                            <div key={key}>
                                {props.toggle === false ?
                                    <div className='d-flex justify-content-center'>
                                        <div className={`${data.path === location.pathname || data.child_path.some(path => location.pathname.includes(path)) ?
                                            `${styles.e_tab_active_toggle}` : `${styles.e_tab_toggle} `} margin-12px-bottom text-center transition padding-12px-tb px-3 cursor-pointer e-border-radius-8 `}
                                            onClick={() => _handleTabChange(data)}
                                            onMouseOver={() => setHover(data.path)}
                                            onMouseLeave={() => setHover(null)}>
                                            <Icon icon={data.path === location.pathname || data.child_path.some(path => location.pathname.includes(path)) || hover === data.path ?
                                                data.image_active : data.image_inactive}
                                                className='margin-neg-6px-top'
                                                size="20px"
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className={`${data.path === location.pathname || data.child_path.some(path => location.pathname.includes(path)) ?
                                        `${styles.e_tab_active}` : `${styles.e_tab} `} margin-12px-bottom transition cursor-pointer e-display-flex gap-0-8 e-border-radius-8 padding-12px-lr py-2`}
                                        onClick={() => _handleTabChange(data)}
                                        onMouseOver={() => setHover(data.path)}
                                        onMouseLeave={() => setHover(null)} >
                                        <Icon icon={data.path === location.pathname || data.child_path.some(path => location.pathname.includes(path)) || hover === data.path ?
                                            data.image_active : data.image_inactive}
                                            className='margin-neg-6px-top'
                                            size="18px" />

                                        <p className='transition e-text-rich-black-light mb-0 e-alt-font-noto-sans e-font-16 e-font-weight-400 line-height-20px'>
                                            {data.label}
                                        </p>
                                    </div>
                                }
                            </div>
                        ))}
                    </>
                    :
                    <>
                        {/* Navigation list for country admin */}
                        {LEFT_ITEMS_COUNTRY.map((data, key) => (
                            <div key={key}>
                                {props.toggle === false ?
                                    <div className='d-flex justify-content-center'>
                                        <div className={`${data.path === location.pathname || data.child_path.some(path => location.pathname.includes(path)) ?
                                            `${styles.e_tab_active_toggle}` : `${styles.e_tab_toggle} `} margin-12px-bottom text-center transition padding-12px-tb px-3 cursor-pointer e-border-radius-8 `}
                                            onClick={() => _handleTabChange(data)}
                                            onMouseOver={() => setHover(data.path)}
                                            onMouseLeave={() => setHover(null)}>
                                            <Icon icon={data.path === location.pathname || data.child_path.some(path => location.pathname.includes(path)) || hover === data.path ?
                                                data.image_active : data.image_inactive}
                                                className='margin-neg-6px-top'
                                                size="20px"
                                            />
                                        </div>
                                    </div>
                                    :
                                    <div className={`${data.path === location.pathname || data.child_path.some(path => location.pathname.includes(path)) ?
                                        `${styles.e_tab_active}` : `${styles.e_tab} `} margin-12px-bottom transition cursor-pointer d-flex align-items-center gap-0-8 e-border-radius-8 py-2 px-2 `}
                                        onClick={() => _handleTabChange(data)}
                                        onMouseOver={() => setHover(data.path)}
                                        onMouseLeave={() => setHover(null)} >

                                        <Icon icon={data.path === location.pathname || data.child_path.some(path => location.pathname.includes(path)) || hover === data.path ?
                                            data.image_active : data.image_inactive}
                                            className='margin-neg-6px-top'
                                            size="18px" />
                                        <p className='transition e-text-rich-black-light mb-0 e-alt-font-noto-sans e-font-16 e-font-weight-400 line-height-20px w-max-content'>
                                            {data.label}
                                        </p>

                                    </div>

                                }
                            </div>
                        ))}
                    </>
                }
            </div>
        </div>
    )
}

export default LeftNavigationWidget;