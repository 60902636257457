/*
 *   File : manage-news.js
 *   Author : https://evoqins.com
 *   Description : Manage news page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useRef, useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';
import Cookies from "js-cookie";

//custom component
import { CustomTextInput, CustomEditor, CustomFileUpload, CustomSelectBox, TagSelector } from '../../../Component/FormElements';
import { APIService, AWSService } from '../../../Service';
import { PrimaryButton, TextButton } from '../../../Component/Buttons';
import { Loader, OverLay } from '../../../Component/Others';
import { EmptyScreen } from '../../../Component/EmptyComponent';
import { Pagination } from '../../../Component/pagination';
import { ConfirmDeleteModal } from '../../../Component/Modal';

// Style
import styles from "../../../Style/Component/notification.module.scss";

const ITEMS_PER_PAGE = 12;

const TAGS = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

const ManageNews = () => {

    const newsRef = useRef();
    const is_super_admin = Cookies.get("admin_type") === "1" ? true : false;

    const [title, setTitle] = useState({ en: "", ar: "" });
    const [titleError, setTitleError] = useState({ en: "", ar: "" });

    const [tagData, setTagData] = useState([]);
    const [tagDataLoading, setTagDataLoading] = useState(true);
    const [selectedTag, setSelectedTag] = useState(null);
    const [selectedTagError, setSelectedTagError] = useState("");

    const [editor, setEditor] = useState({ en: EditorState.createEmpty(), ar: EditorState.createEmpty() });
    const [editorData, setEditorData] = useState({ en: '', ar: '' });
    const [editorError, setEditorError] = useState({ en: '', ar: '' });

    const [image, setImage] = useState(null);
    const [imageError, setImageError] = useState("");
    const [imageLoader, setImageLoader] = useState(true);
    const [toggleMenu, setToggleMenu] = useState(null);
    const [newsData, setNewsData] = useState({});
    const [loading, setLoading] = useState(true);

    const [totalPageCount, setTotalPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [deleteId, setDeleteId] = useState(null);
    const [deleteResponse, setDeleteResponse] = useState(false);

    const [newsEditData, setNewsEditData] = useState({});
    const [createLoader, setCreateLoader] = useState(true);
    const [menuOpen, setMenuOpen] = useState(false);

    const [selectedCountries, setSelectedCountries] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [countryDataLoading, setCountryDataLoading] = useState(true);

    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [countrySelect, setCountrySelect] = useState(false);

    const [toggleFilter, setToggleFilter] = useState(false);

    const [errorUpdate, setErrorUpdate] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
        _getNewsTag();
        setCreateLoader(false);
        setImageLoader(false);
        setCountryDataLoading(false);

        if (is_super_admin === true) {
            _getCountries();
        }
    }, [is_super_admin]);

    useEffect(() => {
        _getNewsList();
    }, [currentPage, selectedCountry]);

    useEffect(() => {
        setImageError("");
    }, [image]);

    useEffect(() => {
        setSelectedTagError("");
    }, [selectedTag]);

    useEffect(() => {
        if (deleteId !== null) {
            const delete_modal = new Modal(document.getElementById('confirm-delete-modal'));
            delete_modal.show();
        }
    }, [deleteId]);

    useEffect(() => {
        if (Object.keys(newsEditData).length !== 0) {
            setTitle({ en: newsEditData.data.title, ar: newsEditData.data.title_arabic });
            setImage(newsEditData.data.image_url);
            setSelectedTag({ value: newsEditData.data.tag, label: newsEditData.data.tag_name });

            const html_content = htmlToDraft(newsEditData.data.content); //convert html to draft
            const content = ContentState.createFromBlockArray(html_content.contentBlocks);
            const updated_content = EditorState.createWithContent(content);

            const html_content_arabic = htmlToDraft(newsEditData.data.content_arabic); //convert html to draft
            const content_arabic = ContentState.createFromBlockArray(html_content_arabic.contentBlocks);
            const updated_content_arabic = EditorState.createWithContent(content_arabic);

            setEditor({ en: updated_content, ar: updated_content_arabic });

            setEditorData({ en: newsEditData.data.content, ar: newsEditData.data.content_arabic });

            if (is_super_admin) {
                const updated_selected_countries = newsEditData.data.countries.map(country => {
                    return {
                        value: country.value,
                        label: country.label,
                    };
                });

                setSelectedCountries(updated_selected_countries);
            }
        }
    }, [newsEditData]);

    // handle title change 
    const _handleChangeTitle = (lang, value) => {
        setTitle(prev => ({
            ...prev,
            [lang]: value
        }));

        setTitleError(prev => ({
            ...prev,
            [lang]: ""
        }));
    };

    // handle edit editor
    const _handleEditorChange = (lang, data) => {
        setEditor(prev => ({ ...prev, [lang]: data }));
        _getHtmlFormat(lang, data);
        setEditorError(prev => ({ ...prev, [lang]: "" }));
    };

    // convert html format 
    const _getHtmlFormat = (lang, editorState) => {
        const content = editorState.getCurrentContent();
        const raw_content = convertToRaw(content);
        const html_content = draftToHtml(raw_content);

        setEditorData(prev => ({
            ...prev,
            [lang]: html_content
        }));
    };

    // handle upload image
    const _handleUploadImage = (url) => {
        setImageLoader(true);
        _getPresignedUrl(url);
    };

    // handle change image
    const _handleChangeImage = () => {
        setImage(null);
    };

    // handle create news
    const _handleCreateNews = () => {
        let is_valid = true;

        if (title.en.trim().length === 0) {
            setTitleError(prev => ({
                ...prev,
                en: "Title english is required"
            }));
            is_valid = false;
        }

        if (title.ar.trim().length === 0) {
            setTitleError(prev => ({
                ...prev,
                ar: "Title arabic is required"
            }));
            is_valid = false;
        }

        if (editorData.en.length === 0 || _validateEmptyTag(editorData.en, TAGS)) {
            setEditorError(prev => ({
                ...prev,
                en: "English content is required"
            }));
            is_valid = false;
        }

        if (editorData.ar.length === 0 || _validateEmptyTag(editorData.ar, TAGS)) {
            setEditorError(prev => ({
                ...prev,
                ar: "Arabic content is required"
            }));
            is_valid = false;
        }

        if (selectedTag === null) {
            setSelectedTagError("Tag is required");
            is_valid = false;
        }

        if (image === null) {
            setImageError("Upload an image");
            is_valid = false;
        }

        setErrorUpdate(prev => !prev);

        if (is_valid === true) {
            setCreateLoader(true);
            if (newsEditData.type === "edit") {
                _updateNews();
            } else {
                _createNews();
            }
        }
    };

    // handle validate empty tag 
    const _validateEmptyTag = (html_value, tag_value) => {
        let element = document.createElement('div');
        element.innerHTML = html_value;

        for (let tag of tag_value) {
            let elements = element.getElementsByTagName(tag);
            for (let element of elements) {
                let innerHTML = element.innerHTML.trim();
                if (innerHTML === '' || innerHTML === '&nbsp;') {
                    return true;
                }
            }
        }
        return false;
    };

    // handle toggle menu 
    const _handleToggleMenu = (index) => {
        setToggleMenu(index);
    };

    // handle delete news 
    const _handleDeleteNews = (id) => {
        setDeleteId(id)
        setDeleteResponse(false);
    };

    // handle delete news 
    const _handleEditNews = (data, type) => {
        setNewsEditData({ data, type });

        window.scrollTo({
            top: 0, behavior: 'smooth'
        });
    };

    //handle pagination
    const _handlePagination = (new_start_limit) => {
        const selected_page = Math.floor(new_start_limit / ITEMS_PER_PAGE) + 1;
        setCurrentPage(selected_page);
        if (newsRef.current) {
            newsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // remove html tags 
    const _removeTags = (content) => {
        if (content === null || content === '') {
            return false;
        } else {
            content = content.replace(/&nbsp;/g, ' ');
            content = content.replace(/(<([^>]+)>)/ig, '');
            return content;
        }
    };

    // handle select tag 
    const _handleSelectTag = (value) => {
        setSelectedTag(value);
    };

    // handle select countries
    const _handleSelectCountries = (value) => {
        setSelectedCountries(value);
    };

    // handle filter country 
    const _handleSelectCountry = (value) => {
        setSelectedCountry(value);
        setLoading(true);
        setCurrentPage(1);
    };

    // get presigned url
    async function _getPresignedUrl(file) {
        let is_post = true;
        let url = 'general/file-upload';
        let data = {
            "file": file.name,
            "is_public": true
        };
        try {
            let response = await APIService(is_post, url, data);

            if (response.status_code === 200) {
                let aws_fields = response.data.upload_info.fields;
                let aws_url = response.data.upload_info.url;

                try {
                    let aws_response = await AWSService(aws_fields, file, aws_url);
                    if (aws_response.status === 'ok') {
                        setImage(response.data.view_info);
                    } else {
                        toast.dismiss();
                        toast.error(aws_response.message, {
                            type: "error",
                            className: "e-toast",
                        });
                    }
                } catch (err) {
                    console.log("error")
                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        } catch (err) {
        }
        setImageLoader(false);
    };

    // API - Create news 
    const _createNews = () => {

        const updated_selected_countries = selectedCountries.map(item => item.value);

        let is_post = true;
        let url = is_super_admin ? 'news/create' : 'CA/news/create';
        let data = {
            "title": title.en,
            "title_arabic": title.ar,
            "content": editorData.en,
            "content_arabic": editorData.ar,
            "tag": selectedTag.value,
            "image_url": image,
            "countries": updated_selected_countries
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("News created successfully", {
                    type: "success",
                    className: "e-toast",
                });

                setTitle("");
                setEditorData({ en: '', ar: '' });
                setEditor({ en: EditorState.createEmpty(), ar: EditorState.createEmpty() });
                setSelectedTag(null);
                setImage(null);
                setLoading(true);
                setSelectedCountries([]);

                _getNewsList();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setCreateLoader(false);
        });
    };

    // API - update news 
    const _updateNews = () => {

        const updated_selected_countries = selectedCountries.map(item => item.value);

        let is_post = true;
        let url = is_super_admin ? 'news/update' : 'CA/news/update';
        let data = {
            "news_id": newsEditData.data.news_id,
            "title": title.en,
            "title_arabic": title.ar,
            "content": editorData.en,
            "content_arabic": editorData.ar,
            "tag": selectedTag.value,
            "image_url": image,
            "countries": updated_selected_countries
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("News updated successfully", {
                    type: "success",
                    className: "e-toast",
                });

                setTitle("");
                setEditorData({ en: '', ar: '' });
                setEditor({ en: EditorState.createEmpty(), ar: EditorState.createEmpty() });
                setSelectedTag(null);
                setImage(null);
                setNewsEditData({});
                setLoading(true);
                setSelectedCountries([]);

                _getNewsList();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setCreateLoader(false);
        });
    };

    // API - Get news list
    const _getNewsList = () => {
        let is_post = true;
        let url = is_super_admin ? 'news/list' : 'CA/news/list';
        let data = {
            "page_num": currentPage,
            "limit": ITEMS_PER_PAGE,
            "country_id": selectedCountry.value
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setNewsData(response.data);
                setTotalPageCount(Math.ceil(response.data.total_count / ITEMS_PER_PAGE));
            } else {
                setNewsData(null);
            }
            setLoading(false);
        });
    };

    // API - Delete news
    const _deleteNews = () => {
        let is_post = true;
        let url = is_super_admin ? 'news/delete' : 'CA/news/delete';
        let data = {
            "news_id": deleteId
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setDeleteResponse(true);
                setNewsEditData({});
                setTitle("");
                setImage(null);
                setSelectedTag(null);
                setEditorData({ en: '', ar: '' });

                setEditor({ en: EditorState.createEmpty(), ar: EditorState.createEmpty() });
                setSelectedCountries([]);

            } else {
                setDeleteResponse(false);
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        });
    };

    // API - Get news tag
    const _getNewsTag = () => {
        let is_post = false;
        let url = 'general/news-tag';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setTagData(response.data);
            } else {
            }
            setTagDataLoading(false);
        });
    };

    // API - Get countries list
    const _getCountries = () => {

        const country_filter_options = [];

        let is_post = false;
        let url = 'general/list-countries';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCountryList(response.data);

                country_filter_options.push({
                    value: null,
                    label: "All"
                });

                response.data.map((country) => (
                    country_filter_options.push({
                        value: country.value,
                        label: country.label
                    })
                ));
                setCountrySelect(true);
                setSelectedCountry(country_filter_options[0]);
                setCountries(country_filter_options);

            } else {
            }
            setCountryDataLoading(false);
        });
    };

    return (
        <section className='pb-5'>
            <div className={`${is_super_admin ? "e-bg-azureish-white" : "e-bg-lavender-blush-light"} d-flex justify-content-between  pt-3 padding-52px-lr padding-96px-bottom`}>
                <h1 className='mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-raisin-black e-font-weight-600'>
                    News
                </h1>
            </div>

            <div className='padding-52px-lr margin-neg-66px-top'>
                <div className='row'>
                    <div className='col-xl-6 col-lg-6 col-md-8 col-sm-12 col-12'>
                        <div className='e-border-1px-platinum-light py-3 ps-4 pe-2 e-bg-white e-admin-box-shadow e-border-radius-16 mb-4'>
                            <h3 className='mb-2 ps-1 e-primary-font-playfair e-text-dark-jungle-green e-font-18 line-height-28px e-font-weight-500'>
                                {newsEditData.type === "edit" ? "Edit news" : "Create news"}
                            </h3>

                            <p className='mb-0 ps-1 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                {newsEditData.type === "edit" ? "Edit news contents." : "Add news contents."}
                            </p>

                            {/* text fields  */}
                            {tagDataLoading && countryDataLoading ?
                                <Loader className="h-40vh" />  //loader
                                :
                                <div className={`${styles.e_schedule} ${menuOpen ? 'overflow-hidden' : ''} pt-3 h-358px overflow-y-auto overflow-x-hidden`}>
                                    <div className='pe-3 ps-1 pb-2'>

                                        {/* Title */}
                                        <CustomTextInput label="Title (English)"
                                            placeholder="News title"
                                            type="text"
                                            require
                                            value={title.en}
                                            error={titleError.en}
                                            className="w-100"
                                            labelStyle="e-font-weight-500 e-text-raisin-black"
                                            handleChange={(e) => _handleChangeTitle("en", e)} />

                                        {/* Title arabic */}
                                        <CustomTextInput label="Title (Arabic)"
                                            placeholder="News title"
                                            type="text"
                                            require
                                            value={title.ar}
                                            error={titleError.ar}
                                            className="w-100"
                                            labelStyle="e-font-weight-500 e-text-raisin-black"
                                            handleChange={(e) => _handleChangeTitle("ar", e)} />

                                        {/* custom wysiwyg editor english*/}
                                        <CustomEditor label="Content (English)"
                                            editorState={editor.en}
                                            require
                                            editorStyle={{ minHeight: "200px" }}
                                            options={['inline', 'blockType', 'list', 'link']}
                                            className='h-296px'
                                            error={editorError.en}
                                            onEditorStateChange={(data) => _handleEditorChange('en', data)} />

                                        {/* custom wysiwyg editor arabic */}
                                        <CustomEditor label="Content (Arabic)"
                                            editorState={editor.ar}
                                            require
                                            editorStyle={{ minHeight: "200px" }}
                                            options={['inline', 'blockType', 'list', 'link']}
                                            className='h-296px'
                                            error={editorError.ar}
                                            onEditorStateChange={(data) => _handleEditorChange('ar', data)} />

                                        {/* tag selector */}
                                        <CustomSelectBox label="Add tag"
                                            value={selectedTag}
                                            options={tagData}
                                            error={selectedTagError}
                                            menuIsOpen={menuOpen}
                                            menuPortalTarget={document.body}
                                            className="e-section-select e-bg-white"
                                            isSearchable={false}
                                            onMenuOpen={() => setMenuOpen(true)}
                                            onMenuClose={() => setMenuOpen(false)}
                                            selectChange={(id) => _handleSelectTag(id)}
                                        />

                                        {/* countries select */}
                                        {is_super_admin &&
                                            <TagSelector label="Select countries"
                                                value={selectedCountries}
                                                options={countryList}
                                                menuPortalTarget={document.body}
                                                className="e-bg-white e-border-radius-12"
                                                handleTagChange={_handleSelectCountries} />
                                        }

                                        {/* upload image */}
                                        <CustomFileUpload label="Upload thumbnail"
                                            placeholder="Drop your image here"
                                            file={image}
                                            fileType={["JPG", "PNG", "JPEG"]}
                                            resolution="328px * 90px"
                                            requiredWidth={328}
                                            requiredHeight={90}
                                            errorUpdate={errorUpdate}
                                            require
                                            error={imageError}
                                            loader={imageLoader}
                                            handleChange={_handleUploadImage} />

                                        {/* change thumbnail button */}
                                        {image !== null &&
                                            <div className='mb-2 margin-neg-20px-top'>
                                                <TextButton name="Change image"
                                                    onPress={_handleChangeImage} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            }

                            {/* create button */}
                            <div className='pe-3'>
                                <PrimaryButton name={newsEditData.type === "edit" ? "Update news" : "Create news"}
                                    className='e-font-16 w-100 mt-2'
                                    status={createLoader}
                                    onPress={_handleCreateNews} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* created history */}
                <div ref={newsRef} className='pt-3'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <h4 className='mb-0 e-text-dark-jungle-green e-primary-font-playfair e-font-18 e-font-weight-500 line-height-28px'>
                            Created news history
                        </h4>

                        {/* filter toggle button */}
                        {countrySelect === true &&
                            <img src={toggleFilter ? require("../../../Assets/Image/filter-icon-white.svg").default :
                                require("../../../Assets/Image/filter-icon.svg").default}
                                draggable={false}
                                className={`${toggleFilter ? `e-bg-indigo` : ``} transition e-border-1px-cool-grey e-border-radius-8 p-2 cursor-pointer`}
                                alt='filter'
                                onClick={() => setToggleFilter(!toggleFilter)} />
                        }
                    </div>

                    {/* Filter */}
                    {toggleFilter &&
                        <div className='e-bg-anti-flash-white-dark py-3 px-4 mt-3'>
                            <div className='col-xl-2 col-lg-3 col-md-3 col-sm-4 col-8 pe-0'>
                                <CustomSelectBox label="Country"
                                    value={selectedCountry}
                                    options={countries}
                                    type={2}
                                    className="e-bg-white e-border-radius-8 e-table-select "
                                    labelClass="e-font-weight-600"
                                    isSearchable={false}
                                    selectChange={(temp) => _handleSelectCountry(temp)}
                                />
                            </div>
                        </div>
                    }

                    {loading ?
                        <Loader className="min-h-50vh" />  //loader
                        :
                        <>
                            {newsData.total_count > 0 ?
                                <>
                                    {newsData.data.map((news, key) => (
                                        <div key={key} className='mt-3 e-bg-white p-3 e-border-radius-16'>
                                            <div className='row'>
                                                <div className='col-2 pe-0 my-auto'>
                                                    <img src={news.image_url}
                                                        draggable={false}
                                                        className='w-100 e-border-radius-8'
                                                        height={120}
                                                        alt='notification' />
                                                </div>
                                                <div className='col-9 ps-3'>
                                                    <span className='e-bg-lust p-1 e-border-radius-40 e-text-white e-alt-font-noto-sans e-font-10 e-font-weight-400 line-height-10px '>
                                                        {news.tag_name}
                                                    </span>
                                                    <h6 className='mb-0 pt-2 e-primary-font-playfair e-font-16 line-height-20px e-text-raisin-black e-font-weight-600'>
                                                        {news.title}
                                                    </h6>
                                                    <p className='mb-1 padding-10px-top e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-22px e-ellipse'>
                                                        {_removeTags(news.content)}
                                                    </p>

                                                    <p className='pt-1 mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                                                        News created on: <span className='e-text-raisin-black'>{news.created_on}</span>
                                                    </p>
                                                </div>

                                                {/* delete and update menu */}
                                                <div className='col-1 text-end'>
                                                    <div className="e-dropdown-more-wrapper position-relative ">
                                                        <img src={require("../../../Assets/Image/Header/menu.svg").default}
                                                            draggable={false}
                                                            className=' e-border-1px-anti-flash-white e-border-radius-50per p-1 cursor-pointer'
                                                            alt='menu'
                                                            onClick={() => _handleToggleMenu(key + 1)} />
                                                        {toggleMenu === key + 1 &&
                                                            <>
                                                                <OverLay isOpen={toggleMenu} onClose={() => setToggleMenu(null)} />
                                                                <div className="e-dropdown-more e-bg-white text-start top-24px right-neg-10px"
                                                                    onClick={() => setToggleMenu(null)}>
                                                                    <p className='mb-3 e-text-rich-black-light e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px cursor-pointer text-decoration-hover-underline'
                                                                        onClick={() => _handleDeleteNews(news.news_id)} >
                                                                        Delete
                                                                    </p>

                                                                    <p className='mb-0 e-text-rich-black-light e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px cursor-pointer text-decoration-hover-underline'
                                                                        onClick={() => _handleEditNews(news, "edit")} >
                                                                        Edit news
                                                                    </p>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    {/* pagination */}
                                    {totalPageCount > 1 &&
                                        <div className="d-flex justify-content-center e-gap-0-16 mt-4 pt-3">
                                            <Pagination itemsPerPage={ITEMS_PER_PAGE}
                                                totalPage={totalPageCount}
                                                showFirstLastPages={true}
                                                onChange={_handlePagination} />
                                        </div>
                                    }
                                </>
                                :
                                <div className='min-h-30vh pt-5'>
                                    {/* empty screen */}
                                    <EmptyScreen message="No news found" />
                                </div>
                            }
                        </>
                    }
                </div>
            </div>

            {/*Confirm delete modal */}
            {deleteId !== null &&
                <ConfirmDeleteModal label="news"
                    deleteId={deleteId}
                    response={deleteResponse}
                    delete={() => _deleteNews()}
                    handleClose={() => setDeleteId(null)}
                    success={() => {
                        _getNewsList();
                        setLoading(true);
                    }} />
            }
        </section>
    )
}

export default ManageNews;
