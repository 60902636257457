/*
*   File : data-table.js
*   Author : https://evoqueinnovativelab.com
*   Description : Data table component
*   Integrations : react-data-table-component
*   Version : 1.0.0
*/

import React, { memo } from 'react';
import DataTable from 'react-data-table-component';

const DataTableComponent = (props) => {

    return (
        <DataTable columns={props.columns}
            data={props.data}
            paginationPerPage={props.paginationPerPage ? props.paginationPerPage : 15}
            paginationServer={props.paginationServer}
            paginationTotalRows={props.paginationTotalRows}
            pagination={props.pagination}
            selectableRows={props.selectableRows}
            paginationIconPrevious={props.paginationIconPrevious}
            paginationIconNext={props.paginationIconNext}
            onRowClicked={(e) => props.rowClick(e)}
            onChangeRowsPerPage={props.onChangeRowsPerPage}
            paginationComponentOptions={props.paginationComponentOptions}
            paginationIconLastPage={props.paginationIconLastPage}
            paginationIconFirstPage={props.paginationIconFirstPage}
            onChangePage={props.onChangePage}
        />
    );
}

DataTableComponent.defaultProps = {
    rowClick: () => { },
    data: []
}

export default memo(DataTableComponent);