/*
 *   File : banner.js
 *   Author : https://evoqins.com
 *   Description : Banner card component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo } from 'react';

// Custom component
import { SecondaryButton, TextButton } from '../Buttons';
import { Icon } from '../Icon';

const BannerCard = (props) => {

    return (
        <div className="row">
            <div className='col-4'>
                <img src={props.data.image}
                    draggable={false}
                    className='w-100 e-border-radius-24'
                    height={138}
                    alt="card-img" />
            </div>

            <div className="col-7">
                <div className='d-flex gap-4'>
                    {/* <div>
                        <p className='mb-0 e-alt-font-noto-sans e-text-raisin-black e-font-12 e-font-weight-400 line-height-22px'>
                            Type
                        </p>
                        <p className='mb-0 e-alt-font-noto-sans e-text-raisin-black e-font-14 e-font-weight-700 line-height-22px'>
                            {props.data.type_id === 1 ? 'In app' : 'General'}
                        </p>
                    </div>
                    <div >
                        <p className='mb-0 e-alt-font-noto-sans e-text-raisin-black e-font-12 e-font-weight-400 line-height-22px'>
                            Resource
                        </p>
                        <p className='mb-0 e-alt-font-noto-sans e-text-raisin-black e-font-14 e-font-weight-700 line-height-22px'>
                            {props.data.resource ? props.data.resource : "--"}
                        </p>
                    </div> */}
                </div>

                {
                    props.data.type_id === 1 ?
                        <>
                            <p className='mb-3 e-alt-font-noto-sans e-text-raisin-black e-font-14 e-font-weight-400 line-height-18px'>
                                {props.data.content_name}
                            </p>
                            <div className='d-flex justify-content-between gap-3 '>
                                <p className="mb-0 e-alt-font-noto-sans e-text-raisin-black e-font-16 e-font-weight-500 line-height-22px e-ellipse">
                                    {props.data.data_name}
                                </p>
                                {props.bannerType === 1 &&
                                    <p className='mb-0 h-100 text-nowrap e-border-bottom-1px-indigo d-flex gap-1 align-items-center e-text-indigo e-alt-font-noto-sans e-font-16 e-font-weight-600 line-height-24px cursor-pointer'
                                        onClick={props.handleHideAndShowBanner}>
                                        <Icon icon={props.bannerType !== 2 ? "eye-hide-icon" : "show-eye-icon"}
                                            className='cursor-pointer'
                                            size="24px"
                                            onClick={props.onClose} />
                                        {props.bannerType !== 2 && props.data.is_active ? "Hide banner" : "Show banner"}
                                    </p>
                                }
                            </div>
                        </>
                        :
                        <>
                            <p className='mb-3 e-alt-font-noto-sans e-text-raisin-black e-font-14 e-font-weight-400 line-height-18px'>
                                Created link
                            </p>
                            <p className='mb-0 e-alt-font-noto-sans e-text-raisin-black e-font-16 e-font-weight-400 line-height-24px'>
                                <div className='d-flex justify-content-between gap-3'>
                                    {props.data.url ?
                                        <a href={props.data.url} target='_blank'
                                            className='e-text-raisin-black text-decoration-none text-decoration-hover-underline text-truncate'>
                                            {props.data.url}
                                        </a>
                                        :
                                        '-'
                                    }
                                    {props.bannerType === 1 &&
                                        <span className='text-nowrap e-border-bottom-1px-indigo d-flex gap-1 align-items-center e-text-indigo e-alt-font-noto-sans e-font-16 e-font-weight-600 line-height-24px cursor-pointer'
                                            onClick={props.handleHideAndShowBanner}>
                                            <Icon icon={props.bannerType !== 2 ? "eye-hide-icon" : "show-eye-icon"}
                                                className='cursor-pointer'
                                                size="24px"
                                                data-bs-dismiss="modal"
                                                onClick={props.onClose} />
                                            {props.bannerType !== 2 ? "Hide banner" : "Show banner"}
                                        </span>
                                    }
                                </div>
                            </p>
                        </>
                }


                {/* Action Buttons */}

                {
                    props.bannerType === 1 &&
                    <div className='mt-3 d-flex gap-3 align-items-center'>
                        <SecondaryButton name="Edit"
                            className="e-border-radius-16"
                            onPress={props.handleEdit} />

                        <TextButton name="Archive banner"
                            className="px-2 e-font-16"
                            onPress={props.handleAddToArchive} />
                    </div>
                }
                {
                    props.bannerType === 2 &&
                    <div className='mt-3 d-flex gap-3 align-items-center'>
                        {/* <SecondaryButton name="Unhide & activate banner"
                            className="e-bg-white e-border-radius-16"
                            onPress={() => { }} /> */}
                        <SecondaryButton name="Edit"
                            className="e-border-radius-16"
                            onPress={props.handleEdit} />
                        {/* <TextButton name="Archive banner"
                            className="px-2 e-font-16"
                            onPress={props.handleAddToArchive} /> */}
                        <span className='text-nowrap e-border-bottom-1px-indigo d-flex gap-1 align-items-center e-text-indigo e-alt-font-noto-sans e-font-16 e-font-weight-600 line-height-24px cursor-pointer'
                            onClick={props.handleHideAndShowBanner}>
                            <Icon icon={props.bannerType !== 2 ? "eye-hide-icon" : "show-eye-icon"}
                                className='cursor-pointer'
                                size="24px"
                                data-bs-dismiss="modal"
                                onClick={props.onClose} />
                            {props.bannerType !== 2 ? "Hide banner" : "Show banner"}
                        </span>
                    </div>
                }
                {
                    props.bannerType === 3 &&
                    <div className='mt-3 d-flex gap-3 align-items-center'>
                        {/* <SecondaryButton name="Unarchive & activate banner"
                            className="e-bg-white e-border-radius-16"
                            onPress={() => { }} /> */}
                        <SecondaryButton name="Edit"
                            className="e-border-radius-16"
                            onPress={props.handleEdit} />
                        <TextButton name="Unarchive banner"
                            className="px-2 e-font-16"
                            onPress={props.handleRemoveArchived} />
                    </div>
                }
                {
                    props.bannerType === 4 &&
                    <div className='mt-3 d-flex gap-3 align-items-center'>
                        <p className="mb-0 e-alt-font-noto-sans e-text-raisin-black e-font-14 e-font-weight-500 line-height-18px">
                            {props.data.scheduled_time}
                        </p>
                        <SecondaryButton name="Edit"
                            className="e-border-radius-16"
                            onPress={props.handleEdit} />
                        <TextButton name="Archive banner"
                            className="px-2 e-font-16"
                            onPress={props.handleAddToArchive} />
                    </div>
                }

                {/* <div className='text-end pt-2'>
                    <TextButton name="Edit"
                        className="e-font-16"
                        onPress={props.handleEdit} />
                    <TextButton name="Delete"
                        className="ps-3 e-text-lust e-font-16"
                        onPress={_handleDelete}
                    />

                </div> */}
            </div>
        </div>
    )
}

export default memo(BannerCard);