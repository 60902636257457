/*
 *   File : notification.js
 *   Author : https://evoqins.com
 *   Description : Notification page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// Custom components 
import { NotificationCard } from '../../../Component/Cards';
import { TextButton } from '../../../Component/Buttons';
import { APIService } from '../../../Service';
import { EmptyScreen } from '../../../Component/EmptyComponent';
import { Loader } from '../../../Component/Others';
import { Pagination } from '../../../Component/pagination';

const ITEMS_PER_PAGE = 16;

const NotificationList = () => {

    const navigate = useNavigate();

    const [notificationData, setNotificationData] = useState({});
    const [loading, setLoading] = useState(true);

    const [totalPageCount, setTotalPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [readNotification, setReadNotification] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });

    }, []);

    useEffect(() => {
        _getNotificationList();
    }, [currentPage]);

    useEffect(() => {
        if (loading === false) {
            const notification_read = notificationData.data.some(notification => !notification.read);
            setReadNotification(notification_read);
        }
    }, [loading, notificationData]);

    // handle mark all read 
    const _handleMarkAll = () => {
        _markAll();
    };

    // handle click notification 
    const _handleClickNotification = (data) => {
        if (data.read === false) {
            _markNotificationRead(data.notification_id);
        }

        if (data.type === "NEW_ACCOUNT") {
            navigate("/pending-request");
        } else if (data.type === "REDEEM_REQUEST") {
            navigate("/redeem-offer", {
                state: 2
            });
        } else {
            navigate("/pending-request", {
                state: 2
            });
        }
    };

    //handle pagination
    const _handlePagination = (new_start_limit) => {
        const selected_page = Math.floor(new_start_limit / ITEMS_PER_PAGE) + 1;
        setCurrentPage(selected_page);

        window.scrollTo({
            top: 0, behavior: 'smooth'
        });
    };

    // API - Get notification list
    const _getNotificationList = () => {
        let is_post = true;
        let url = 'CA/notifications/list';
        let data = {
            "page_number": currentPage,
            "limit": ITEMS_PER_PAGE
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setNotificationData(response.data);
                setTotalPageCount(Math.ceil(response.data.total_count / ITEMS_PER_PAGE));
            } else { }
            setLoading(false);
        });
    };

    // API - Mark all as read
    const _markAll = () => {
        let is_post = false;
        let url = 'CA/notifications/mark-all';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success(response.message, {
                    type: "success",
                    className: "e-toast",
                });
                _getNotificationList();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        });
    };

    // API - Notification read
    const _markNotificationRead = (notification_id) => {
        let is_post = true;
        let url = 'CA/notifications/read';      //todo after confirm 
        let data = {
            "notification_id": notification_id
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                _getNotificationList();
            } else {

            }
        });
    };

    return (
        <section className='pb-5 min-h-70vh'>
            <div className='e-bg-lavender-blush-light pt-3 padding-98px-bottom padding-52px-lr'>
                <h1 className='mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                    Notification
                </h1>
            </div>
            <div className='padding-52px-lr margin-neg-66px-top'>
                <div className='e-bg-white e-admin-box-shadow p-4 e-border-radius-16 '>
                    <div className='d-flex align-items-baseline justify-content-space-between'>
                        <h4 className='mb-2 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-600 line-height-28px w-max-content'>
                            All notifications
                        </h4>

                        {/* mark read button */}
                        <div>
                            {!loading && readNotification &&
                                <TextButton name="Mark all as read"
                                    onPress={_handleMarkAll} />
                            }
                        </div>
                    </div>

                    {loading ?
                        <Loader />  //loader
                        :
                        <>
                            {notificationData.data.length !== 0 ?
                                // Notification card 
                                <div className='pt-4 mt-2'>
                                    {notificationData.data.map((data, key) => (
                                        <div key={key} className='pb-4'>
                                            <NotificationCard notification={data}
                                                handleClick={_handleClickNotification} />
                                        </div>
                                    ))}

                                    {/* pagination */}
                                    {totalPageCount > 1 &&
                                        <div className="d-flex justify-content-center e-gap-0-16 mt-4 pt-3">
                                            <Pagination itemsPerPage={ITEMS_PER_PAGE}
                                                className="e-light-pagination"
                                                totalPage={totalPageCount}
                                                showFirstLastPages={true}
                                                onChange={_handlePagination}
                                            />
                                        </div>
                                    }
                                </div>
                                :
                                <div className='h-100vh pt-5 mt-5'>
                                    {/* empty screen */}
                                    <EmptyScreen message="No notification found" />
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </section >
    )
}

export default NotificationList;