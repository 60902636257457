/*
 *   File : employees.js
 *   Author : https://evoqins.com
 *   Description : sales specialist page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Custom components
import { DataTableComponent } from '../../../../Component/DataTable';
import { CustomSearchInput } from '../../../../Component/FormElements';
import { SummaryCard } from '../../../../Component/Cards';
import { Icon } from '../../../../Component/Icon';
import { APIService } from '../../../../Service';
import { Loader } from '../../../../Component/Others';
import { EmptyScreen } from '../../../../Component/EmptyComponent';

const ITEMS_PER_PAGE = 15;

const Employee = (props) => {

    const tableRef = useRef(null);
    const navigate = useNavigate();

    const [searchValue, setSearchValue] = useState("");
    const [registeredMemberData, setRegisteredMemberData] = useState({});
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        _registeredMembers();
    }, [searchValue, currentPage]);

    // handle search registered members 
    const _handleSearchMembers = (value) => {
        setSearchValue(value);
        setLoading(true);
        setCurrentPage(1);
    };

    // handle table page change 
    const _handlePageChange = (page_num) => {
        setCurrentPage(page_num);
        if (tableRef.current) {
            tableRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // handle navigate detail page 
    const _handleNavigateDetail = (id) => {
        navigate("/sales-specialist/detail", {
            state: {
                customer_id: id,
                tabIndex: 1
            }
        });
    };

    // registered members table 
    const TABLE_COLUMNS = [
        {
            name: 'Emp. ID',
            selector: row => row.user_id === null ? '-' : row.user_id,
            width: "120px"
        },
        {
            name: 'Name',
            selector: row => row.customer_name,
            width: "180px"
        },
        {
            name: 'Joined on',
            selector: row => row.joined_on,
            width: "140px"
        },
        {
            name: 'Email',
            // selector: row => row.email,
            width: "200px",
            cell: (row) => row.email ?
                <a href={`mailto:${row.email}`} className='e-text-rich-black text-decoration-hover-underline text-decoration-none cursor-pointer'>
                    {row.email}
                </a>
                :
                '-'
        },
        {
            name: 'Phone no.',
            // selector: row => row.number,
            width: "160px",
            cell: (row) => row.contact_no ?
                <a href={`tel:${row.contact_no}`} className='e-text-rich-black text-decoration-hover-underline text-decoration-none cursor-pointer'>
                    {row.contact_no}
                </a>
                :
                '-'
        },
        {
            name: 'Sales (Units)',
            selector: row => row.total_sales,
            width: "140px",
        },
        {
            name: "Reward points",
            selector: row => row.reward,
            width: "140px",
        },
        {
            name: "Revenue generated",
            selector: row => row.revenue,
            width: "160px"
        }

    ];

    // API - Get store detail data
    const _registeredMembers = () => {
        let is_post = true;
        let url = 'CA/store/registered-member-list';
        let data = {
            "store_id": props.storeId,
            "query": searchValue,
            "page_num": currentPage,
            "limit": ITEMS_PER_PAGE
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setRegisteredMemberData(response.data);
            } else { }
            setLoading(false);
        });
    };

    return (
        <>
            {/* Summary  */}
            <div className='e-admin-box-shadow p-4 e-bg-white e-border-radius-16'>
                <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                    Summary
                </h4>
                <div className='row row-cols-xl-4 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 row-gap-3 padding-10px-top'>

                    <div className='col'>
                        <SummaryCard title="No. of sales specialists"
                            loader={props.loader}
                            quantity={props.summaryData.sales_specialist} />
                    </div>
                    <div className='col'>
                        <SummaryCard title="Avg. sale per person"
                            loader={props.loader}
                            quantity={props.summaryData.average_sale} />
                    </div>
                    <div className='col'>
                        <SummaryCard title="Avg. point per person"
                            loader={props.loader}
                            quantity={props.summaryData.points_earned} />
                    </div>
                </div>
            </div>

            {/* Registered members table */}
            <div ref={tableRef} className='margin-20px-top e-bg-white e-admin-box-shadow py-4 e-border-radius-16 '>
                <div className='row px-4'>
                    <div className='col-xl-7 col-lg-7 col-md-6 col-sm-12'>
                        <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-600 line-height-28px' >
                            All registered members
                        </h4>
                    </div>
                    <div className='col-xl-5 col-lg-5 col-md-6 col-sm-8 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-2 text-end'>
                        <div className='position-relative'>
                            <CustomSearchInput placeholder="Search by name"
                                type="text"
                                require
                                value={searchValue}
                                className="w-100 padding-10px-tb"
                                labelStyle="e-font-weight-500"
                                handleChange={(e) => _handleSearchMembers(e)} />
                            {searchValue.length !== 0 &&
                                <Icon icon="close-icon"
                                    className='position-absolute top-12px right-12px cursor-pointer'
                                    size="20px"
                                    onPress={() => {
                                        setSearchValue("");
                                        setLoading(true);
                                    }} />
                            }
                        </div>
                    </div>
                </div>

                {loading ?
                    // loader 
                    <Loader />
                    :
                    <>
                        {/* registered members data table */}
                        {registeredMemberData.data.length !== 0 ?
                            <div className='e-members-detail-table'>
                                <DataTableComponent columns={TABLE_COLUMNS}
                                    data={registeredMemberData.data}
                                    pagination={true}
                                    paginationServer={true}
                                    paginationTotalRows={registeredMemberData.total_count}
                                    rowClick={(row) => _handleNavigateDetail(row.customer_id)}
                                    onChangePage={_handlePageChange} />
                            </div>
                            :
                            // empty screen
                            <div className='h-100vh pt-5 mt-5'>
                                <EmptyScreen message="No data found" />
                            </div>
                        }
                    </>

                }
            </div>
        </>
    )
}

export default Employee;