/*
 *   File : text-button.js
 *   Author : https://evoqins.com
 *   Description : text button component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo } from 'react';

const TextButton = (props) => {
    return (
        <span className={`${props.className} e-text-indigo e-alt-font-noto-sans e-font-14 e-font-weight-600 line-height-20px text-decoration-hover-underline cursor-pointer`}
            onClick={props.onPress}>
            {props.name}
        </span>
    )
}

export default memo(TextButton);