/*
 *   File : flash-quiz.js
 *   Author : https://evoqins.com
 *   Description : Flash quiz card component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo } from 'react';

//Custom component
import { TextButton } from '../Buttons';

//styles
import styles from "../../Style/Component/card.module.scss";

const FlashQuizCard = (props) => {
    return (
        <div className={`${styles.e_quiz_card_wrapper} e-admin-box-shadow e-border-radius-16 p-3 e-flash-quiz cursor-pointer`}
            onClick={() => props.handleView(props.flashQuiz.quiz_id)}>
            <div className='d-flex gap-0-16 align-items-baseline'>
                <p className='mb-0 e-alt-font-noto-sans e-text-raisin-black e-font-16 e-font-weight-700 line-height-14px'>
                    {props.flashQuiz.reward_points}
                    <span className='ps-1 e-font-14 e-font-weight-400 line-height-12px'>
                        {props.flashQuiz.reward_points === 1 ? "point" : "points"}
                    </span>
                </p>
                <p className='mb-0 e-alt-font-noto-sans e-text-raisin-black e-font-14 e-font-weight-400 line-height-20px'>
                    {props.flashQuiz.quiz_duration}
                </p>
            </div>

            <div className='padding-12px-top'>
                <h6 className='mb-0 e-primary-font-playfair e-text-raisin-black e-font-16 e-font-weight-700 line-height-18px'>
                    {props.flashQuiz.title}
                </h6>
                <p className='mb-0 padding-12px-top e-alt-font-noto-sans e-text-davys-grey e-font-14 e-font-weight-400 line-height-20px h-74px e-ellipse-3-line'>
                    {props.flashQuiz.description}
                </p>

                <div className='d-flex gap-4 pt-2'>
                    <div>
                        <h6 className='mb-0 e-primary-font-playfair e-text-raisin-black e-font-16 e-font-weight-700 line-height-18px'>
                            Start date
                        </h6>
                        <p className='mb-0 padding-12px-top e-alt-font-noto-sans e-text-davys-grey e-font-14 e-font-weight-400 line-height-20px'>
                            {props.flashQuiz.start_date}
                        </p>
                    </div>
                    <div>
                        <h6 className='mb-0 e-primary-font-playfair e-text-raisin-black e-font-16 e-font-weight-700 line-height-18px'>
                            End date
                        </h6>
                        <p className='mb-0 padding-12px-top e-alt-font-noto-sans e-text-davys-grey e-font-14 e-font-weight-400 line-height-20px'>
                            {props.flashQuiz.end_date}
                        </p>
                    </div>
                </div>

                <div className='pt-1 px-2 text-end'>
                    <TextButton name="View details" />
                </div>
            </div>

        </div>
    )
}

export default memo(FlashQuizCard);