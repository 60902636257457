/*
 *   File : video.js
 *   Author : https://evoqins.com
 *   Description : Video card component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo } from 'react';

// Custom component
import { TextButton } from '../Buttons';

const VideoCard = (props) => {

    return (
        <div className="card overflow-hidden border-0 e-admin-box-shadow e-border-radius-16 cursor-pointer"
            onClick={() => props.handleView(props.video.video_id)}>
            <div className='position-relative'>
                <img src={props.video.image}
                    draggable={false}
                    className='w-100 e-res-h-262px'
                    height={262}
                    alt="card-img" />
                <img src={require("../../Assets/Image/Card/video-play.svg").default}
                    draggable={false}
                    className='position-absolute top-40 left-48'
                    alt='play' />
            </div>
            <div className="card-body padding-12px-top pb-4">
                <h6 className="mb-0 e-primary-font-playfair e-text-raisin-black e-font-16 e-font-weight-500 line-height-18px e-ellipse h-36px">
                    {props.video.title}
                </h6>
                <p className='mb-0 pt-2 e-alt-font-noto-sans e-text-davys-grey e-font-12 e-font-weight-400 line-height-12px'>
                    Watch time {props.video.video_duration}
                </p>
                <div className='d-flex pt-2 flex-wrap gap-2 h-32px'>
                    {props.video.tags?.map((data, key) => (
                        <span key={key} className='padding-2px-tb e-bg-periwinkle e-border-radius-20 px-2 e-alt-font-noto-sans e-text-raisin-black e-font-14 e-font-weight-400 line-height-22px'>
                            {data}
                        </span>
                    ))}
                </div>

                <div className='e-display-flex pt-2 justify-content-space-between pe-2'>
                    <p className='mb-0 e-alt-font-noto-sans e-text-davys-grey e-font-12 e-font-weight-400 line-height-14px'>
                        {props.video.reward_points === 1 ? "Reward point" : "Reward points"}
                        <span className='ps-1 e-text-raisin-black e-font-14 e-font-weight-600 line-height-14px'>
                            {props.video.reward_points}
                        </span>
                    </p>
                    <TextButton name="View details" />
                </div>
            </div>
        </div >
    )
}

export default memo(VideoCard);