/*
 *   File : text-input.js
 *   Author : https://evoqins.com
 *   Description : Text input component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo, useState } from 'react';

//custom style
import styles from "../../Style/Component/input.module.scss";

const CustomSearchInput = (props) => {

    const [isInputFocused, setIsInputFocused] = useState(false);

    // handle focus 
    const _handleFocus = () => {
        setIsInputFocused(true);
    };

    const _handleBlur = () => {
        setIsInputFocused(false);
    };

    //input handle change
    const _handleChange = (e) => {
        let value = e.target.value;
        props.handleChange(value);
    };

    // handle enter key press
    const keyPress = (e) => {
        if (e.keyCode === 13) {
            props.onKeyPress();
        }
    };

    return (
        <div >
            {props.label &&
                <label className={`${props.labelStyle} e-text-rich-black e-alt-font-noto-sans e-font-weight-500 line-height-18px e-font-14 pb-2`}>
                    {props.label}
                </label>
            }

            <div className={`${isInputFocused ? `e-border-1px-indigo ` : `e-border-1px-columbia-blue ${props.borderStyle}`} e-border-radius-8 position-relative`}>
                <input id={props.id}
                    ref={props.ref}
                    value={props.value}
                    defaultValue={props.defaultValue}
                    maxLength={props.maxLength}
                    tabIndex={props.tabIndex}
                    type={props.type}
                    required={props.required}
                    readOnly={props.readOnly}
                    autoFocus={props.autoFocus}
                    placeholder={props.placeholder}
                    className={`${styles.e_input} ${props.className} padding-44px-left padding-40px-right padding-12px-tb border-0 e-border-radius-12 e-bg-white e-font-14 e-text-raisin-black e-font-weight-400 line-height-22px e-alt-font-noto-sans`}
                    onWheel={() => document.activeElement.blur()}
                    onFocus={_handleFocus}
                    onBlur={_handleBlur}
                    onChange={_handleChange}
                    onKeyDown={(e) => keyPress(e)}
                    onClick={props.onClick} />

                <img src={require("../../Assets/Image/search-icon-table.svg").default}
                    draggable={false}
                    className='position-absolute top-11px left-16px'
                    alt='search' />
            </div>

        </div >
    )
}

CustomSearchInput.defaultProps = {
    onKeyPress: () => { },
    className: null,
    error: "",
}

export default memo(CustomSearchInput);