/*
 *   File : footer.js
 *   Author : https://evoqins.com
 *   Description : Footer component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React from 'react';

const Footer = (props) => {
    return (
        <div className={`${props.type !== 2 && "position-absolute bottom-0 w-100"}`}>
            <div className={`${props.type === 2 && props.token === "super_admin" ? "e-bg-lavender" : "e-bg-bright-gray"} px-4 padding-62px-top padding-62px-bottom `}>
                <div className='container'>
                    <div className='d-flex align-items-start justify-content-space-between'>
                        <p className='mb-0 e-font-14 line-height-22px e-text-dark-silver e-font-weight-400 e-alt-font-noto-sans'>
                            {new Date().getFullYear()} © Jotun All Rights Reserved
                        </p>
                        {props.token === "country_admin" &&
                            <div className='d-flex gap-0-48'>
                                <a href='/' className='e-text-dark-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-22px text-decoration-hover-underline text-decoration-none cursor-pointer'>
                                    Home
                                </a>
                                {/* <a href='/' className='e-text-dark-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-22px text-decoration-hover-underline text-decoration-none cursor-pointer'>
                                    Terms
                                </a>
                                <a href='/' className='e-text-dark-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-22px text-decoration-hover-underline text-decoration-none cursor-pointer'>
                                    Privacy
                                </a> */}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;