/*
 *   File : flash-quiz-created.js
 *   Author : https://evoqins.com
 *   Description : Flash quiz created component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { Fragment, useState, useEffect, useRef } from 'react';

//Custom Component
import { DataTableComponent } from '../../../Component/DataTable';
import { CustomSelectBox } from '../../../Component/FormElements';
import { SummaryCard } from '../../../Component/Cards';
import { EmptyScreen } from '../../../Component/EmptyComponent';
import { Loader } from '../../../Component/Others';

// Service
import { APIService } from '../../../Service';

const TABLE_COLUMNS = [
    {
        name: 'Flash quiz name',
        selector: row => row.flash_quiz_name,
        width: "320px"
    },
    {
        name: 'Category',
        selector: row => row.category,
    },
    {
        name: 'Linked products',
        cell: row => row.linked_products ? row.linked_products.map((item, key) => `${item.product} ${key === row.linked_products.length - 1 ? "" : " , "}`) : "-",
    },
    {
        name: 'Status',
        // selector: row => row.status,
        cell: row =>
            <>
                {row.status ?
                    <div className={`${row.status === "Active" ? `e-bg-tea-green px-3` :
                        row.status === "Archived" ? `e-bg-light-red padding-12px-lr` : ``}  e-border-radius-16 e-font-weight-500 e-font-12 padding-2px-tb`}>
                        {row.status}
                    </div>
                    :
                    "--"
                }
            </>
    },
    {
        name: 'Taken',
        selector: row => row.enrolled_users
    },
    {
        name: "Reward points",
        selector: row => row.points
    }

];


const CATEGORY_OPTIONS = [
    {
        value: null,
        label: 'All',
    },
    {
        value: 1,
        label: 'Product'
    },
    {
        value: 2,
        label: 'Skills and service'
    },
];


const STATUS_OPTIONS = [
    {
        value: null,
        label: 'All',
    },
    {
        value: 1,
        label: 'Active'
    },
    {
        value: 2,
        label: 'Archived'
    },
];

const FlashQuizCreated = React.memo((props) => {

    const tableRef = useRef(null);

    const [toggleFilter, setToggleFilter] = useState(false);

    const [apiLoader, setApiLoader] = useState(true);

    const [selectedCategory, setSelectedCategory] = useState(CATEGORY_OPTIONS[0]);
    const [selectedStatus, setSelectedStatus] = useState(STATUS_OPTIONS[0]);
    const [pageNumber, setPageNumber] = useState(1);

    const [quizData, setQuizData] = useState({});
    const [summaryData, setSummaryData] = useState({});


    useEffect(() => {
        _getFlashQuiz();
        //eslint-disable-next-line
    }, [pageNumber, selectedCategory, selectedStatus]);

    // handle page change 
    const _handlePageChange = (page_num) => {
        setPageNumber(page_num);

        if (tableRef.current) {
            tableRef.current.scrollIntoView({ behavior: 'smooth', block: "start" });
        }
    };

    // API - quiz list
    const _getFlashQuiz = () => {
        const url = "country-admin/flash-quiz";

        const request = {
            "admin_id": props.adminId,
            "page_num": pageNumber,
            "category_filter": selectedCategory.value,
            "status_filter": selectedStatus.value
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setQuizData(response.data);
                setSummaryData(response.data.summary);
            } else {
                setSummaryData({});
                setQuizData({});
            }
            setApiLoader(false);
        });
    }

    return (
        <>
            {
                apiLoader ?
                    <div className='margin-20px-top e-bg-white e-admin-box-shadow py-4 e-border-radius-16 '>
                        {/* loader  */}
                        <Loader />
                    </div>
                    :
                    <Fragment>
                        {/* Summary  */}
                        <div className='e-admin-box-shadow p-4 e-bg-white e-border-radius-16'>
                            <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                                Summary
                            </h4>
                            <div className='row row-gap-3 row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-3 row-cols-2 padding-10px-top'>
                                <div className='col'>
                                    <SummaryCard title="Flash quizzes created"
                                        quantity={summaryData.quiz_created} />
                                </div>
                                <div className='col'>
                                    <SummaryCard title="Active flash quizzes"
                                        quantity={summaryData.active_quiz} />
                                </div>
                                <div className='col'>
                                    <SummaryCard title="Archived flash quizzes"
                                        quantity={summaryData.archived_quiz} />
                                </div>
                                <div className='col'>
                                    <SummaryCard title="Average enrollments"
                                        quantity={summaryData.avg_enrollments} />
                                </div>

                            </div>
                        </div>

                        {/* Flash quiz created table */}
                        <div ref={tableRef} className='margin-20px-top e-bg-white e-admin-box-shadow py-4 e-border-radius-16 '>
                            <div className='d-flex px-4 justify-content-space-between'>
                                <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-600 line-height-28px'>
                                    Flash quiz created
                                </h4>
                                <img src={toggleFilter ? require("../../../Assets/Image/filter-icon-white.svg").default : require("../../../Assets/Image/filter-icon.svg").default}
                                    draggable={false}
                                    className={`${toggleFilter ? `e-bg-indigo` : ``} transition e-border-1px-anti-flash-white  e-border-radius-8 p-2 cursor-pointer`}
                                    alt='filter'
                                    onClick={() => setToggleFilter(!toggleFilter)} />
                            </div>

                            {/* filter */}
                            {toggleFilter &&
                                <div className='e-bg-anti-flash-white-dark py-3 px-4 mt-2'>
                                    <div className='row'>
                                        <div className='col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6'>
                                            <CustomSelectBox label="Filter by"
                                                value={selectedCategory}
                                                options={CATEGORY_OPTIONS}
                                                type={2}
                                                className="e-bg-white e-border-radius-8 e-table-select"
                                                isSearchable={false}
                                                selectChange={(temp) => { setSelectedCategory(temp); }}
                                            />
                                        </div>
                                        <div className='col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6'>
                                            <CustomSelectBox label="Status"
                                                value={selectedStatus}
                                                options={STATUS_OPTIONS}
                                                className="e-bg-white e-border-radius-8 e-table-select"
                                                isSearchable={false}
                                                selectChange={(temp) => { setSelectedStatus(temp); }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* Data table */}

                            {
                                quizData.flash_quiz.length !== 0 ?
                                    <div className='e-table'>
                                        <DataTableComponent columns={TABLE_COLUMNS}
                                            pagination={true}
                                            paginationServer={true}
                                            paginationTotalRows={quizData.total_count}
                                            onChangePage={_handlePageChange}
                                            data={quizData.flash_quiz} />
                                    </div>
                                    :
                                    <div className='h-100 py-5 my-5'>
                                        <EmptyScreen message="No quiz found" />
                                    </div>
                            }
                        </div>
                    </Fragment>
            }
        </>
    )
})

export default FlashQuizCreated;