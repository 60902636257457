/*
 *   File : manage-quiz.js
 *   Author : https://evoqins.com
 *   Description : Manage quiz page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

// Custom component
import { PrimaryButton } from '../../../Component/Buttons';
import { CustomCheckbox, CustomTextInput, TextArea } from '../../../Component/FormElements';
import { Icon } from '../../../Component/Icon';
import { APIService } from '../../../Service';

const ANSWER_ERROR = {
    question: "",
    question_arabic: "",
    hint: "",
    hint_arabic: "",
    answers: [
        {
            answer: "",
            answer_arabic: "",
            is_answer: ""
        },
    ]
};

const ManageQuiz = () => {

    const { state } = useLocation();
    const moduleRef = useRef([]);
    const navigate = useNavigate();

    const [adminType, setAdminType] = useState(Cookies.get("admin_type") === "1" ? "super_admin" : "country_admin");
    const [title, setTitle] = useState({ en: "", ar: "" });
    const [titleError, setTitleError] = useState({ en: "", ar: "" });
    const [description, setDescription] = useState({ en: "", ar: "" });
    const [descriptionError, setDescriptionError] = useState({ en: "", ar: "" });

    const [rewardPoint, setRewardPoint] = useState("");
    const [rewardPointError, setRewardPointError] = useState("");
    const [quizTime, setQuizTime] = useState({ en: "", ar: "" });
    const [quizTimeError, setQuizTimeError] = useState({ en: "", ar: "" });

    const [sectionData, setSectionData] = useState([
        {
            question: "",
            question_arabic: "",
            hint: "",
            hint_arabic: "",
            answers: [
                {
                    answer: "",
                    answer_arabic: "",
                    is_answer: true
                },
                {
                    answer: "",
                    answer_arabic: "",
                    is_answer: false
                }
            ],
            errors: ANSWER_ERROR,
            id: 1
        }
    ]);

    const [editData, setEditData] = useState({});

    const [createLoader, setCreateLoader] = useState(true);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    }, []);

    useEffect(() => {
        if (state !== null) {
            if (state.type === "edit") {
                _getQuizDetail();
            }
        } else {
            navigate("/manage-resource");
        }
        setCreateLoader(false);
    }, [state]);

    console.log("state:", state)

    useEffect(() => {
        if (state !== null && state.type === "edit" && editData) {
            setTitle({ en: editData.title, ar: editData.title_arabic });
            setDescription({ en: editData.description, ar: editData.description_arabic });
            setRewardPoint(editData.points);
            setQuizTime({ en: editData.duration_text, ar: editData.duration_text_arabic });

            if (editData.question) {
                const updated_question = editData.question.map(question => ({
                    ...question,
                    errors: ANSWER_ERROR,
                }));
                setSectionData(updated_question);
            }
        }
    }, [editData]);

    useEffect(() => {
        setRewardPointError("");
    }, [rewardPoint]);

    // handle change title 
    const _handleChangeTitle = (lang, value) => {
        setTitle(prev => ({
            ...prev,
            [lang]: value
        }));

        setTitleError(prev => ({
            ...prev,
            [lang]: ""
        }))
    };

    // handle change title 
    const _handleChangeDescription = (lang, value) => {
        setDescription(prev => ({
            ...prev,
            [lang]: value
        }));

        setDescriptionError(prev => ({
            ...prev,
            [lang]: ""
        }))
    };

    // handle change quiz time 
    const _handleChangeQuizTime = (lang, value) => {
        setQuizTime(prev => ({
            ...prev,
            [lang]: value
        }));

        setQuizTimeError(prev => ({
            ...prev,
            [lang]: ""
        }));
    };

    // handle change
    const _handleChange = useMemo(() => (index, field, value) => {
        const new_data = [...sectionData];
        new_data[index] = {
            ...sectionData[index],
            [field]: value,
            errors: {
                ...sectionData[index].errors,
                [field]: "" // Clear error for the edited field
            }
        };
        setSectionData(new_data);
    }, [sectionData]);


    // handle change for answers
    const _handleChangeAnswer = (index, ans_index, field, value) => {
        const new_data = [...sectionData];

        if (field === "is_answer") {
            new_data[index].answers = new_data[index].answers.map((ans, idx) => ({
                ...ans,
                is_answer: idx === ans_index ? true : false
            }));
        } else {
            new_data[index].answers[ans_index] = {
                ...new_data[index].answers[ans_index],
                [field]: value
            };
        }

        if (field === 'answer') {
            const error_key = `answer${ans_index}`;
            new_data[index].errors[error_key] = "";
        } else if (field === 'answer_arabic') {
            const error_key = `answer_arabic${ans_index}`;
            new_data[index].errors[error_key] = "";
        }

        setSectionData(new_data);
    };

    // handle add more section
    const _handleAddSection = () => {
        setSectionData(prev => [
            ...prev, {
                question: "",
                question_arabic: "",
                hint: "",
                hint_arabic: "",
                answers: [
                    {
                        answer: "",
                        answer_arabic: "",
                        is_answer: true
                    },
                    {
                        answer: "",
                        answer_arabic: "",
                        is_answer: false
                    },
                ],
                errors: ANSWER_ERROR,
                id: prev.length + 1
            }
        ]);
    };

    // handle delete each section
    const _handleDeleteSection = (index) => {
        const new_sections = [...sectionData];
        new_sections.splice(index, 1);
        setSectionData(new_sections);
    };

    // handle add more answers
    const _handleAddMoreAnswer = (index) => {
        const new_data = [...sectionData];
        if (new_data[index].answers.length < 4) { // Limiting to a maximum of 4 answers
            new_data[index].answers.push({
                answer: "",
                is_answer: false
            });
            setSectionData(new_data);
        } else {

        }
    };

    // handle delete answer
    const _handleDeleteAnswer = (sec_index, answer_index) => {
        const new_data = [...sectionData];
        const deleted_answer = new_data[sec_index].answers[answer_index];
        new_data[sec_index].answers.splice(answer_index, 1);

        if (deleted_answer.is_answer && new_data[sec_index].answers.length > 0) {
            new_data[sec_index].answers[0].is_answer = true;   //set up index 0 should be selected if deleted selected answer
        }

        setSectionData(new_data);
    };

    // validate the form
    const _validate = () => {
        let is_valid = true;

        // Check title
        if (title.en.trim().length === 0) {
            setTitleError(prev => ({
                ...prev,
                en: "English title is required"
            }));
            is_valid = false;
        }

        if (title.ar.trim().length === 0) {
            setTitleError(prev => ({
                ...prev,
                ar: "Arabic title is required"
            }));
            is_valid = false;
        }

        if (description.en.trim().length === 0) {
            setDescriptionError(prev => ({
                ...prev,
                en: "Arabic description is required"
            }));
            is_valid = false;
        }

        if (description.ar.trim().length === 0) {
            setDescriptionError(prev => ({
                ...prev,
                ar: "Arabic description is required"
            }));
            is_valid = false;
        }

        // Check reward point
        if (rewardPoint.length === 0) {
            setRewardPointError("Reward point is required");
            is_valid = false;
        } else {
            setRewardPointError("")
        }
        if (quizTime.en.trim().length === 0) {
            setQuizTimeError(prev => ({
                ...prev,
                en: "Quiz time english is required"
            }));
            is_valid = false;
        }

        if (quizTime.ar.trim().length === 0) {
            setQuizTimeError(prev => ({
                ...prev,
                ar: "Quiz time arabic is required"
            }));
            is_valid = false;
        }

        // for error
        const new_section = sectionData.map((data) => {
            const error_object = {};

            // Check quiz question
            if (data.question.trim().length === 0) {
                error_object.question = "Quiz question english is required";
                is_valid = false;
            }

            if (data.question_arabic.trim().length === 0) {
                error_object.question_arabic = "Quiz question arabic is required";
                is_valid = false;
            }

            if (data.hint.trim().length === 0) {
                error_object.hint = "Hint english is required";
                is_valid = false;
            }

            if (data.hint_arabic.trim().length === 0) {
                error_object.hint_arabic = "Hint arabic is required";
                is_valid = false;
            }

            // Check answers
            data.answers.map((answer, index) => {
                if (answer.answer.trim().length === 0) {
                    error_object[`answer${index}`] = "Choice english is required";
                    is_valid = false;
                }

                if (answer.answer_arabic.trim().length === 0) {
                    error_object[`answer_arabic${index}`] = "Choice arabic is required";
                    is_valid = false;
                }
            });

            return { ...data, errors: error_object };
        });

        // focus error 
        if (state.type === "edit") {
            for (let i = 0; i < new_section.length; i++) {
                const section = new_section[i];
                if (section.errors && Object.values(section.errors).some(error => error.trim().length !== 0)) {
                    if (moduleRef.current[i]) {
                        moduleRef.current[i].scrollIntoView({ behavior: 'smooth', block: 'start', });
                        break;
                    }
                }
            }
        };

        setSectionData(new_section);

        return is_valid;
    };

    // handle submit quiz
    const _handleSubmitQuiz = () => {
        if (_validate()) {
            setCreateLoader(true);
            if (state.type === "edit") {
                _editQuiz();
            } else {
                _createQuiz();
            }
        } else {
            console.log("error");
        }
    };

    // handle back
    const _handleBack = () => {
        navigate("/manage-resource/course-detail", {
            state: {
                course_id: state.course_id,
                tab_id: 2,
                language: state.language
            }
        });
    };

    // API - Create quiz
    const _createQuiz = () => {
        let is_post = true;
        let url = adminType === "super_admin" ? 'course/add-quiz' : 'CA/course/add-quiz';
        let data = {
            "course_id": state.course_id,
            "title": title.en,
            "title_arabic": title.ar,
            "description": description.en,
            "description_arabic": description.ar,
            "time": quizTime.en,
            "time_arabic": quizTime.ar,
            "reward_points": rewardPoint,
            "questions": sectionData
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                // navigate("/manage-resource/course-detail", {
                //     state: {
                //         course_id: state.course_id,
                //         tab_id: 2
                //     }
                // });

                navigate('/manage-resource/manage-download', {
                    state: {
                        course_id: state.course_id,
                        type: "",
                        language: state.language
                    }
                });

                toast.dismiss();
                toast.success("Quiz added successfully", {
                    type: "success",
                    className: "e-toast",
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setCreateLoader(false);
        });
    };

    // API - Update quiz
    const _editQuiz = () => {
        let is_post = true;
        let url = adminType === "super_admin" ? 'course/edit-quiz' : 'CA/course/edit-quiz';
        let data = {
            "course_id": state.course_id,
            "title": title.en,
            "title_arabic": title.ar,
            "description": description.en,
            "description_arabic": description.ar,
            "time": quizTime.en,
            "time_arabic": quizTime.ar,
            "reward_points": rewardPoint,
            "questions": sectionData
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                navigate("/manage-resource/course-detail", {
                    state: {
                        course_id: state.course_id,
                        tab_id: 2,
                        language: state.language
                    }
                });

                toast.dismiss();
                toast.success("Quiz updated successfully", {
                    type: "success",
                    className: "e-toast",
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setCreateLoader(false);
        });
    };

    // API - Quiz detail
    const _getQuizDetail = () => {
        let is_post = true;
        let url = adminType === "super_admin" ? 'course/quiz-detail' : 'CA/course/quiz-detail';
        let data = {
            "course_id": state.course_id
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setEditData(response.data);
            } else { }
        });
    };

    return (
        <section className='pb-5 '>
            <div className={`${adminType === "super_admin" ? 'e-bg-azureish-white' : 'e-bg-lavender-blush-light'} pt-3 padding-98px-bottom padding-52px-lr`}>
                <div className='e-display-flex'>
                    <Icon icon="arrow-left"
                        className='cursor-pointer'
                        size="24px"
                        onPress={_handleBack} />
                    <h1 className='ps-1 mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                        {state?.type === "edit" ? 'Edit quiz' : 'Add new quiz'}
                    </h1>
                </div>
            </div>

            <div className='margin-neg-66px-top padding-52px-lr'>
                <div className='e-bg-white e-admin-box-shadow p-4 e-border-radius-16 '>
                    <div className='d-flex gap-0-8 justify-content-space-between'>
                        <h3 className='mb-0 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px'>
                            Quiz details
                        </h3>

                        {/* handle submit button */}
                        <PrimaryButton name={state?.type === "edit" ? "Save & Update Quiz" : "Save & Add Quiz"}
                            className={`${createLoader ? 'padding-6px-tb' : 'py-2'} e-border-radius-16 e-font-16 w-186px`}
                            status={createLoader}
                            onPress={_handleSubmitQuiz} />
                    </div>

                    {/* input section */}
                    <div className='row'>
                        <div className='col-xl-9 col-lg-9 col-md-10 col-12'>
                            {/* Quiz name english */}
                            <CustomTextInput label="Quiz title (English)"
                                placeholder="Successful negotiation essential strategies & skills"
                                type="text"
                                require
                                value={title.en}
                                error={titleError.en}
                                className="w-100 padding-10px-tb"
                                labelStyle="e-font-weight-500 e-text-raisin-black"
                                borderStyle="e-border-1px-platinum-light"
                                handleChange={(e) => _handleChangeTitle("en", e)} />

                            {/* Quiz name arabic */}
                            <CustomTextInput label="Quiz title (Arabic)"
                                placeholder="Successful negotiation essential strategies & skills"
                                type="text"
                                require
                                value={title.ar}
                                error={titleError.ar}
                                className="w-100 padding-10px-tb"
                                labelStyle="e-font-weight-500 e-text-raisin-black"
                                borderStyle="e-border-1px-platinum-light"
                                handleChange={(e) => _handleChangeTitle("ar", e)} />

                            {/*English description */}
                            <TextArea label="Quiz description (English)"
                                placeholder="Enter your text"
                                type="text"
                                rows="3"
                                value={description.en}
                                error={descriptionError.en}
                                require
                                className="w-100 px-3 padding-10px-tb"
                                handleChange={(e) => _handleChangeDescription("en", e)} />

                            {/* Arabic description */}
                            <TextArea label="Quiz description (Arabic)"
                                placeholder="Enter your text"
                                type="text"
                                rows="3"
                                value={description.ar}
                                error={descriptionError.ar}
                                require
                                className="w-100 px-3 padding-10px-tb"
                                handleChange={(e) => _handleChangeDescription("ar", e)} />

                            <div className='row'>
                                {/* Quiz time english*/}
                                <div className='col-6 pe-2'>
                                    <CustomTextInput label="Quiz time (English)"
                                        placeholder="2 min"
                                        type="text"
                                        require
                                        value={quizTime.en}
                                        error={quizTimeError.en}
                                        className="w-100 padding-10px-tb"
                                        labelStyle="e-font-weight-500 e-text-raisin-black"
                                        borderStyle="e-border-1px-platinum-light e-border-radius-8"
                                        handleChange={(e) => _handleChangeQuizTime("en", e)} />
                                </div>

                                {/* Quiz time arabic*/}
                                <div className='col-6 ps-2'>
                                    <CustomTextInput label="Quiz time (Arabic)"
                                        placeholder="2 min"
                                        type="text"
                                        require
                                        value={quizTime.ar}
                                        error={quizTimeError.ar}
                                        className="w-100 padding-10px-tb"
                                        labelStyle="e-font-weight-500 e-text-raisin-black"
                                        borderStyle="e-border-1px-platinum-light e-border-radius-8"
                                        handleChange={(e) => _handleChangeQuizTime("ar", e)} />
                                </div>
                            </div>

                            <div className='row'>
                                {/* Reward point */}
                                <div className='col-6'>
                                    <CustomTextInput label="Total reward point"
                                        placeholder="100"
                                        type="number"
                                        require
                                        value={rewardPoint}
                                        error={rewardPointError}
                                        className="w-100 padding-10px-tb"
                                        labelStyle="e-font-weight-500 e-text-raisin-black"
                                        borderStyle="e-border-1px-platinum-light e-border-radius-8"
                                        handleChange={(e) => setRewardPoint(e)} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Add sections */}
                    {sectionData.map((data, key) => (
                        <div key={key} ref={error => moduleRef.current[key] = error} className='row mb-2'>
                            <div className='col-xl-9 col-lg-9 col-md-10 col-12'>

                                <div className={`${key === 0 ? 'justify-content-between' : 'justify-content-end'} d-flex align-items-center`}>
                                    {key === 0 &&
                                        <h3 className='mb-3 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px'>
                                            {state?.type === "edit" ? "Edit modules" : "Add modules"}
                                        </h3>
                                    }

                                    {sectionData.length > 1 &&
                                        <p className='mb-2 text-end e-text-lust e-font-16 e-alt-font-noto-sans e-font-weight-600 line-height-22px cursor-pointer text-decoration-hover-underline'
                                            onClick={() => _handleDeleteSection(key)} >
                                            Delete
                                        </p>
                                    }
                                </div>

                                <div className='e-bg-ghost-white p-3 e-border-radius-16'>

                                    {/* Quiz question english*/}
                                    <CustomTextInput label="Quiz question (English)"
                                        placeholder="Successful negotiation essential strategies & skills"
                                        type="text"
                                        require
                                        value={data.question}
                                        error={data.errors.question}
                                        className="w-100 padding-10px-tb"
                                        labelStyle="e-font-weight-500 e-text-raisin-black"
                                        borderStyle="e-border-1px-platinum-light"
                                        handleChange={(e) => _handleChange(key, 'question', e)}
                                    />

                                    {/* Quiz question arabic*/}
                                    <CustomTextInput label="Quiz question (Arabic)"
                                        placeholder="Successful negotiation essential strategies & skills"
                                        type="text"
                                        require
                                        value={data.question_arabic}
                                        error={data.errors.question_arabic}
                                        className="w-100 padding-10px-tb"
                                        labelStyle="e-font-weight-500 e-text-raisin-black"
                                        borderStyle="e-border-1px-platinum-light"
                                        handleChange={(e) => _handleChange(key, 'question_arabic', e)}
                                    />

                                    {/* questions */}
                                    {data.answers.map((answers, index) => (
                                        <div className='row'>
                                            <div className='col-10'>
                                                <CustomTextInput label={index === 0 ? `Add choices` : ``}
                                                    placeholder={`Choice ${index + 1} (English)`}
                                                    type="text"
                                                    require
                                                    value={answers.answer}
                                                    error={data.errors[`answer${index}`]}
                                                    className="w-100 padding-10px-tb"
                                                    labelStyle="e-font-weight-500 e-text-raisin-black"
                                                    borderStyle="e-border-1px-platinum-light"
                                                    handleChange={(e) => _handleChangeAnswer(key, index, 'answer', e)}

                                                />

                                                {/* Arabic choices */}
                                                <CustomTextInput
                                                    placeholder={`Choice ${index + 1} (Arabic)`}
                                                    type="text"
                                                    require
                                                    value={answers.answer_arabic}
                                                    error={data.errors[`answer_arabic${index}`]}
                                                    className="w-100 padding-10px-tb"
                                                    labelStyle="e-font-weight-500 e-text-raisin-black"
                                                    borderStyle="e-border-1px-platinum-light"
                                                    handleChange={(e) => _handleChangeAnswer(key, index, 'answer_arabic', e)}

                                                />
                                            </div>
                                            <div className='col-2 text-center'>
                                                <CustomCheckbox label={index === 0 ? "Answer" : ""}
                                                    require
                                                    checked={answers.is_answer}
                                                    className="w-100 padding-10px-tb"
                                                    labelStyle="e-font-weight-500 e-text-raisin-black"
                                                    borderStyle="e-border-1px-platinum-light"
                                                    handleChange={(e) => _handleChangeAnswer(key, index, 'is_answer', e)}
                                                />

                                                {/* delete answer */}
                                                {data.answers.length > 2 &&
                                                    <div className={`text-end ${index === 0 ? 'pt-3' : 'pt-1'}`}>
                                                        <img src={require("../../../Assets/Image/CountryAdmin/delete.svg").default}
                                                            draggable={false}
                                                            className='cursor-pointer'
                                                            alt='delete'
                                                            onClick={() => _handleDeleteAnswer(key, index)} />
                                                    </div>}

                                            </div>
                                        </div>
                                    ))}

                                    <div className={`d-flex ${data.answers.length < 4 ? 'justify-content-between' : 'justify-content-end'}`}>
                                        {/* add more answer */}
                                        {data.answers.length < 4 &&
                                            <p className='mb-0 e-text-indigo e-font-14 e-alt-font-noto-sans e-font-weight-600 line-height-22px cursor-pointer'
                                                onClick={() => _handleAddMoreAnswer(key)} >
                                                + <span className='cursor-pointer text-decoration-hover-underline '>Add answer</span>
                                            </p>
                                        }
                                    </div>

                                    {/* Add hint */}
                                    <div className='pt-2'>
                                        {/* English hint */}
                                        <CustomTextInput label="Add hint (English)"
                                            placeholder="Enhances paints grip for a smooth and durable finish"
                                            type="text"
                                            require
                                            value={data.hint}
                                            error={data.errors.hint}
                                            className="w-100 padding-10px-tb"
                                            labelStyle="e-font-weight-500 e-text-raisin-black"
                                            borderStyle="e-border-1px-platinum-light"
                                            handleChange={(e) => _handleChange(key, 'hint', e)} />

                                        {/* Arabic hint */}
                                        <CustomTextInput label="Add hint (Arabic)"
                                            placeholder="Enhances paints grip for a smooth and durable finish"
                                            type="text"
                                            require
                                            value={data.hint_arabic}
                                            error={data.errors.hint_arabic}
                                            className="w-100 padding-10px-tb"
                                            labelStyle="e-font-weight-500 e-text-raisin-black"
                                            borderStyle="e-border-1px-platinum-light"
                                            handleChange={(e) => _handleChange(key, 'hint_arabic', e)} />
                                    </div>
                                </div>
                            </div>

                            {/* Add more button */}
                            < div className='col-xl-3 col-lg-3 col-md-10 col-12 text-xl-center text-lg-center text-end pt-xl-0 pt-lg-0 pt-2 my-auto' >
                                {
                                    sectionData.length === key + 1 &&
                                    <p className='mb-0 e-text-indigo e-font-16 e-alt-font-noto-sans e-font-weight-600 line-height-22px cursor-pointer'
                                        onClick={_handleAddSection} >
                                        + <span className='cursor-pointer text-decoration-hover-underline '>Add new quiz</span>
                                    </p>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div >
        </section >
    )
}

export default ManageQuiz;