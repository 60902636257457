/*
 *   File : manage-notification.js
 *   Author : https://evoqins.com
 *   Description :  Manage notification page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';

// Custom components
import { ScheduledNotifications } from '../../../Component/Cards';
import { ConfigureAlarm, ConfigurePushNotification } from './Helper';
import { CustomSelectBox } from '../../../Component/FormElements';
import { APIService } from '../../../Service';
import { Loader } from '../../../Component/Others';
import { EmptyScreen } from '../../../Component/EmptyComponent';
import { ConfirmDeleteModal } from '../../../Component/Modal';
import { Pagination } from '../../../Component/pagination';

//Assets
import NextIcon from "../../../Assets/Image/next-icon.svg";

const NOTIFICATION_TAB = [
    {
        id: 1,
        name: "Push notifications"
    },
    {
        id: 2,
        name: "Jotun alarm"
    },
    {
        id: 3,
        name: "View schedule"
    },
];

const SCHEDULED_FILTER = [
    {
        value: 0,
        label: 'All',
    },
    {
        value: 1,
        label: 'Weekly'
    },
    {
        value: 2,
        label: 'Monthly'
    },
    {
        value: 3,
        label: 'Yearly'
    },
];

const ITEMS_PER_PAGE = 12;

const ManageNotification = () => {

    const navigate = useNavigate();
    const { state } = useLocation();

    const [tabIndex, setTabIndex] = useState(0);
    const [toggleFilter, setToggleFilter] = useState(false);
    const [selected, setSelected] = useState(SCHEDULED_FILTER[0]);
    const [scheduledData, setScheduledData] = useState({});
    const [loading, setLoading] = useState(true);
    const [deleteId, setDeleteId] = useState(null);
    const [notificationDeleteResponse, setNotificationDeleteResponse] = useState(false);
    const [totalPageCount, setTotalPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [editScheduled, setEditScheduled] = useState({});

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    }, [tabIndex]);

    useEffect(() => {
        const opened_modal = document.querySelector('.modal.show');
        if (opened_modal) {
            const modal = Modal.getInstance(opened_modal);
            modal.hide();

            setDeleteId(null);
        }
    }, [state]);

    useEffect(() => {
        if (state !== null) {
            setTabIndex(state);

            if (state.type === "edit") {
                setTabIndex(state.id);
                setEditScheduled(state.data);
            } else {
                setEditScheduled({});
            }

        } else {
            setTabIndex(1);
        }
        setDeleteId(null);
    }, [state]);

    useEffect(() => {
        if (tabIndex === 3) {
            _getScheduledNotification();
        }
    }, [tabIndex, currentPage, selected]);

    useEffect(() => {
        if (deleteId !== null) {
            const delete_modal = new Modal(document.getElementById('confirm-delete-modal'));
            delete_modal.show();
        }
    }, [deleteId]);

    // handle tab change 
    const _handleTabChange = (id) => {
        navigate("/manage-notification", {
            state: id
        });
    };

    // handle filter scheduled notification 
    const _handleFilterScheduled = (value) => {
        setSelected(value);
        setLoading(true);
    };

    //handle pagination
    const _handlePagination = (new_start_limit) => {
        const selected_page = Math.floor(new_start_limit / ITEMS_PER_PAGE) + 1;
        setCurrentPage(selected_page);

        window.scrollTo({
            top: 0, behavior: 'smooth'
        });
    };

    // handle send scheduled notification now 
    const _handleSendNotification = (id) => {
        _sendNotificationNow(id);
    };

    // handle delete scheduled notification
    const _handleDeleteNotification = (id) => {
        setDeleteId(id);
        setNotificationDeleteResponse(false);
    };

    //API - Get scheduled notification list
    const _getScheduledNotification = () => {
        let is_post = true;
        let url = 'CA/notifications/scheduled-notifications';
        let data = {
            "page_num": currentPage,
            "filter": selected.value === 0 ? null : selected.value
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setScheduledData(response.data);
                setTotalPageCount(Math.ceil(response.data.total_entries / ITEMS_PER_PAGE));
            } else { }
            setLoading(false);
        });
    };

    //API - Delete scheduled notification
    const _deleteNotification = () => {
        let is_post = true;
        let url = 'CA/notifications/delete-scheduled-notification';
        let data = {
            "notification_id": deleteId
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setNotificationDeleteResponse(true);
            } else {
                setNotificationDeleteResponse(false);
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        });
    };

    //API - Send scheduled notification now
    const _sendNotificationNow = (notification_id) => {
        let is_post = true;
        let url = 'CA/notifications/send-now';
        let data = {
            "notification_id": notification_id
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Notification send successfully", {
                    type: "success",
                    className: "e-toast",
                });
                setLoading(true);
                _getScheduledNotification();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        });
    };

    return (
        <section className='pb-5 min-h-70vh'>
            <div className='e-bg-lavender-blush-light pt-3  padding-52px-lr padding-20px-bottom'>
                <h1 className='mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                    Notification settings
                </h1>

                {/* Notification category tab */}
                <div className='d-flex gap-0-16 padding-40px-top overflow-auto pb-xl-0 pb-lg-0 pb-md-0 pb-1 e-tab-scroll'>
                    {NOTIFICATION_TAB.map((data, key) => (
                        <div key={key} className={`${tabIndex === data.id ? 'e-bg-lust e-text-white border-transparent' : 'e-border-1px-cool-grey e-text-rich-black'}  e-border-radius-24 cursor-pointer `}
                            onClick={() => _handleTabChange(data.id)} >
                            <p className='mb-0 px-4 padding-12px-tb e-alt-font-noto-sans  e-font-14 e-font-weight-500 line-height-14px w-max-content'>
                                {data.name}
                            </p>
                        </div>
                    ))}
                </div>
            </div>

            <div className='padding-52px-lr pt-4'>

                {tabIndex === 1 ?
                    // push notification
                    <ConfigurePushNotification editData={editScheduled} />
                    :
                    tabIndex === 2 ?
                        <ConfigureAlarm />
                        :
                        //  Scheduled notification
                        <div className=''>
                            <div className='d-flex justify-content-space-between'>
                                <h4 className='mb-0 e-text-dark-jungle-green e-primary-font-playfair e-font-18 e-font-weight-500 line-height-28px'>
                                    Scheduled notification
                                </h4>
                                <img src={toggleFilter ? require("../../../Assets/Image/filter-icon-white.svg").default : require("../../../Assets/Image/filter-icon.svg").default}
                                    draggable={false}
                                    className={`${toggleFilter ? `e-bg-indigo` : ``} transition e-border-1px-cool-grey  e-border-radius-8 p-2 cursor-pointer`}
                                    alt='filter'
                                    onClick={() => setToggleFilter(!toggleFilter)} />
                            </div>

                            {/* toggle filter */}
                            {toggleFilter &&
                                <div className='e-bg-anti-flash-white-dark py-3 px-4 mt-3'>
                                    <div className='row'>
                                        <div className='col-xl-2 col-lg-3 col-md-4 col-sm-5 col-8'>
                                            <CustomSelectBox label="Select type"
                                                value={selected}
                                                options={SCHEDULED_FILTER}
                                                type={2}
                                                className="e-bg-white e-border-radius-4 e-table-select "
                                                labelClass="e-font-weight-600"
                                                isSearchable={false}
                                                selectChange={(value) => _handleFilterScheduled(value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* notification card */}
                            <div className='pt-3 mt-1'>
                                {loading ?
                                    <Loader />  //loader
                                    :
                                    <>
                                        {scheduledData.data.length !== 0 ?
                                            <>
                                                {scheduledData.data.map((data, key) => (
                                                    <ScheduledNotifications key={key}
                                                        data={data}
                                                        type={3}
                                                        handleSend={_handleSendNotification}
                                                        handleDelete={_handleDeleteNotification} />
                                                ))}

                                                {/* pagination */}
                                                {totalPageCount > 1 &&
                                                    <div className="d-flex justify-content-center e-gap-0-16 mt-4 pt-3">
                                                        <Pagination itemsPerPage={ITEMS_PER_PAGE}
                                                            totalPage={totalPageCount}
                                                            onChange={_handlePagination}
                                                            showFirstLastPages={true} />
                                                    </div>
                                                }
                                            </>
                                            :
                                            <div className='h-100vh pt-5 mt-5'>
                                                {/* empty screen */}
                                                <EmptyScreen message="No scheduled notification found" />
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                }
            </div>

            {/* delete modal */}
            {deleteId !== null &&
                <ConfirmDeleteModal label="notification"
                    deleteId={deleteId}
                    response={notificationDeleteResponse}
                    delete={() => _deleteNotification()}
                    handleClose={() => setDeleteId(null)}
                    success={() => _getScheduledNotification()} />
            }
        </section >
    )
}

export default ManageNotification;