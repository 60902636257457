
/*
 *   File : scheduled-notification.js
 *   Author : https://evoqins.com
 *   Description : Statistics card component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo, useState } from 'react';

// Custom components
import { OverLay } from '../Others';
import { useNavigate } from 'react-router-dom';

const ScheduledNotifications = (props) => {

    const navigate = useNavigate()

    const [toggleMore, setToggleMore] = useState(false);

    // handle edit scheduled notification
    const _handleEditScheduled = (data) => {
        navigate("/manage-notification", {
            state: {
                id: 1,
                data: data,
                type: "edit"
            }
        });
    };

    return (
        <>
            {/* push notification history  */}
            {props.type === 1 ?
                <div className='e-bg-white p-3 e-border-radius-16 mt-3'>
                    <div className='row'>
                        <div className='col-2 pe-3'>
                            <img src={props.notification.image_url}
                                draggable={false}
                                className='w-100 e-border-radius-16'
                                height={120}
                                alt='notification' />
                        </div>
                        <div className='col-10 ps-0'>
                            <div className='d-flex justify-content-between'>
                                <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                                    {props.notification.created}
                                </p>
                                <span className='e-text-indigo e-alt-font-noto-sans e-font-16 e-font-weight-600 line-height-22px cursor-pointer'
                                    onClick={props.handleResend}>
                                    Resend
                                </span>
                            </div>
                            <h6 className='col-9 mb-0 padding-6px-top e-primary-font-playfair e-font-16 line-height-20px e-text-raisin-black e-font-weight-600 e-ellipse-single'>
                                {props.notification.title}
                            </h6>
                            <p className='col-9 mb-0 padding-12px-top e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px e-ellipse-3-line'>
                                {props.notification.description}
                            </p>
                        </div>
                    </div>
                </div>
                :
                // Scheduled notification 
                props.type === 3 ?
                    <div className='e-bg-white p-3 e-border-radius-16 mb-3'>
                        <div className='row'>
                            <div className='col-3 pe-3'>
                                <img src={props.data.image_url !== null ? props.data.image_url : require("../../Assets/Image/CountryAdmin/Notification/notification.png")}
                                    draggable={false}
                                    className='w-100 e-border-radius-16'
                                    height={128}
                                    alt='notification' />
                            </div>
                            <div className='col-8 ps-0'>
                                <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                                    Next due on {props.data.date}, {props.data.time}
                                </p>
                                <h6 className='mb-0 padding-6px-top e-primary-font-playfair e-font-16 line-height-20px e-text-raisin-black e-font-weight-600 e-ellipse-single'>
                                    {props.data.title}
                                </h6>
                                <p className='mb-0 padding-12px-top e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px e-ellipse-3-line'>
                                    {props.data.description}
                                </p>
                            </div>
                            <div className='col-1 text-end'>
                                <div className="e-dropdown-more-wrapper position-relative ">
                                    <img src={require("../../Assets/Image/Header/menu.svg").default}
                                        draggable={false}
                                        className=' e-border-1px-anti-flash-white e-border-radius-50per p-1 cursor-pointer'
                                        alt='menu'
                                        onClick={() => setToggleMore(!toggleMore)} />

                                    {toggleMore &&
                                        <>
                                            <OverLay isOpen={toggleMore} onClose={() => setToggleMore(!toggleMore)} />
                                            <div className="e-dropdown-more e-bg-white text-start top-24px right-neg-10px"
                                                onClick={() => setToggleMore(!toggleMore)}>
                                                <p className='mb-4 e-text-rich-black-light e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px cursor-pointer text-decoration-hover-underline'
                                                    onClick={() => props.handleSend(props.data.notification_id)}>
                                                    Send now
                                                </p>
                                                <p className='mb-4 e-text-rich-black-light e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px cursor-pointer text-decoration-hover-underline'
                                                    onClick={() => _handleEditScheduled(props.data)}>
                                                    Edit content
                                                </p>
                                                <p className='mb-0 e-text-rich-black-light e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px cursor-pointer text-decoration-hover-underline'
                                                    onClick={() => props.handleDelete(props.data.notification_id)}>
                                                    Delete content
                                                </p>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className='text-end margin-top-neg-24px'>
                                <span className='e-bg-tea-green py-1 px-2 e-border-radius-100px e-text-raisin-black e-alt-font-noto-sans e-font-12 e-font-weight-500 line-height-16px'>
                                    Push notification
                                </span>
                            </div>
                        </div>
                    </div>
                    :
                    // Alarm notification scheduled 
                    <div className='mt-3'>
                        <div className='e-bg-white p-3 e-border-radius-16'>
                            <div className='row'>
                                <div className='col-2 pe-3'>
                                    <img src={props.data.image_url}
                                        draggable={false}
                                        className='w-100 e-border-radius-16'
                                        height={120}
                                        alt='notification' />
                                </div>
                                <div className='col-9 ps-0'>
                                    <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                                        Schedule from {props.data.start_date} to {props.data.end_date}
                                    </p>
                                    <h6 className='mb-0 padding-6px-top e-primary-font-playfair e-font-16 line-height-20px e-text-raisin-black e-font-weight-600 e-ellipse-single'>
                                        {props.data.title}
                                    </h6>
                                    <p className='mb-0 padding-12px-top e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px e-ellipse-3-line'>
                                        {props.data.description}
                                    </p>
                                </div>
                                <div className='col-1 text-end'>
                                    <div className="e-dropdown-more-wrapper position-relative ">
                                        <img src={require("../../Assets/Image/Header/menu.svg").default}
                                            draggable={false}
                                            className=' e-border-1px-anti-flash-white e-border-radius-50per p-1 cursor-pointer'
                                            alt='menu'
                                            onClick={() => setToggleMore(!toggleMore)} />

                                        {toggleMore &&
                                            <>
                                                <OverLay isOpen={toggleMore} onClose={() => setToggleMore(!toggleMore)} />
                                                <div className="e-dropdown-more e-bg-white text-start top-24px right-neg-10px"
                                                    onClick={() => setToggleMore(!toggleMore)}>
                                                    <p className='mb-0 e-text-rich-black-light e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px cursor-pointer text-decoration-hover-underline'
                                                        onClick={() => props.handleDelete(props.data.alarm_id)} >
                                                        Delete
                                                    </p>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default memo(ScheduledNotifications);