/*
 *   File : summary.js
 *   Author : https://evoqins.com
 *   Description : Summary card component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo } from 'react';

const SummaryCard = (props) => {
    return (
        <div className='e-bg-magnolia p-3 e-border-radius-16'>
            <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-24px w-max-content'>
                {props.title}
            </p>
            {props.loader ?
                <div class="padding-14px-top placeholder-glow ">
                    <p class="mb-0 h-24px placeholder col-6 e-border-radius-8 e-bg-columbia-blue"></p>
                </div>
                :
                <div className="pt-3 placeholder-glow ">
                    <p className="mb-0 e-text-charleston-green e-alt-font-noto-sans e-font-18 e-font-weight-600 line-height-24px">
                        {props.quantity}
                    </p>
                </div>
            }
        </div>
    )
}

SummaryCard.defaultProps = {
    quantity: 0
}

export default memo(SummaryCard);