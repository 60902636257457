/*
 *   File : notification.js
 *   Author : https://evoqins.com
 *   Description : Notification card component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo } from 'react';

const NotificationCard = (props) => {
    return (
        <div className={`${props.notification.read ? 'e-border-1px-platinum-dark' : 'e-border-1px-cool-grey'} e-border-radius-16 p-3 cursor-pointer`}
            onClick={() => props.handleClick(props.notification)} >
            <div className='row'>
                <div className='col-xl-10 col-lg-10 col-md-9 col-sm-9 col-12'>
                    <div className='d-flex gap-0-24px align-items-start'>
                        <img src={props.notification.read ? require("../../Assets/Image/Card/notification-inactive.svg").default :
                            require("../../Assets/Image/Card/notification-active.svg").default}
                            draggable={false}
                            className='mt-1'
                            alt='notification' />
                        <div>
                            <h6 className={`${props.notification.read ? ' e-text-davys-grey' : ' e-text-raisin-black'} mb-2 e-primary-font-playfair e-font-18 line-height-24px e-font-weight-600 e-ellipse`}>
                                {props.notification.title}
                            </h6>
                            <p className={`${props.notification.read ? ' e-text-davys-grey' : ' e-text-raisin-black'} mb-0 e-alt-font-noto-sans e-font-16 e-font-weight-500 line-height-22px`}>
                                {props.notification.description}
                            </p>
                        </div>
                    </div>
                </div>

                <div className='col-xl-2 col-lg-2 col-md-3 col-sm-3 col-12 text-end my-auto'>
                    <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                        {props.notification.created}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default memo(NotificationCard);