/*
 *   File : offer.js
 *   Author : https://evoqins.com
 *   Description : Offer card component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo } from 'react';
import { Modal } from 'bootstrap';

// Custom component
import { TextButton } from '../Buttons';

const OfferCard = (props) => {
    return (
        <div className="card w-100 overflow-hidden border-0 e-admin-box-shadow e-border-radius-16 ">
            <img src={props.data.banner_url}
                draggable={false}
                className='w-100'
                height={160}
                alt="card-img" />

            <div className="card-body padding-12px-top pb-3">
                <div className='d-flex justify-content-space-between'>
                    <h6 className="text-truncate mb-0 e-alt-font-noto-sans e-text-raisin-black e-font-18 e-font-weight-500 line-height-24px">
                        {props.data.title}
                    </h6>

                    <div className='e-display-flex'>
                        <img src={require("../../Assets/Image/Card/streak.svg").default}
                            draggable={false}
                            alt='streak' />
                        <p className='mb-0 e-alt-font-noto-sans e-text-raisin-black e-font-24 e-font-weight-600 line-height-24px'>
                            {props.data.reward_points}
                        </p>
                    </div>
                </div>

                <p className='mb-2 pt-3 e-alt-font-noto-sans e-text-raisin-black e-font-14 e-font-weight-400 line-height-22px'>
                    {props.data.description}
                </p>

                <div className='d-flex gap-0-24px'>
                    <div >
                        <p className='mb-0 e-alt-font-noto-sans e-text-raisin-black e-font-12 e-font-weight-400 line-height-22px'>
                            Created on
                        </p>
                        <p className='mb-0 e-alt-font-noto-sans e-text-raisin-black e-font-14 e-font-weight-700 line-height-22px'>
                            {props.data.created}
                        </p>
                    </div>
                    <div>
                        <p className='mb-0 e-alt-font-noto-sans e-text-raisin-black e-font-12 e-font-weight-400 line-height-22px'>
                            Total redeemed
                        </p>
                        <p className='mb-0 e-alt-font-noto-sans e-text-raisin-black e-font-14 e-font-weight-700 line-height-22px'>
                            {props.data.total_redeemed}
                        </p>
                    </div>
                </div>

                <div className='text-end padding-12px-top'>
                    <TextButton name="Edit"
                        className="e-font-16"
                        onPress={props.handleEdit} />
                    <TextButton name="Delete"
                        className="ps-3 e-text-lust e-font-16"
                        onPress={props.handleDelete} />

                </div>
            </div>
        </div>
    )
}

export default memo(OfferCard);