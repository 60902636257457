/*
 *   File : primary.js
 *   Author : https://evoqins.com
 *   Description : CTA component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo } from 'react';

// Assets 
import Loader from "../../Assets/loader-light.gif";

const PrimaryButton = (props) => {

    return (
        <button className={`${props.className} ${props.status ? `pe-none px-4 padding-10px-tb` : `px-3 padding-12px-top padding-12px-bottom`} ${props.disabled === true ? 'opacity-7' : ''} e-border-radius-12 e-font-14 e-bg-indigo border-transparent e-alt-font-noto-sans e-text-white e-font-weight-500 line-height-20px`}
            data-bs-toggle={props.toggle}
            data-bs-target={props.target}
            data-bs-cancel={props.cancel}
            data-bs-dismiss={props.dismiss}
            disabled={props.disabled}
            id={props.id}
            onClick={(e) => props.onPress(e)}>

            {props.status ?
                // <span className={`${props.loaderClass} spinner-border text-light h-20px w-20px`}></span>
                <img src={Loader}
                    className={`${props.loaderClass} w-20px h-20px margin-2px-block`}
                    alt='loader' />
                :
                <span>{props.name}</span>
            }
        </button>
    )
}

PrimaryButton.defaultProps = {
    onPress: () => { },
    className: null,
    id: null,
    name: null,
    icon: null
};

export default memo(PrimaryButton);