/*
 *   File : empty.js
 *   Author : https://evoqins.com
 *   Description : No data found component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React from 'react'

const EmptyScreen = (props) => {
    return (
        <div className='text-center'>
            <img src={require("../../Assets/Image/empty-icon.svg").default}
                draggable={false}
                alt='empty' />
            <h3 className='mb-0 pt-3 e-primary-font-playfair e-font-16 line-height-22px e-text-rich-black e-font-weight-600'>
                {props.message}
            </h3>
        </div>
    )
}

export default EmptyScreen;