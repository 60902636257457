/*
 *   File : country-admins-detail.js.js
 *   Author : https://evoqins.com
 *   Description : Country Admin detail page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';

//Custom component
import { CoursesCreated, FlashQuizCreated, QuizCreated, RewardPoint, Shop, UserRegistered, VideoSnippet } from '../Helper';
import { Loader, OverLay } from '../../../Component/Others';
import { BlockAdminModal, ResetPasswordModal } from '../../../Component/Modal';

// Service
import { APIService } from '../../../Service';

const CATEGORY_TAB = [
    {
        id: 1,
        name: "Users registered"
    },
    {
        id: 2,
        name: "Courses created"
    },
    {
        id: 3,
        name: "Video snippet"
    },
    {
        id: 4,
        name: "Quiz created"
    },
    {
        id: 5,
        name: "Flash quiz created"
    },
    {
        id: 6,
        name: "Shops & Sales"
    },
    {
        id: 7,
        name: "Reward points/ sales"
    },
]

const CountryAdminsDetail = () => {

    const navigate = useNavigate();
    const { state } = useLocation();

    const [tabIndex, setTabIndex] = useState(1);
    const [toggleMore, setToggleMore] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showBlock, setShowBlock] = useState(false);

    const [apiLoader, setApiLoader] = useState(true);
    const [btnLoader, setBtnLoader] = useState(false);

    const [adminDetail, setAdminDetail] = useState({});
    const [adminId, setAdminId] = useState(null);
    const [cityOptions, setCityOptions] = useState([]);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
        _getCity();
    }, []);

    useEffect(() => {
        if (showPassword === true) {
            const admin_modal = new Modal(document.getElementById('reset-password-modal'));
            admin_modal.show();
        }
    }, [showPassword]);

    useEffect(() => {
        if (showBlock === true) {
            const admin_modal = new Modal(document.getElementById('block-admin-modal'));
            admin_modal.show();
        }
    }, [showBlock]);

    useEffect(() => {
        if (adminId !== null) {
            _getAdminDetails(adminId);
        }
    }, [adminId])

    useEffect(() => {
        if (state !== null) {
            if (state.admin_id) {
                setAdminId(state.admin_id);
            }
        } else {
            navigate("/country-admin");
        }
    }, [state]);

    useEffect(() => {
        if (state !== null) {
            setTabIndex(state.tabIndex);
        } else {
            setTabIndex(1);
        }
    }, [state]);

    // handle password state
    const _handlePassword = () => {
        setShowPassword(true);
    };

    // handle password state
    const _handleBlock = () => {
        setShowBlock(true);
    };

    // handle tab change 
    const _handleTabChange = (id) => {
        navigate("/country-admin/detail", {
            state: {
                tabIndex: id,
                admin_id: adminId
            }
        });
    };

    // API - Admin details
    const _getAdminDetails = (id) => {
        const url = "country-admin/detail";
        const request = {
            "admin_id": Number(id)
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setAdminDetail(response.data);
                setAdminId(response.data.admin_id);
            } else {
                setAdminDetail({});
            }
            setApiLoader(false);
        });
    };

    // API - reset password
    const _resetPassword = async (new_password) => {
        let valid = false;

        setBtnLoader(true);

        const url = "country-admin/change-password";

        const request = {
            "admin_id": adminId,
            "new_password": new_password
        }
        await APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                valid = true;
                toast.success(`${adminDetail.username}'s password reset successfully`, {
                    type: "success"
                });
                setShowPassword(false);
            } else {
                valid = false;
                toast.error(response.message, {
                    type: "error"
                });
            }
            setBtnLoader(false);
        });
        return valid;
    }

    // API - toggle admin block-unblock
    const _toggleAdminBlock = async () => {
        let valid = false;

        setBtnLoader(true);

        const url = "country-admin/block";

        const request = {
            "admin_id": adminId,
        }
        await APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                valid = true;
            } else {
                valid = false;
                toast.error(response.message, {
                    type: "error"
                });
            }
            setBtnLoader(false);
        });
        return valid;
    }

    // API - City list
    const _getCity = () => {
        const city_filter_options = [];

        const is_post = true;
        const url = "general/city";
        const request = {
            "admin_id": adminDetail.admin_id
        }

        APIService(is_post, url, request).then((response) => {
            if (response.status_code === 200) {

                city_filter_options.push({
                    value: null,
                    label: "All"
                });

                response.data.map((city) => (
                    city_filter_options.push({
                        value: city.value,
                        label: city.label
                    })
                ));

                setCityOptions(city_filter_options);

            } else { }
        });
    }

    return (
        <section className='pb-5'>
            {apiLoader === true ?

                // loader 
                <Loader />
                :
                <Fragment>
                    <div className='e-bg-azureish-white pt-3 padding-98px-bottom  padding-52px-lr'>
                        <h6 className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-16 line-height-22px e-font-weight-400'>
                            <span className='e-text-rich-black text-decoration-hover-underline cursor-pointer'
                                onClick={() => navigate("/country-admin")} >Manage country admins</span>/{adminDetail.country}
                        </h6>
                    </div>

                    {/* Admin details */}
                    <div className='margin-neg-66px-top  padding-52px-lr'>
                        <div className='e-bg-white e-border-radius-16 e-admin-box-shadow px-4'>
                            <div className='row'>
                                <div className='col-xl-2 col-lg-3 col-md-3 col-sm-11 col-10 text-center order-1 '>
                                    <div className='padding-32px-tb '>
                                        <img src={require("../../../Assets/Image/SuperAdmin/admin-profile.svg").default}
                                            draggable={false}
                                            className='e-bg-platinum e-border-radius-50per'
                                            alt='profile' />
                                        <p className='mb-0 pt-1 e-primary-font-playfair e-text-rich-black e-font-20 e-font-weight-600 line-height-26px'>
                                            {adminDetail.username === null ? "--" : adminDetail.username}
                                        </p>
                                    </div>

                                </div>
                                <div className='col-xl-9 col-lg-8 col-md-8 col-sm-12 col-12 pt-xl-5 pt-lg-5 pt-md-5 pt-0 pe-0 pb-3 order-xl-2 order-lg-2 order-md-2 order-sm-3 order-3'>
                                    <div className='e-bg-ghost-white e-border-radius-8 p-3'>
                                        <div className='row row-gap-3'>
                                            <div className='col-xl-4 col-lg-5 col-md-6 col-6'>
                                                <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                                    Email
                                                </p>
                                                <p className='mb-0 pt-xl-4 pt-lg-1 pt-1 e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-20px'>
                                                    <a href="mailto:someone@example.com" className='e-text-rich-black text-decoration-hover-underline text-decoration-none'>
                                                        {adminDetail.email}
                                                    </a>
                                                </p>
                                            </div>
                                            <div className='col-xl-3 col-lg-4 col-md-6 col-6'>
                                                <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                                    Phone no.
                                                </p>
                                                <p className='mb-0 pt-xl-4 pt-1 e-text-rich-black e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-20px'>
                                                    <a href="tel:9876564563" className='e-text-rich-black text-decoration-hover-underline text-decoration-none'>
                                                        {adminDetail.contact_no}
                                                    </a>
                                                </p>
                                            </div>
                                            <div className='col-xl-3 col-lg-5 col-md-6 col-6'>
                                                <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                                    Country
                                                </p>
                                                <p className='mb-0 pt-xl-4 pt-1 e-text-rich-black e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-20px'>
                                                    {adminDetail.country}
                                                </p>
                                            </div>
                                            <div className='col-xl-2 col-lg-4 col-md-6 col-6'>
                                                <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                                    Joined on
                                                </p>
                                                <p className='mb-0 pt-xl-4 pt-1 e-text-rich-black e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-20px'>
                                                    {adminDetail.joined_on}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-1 col-lg-1 col-md-1 col-sm-1 col-2 pt-3 text-end order-xl-3 order-lg-3 order-md-3 order-sm-2 order-2'>
                                    <div className="e-dropdown-more-wrapper position-relative ">
                                        <img src={require("../../../Assets/Image/Header/menu.svg").default}
                                            draggable={false}
                                            className=' e-border-1px-anti-flash-white e-border-radius-50per p-1 cursor-pointer'
                                            alt='menu'
                                            onClick={() => setToggleMore(!toggleMore)} />
                                        {toggleMore &&
                                            <>
                                                <OverLay isOpen={toggleMore} onClose={() => setToggleMore(!toggleMore)} />
                                                <div className="e-dropdown-more e-bg-white text-start top-24px right-neg-10px"
                                                    onClick={() => setToggleMore(!toggleMore)}>
                                                    <p className='mb-4 e-text-rich-black-light e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px cursor-pointer text-decoration-hover-underline'
                                                        onClick={_handleBlock}>
                                                        {adminDetail.is_blocked ? "Un-block" : "Block"}
                                                    </p>
                                                    <p className='mb-0 e-text-rich-black-light e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px cursor-pointer text-decoration-hover-underline'
                                                        onClick={_handlePassword}>
                                                        Reset password
                                                    </p>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* category tab */}
                        <div className='d-flex justify-content-space-between overflow-auto padding-32px-top mb-3 pb-2 res-gap-0-16px e-custom-tab'>
                            {CATEGORY_TAB.map((data, key) => (
                                <div key={key} className={`${tabIndex === data.id ? 'e-bg-lust e-text-white' : 'e-text-rich-black'} e-border-1px-cool-grey e-border-radius-24 cursor-pointer`}
                                    onClick={() => _handleTabChange(data.id)} >
                                    <p className='mb-0 px-3 padding-12px-tb e-alt-font-noto-sans  e-font-14 e-font-weight-600 line-height-20px w-max-content'>
                                        {data.name}
                                    </p>
                                </div>
                            ))}
                        </div>

                        {tabIndex === 1 ?
                            <UserRegistered adminId={adminDetail.admin_id}
                                cityData={cityOptions} />
                            : tabIndex === 2 ?
                                <CoursesCreated adminId={adminId} />
                                : tabIndex === 3 ?
                                    <VideoSnippet adminId={adminId} />
                                    :
                                    tabIndex === 4 ?
                                        <QuizCreated adminId={adminId} />
                                        :
                                        tabIndex === 5 ?
                                            <FlashQuizCreated adminId={adminId} />
                                            :
                                            tabIndex === 6 ?
                                                <Shop adminId={adminId} />
                                                :
                                                tabIndex === 7 ?
                                                    <RewardPoint adminId={adminId} />
                                                    :
                                                    null
                        }
                    </div>

                </Fragment>

            }

            {/* show change password modal */}
            {showPassword === true &&
                <ResetPasswordModal type={1}
                    loading={btnLoader}
                    closeModal={() => {
                        setShowPassword(false);
                    }}
                    reset={_resetPassword} />
            }

            {/* confirm block admin modal */}
            {
                showBlock === true &&
                <BlockAdminModal label="admin"
                    loading={btnLoader}
                    blocked={adminDetail.is_blocked}
                    closeModal={() => {
                        setShowBlock(false);
                    }}
                    success={() => {
                        _getAdminDetails(adminId)
                    }}
                    block={_toggleAdminBlock} />
            }
        </section>
    )
}

export default CountryAdminsDetail