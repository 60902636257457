/*
 *   File : employee-detail.js
 *   Author : https://evoqins.com
 *   Description : Employee detail page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { Modal } from 'bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

//Custom component
import { AddMilestoneModal, BlockAdminModal } from '../../../Component/Modal';
import { PrimaryButton, SecondaryButton } from '../../../Component/Buttons';
import { Reward, Training } from './Helper';
import { SummaryCard } from '../../../Component/Cards';
import { Loader, OverLay } from '../../../Component/Others';
import { APIService } from '../../../Service';
import { Icon } from '../../../Component/Icon';
import { toast } from 'react-toastify';

const CATEGORY_TAB = [
    {
        id: 1,
        name: "Overall performance"
    },
    {
        id: 2,
        name: "Training"
    },
    {
        id: 3,
        name: "Rewards"
    },
];

const EmployeeDetail = () => {

    const navigate = useNavigate();
    const { state } = useLocation();

    const [tabIndex, setTabIndex] = useState(0);
    const [toggleMore, setToggleMore] = useState(false);

    const [customerId, setCustomerId] = useState(null);
    const [userDetailData, setUserDetailData] = useState({});
    const [loading, setLoading] = useState(true);

    const [performanceData, setPerformanceData] = useState({});
    const [performanceLoading, setPerformanceLoading] = useState(true);

    const [showBlock, setShowBlock] = useState(false);
    const [btnLoader, setBtnLoader] = useState(false);

    const [awardData, setAwardData] = useState([]);
    const [awardLoading, setAwardLoading] = useState(true);
    const [addAwardModal, setAddAwardModal] = useState(false);
    const [assignLoader, setAssignLoader] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
        _getAwardData();
    }, []);

    useEffect(() => {
        if (state !== null) {
            setTabIndex(state.tabIndex);
            setCustomerId(state.customer_id);
        } else {
            navigate("/sales-specialist");
        }
        setAddAwardModal(false);
    }, [state]);

    useEffect(() => {
        if (customerId !== null) {
            _getUserDetail();
        }
    }, [customerId]);


    useEffect(() => {
        if (loading === false) {
            _getPerformanceData();
        }
    }, [loading]);

    useEffect(() => {
        if (showBlock === true) {
            const admin_modal = new Modal(document.getElementById('block-admin-modal'));
            admin_modal.show();
        }
    }, [showBlock]);

    useEffect(() => {
        if (addAwardModal === true) {
            const milestone_modal = new Modal(document.getElementById('add-milestone-modal'));
            milestone_modal.show();
        }
    }, [addAwardModal]);

    // handle tab change 
    const _handleTabChange = (id) => {
        navigate("/sales-specialist/detail", {
            state: {
                tabIndex: id,
                customer_id: customerId
            }
        });
    };

    // handle password state
    const _handleBlockToggle = () => {
        setShowBlock(true);
    };

    // handle open milestone modal 
    const _handleAddMilestoneModal = () => {
        setAddAwardModal(true);
    };

    // handle self assign 
    const _handleSelfAssign = (customer_id) => {
        setAssignLoader(true);
        _selfAssign(customer_id);
    };

    console.log("userDetailData", userDetailData)

    // API - Get sales specialist detail
    const _getUserDetail = () => {
        let is_post = true;
        let url = 'CA/sales-specialist/detail';
        let data = {
            "customer_id": customerId
        };
        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setUserDetailData(response.data);
            } else { }
            setLoading(false);
        });
    };

    // API - Get over all performance
    const _getPerformanceData = () => {
        let is_post = true;
        let url = 'CA/sales-specialist/over-all-performance';
        let data = {
            "customer_id": customerId
        };
        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setPerformanceData(response.data);
            } else { }
            setPerformanceLoading(false);
        });
    };

    // API - toggle admin block-unblock
    const _toggleBlockUser = async () => {
        let valid = false;

        setBtnLoader(true);

        const url = "CA/sales-specialist/block";

        const request = {
            "customer_id": customerId,
        }
        await APIService(true, url, request).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                valid = true;
            } else {
                valid = false;
                toast.error(response.message, {
                    type: "error"
                });
            }
            setBtnLoader(false);
        });
        return valid;
    };

    // API - Get award data
    const _getAwardData = () => {
        let is_post = false;
        let url = "general/awards";
        let data = {}

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setAwardData(response.data);
            } else { }
            setAwardLoading(false);
        });
    };

    // API - Assign to self
    const _selfAssign = (customer_id) => {
        let is_post = true;
        let url = 'CA/sales-specialist/assign-to-me';
        let data = {
            "customer_id": customer_id
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Assigned successfully", {
                    type: "success",
                    className: "e-toast",
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setAssignLoader(false);
        });
    };

    return (
        <section className='pb-5'>
            <div className='e-bg-lavender-blush-light pt-3 padding-98px-bottom  padding-52px-lr'>
                <h6 className='mb-0 e-text-davys-grey  e-alt-font-noto-sans e-font-16 line-height-22px e-font-weight-400'>
                    <span className='e-text-rich-black text-decoration-hover-underline e-font-weight-500 cursor-pointer'
                        onClick={() => navigate("/sales-specialist")} >
                        Sales specialists
                    </span>
                    /{userDetailData.customer_name}
                </h6>
            </div>

            {loading ?
                // loader 
                <Loader />
                :
                <div className='margin-neg-66px-top  padding-52px-lr'>
                    {/* Admin details */}
                    <div className='e-admin-box-shadow e-bg-white e-border-radius-16 px-4'>
                        <div className='row position-relative'>
                            <div className='col-xl-2 col-lg-3 col-md-2 col-sm-11 col-10 text-center order-1 '>
                                <div className='padding-32px-tb '>
                                    <img src={userDetailData.profile_img !== null ? userDetailData.profile_img :
                                        require("../../../Assets/Image/SuperAdmin/admin-profile.svg").default}
                                        draggable={false}
                                        className={`${userDetailData.profile_img !== null ? 'e-bg-platinum p-1 w-82px' : ''} e-bg-platinum e-border-radius-50per`}
                                        height={82}
                                        alt='profile' />
                                    <p className='mb-0 pt-1 e-primary-font-playfair e-text-rich-black e-font-20 e-font-weight-600 line-height-26px'>
                                        {userDetailData.customer_name}
                                    </p>
                                </div>
                            </div>
                            <div className='col-xl-9 col-lg-8 col-md-9 col-sm-12 col-12  pt-xl-5 pt-lg-5 pt-md-5 pt-0 pb-3 order-xl-2 order-lg-2 order-md-2 order-sm-3 order-3'>
                                <div className='e-bg-ghost-white e-border-radius-8 p-3'>
                                    <div className='row row-gap-3 justify-content-between'>
                                        <div className='col-xl-auto col-lg-4 col-md-6 col-12'>
                                            <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                                Email
                                            </p>
                                            <p className='mb-0 pt-xl-4 pt-lg-1 pt-1 e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-20px'>
                                                <a href="mailto:someone@example.com"
                                                    className='e-text-rich-black text-decoration-hover-underline text-decoration-none'>
                                                    {userDetailData.email === null ? "-" : userDetailData.email}
                                                </a>
                                            </p>
                                        </div>
                                        <div className='col-xl-auto col-lg-4 col-md-6 col-6'>
                                            <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                                Phone no.
                                            </p>
                                            <p className='mb-0 pt-xl-4 pt-1 e-text-rich-black e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-20px'>
                                                <a href={`tel:${userDetailData.contact_no}`} className='e-text-rich-black text-decoration-hover-underline text-decoration-none'>
                                                    {userDetailData.contact_no}
                                                </a>
                                            </p>
                                        </div>
                                        <div className='col-xl-auto col-lg-4 col-md-6 col-6 '>
                                            <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                                Country
                                            </p>
                                            <p className='mb-0 pt-xl-4 pt-1 e-text-rich-black e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-20px'>
                                                {userDetailData.country}
                                            </p>
                                        </div>
                                        <div className='col-xl-auto col-lg-4 col-md-6 col-6'>
                                            <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                                Joined on
                                            </p>
                                            <p className='mb-0 pt-xl-4 pt-1 e-text-rich-black e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-20px'>
                                                {userDetailData.joined_on}
                                            </p>
                                        </div>
                                        <div className='col-xl-auto col-lg-4 col-md-6 col-6'>
                                            <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                                Shop name
                                            </p>
                                            <p className='mb-0 pt-xl-4 pt-1 e-text-rich-black e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-20px'>
                                                {userDetailData.store_name}
                                            </p>
                                        </div>
                                        <div className='col-xl-auto col-lg-4 col-md-6 col-auto'>
                                            <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                                Country Admin
                                            </p>
                                            {/* Assign button */}
                                            <p className='text-nowrap justify-content-center w-106px h-38px d-inline-flex p-2  mt-xl-3 mt-1 mb-0 e-border-1px-indigo e-text-indigo e-border-radius-16 cursor-pointer   e-alt-font-noto-sans  e-font-14 e-font-weight-600 line-height-20px'
                                                onClick={() => { _handleSelfAssign(userDetailData.customer_id) }}>
                                                {assignLoader ?
                                                    <img src={require("../../../Assets/loader-dark.gif")}
                                                        className={` w-18px h-18px`}
                                                        alt='loader' />
                                                    :
                                                    "Assign to me"
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='position-absolute top-16px'>
                                <div className="e-dropdown-more-wrapper position-relative ">
                                    <div className='d-flex justify-content-end'>
                                        <div className='mb-0 p-1 e-border-1px-anti-flash-white e-border-radius-50per  cursor-pointer'>
                                            <Icon icon="menu"
                                                size={"24px"}
                                                className="d-flex"
                                                onPress={() => setToggleMore(!toggleMore)} />
                                        </div>
                                    </div>
                                    {toggleMore &&
                                        <>
                                            <OverLay isOpen={toggleMore} onClose={() => setToggleMore(!toggleMore)} />
                                            <div className="e-dropdown-more e-bg-white text-start top-24px right-neg-10px"
                                                onClick={() => setToggleMore(!toggleMore)}>
                                                <p className='mb-0 e-text-rich-black-light e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px cursor-pointer text-decoration-hover-underline'
                                                    onClick={_handleBlockToggle}>
                                                    {userDetailData.is_blocked ? "Un-block" : "Block"}
                                                </p>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* category tab */}
                    <div className='d-flex flex-wrap padding-32px-top pb-4 justify-content-space-between align-items-center'>
                        <div className='d-flex gap-0-24px overflow-auto pb-xl-0 pb-lg-0 pb-md-0 pb-1 e-tab-scroll'>
                            {CATEGORY_TAB.map((data, key) => (
                                <div key={key} className={`${tabIndex === data.id ? 'e-bg-lust e-text-white pe-none' : 'e-text-rich-black'} e-border-1px-cool-grey e-border-radius-24 cursor-pointer`}
                                    onClick={() => _handleTabChange(data.id)} >
                                    <p className='mb-0 px-4 padding-12px-tb e-alt-font-noto-sans  e-font-14 e-font-weight-500 line-height-14px w-max-content'>
                                        {data.name}
                                    </p>
                                </div>
                            ))}
                        </div>
                        {!awardLoading &&
                            <PrimaryButton name="Add awards & rewards"
                                className="e-border-radius-16 padding-14px-tb line-height-12px e-font-weight-500 mt-xl-0 mt-lg-0 mt-md-0 mt-2"
                                onPress={_handleAddMilestoneModal} />
                        }
                    </div>


                    {/* Summary  */}
                    {tabIndex === 1 ?
                        <div className='e-admin-box-shadow p-4 e-bg-white e-border-radius-16'>
                            <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                                Performance summary
                            </h4>
                            {/* Summary cards */}
                            <div className='row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 row-gap-3 padding-10px-top'>
                                <div className='col'>
                                    <SummaryCard title="Total sales(In units)"
                                        loader={performanceLoading}
                                        quantity={performanceData.total_sales_unit} />
                                </div>
                                <div className='col'>
                                    <SummaryCard title="Premium sales"
                                        loader={performanceLoading}
                                        quantity={performanceData.premium_sales} />
                                </div>
                                <div className='col'>
                                    <SummaryCard title="Revenue generated"
                                        loader={performanceLoading}
                                        quantity={performanceData.revenue} />
                                </div>
                                <div className='col'>
                                    <SummaryCard title="Reward point earned"
                                        loader={performanceLoading}
                                        quantity={performanceData.points} />
                                </div>
                                <div className='col'>
                                    <SummaryCard title="Training"
                                        loader={performanceLoading}
                                        quantity={performanceData.training} />
                                </div>
                            </div>
                        </div>
                        :
                        tabIndex === 2 ?
                            // training 
                            <Training customerId={customerId} />
                            :
                            <Reward customerId={customerId} />
                    }

                </div>
            }

            {/* Add award modal */}
            {addAwardModal === true &&
                <AddMilestoneModal customerId={customerId}
                    award={awardData}
                    handleClose={() => setAddAwardModal(false)} />
            }

            {/* confirm block user modal */}
            {showBlock === true &&
                <BlockAdminModal label="user"
                    loading={btnLoader}
                    blocked={userDetailData.is_blocked}
                    closeModal={() => setShowBlock(false)}
                    success={() => _getUserDetail()}
                    block={_toggleBlockUser} />
            }
        </section>
    )
}

export default EmployeeDetail;