/*
 *   File : list-competition.js
 *   Author : https://evoqins.com
 *   Description : Competitions listing page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

// Packages
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from "bootstrap";
import { toast } from "react-toastify";

// Custom components
import { PrimaryButton, SecondaryButton, TextButton } from "../../../Component/Buttons";
import { CustomSelectBox } from "../../../Component/FormElements";
import { CompetitionCard } from "../../../Component/Cards";
import { APIService } from "../../../Service";
import { Loader } from "../../../Component/Others";
import { ConfirmDeleteModal, ManageCompetitionModal, ManageKpiModal } from "../../../Component/Modal";
import { EmptyScreen } from "../../../Component/EmptyComponent";
import { Pagination } from "../../../Component/pagination";

const WEEK_OPTIONS = [
    {
        value: 0,
        label: 'All',
    },
    {
        value: 1,
        label: 'Active'
    },
    {
        value: 2,
        label: 'Expired'
    },
];

const CATEGORY_TAB = [
    {
        id: 1,
        name: "Created competition"
    },
    {
        id: 2,
        name: "Manage widgets"
    },
];

const ITEMS_PER_PAGE = 16;

const ListCompetitions = () => {

    const navigate = useNavigate();
    const { state } = useLocation();

    const [toggleFilter, setToggleFilter] = useState(false);
    const [selected, setSelected] = useState(WEEK_OPTIONS[0]);

    const [competitionData, setCompetitionData] = useState({});
    const [loading, setLoading] = useState(true);
    const [competitionModal, setCompetitionModal] = useState(false);

    const [productList, setProductList] = useState([]);
    const [productLoading, setProductLoading] = useState(true);

    const [totalPageCount, setTotalPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [btnLoader, setBtnLoader] = useState({});
    const [tabIndex, setTabIndex] = useState(0);

    const [kpiList, setKpiList] = useState({});
    const [kpiDataLoading, setKpiDataLoading] = useState(true);

    const [kpiModal, setKpiModal] = useState(false);
    const [kpiEditData, setKpiEditData] = useState({});

    const [manageKpiLoader, setManageKpiLoader] = useState({});

    const [deleteId, setDeleteId] = useState(null);
    const [kpiDeleteResponse, setKpiDeleteResponse] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    }, []);

    useEffect(() => {
        if (state !== null) {
            setTabIndex(state);
        } else {
            setTabIndex(1);
        }
        setKpiModal(false);
        setCompetitionModal(false);
    }, [state]);

    useEffect(() => {
        _getCompetitionList();
    }, [selected, currentPage]);

    useEffect(() => {
        if (tabIndex == 2) {
            _getKpiList();
        }
    }, [tabIndex]);

    useEffect(() => {
        if (competitionModal === true) {
            const manage_modal = new Modal(document.getElementById('manage-competition-modal'));
            manage_modal.show();
        }
    }, [competitionModal]);

    useEffect(() => {
        if (kpiModal === true) {
            const manage_modal = new Modal(document.getElementById('manage-kpi-modal'));
            manage_modal.show();
        }
    }, [kpiModal]);

    useEffect(() => {
        if (deleteId !== null) {
            const delete_modal = new Modal(document.getElementById('confirm-delete-modal'));
            delete_modal.show();
        }
    }, [deleteId]);

    // handle tab change 
    const _handleTabChange = (id) => {
        navigate("/competition", {
            state: id
        });
        setCurrentPage(1);
    };

    // handle send notification 
    const _handleSendNotification = (id) => {
        setBtnLoader(prev => ({ ...prev, [id]: true }));
        _sendNotification(id);
    };

    //handle navigate detail page
    function _handleNavigate(id) {
        navigate("competition-detail", {
            state: {
                competition_id: id
            }
        });
    };

    // handle create modal 
    const _handleCreate = () => {
        setCompetitionModal(true);
        _getProducts();   //API-products
    };

    // handle filter  
    const _handleFilter = (value) => {
        setLoading(true);
        setSelected(value);
        setCurrentPage(1);
    };

    //handle pagination
    const _handlePagination = (new_start_limit) => {
        const selected_page = Math.floor(new_start_limit / ITEMS_PER_PAGE) + 1;
        setCurrentPage(selected_page);

        window.scrollTo({
            top: 0, behavior: 'smooth'
        });
    };

    // handle create widget modal 
    const _handleCreateWidget = (data, type) => {
        setKpiModal(true);
        _getProducts();   //API-products

        setKpiEditData({ data, type });
    };

    // handle manage enable or disable kpi
    const _handleManageKpi = (kpi_id) => {
        setManageKpiLoader(prev => ({ ...prev, [kpi_id]: true }));
        _manageKpi(kpi_id);

        window.scrollTo({
            top: 0, behavior: 'smooth'
        });
    };

    // handle delete custom kpi 
    const _handleDeleteKpi = (id) => {
        setKpiDeleteResponse(false);
        setDeleteId(id);

        window.scrollTo({
            top: 0, behavior: 'smooth'
        });
    };

    // API - Get competition list
    const _getCompetitionList = () => {
        let is_post = true;
        let url = 'CA/competition/list';
        let data = {
            "page_num": currentPage,
            "limit": ITEMS_PER_PAGE,
            "filter": selected.value === 0 ? null : selected.value
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCompetitionData(response.data);
                setTotalPageCount(Math.ceil(response.data.total_count / ITEMS_PER_PAGE));
            } else {

            }
            setLoading(false);
        });
    };

    // API - get products
    const _getProducts = () => {
        let is_post = true;
        let url = 'general/products';
        let data = {
            "query": "",
            "page_num": 1
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setProductList(response.data);
            } else {
            }
            setProductLoading(false);
        });
    };

    // API - Send notification
    const _sendNotification = (competition_id) => {
        let is_post = true;
        let url = 'CA/competition/send-notification';
        let data = {
            "competition_id": competition_id
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Notification send successfully", {
                    type: "success",
                    className: "e-toast",
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setBtnLoader(prev => ({ ...prev, [competition_id]: false }));
        });
    };

    // API - Get kpi list
    const _getKpiList = () => {
        let is_post = true;
        let url = 'CA/custom-kpi/list';
        let data = {
            "page_num": 1,
            "page_size": 15
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setKpiList(response.data);
            } else { }

            setKpiDataLoading(false);
        });
    };

    // API - Manage kpi enable or disable
    const _manageKpi = (kpi_id) => {
        setManageKpiLoader(true);
        let is_post = true;
        let url = 'CA/custom-kpi/manage';
        let data = {
            "id": kpi_id
        };
        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.success(response.data.message, {
                    type: "success",
                    className: "e-toast",
                });

                _getKpiList(); //API - Get kpi list
            } else {
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setManageKpiLoader(prev => ({ ...prev, [kpi_id]: false }));
        });
    };

    // API - Delete kpi
    const _deleteKpi = () => {
        let is_post = true;
        let url = 'CA/custom-kpi/delete';
        let data = {
            "id": deleteId
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setKpiDeleteResponse(true);
            } else {
                setKpiDeleteResponse(false);
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        });
    };

    return (
        <Fragment>
            <section className='pb-5 '>
                <div className={`${tabIndex === 1 ? "padding-68px-bottom" : "padding-26px-bottom"} e-bg-lavender-blush-light pt-3 padding-52px-lr`}>
                    <div className='d-flex justify-content-space-between flex-wrap'>
                        <h1 className='mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                            Manage competition
                        </h1>

                        {/* add competition button  */}
                        <div className="d-flex gap-0-16">
                            {/* <SecondaryButton name="Send notification"
                                className="py-2 e-border-radius-16 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0 mt-2" /> */}
                            {tabIndex === 1 ?
                                <>
                                    <PrimaryButton name="Create competition"
                                        className="py-2 e-border-radius-16 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0 mt-2"
                                        onPress={_handleCreate} />
                                    <img src={toggleFilter ? require("../../../Assets/Image/filter-icon-white.svg").default : require("../../../Assets/Image/filter-icon.svg").default}
                                        draggable={false}
                                        className={`${toggleFilter ? `e-bg-indigo` : ``} transition e-border-1px-cool-grey e-border-radius-8 p-2 cursor-pointer`}
                                        alt='filter'
                                        onClick={() => setToggleFilter(!toggleFilter)} />
                                </>
                                :
                                <PrimaryButton name="Create widget"
                                    className="py-2 e-border-radius-16 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0 mt-2"
                                    onPress={() => _handleCreateWidget({}, "create")} />
                            }
                        </div>
                    </div>

                    {/* Tab */}
                    <div className='pt-4 mt-2 d-flex gap-0-16 overflow-auto pb-xl-0 pb-lg-0 pb-md-0 pb-1 e-tab-scroll'>
                        {CATEGORY_TAB.map((data, key) => (
                            <div key={key} className={`${tabIndex === data.id ? 'e-bg-lust e-text-white border-transparent pe-none' : 'e-text-rich-black e-border-1px-cool-grey'} transition e-border-radius-24 cursor-pointer`}
                                onClick={() => _handleTabChange(data.id)} >
                                <p className='mb-0 w-max-content px-4 padding-12px-tb e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-14px'>
                                    {data.name}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className={toggleFilter ? "pt-3 padding-52px-lr margin-neg-98px-top" : tabIndex === 2 ? "padding-52px-lr" : "padding-52px-lr margin-neg-56px-top"}>
                    {/* filter */}
                    {toggleFilter &&
                        <div className='e-bg-anti-flash-white-dark py-3 px-4 margin-neg-46px-top position-relative'>
                            <div className='row'>
                                {/* Filter select */}
                                <div className='col-xl-2 col-lg-3 col-md-3 col-sm-4 col-8'>
                                    <CustomSelectBox label="Select type"
                                        value={selected}
                                        options={WEEK_OPTIONS}
                                        type={2}
                                        className="e-bg-white e-border-radius-8 e-table-select"
                                        isSearchable={false}
                                        selectChange={(id) => _handleFilter(id)} />
                                </div>
                            </div>
                        </div>
                    }
                    {tabIndex === 1 ?
                        <div className="row pt-3 row-gap-3">

                            {loading ?
                                <Loader />  //loader
                                :
                                <>
                                    {competitionData.data.length !== 0 ?
                                        <>
                                            {competitionData.data.map((item, index) => {
                                                return (
                                                    <div key={index} className="col-lg-6 d-flex" >
                                                        <CompetitionCard data={item}
                                                            loader={btnLoader[item.competition_id]}
                                                            sendNotification={() => _handleSendNotification(item.competition_id)}
                                                            handleNavigate={() => _handleNavigate(item.competition_id)} />
                                                    </div>
                                                )
                                            })}

                                            {/* pagination */}
                                            {totalPageCount > 1 &&
                                                <div className="d-flex justify-content-center e-gap-0-16 mt-4 pt-3">
                                                    <Pagination itemsPerPage={ITEMS_PER_PAGE}
                                                        totalPage={totalPageCount}
                                                        showFirstLastPages={true}
                                                        onChange={_handlePagination} />
                                                </div>
                                            }
                                        </>
                                        :
                                        // empty screen
                                        <div className='h-100vh pt-5 mt-5'>
                                            <EmptyScreen message="No competition found" />
                                        </div>
                                    }
                                </>
                            }
                        </div>
                        :

                        // Created widget
                        <div className="padding-40px-top">
                            {kpiDataLoading ?
                                <Loader />  //loader
                                :
                                <>
                                    <h3 className='mb-3 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px'>
                                        Created widget
                                    </h3>
                                    {kpiList.total_count > 0 ?
                                        <>
                                            {/*kpi list card */}
                                            {kpiList.data.map((data, key) => (
                                                <div key={key} className="e-bg-white e-border-radius-16 p-4 mb-3">
                                                    <div className="d-flex gap-2">
                                                        <h6 className="mb-0 e-alt-font-noto-sans e-text-raisin-black e-font-18 e-font-weight-500 line-height-24px">
                                                            {data.title}
                                                        </h6>
                                                        {data.is_active &&
                                                            <span className="e-alt-font-noto-sans e-text-raisin-black e-font-10 e-font-weight-600 line-height-10px e-bg-peach-yellow e-border-radius-40 p-2">
                                                                Active widget
                                                            </span>
                                                        }
                                                    </div>

                                                    <div className="row pt-3">
                                                        <div className="col-8">
                                                            <div className="d-flex gap-0-24px mb-2">
                                                                <div className="d-block">
                                                                    <p className="e-font-12 e-alt-font-noto-sans e-font-weight-400 mb-0 line-height-22px e-text-raisin-black">
                                                                        Min. sales target
                                                                    </p>
                                                                    <span className="e-font-14 e-alt-font-noto-sans e-font-weight-700 mb-0 line-height-22px e-text-raisin-black letter-spacing-005em ">
                                                                        {data.target}
                                                                    </span>
                                                                </div>
                                                                <div className="d-block">
                                                                    <p className="e-font-12 e-alt-font-noto-sans e-font-weight-400 mb-0 line-height-22px e-text-raisin-black">
                                                                        Target product
                                                                    </p>
                                                                    <span className="e-font-14 e-alt-font-noto-sans e-font-weight-700 mb-0 line-height-22px e-text-raisin-black letter-spacing-005em ">
                                                                        {data.product_name}
                                                                    </span>
                                                                </div>
                                                                <div className="d-block">
                                                                    <p className="e-font-12 e-alt-font-noto-sans e-font-weight-400 mb-0 line-height-22px e-text-raisin-black">
                                                                        Start date
                                                                    </p>
                                                                    <span className="e-font-14 e-alt-font-noto-sans e-font-weight-700 mb-0 line-height-22px e-text-raisin-black letter-spacing-005em ">
                                                                        {data.start_date_text}
                                                                    </span>
                                                                </div>
                                                                <div className="d-block">
                                                                    <p className="e-font-12 e-alt-font-noto-sans e-font-weight-400 mb-0 line-height-22px e-text-raisin-black">
                                                                        End date
                                                                    </p>
                                                                    <span className="e-font-14 e-alt-font-noto-sans e-font-weight-700 mb-0 line-height-22px e-text-raisin-black letter-spacing-005em ">
                                                                        {data.end_date_text}
                                                                    </span>
                                                                </div>
                                                                <div className="d-block">
                                                                    <p className="e-font-12 e-alt-font-noto-sans e-font-weight-400 mb-0 line-height-22px e-text-raisin-black">
                                                                        Points
                                                                    </p>
                                                                    <div className='e-display-flex align-items-center'>
                                                                        <img src={require("../../../Assets/Image/Card/streak.svg").default}
                                                                            draggable={false}
                                                                            width={12}
                                                                            alt='streak' />
                                                                        <p className='mb-0 e-font-14 e-alt-font-noto-sans e-font-weight-700 mb-0 line-height-22px e-text-raisin-black letter-spacing-005em'>
                                                                            {data.points}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/* Custom buttons */}
                                                        <div className="col-4 my-auto">
                                                            <div className="d-flex gap-4 align-items-center justify-content-end">
                                                                <TextButton name="Edit"
                                                                    className="e-font-16"
                                                                    onPress={() => _handleCreateWidget(data, "edit")} />
                                                                <TextButton name="Delete"
                                                                    className="e-text-lust e-font-16"
                                                                    onPress={() => _handleDeleteKpi(data.id)} />
                                                                <SecondaryButton name={data.is_active ? "Remove widget" : "Display widget"}
                                                                    className={`${manageKpiLoader[data.id] ? "w-132px" : ""} e-border-radius-16 `}
                                                                    status={manageKpiLoader[data.id]}
                                                                    onPress={() => _handleManageKpi(data.id)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                        :
                                        // empty screen
                                        <div className='h-100vh pt-5 mt-5'>
                                            <EmptyScreen message="No data found" />
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    }
                </div>

                {/* Manage competition modal */}
                {competitionModal === true &&
                    <ManageCompetitionModal product={productList.data}
                        edit={false}
                        loading={productLoading}
                        success={() => _getCompetitionList()}
                        handleClose={() => setCompetitionModal(false)} />
                }

                {/* Manage kpi modal */}
                {kpiModal === true &&
                    <ManageKpiModal product={productList.data}
                        edit={false}
                        loading={productLoading}
                        editData={kpiEditData}
                        success={() => _getKpiList()}
                        handleClose={() => setKpiModal(false)} />
                }

                {/* confirm delete modal */}
                {deleteId !== null &&
                    <ConfirmDeleteModal label="widget"
                        deleteId={deleteId}
                        response={kpiDeleteResponse}
                        delete={() => _deleteKpi()}
                        handleClose={() => setDeleteId(null)}
                        success={() => {
                            setKpiDataLoading(true);
                            _getKpiList();
                        }} />
                }
            </section>
        </Fragment>
    )
}
export default ListCompetitions;