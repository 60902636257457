/*
 *   File : otp-input.js
 *   Author : https://evoqins.com
 *   Description : OTP input component
 *   Integrations : NA
 *   Version : 1.0.0
*/

//custom style
import { useEffect, forwardRef, memo, useState, useRef } from "react";

//styles
import styles from "../../Style/Component/input.module.scss";

const OTPInput = forwardRef((props, ref) => {

    const [pin, setPin] = useState([]);
    const otpInputRef = useRef(null);
    const input_width = useRef(0);


    useEffect(() => {
        input_width.current = otpInputRef.current.clientWidth;
    }, []);

    useEffect(() => {
        if (ref && props.focused_input === 2 && props.confirm_pin.length < 4) {
            ref.current.focus();
        };
    }, [props.focused_input]);

    useEffect(() => {
        setPin(props.confirm_pin);
        if (ref && props.confirm_pin.length === 5) {
            ref.current.blur();
            props.onBlur();
        }
    }, [props.confirm_pin]);

    const _preventDefaults = (e) => {
        const key_code = e.keyCode || e.which;
        const is_ctrl_pressed = e.ctrlKey || e.metaKey; // Check if Ctrl key is pressed

        if (e.keyCode === 13) {
            props.onKeyPress();
        }

        if (key_code == 9 || // Tab key
            (is_ctrl_pressed && (key_code == 65 || key_code == 67 || key_code == 88 || key_code == 86))
        ) {
            // Ctrl+A, Ctrl+C, Ctrl+X, or Ctrl+V    
            e.preventDefault();
        }

        if (
            (key_code >= 48 && key_code <= 57) || // 0-9
            (key_code >= 96 && key_code <= 105) || // Numpad 0-9
            key_code === 8 || // Backspace
            (key_code >= 37 && key_code <= 40) // Arrow keys
        ) {
            // Allow the keypress
        } else {
            e.preventDefault();
        }
    };

    return (
        <>
            {props.label &&
                <label className={`${props.labelStyle} e-text-rich-black e-alt-font-noto-sans e-font-weight-500 line-height-18px e-font-14 pb-2`}>
                    {props.label}
                    {props.require &&
                        <span className='e-text-lust'>*</span>
                    }
                </label>
            }
            <div className={`${styles.e_otp_wrapper} h-60px user-select-none`}>
                <input style={{ width: input_width.current }}
                    type="text"
                    ref={ref}
                    id={props.id}
                    value={props.value}
                    spellCheck={props.spellCheck}
                    className={`mb-0 e-caret-color-transparent`}
                    onFocus={props.onFocus}
                    onBlur={props.onBlur}
                    onChange={props.onChange}
                    onKeyDown={_preventDefaults}
                    autoComplete="off"
                    autoFocus={props.autoFocus}
                    tabIndex={props.tabIndex} />

                <label ref={otpInputRef} className='d-flex gap-0-24px'>
                    <div className={`justify-content-center w-44px h-44px e-font-weight-500 e-font-16 line-height-20px e-inline-flex e-alt-font-noto-sans 
                ${props.focused_input === 2 ? 'e-border-1px-indigo' : 'e-border-1px-columbia-blue'}`}>
                        {pin.length !== 0 ?
                            // <span className="pt-1 e-text-egyptian-blue">{props.confirm_pin[0]}</span>
                            <span className="pt-1 e-text-egyptian-blue">*</span>
                            :
                            <span className="pt-2"></span>
                        }
                    </div>
                    <div className={`justify-content-center w-44px h-44px e-font-weight-500 e-alt-font-noto-sans e-font-16 line-height-20px e-inline-flex
                 ${props.focused_input === 2 && pin.length >= 2 ? 'e-border-1px-indigo' : 'e-border-1px-columbia-blue'}`}>
                        {pin.length >= 2 ?
                            // <span className="pt-1 e-text-egyptian-blue">{props.confirm_pin[1]}</span>
                            <span className="pt-1 e-text-egyptian-blue">*</span>
                            :
                            <span className="pt-2"></span>
                        }
                    </div>
                    <div className={`justify-content-center w-44px h-44px e-font-weight-500 e-alt-font-noto-sans e-font-16 line-height-20px e-inline-flex
                 ${props.focused_input === 2 && pin.length >= 3 ? 'e-border-1px-indigo' : 'e-border-1px-columbia-blue'}`}>
                        {pin.length >= 3 ?
                            <span className="pt-1 e-text-egyptian-blue">*</span>
                            :
                            <span className="pt-2"></span>
                        }
                    </div>
                    <div className={`justify-content-center w-44px h-44px e-font-weight-500 e-alt-font-noto-sans e-font-16 line-height-20px e-inline-flex 
                ${props.focused_input === 2 && pin.length >= 4 ? 'e-border-1px-indigo' : 'e-border-1px-columbia-blue'}`}>
                        {pin.length >= 4 ?
                            <span className="pt-1 e-text-egyptian-blue">*</span>
                            :
                            <span className="pt-2"></span>
                        }
                    </div>
                </label>

                {/* Error message */}
                {props.error &&
                    <p className='mb-0 e-error-message e-font-12  e-font-weight-400 e-primary-font-inter' >
                        {props.error}
                    </p>
                }
            </div>
        </>
    )
});

OTPInput.defaultProps = {
    onKeyPress: () => { },
    className: null,
    error: "",
}

export default memo(OTPInput);