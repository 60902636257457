/*
 *   File : stacked-bar.js
 *   Author : https://evoqins.com
 *   Description : Stacked bar component
 *   Integrations : react-chartjs-2,chart.js
 *   Version : 1.0.0
*/

import React, { memo } from 'react'
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const OPTIONS = {
    plugins: {
        title: {
            display: false,
        },
        legend: {
            display: false
        }
    },
    responsive: true,
    scales: {
        x: {
            stacked: true,
            grid: {
                display: false,
            },
            border: {
                display: false
            }
        },
        y: {
            stacked: true,
            grid: {
                display: false,
            },
            border: {
                display: false
            },
            ticks: {
                display: false,
            },

        },
    },
};

const StackedBarChart = (props) => {

    const labels = props.label;
    const CHART_DATA = {
        labels,
        datasets: [
            {
                label: 'Value',
                data: props.interior,
                backgroundColor: '#F5B01A',
                barThickness: 20,

            },
            {
                label: 'Value',
                data: props.exterior,
                backgroundColor: '#E02126',
                barThickness: 20,
            },
            // {
            //     label: 'Dataset 3',
            //     data: [17, 16, 20, 11, 8, 9],
            //     backgroundColor: '#213366',
            //     barThickness: 26,
            // },
        ],
    };

    return (
        <div>
            <Bar options={OPTIONS} data={CHART_DATA} />
        </div>
    )
}

export default memo(StackedBarChart);
