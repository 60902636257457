/*
 *   File : video-snippet.js
 *   Author : https://evoqins.com
 *   Description : Video snippet component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { Fragment, useEffect, useRef, useState } from 'react';

//Custom Component
import { DataTableComponent } from '../../../Component/DataTable';
import { CustomSelectBox } from '../../../Component/FormElements';
import { SummaryCard } from '../../../Component/Cards';
import { EmptyScreen } from '../../../Component/EmptyComponent';
import { Loader } from '../../../Component/Others';

// Services
import { APIService } from '../../../Service';

const TABLE_COLUMNS = [
    {
        name: 'Video name',
        selector: row => row.video_name,
        width: "320px"
    },
    {
        name: 'Category',
        selector: row => row.category,
    },
    {
        name: 'Linked products',
        cell: row => row.linked_products ? row.linked_products.map((item, key) => `${item.product} ${key === row.linked_products.length - 1 ? "" : " , "}`) : "-",
    },
    {
        name: 'Status',
        // selector: row => row.status,
        cell: row =>
            <>
                {row.status ?
                    <div className={`${row.status === "Active" ? `e-bg-tea-green px-3` :
                        row.status === "Archived" ? `e-bg-light-red padding-12px-lr` : ``}  e-border-radius-16 e-font-weight-500 e-font-12 padding-2px-tb`}>
                        {row.status}
                    </div>
                    :
                    "-"
                }
            </>
    },
    {
        name: 'Views',
        selector: row => row.enrolled_users
    },
    {
        name: "Reward points",
        selector: row => row.points
    }

];

const CATEGORY_OPTIONS = [
    {
        value: null,
        label: 'All',
    },
    {
        value: 1,
        label: 'Product'
    },
    {
        value: 2,
        label: 'Skills and service'
    },
];


const STATUS_OPTIONS = [
    {
        value: null,
        label: 'All',
    },
    {
        value: 1,
        label: 'Active'
    },
    {
        value: 2,
        label: 'Archived'
    },
];

const VideoSnippet = React.memo((props) => {

    const tableRef = useRef(null);

    const [toggleFilter, setToggleFilter] = useState(false);

    const [apiLoader, setApiLoader] = useState(true);

    const [selectedCategory, setSelectedCategory] = useState(CATEGORY_OPTIONS[0]);
    const [selectedStatus, setSelectedStatus] = useState(STATUS_OPTIONS[0]);
    const [pageNumber, setPageNumber] = useState(1);

    const [videoData, setVideoData] = useState({});
    const [summaryData, setSummaryData] = useState({});

    useEffect(() => {
        _getVideos();
        //eslint-disable-next-line
    }, [pageNumber, selectedCategory, selectedStatus]);

    // handle page change 
    const _handlePageChange = (page_num) => {
        setPageNumber(page_num);

        if (tableRef.current) {
            tableRef.current.scrollIntoView({ behavior: 'smooth', block: "start" });
        }
    };

    // API - video list
    const _getVideos = () => {
        const url = "country-admin/video";

        const request = {
            "admin_id": props.adminId,
            "page_num": pageNumber,
            "category_filter": selectedCategory.value,
            "status_filter": selectedStatus.value
        }

        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setVideoData(response.data);
                setSummaryData(response.data.summary);
            } else {
                setSummaryData({});
                setVideoData({});
            }
            setApiLoader(false);
        });
    }

    return (
        <>
            {apiLoader ?
                <div className='margin-20px-top e-bg-white e-admin-box-shadow py-4 e-border-radius-16 '>
                    {/* loader  */}
                    <Loader />
                </div>
                :
                <Fragment>
                    {/* Summary  */}
                    <div className='e-admin-box-shadow p-4 e-bg-white e-border-radius-16'>
                        <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                            Summary
                        </h4>
                        <div className='row row-gap-3 row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-3 row-cols-2 padding-10px-top'>
                            <div className='col'>
                                <SummaryCard title="Videos created"
                                    quantity={summaryData.videos_created} />
                            </div>
                            <div className='col'>
                                <SummaryCard title="Active videos"
                                    quantity={summaryData.active_videos} />
                            </div>
                            <div className='col'>
                                <SummaryCard title="Archived videos"
                                    quantity={summaryData.archived_videos} />
                            </div>
                            <div className='col'>
                                <SummaryCard title="Average views"
                                    quantity={summaryData.avg_enrollments} />
                            </div>

                        </div>
                    </div>

                    {/* Video snippet table */}
                    <div ref={tableRef} className='margin-20px-top e-bg-white e-admin-box-shadow py-4 e-border-radius-16 '>
                        <div className='d-flex px-4 justify-content-space-between'>
                            <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-600 line-height-28px'>
                                Video snippet
                            </h4>
                            <img src={toggleFilter ? require("../../../Assets/Image/filter-icon-white.svg").default : require("../../../Assets/Image/filter-icon.svg").default}
                                draggable={false}
                                className={`${toggleFilter ? `e-bg-indigo` : ``} transition e-border-1px-anti-flash-white  e-border-radius-8 p-2 cursor-pointer`}
                                alt='filter'
                                onClick={() => setToggleFilter(!toggleFilter)} />
                        </div>

                        {/* filter */}
                        {toggleFilter &&
                            <div className='e-bg-anti-flash-white-dark py-3 px-4 mt-2'>
                                <div className='row'>
                                    <div className='col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6'>
                                        <CustomSelectBox label="Filter by"
                                            value={selectedCategory}
                                            options={CATEGORY_OPTIONS}
                                            type={2}
                                            className="e-bg-white e-border-radius-8 e-table-select"
                                            isSearchable={false}
                                            selectChange={(temp) => { setSelectedCategory(temp); }}
                                        />
                                    </div>
                                    <div className='col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6'>
                                        <CustomSelectBox label="Status"
                                            value={selectedStatus}
                                            options={STATUS_OPTIONS}
                                            className="e-bg-white e-border-radius-8 e-table-select"
                                            isSearchable={false}
                                            selectChange={(temp) => { setSelectedStatus(temp); }}
                                        />
                                    </div>
                                </div>
                            </div>
                        }

                        {/* Data table */}


                        {
                            videoData.videos.length !== 0 ?
                                <div className='e-table'>
                                    <DataTableComponent columns={TABLE_COLUMNS}
                                        pagination={true}
                                        paginationServer={true}
                                        data={videoData.videos}
                                        paginationTotalRows={videoData.total_count}
                                        onChangePage={_handlePageChange} />
                                </div>
                                :
                                <div className='h-100 py-5 my-5'>
                                    <EmptyScreen message="No videos found" />
                                </div>
                        }
                    </div>
                </Fragment>}
        </>
    )
})

export default VideoSnippet;