/*
 *   File : training.js
 *   Author : https://evoqins.com
 *   Description :  Training detail
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';

//Custom component
import { DataTableComponent } from '../../../../Component/DataTable';
import { SummaryCard } from '../../../../Component/Cards';
import { APIService } from '../../../../Service';
import { Loader } from '../../../../Component/Others';
import { EmptyScreen } from '../../../../Component/EmptyComponent';

const TABLE_CATEGORY_TAB = [
    {
        id: 1,
        name: "Resources"
    },
    {
        id: 2,
        name: "Videos"
    },
    {
        id: 3,
        name: "Quiz"
    },
    {
        id: 4,
        name: "Flash quiz"
    },
];

const ITEMS_PER_PAGE = 15;

const Training = (props) => {

    const [tabIndex, setTabIndex] = useState(1);

    const [trainingData, setTrainingData] = useState({});
    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [tableLoading, setTableLoading] = useState(true);

    useEffect(() => {
        _getTrainingDetail();
    }, [tabIndex, currentPage]);

    // handle tab change 
    const _handleTabChange = (id) => {
        setTabIndex(id);
        setTableLoading(true);
    };

    // handle resource table page change 
    const _handleTablePageChange = (page_num) => {
        setCurrentPage(page_num);
    };

    // resource table 
    const TABLE_COLUMNS_RESOURCES = [
        {
            name: 'Resource name',
            selector: row => row.course_name,
            width: "460px"
        },
        {
            name: 'Completed on',
            selector: row => row.completed_at.length === 0 ? '-' : row.completed_at,
            width: "160px"
        },
        {
            name: "Earned rewards (Points)",
            selector: row => row.points,
        }
    ];

    // video data table 
    const TABLE_COLUMNS_VIDEOS = [
        {
            name: 'Watched videos name',
            selector: row => row.video_name,
            width: "400px"
        },
        {
            name: 'Watched on',
            selector: row => row.watched_on,
            width: "160px"
        },
        {
            name: "Earned rewards (Points)",
            selector: row => row.points,
        }

    ];

    // quiz data table 
    const TABLE_COLUMNS_QUIZ = [
        {
            name: 'Quiz names',
            selector: row => row.quiz_name,
            width: "400px"
        },
        {
            name: 'Played on',
            selector: row => row.played_on,
            width: "160px"
        },
        {
            name: "Earned rewards (Points)",
            selector: row => row.points,
        }
    ];

    // flash quiz tata table 
    const TABLE_COLUMNS_FLASH_QUIZ = [
        {
            name: 'Quiz names',
            selector: row => row.quiz_name,
            width: "400px"
        },
        {
            name: 'Played on',
            selector: row => row.played_on,
            width: "160px"
        },
        {
            name: "Earned rewards (Points)",
            selector: row => row.points,
        }

    ];

    // API - Get sales specialist training data
    const _getTrainingDetail = () => {
        let is_post = true;
        let url = 'CA/sales-specialist/training';
        let data = {
            "customer_id": props.customerId,
            "filter": tabIndex,
            "page_num": currentPage,
            "limit": ITEMS_PER_PAGE
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setTrainingData(response.data);
            } else { }
            setLoading(false);
            setTableLoading(false);
        });
    };

    return (
        <>
            {/* Summary  */}
            <div className='e-admin-box-shadow p-4 e-bg-white e-border-radius-16'>
                <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                    Training summary
                </h4>
                <div className='row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 row-gap-3 padding-10px-top'>
                    {/* Summary cards */}

                    <div className='col'>
                        <SummaryCard title="Ongoing resources"
                            loader={loading}
                            quantity={trainingData.ongoing_course} />
                    </div>
                    <div className='col'>
                        <SummaryCard title="Completed resources"
                            loader={loading}
                            quantity={trainingData.completed_course} />
                    </div>
                    <div className='col'>
                        <SummaryCard title="Quiz"
                            loader={loading}
                            quantity={trainingData.completed_quiz} />
                    </div>
                    <div className='col'>
                        <SummaryCard title="Videos watched"
                            loader={loading}
                            quantity={trainingData.watched_videos} />
                    </div>
                    <div className='col'>
                        <SummaryCard title="Flash quiz"
                            loader={loading}
                            quantity={trainingData.completed_flash_quiz} />
                    </div>
                </div>
            </div>

            {/* Courses created table */}
            <div className='margin-20px-top e-bg-white e-admin-box-shadow py-4 e-border-radius-16 '>
                <h4 className='mb-3 px-4 e-text-rich-black e-alt-font-noto-sans e-font-18 e-font-weight-500 line-height-18px'>
                    Training details
                </h4>

                {/* table category tab */}
                <div className='d-flex gap-0-16 px-4 overflow-auto pb-xl-0 pb-lg-0 pb-md-0 pb-1 e-tab-scroll'>
                    {TABLE_CATEGORY_TAB.map((data, key) => (
                        <div key={key} className={`${tabIndex === data.id ? 'e-bg-lust e-text-white pe-none' : 'e-text-rich-black'} e-border-1px-cool-grey e-border-radius-24 cursor-pointer`}
                            onClick={() => _handleTabChange(data.id)} >
                            <p className='mb-0 px-4 padding-12px-tb e-alt-font-noto-sans  e-font-14 e-font-weight-500 line-height-14px w-max-content'>
                                {data.name}
                            </p>
                        </div>
                    ))}
                </div>

                {/* Data table */}
                <div className='e-table'>
                    {/* data table resources */}
                    {tabIndex === 1 ?
                        <>
                            {tableLoading ?
                                // loader 
                                <Loader />
                                :
                                <>
                                    {trainingData.data.data.length !== 0 ?
                                        <DataTableComponent columns={TABLE_COLUMNS_RESOURCES}
                                            data={trainingData.data.data}
                                            pagination={true}
                                            paginationServer={true}
                                            paginationTotalRows={trainingData.data.total_count}
                                            onChangePage={_handleTablePageChange} />
                                        :
                                        // empty screen
                                        <div className='h-100vh pt-5 mt-5'>
                                            <EmptyScreen message="No data found" />
                                        </div>
                                    }
                                </>
                            }
                        </>
                        :
                        //data table videos
                        tabIndex === 2 ?
                            <>
                                {tableLoading ?
                                    // loader 
                                    <Loader />
                                    :
                                    <>
                                        {trainingData.data.data.length !== 0 ?
                                            <DataTableComponent columns={TABLE_COLUMNS_VIDEOS}
                                                data={trainingData.data.data}
                                                pagination={true}
                                                paginationServer={true}
                                                paginationTotalRows={trainingData.data.total_count}
                                                onChangePage={_handleTablePageChange} />
                                            :
                                            // empty screen
                                            <div className='h-100vh pt-5 mt-5'>
                                                <EmptyScreen message="No data found" />
                                            </div>
                                        }
                                    </>
                                }
                            </>
                            :
                            //data table quiz
                            tabIndex === 3 ?
                                <>
                                    {tableLoading ?
                                        // loader 
                                        <Loader />
                                        :
                                        <>
                                            {trainingData.data.data.length !== 0 ?
                                                <DataTableComponent columns={TABLE_COLUMNS_QUIZ}
                                                    data={trainingData.data.data}
                                                    pagination={true}
                                                    paginationServer={true}
                                                    paginationTotalRows={trainingData.data.total_count}
                                                    onChangePage={_handleTablePageChange} />
                                                :
                                                // empty screen
                                                <div className='h-100vh pt-5 mt-5'>
                                                    <EmptyScreen message="No data found" />
                                                </div>
                                            }
                                        </>
                                    }
                                </>
                                :
                                <>
                                    {tableLoading ?
                                        // loader 
                                        <Loader />
                                        :
                                        <>
                                            {trainingData.data.data.length !== 0 ?
                                                <DataTableComponent columns={TABLE_COLUMNS_FLASH_QUIZ}
                                                    data={trainingData.data.data}
                                                    pagination={true}
                                                    paginationServer={true}
                                                    paginationTotalRows={trainingData.data.total_count}
                                                    onChangePage={_handleTablePageChange} />
                                                :
                                                // empty screen
                                                <div className='h-100vh pt-5 mt-5'>
                                                    <EmptyScreen message="No data found" />
                                                </div>
                                            }
                                        </>
                                    }
                                </>
                    }
                </div>
            </div>
        </>
    )
}

export default Training;