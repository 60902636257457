/*
 *   File : date-picker.js
 *   Author : https://evoqins.com
 *   Description : date picker component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useState } from 'react';
import { DayPicker } from 'react-day-picker';

const CustomDatePicker = (props) => {

    const [selectedDate, setSelectedDate] = useState(null);
    const default_month = new Date();

    useEffect(() => {
        if (props.selected !== null) {
            setSelectedDate(new Date(props.selected));
        } else {
            setSelectedDate(props.selected);
        }
    }, [props.selected]);

    // Function to disable past days
    const _handleDisablePastDays = (day) => {
        if (props.type === "alarm") {
            const midnight = new Date();
            midnight.setHours(0, 0, 0, 0);  //jotun alarm cannot select today

            if (props.minDate && day.getTime() < props.minDate.getTime()) {
                return true;
            }

            return day.getTime() <= midnight.getTime();

        } else if (props.minDate) {

            return day.getTime() < props.minDate.getTime();  // Disable dates before minDate

        } else {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            day.setHours(0, 0, 0, 0);
            return day.getTime() < today.getTime();  // disable past days
        }
    };

    return (
        <DayPicker mode="single"
            selected={selectedDate}
            showOutsideDays
            className={`${props.className} ${props.type === "modal" ? "" : "position-absolute"} e-day-picker w-358px e-bg-white e-border-radius-16 z-index-99`}
            format="yyyy-MM-dd"
            defaultMonth={default_month}
            fromMonth={default_month}
            disabled={_handleDisablePastDays}
            selectedDays={props.selected}
            onDayClick={props.onDayClick}
            onSelect={props.onSelect} />
    )
}

export default memo(CustomDatePicker);