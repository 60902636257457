/*
 *   File : store-detail.js
 *   Author : https://evoqins.com
 *   Description : Store detail page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';

// Custom component 
import { Employee } from './Helper';
import { useLocation, useNavigate } from 'react-router-dom';
import { SummaryCard } from '../../../Component/Cards';
import { APIService } from '../../../Service';
import { Loader } from '../../../Component/Others';

const CATEGORY_TAB = [
    {
        id: 1,
        name: "Store performance"
    },
    {
        id: 2,
        name: "Sales specialists"
    },
];

const StoreDetail = () => {

    const navigate = useNavigate();
    const { state } = useLocation();

    const [tabIndex, setTabIndex] = useState(0);

    const [storeId, setStoreId] = useState(null);
    const [storeDetailData, setStoreDetailData] = useState({});
    const [loading, setLoading] = useState(true);
    const [summaryLoading, setSummaryLoading] = useState(true);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    }, []);
    console.log("state::", state)

    useEffect(() => {
        if (state !== null) {
            setTabIndex(state.tabIndex);
            setStoreId(state.store_id);
        } else {
            navigate("/store-listing")
        }
    }, [state]);

    useEffect(() => {
        if (storeId !== null) {
            _storeDetail();  //API
        }
    }, [tabIndex, storeId]);

    // handle tab change 
    const _handleTabChange = (id) => {
        navigate("/store-listing/detail", {
            state: {
                tabIndex: id,
                store_id: storeId
            }
        });
        setSummaryLoading(true);
    };

    // API - Get store detail data
    const _storeDetail = () => {
        let is_post = true;
        let url = 'CA/store/detail';
        let data = {
            "store_id": storeId,
            "filter": tabIndex
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setStoreDetailData(response.data);
            } else { }
            setLoading(false);
            setSummaryLoading(false);
        });
    };

    return (
        <section className='padding-120px-bottom'>
            <div className='e-bg-lavender-blush-light pt-3 padding-98px-bottom  padding-52px-lr'>
                <h6 className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-16 line-height-22px e-font-weight-400'>
                    <span className='e-text-rich-black e-font-weight-500 text-decoration-hover-underline cursor-pointer'
                        onClick={() => navigate("/store-listing")} >
                        Store detail
                    </span>
                    /Jotun Indian private limited
                </h6>
            </div>

            {/* Admin details */}
            <div className='margin-neg-66px-top  padding-52px-lr'>

                {loading ?
                    // loader 
                    <Loader />
                    :
                    <>
                        <div className='e-admin-box-shadow e-bg-white e-border-radius-16 '>
                            <div className='row padding-32px-tb px-4'>
                                <div className='col-xl-5 col-lg-5 col-md-5 col-12 pb-xl-0 pb-lg-0 pb-md-0 pb-3 text-xl-start text-lg-start text-md-start text-center'>
                                    <h3 className='mb-3 e-primary-font-playfair e-text-charleston-green e-font-24 e-font-weight-600 line-height-32px'>
                                        {storeDetailData.name}
                                    </h3>
                                    <p className='mb-2 e-alt-font-noto-sans e-text-raisin-black e-font-16 e-font-weight-400 line-height-22px'>
                                        Store ID: {storeDetailData.store_id}
                                    </p>
                                    <p className='mb-0 e-alt-font-noto-sans e-text-raisin-black e-font-16 e-font-weight-400 line-height-22px'>
                                        Location: {storeDetailData.location}
                                    </p>
                                </div>
                                <div className='col-xl-7 col-lg-7 col-md-7 col-12 p-0'>
                                    <div className='e-bg-ghost-white e-border-radius-8 p-3'>
                                        <div className='row'>
                                            <div className='col-xl-3 col-lg-6 col-md-6 col-6'>
                                                <p className='mb-3 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-20px'>
                                                    Phone number
                                                </p>
                                                <p className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-14 e-font-weight-600 line-height-20px'>
                                                    <a href={`tel:${storeDetailData.mobile}`} className='e-text-rich-black text-decoration-hover-underline text-decoration-none'>
                                                        {storeDetailData.mobile}
                                                    </a>
                                                </p>
                                            </div>
                                            <div className='col-xl-2 col-lg-6 col-md-6 col-6'>
                                                <p className='mb-3 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-20px'>
                                                    Location
                                                </p>
                                                <p className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-14 e-font-weight-600 line-height-20px'>
                                                    {storeDetailData.location}
                                                </p>

                                            </div>
                                            <div className='col-xl-4 col-lg-6 col-md-6 col-6 pt-xl-0 pt-lg-3 pt-md-3 pt-3'>
                                                <p className='mb-3 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-20px'>
                                                    Overall sales
                                                </p>
                                                <p className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-14 e-font-weight-600 line-height-20px'>
                                                    {storeDetailData.overall_sales}<span className='e-font-weight-400'>{storeDetailData.overall_sales === null ? '-' : "(Sales per units)"}</span>
                                                </p>
                                            </div>
                                            <div className='ps-xl-0 col-xl-3 col-lg-6 col-md-6 col-6 pt-xl-0 pt-lg-3 pt-md-3 pt-3 '>
                                                <p className='mb-3 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-20px w-max-content'>
                                                    Revenue generated
                                                </p>
                                                <p className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-14 e-font-weight-600 line-height-20px'>
                                                    {storeDetailData.revenue}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/* category tab */}
                        <div className='d-flex gap-0-16 my-4 overflow-auto'>
                            {CATEGORY_TAB.map((data, key) => (
                                <div key={key} className={`${tabIndex === data.id ? 'e-bg-lust e-text-white pe-none' : 'e-text-rich-black'} e-border-1px-cool-grey e-border-radius-24 cursor-pointer`}
                                    onClick={() => _handleTabChange(data.id)} >
                                    <p className='mb-0 px-4 padding-12px-tb e-alt-font-noto-sans  e-font-14 e-font-weight-500 line-height-14px w-max-content'>
                                        {data.name}
                                    </p>
                                </div>
                            ))}
                        </div>


                        {/* Summary  */}
                        {tabIndex === 1 ?
                            <div className='e-admin-box-shadow p-4 e-bg-white e-border-radius-16'>
                                <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                                    Store performance summary
                                </h4>

                                {/* Summary cards */}
                                <div className='row row-cols-xl-4 row-cols-lg-3 row-cols-md-3 row-cols-sm-2 row-cols-1 row-gap-3 padding-10px-top'>
                                    <div className='col'>
                                        <SummaryCard title="Total sales(Units)"
                                            loader={summaryLoading}
                                            quantity={storeDetailData.data.total_sale} />
                                    </div>
                                    <div className='col'>
                                        <SummaryCard title="Premium sales"
                                            loader={summaryLoading}
                                            quantity={storeDetailData.data.premium_sale} />
                                    </div>
                                    <div className='col'>
                                        <SummaryCard title="Painters visited"
                                            loader={summaryLoading}
                                            quantity={storeDetailData.data.painter_visited} />
                                    </div>
                                    <div className='col'>
                                        <SummaryCard title="No. of sales specialists"
                                            loader={summaryLoading}
                                            quantity={storeDetailData.data.sales_specialist} />
                                    </div>
                                    <div className='col'>
                                        <SummaryCard title="Reward points earned"
                                            loader={summaryLoading}
                                            quantity={storeDetailData.data.reward_earned} />
                                    </div>
                                </div>
                            </div>
                            :
                            // employee details
                            <Employee storeId={storeId}
                                loader={summaryLoading}
                                summaryData={storeDetailData.data} />
                        }
                    </>
                }
            </div>
        </section>
    )
}

export default StoreDetail;