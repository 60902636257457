/*
 *   File : editor.js
 *   Author : https://evoqins.com
 *   Description : Custom wysuwyg editor component
 *   Integrations : react-draft-wysiwyg
 *   Version : 1.0.0
*/

import React, { memo } from 'react';
import { Editor } from 'react-draft-wysiwyg';

//style
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const CustomEditor = (props) => {
    return (
        <div className={props.className}>
            {props.label &&
                <label className={`${props.labelStyle} e-text-rich-black e-alt-font-noto-sans e-font-weight-500 line-height-18px e-font-14 padding-6px-bottom`}>
                    {props.label}
                    {props.require &&
                        <span className='e-text-lust'>*</span>
                    }
                </label>
            }
            <Editor editorState={props.editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                editorStyle={props.editorStyle}
                toolbar={{
                    options: props.options,
                    inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline'],
                    },
                    blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    }
                }}
                onEditorStateChange={props.onEditorStateChange}
            />
            <p className="mb-0 pt-1 e-error-message e-alt-font-noto-sans e-font-12 line-height-12px margin-neg-2px-top">
                {props.error}
            </p>
        </div>
    )
}

export default memo(CustomEditor);
