/*
 *   File : tag-selector.js
 *   Author : https://evoqins.com
 *   Description : tag selector component
 *   Integrations : react-select
 *   Version : 1.0.0
*/

import React, { memo, useState } from 'react';
import Select from 'react-select';

const COMPONENTS = {
    DropdownIndicator: null,
};

const TagSelector = (props) => {

    const [value, setValue] = useState(props.value);
    const [inputValue, setInputValue] = useState('');

    // handle change
    const _handleChange = (value) => {
        props.handleTagChange(value);
    };

    // function to create option
    const _createOption = (input) => ({
        value: input,
        label: input,
    });

    // handle key down
    const _handleKeyDown = (event) => {
        if (props.type === 2) {
            if (inputValue.length === 0) return;
            switch (event.key) {
                case 'Enter':
                case 'Tab':
                    setValue((prev) => [...prev, _createOption(inputValue)]);
                    props.handleTagChange((prev) => [...prev, _createOption(inputValue)]);
                    setInputValue('');
                    event.preventDefault();
            }
        }
    };

    return (
        <div className='h-84px '>
            {props.label &&
                <label className={`${props.labelClass} padding-6px-bottom e-text-black e-alt-font-noto-sans e-font-14 e-font-weight-500 line-height-20px`}>
                    {props.label}
                    {props.require &&
                        <span className='e-text-lust'>*</span>
                    }
                </label>
            }
            <Select value={props.value}
                inputValue={inputValue}
                isMulti
                isClearable
                placeholder={props.placeholder}
                options={props.options}
                className={`${props.className} ${props.type === 2 ? 'e-tag-select-input' : ''} e-tag-select `}
                classNamePrefix="select"
                components={props.type === 2 && COMPONENTS}
                menuIsOpen={props.menuIsOpen}
                menuPortalTarget={props.menuPortalTarget}
                styles={{
                    dropdownIndicator: (base, state) => ({
                        ...base,
                        transition: "all .2s ease",
                        transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
                    }),
                    control: (styles, state) => ({
                        ...styles,
                        borderColor: state.menuIsOpen ? '#213366' : props.borderColor ? props.borderColor : '#E3E5E5',
                        boxShadow: state.menuIsOpen ? '#213366' : 'none',
                        '&:hover': {
                            borderColor: '##213366',
                        },
                        borderRadius: props.type === 1 ? "0.75rem" : props.type === 2 ? "0.25rem" : "0.5rem",
                        cursor: "pointer",
                        background: "transparent",
                        width: props.width ? props.width : ""
                    }),
                    menuList: (styles) => ({
                        ...styles,
                        maxHeight: '180px',
                        '&::-webkit-scrollbar-thumb': {
                            background: "#213366",
                            borderRadius: "3rem",
                        },

                        '&::-webkit-scrollbar': {
                            width: "0.125rem",
                            display: "block",
                        }
                    }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999 })
                }}

                onKeyDown={_handleKeyDown}
                onChange={_handleChange}
                onInputChange={(value) => setInputValue(value)} />

            {props.error &&
                <p className='mb-0 e-error-message e-alt-font-noto-sans e-font-12 line-height-12px padding-2-top'>
                    {props.error}
                </p>
            }
        </div>
    )
}

export default memo(TagSelector);