export const _amountInWords = (val) => {
    let value = val;
    if (value >= 10000000) {
        value = (value / 10000000) + 'crore';
    } else if (value >= 100000) {
        value = (value / 100000) + 'L';
    } else if (value >= 1000) {
        value = (value / 1000) + 'k';
    }
    return value;
};
