/*
 *   File : reward.js
 *   Author : https://evoqins.com
 *   Description : Rewards detail
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';

//Custom component
import { DataTableComponent } from '../../../../Component/DataTable';
import { SummaryCard } from '../../../../Component/Cards';
import { APIService } from '../../../../Service';
import { Loader } from '../../../../Component/Others';
import { EmptyScreen } from '../../../../Component/EmptyComponent';

const TABLE_CATEGORY_TAB = [
    {
        id: 1,
        name: "Resource reward points"
    },
    {
        id: 2,
        name: "Sales reward points"
    },
    {
        id: 3,
        name: "Other reward points"
    },
];

const ITEMS_PER_PAGE = 15;

const Reward = (props) => {

    const [tabIndex, setTabIndex] = useState(1);

    const [rewardData, setRewardData] = useState({});
    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [tableLoading, setTableLoading] = useState(true);

    useEffect(() => {
        _getRewardDetails();
    }, [currentPage, tabIndex]);

    // handle tab change 
    const _handleTabChange = (id) => {
        setTabIndex(id);
        setTableLoading(true);
    };

    // handle resource table page change 
    const _handleTablePageChange = (page_num) => {
        setCurrentPage(page_num);
    };

    // resource data table 
    const TABLE_COLUMNS_RESOURCES = [
        {
            name: 'Date',
            selector: row => row.date,
            width: "140px"
        },
        {
            name: 'Rewards earned from',
            selector: row => row.earned_from,
            width: "200px"
        },
        {
            name: "Earned rewards",
            selector: row => row.earned_point,
        }
    ];

    // Sales data table 
    const TABLE_COLUMNS_SALES = [
        {
            name: 'Date',
            selector: row => row.date,
            width: "140px"
        },
        {
            name: 'Sales(Per unit)',
            selector: row => row.sales_per_unit,
            width: "140px"
        },
        {
            name: 'Product',
            selector: row => row.product,
            width: "260px"
        },
        {
            name: "Earned rewards",
            selector: row => row.earned_point,
        }
    ];

    // other data table 
    const TABLE_COLUMNS_OTHER = [
        {
            name: 'Date',
            selector: row => row.date,
            width: "140px"
        },
        {
            name: 'Reward',
            selector: row => row.reward,
            width: "160px"
        },
        {
            name: "Earned rewards",
            selector: row => row.earned_point,
        }

    ];

    // API - Get sales specialist reward data
    const _getRewardDetails = () => {
        let is_post = true;
        let url = 'CA/sales-specialist/rewards';
        let data = {
            "customer_id": props.customerId,
            "filter": tabIndex,
            "page_num": currentPage,
            "limit": ITEMS_PER_PAGE
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setRewardData(response.data);
            } else { }
            setLoading(false);
            setTableLoading(false);
        });
    };

    return (
        <>
            {/* Summary  */}
            <div className='e-admin-box-shadow p-4 e-bg-white e-border-radius-16'>
                <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                    Earned rewards points summary
                </h4>
                <div className='row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 row-gap-3 padding-10px-top'>

                    <div className='col'>
                        <SummaryCard title="Store level rewards"
                            loader={loading}
                            quantity={rewardData.store_level_reward} />
                    </div>
                    <div className='col'>
                        <SummaryCard title="Resource reward"
                            loader={loading}
                            quantity={rewardData.resourse_reward} />
                    </div>
                    <div className='col'>
                        <SummaryCard title="Quiz rewards"
                            loader={loading}
                            quantity={rewardData.quiz_reward} />
                    </div>
                    <div className='col'>
                        <SummaryCard title="Rewards from videos"
                            loader={loading}
                            quantity={rewardData.video_reward} />
                    </div>
                    <div className='col'>
                        <SummaryCard title="Flash quiz rewards"
                            loader={loading}
                            quantity={rewardData.flash_quiz_reward} />
                    </div>
                    <div className='col'>
                        <SummaryCard title="Sales rewards"
                            loader={loading}
                            quantity={rewardData.salse_reward} />
                    </div>
                    <div className='col'>
                        <SummaryCard title="Other rewards"
                            loader={loading}
                            quantity={rewardData.other_reward} />
                    </div>
                </div>
            </div>

            {/* Courses created table */}
            <div className='margin-20px-top e-bg-white e-admin-box-shadow py-4 e-border-radius-16 '>
                <h4 className='mb-3 px-4 e-text-rich-black e-alt-font-noto-sans e-font-18 e-font-weight-500 line-height-18px'>
                    Rewards details
                </h4>

                {/* table category tab */}
                <div className='d-flex gap-0-16 px-4 overflow-auto pb-xl-0 pb-lg-0 pb-md-0 pb-1 e-tab-scroll'>
                    {TABLE_CATEGORY_TAB.map((data, key) => (
                        <div key={key} className={`${tabIndex === data.id ? 'e-bg-lust e-text-white' : 'e-text-rich-black'} e-border-1px-cool-grey e-border-radius-24 cursor-pointer`}
                            onClick={() => _handleTabChange(data.id)} >
                            <p className='mb-0 px-4 padding-12px-tb e-alt-font-noto-sans  e-font-14 e-font-weight-500 line-height-14px w-max-content'>
                                {data.name}
                            </p>
                        </div>
                    ))}
                </div>

                {/* Data table */}
                <div className='e-table'>
                    {/* resource reward point table */}
                    {tabIndex === 1 ?
                        <>
                            {tableLoading ?
                                // loader 
                                <Loader />
                                :
                                <>
                                    {rewardData.data.length !== 0 ?
                                        <DataTableComponent columns={TABLE_COLUMNS_RESOURCES}
                                            data={rewardData.data}
                                            pagination={true}
                                            paginationServer={true}
                                            paginationTotalRows={rewardData.total_count}
                                            onChangePage={_handleTablePageChange} />
                                        :
                                        // empty screen
                                        <div className='h-100vh pt-5 mt-5'>
                                            <EmptyScreen message="No data found" />
                                        </div>
                                    }
                                </>
                            }
                        </>
                        :
                        // sales reward point data table
                        tabIndex === 2 ?
                            // <DataTableComponent columns={TABLE_COLUMNS_SALES}
                            //     pagination={true}
                            //     data={TABLE_DATA_SALES} />
                            <>
                                {tableLoading ?
                                    // loader 
                                    <Loader />
                                    :
                                    <>
                                        {rewardData.data.length !== 0 ?
                                            <DataTableComponent columns={TABLE_COLUMNS_SALES}
                                                data={rewardData.data}
                                                pagination={true}
                                                paginationServer={true}
                                                paginationTotalRows={rewardData.total_count}
                                                onChangePage={_handleTablePageChange} />
                                            :
                                            // empty screen
                                            <div className='h-100vh pt-5 mt-5'>
                                                <EmptyScreen message="No data found" />
                                            </div>
                                        }
                                    </>
                                }
                            </>
                            :
                            // other reward point data table
                            // <DataTableComponent columns={TABLE_COLUMNS_OTHER}
                            //     pagination={true}
                            //     data={TABLE_DATA_OTHER} />
                            <>
                                {tableLoading ?
                                    // loader 
                                    <Loader />
                                    :
                                    <>
                                        {rewardData.data.length !== 0 ?
                                            <DataTableComponent columns={TABLE_COLUMNS_OTHER}
                                                data={rewardData.data}
                                                pagination={true}
                                                paginationServer={true}
                                                paginationTotalRows={rewardData.total_count}
                                                onChangePage={_handleTablePageChange} />
                                            :
                                            // empty screen
                                            <div className='h-100vh pt-5 mt-5'>
                                                <EmptyScreen message="No data found" />
                                            </div>
                                        }
                                    </>
                                }
                            </>
                    }
                </div>
            </div>
        </>
    )
}

export default Reward