/*
 *   File : add-admin.js
 *   Author : https://evoqins.com
 *   Description : Add admin modal component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useState } from 'react';

//Custom component
import { CustomSelectBox, CustomTextInput } from '../FormElements';
import { PrimaryButton } from '../Buttons';
import { _getCountryList } from '../../Helper/api';
import { Icon } from '../Icon';
import { APIService } from '../../Service';
import { toast } from 'react-toastify';

const AddAdminModal = React.memo((props) => {
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [number, setNumber] = useState("");
    const [numberError, setNumberError] = useState("");
    const [selectedCounty, setSelectedCountry] = useState([]);
    const [countryOptions, setCountryOptions] = useState(props.countries);

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (props.countries.length !== 0) {
            setSelectedCountry(props.countries[0]);
        }
        setLoader(false);
    }, [props.countries]);

    useEffect(() => {
        var my_modal = document.getElementById("add-admin-modal");
        my_modal.addEventListener("hidden.bs.modal", () => {

            setName("");
            setNumber("");
            setEmail("");
        });
    }, []);

    useEffect(() => {
        setNameError("");
    }, [name]);

    useEffect(() => {
        setEmailError("");
    }, [email]);

    useEffect(() => {
        setNumberError("");
    }, [number]);


    // handle close modal
    const _handleCloseModal = () => {
        var button = document.getElementById("add-admin-modal-close");
        button.click();
        setName("");
        setNumber("");
        setEmail("");
        setNameError("");
        setNumberError("");
        setEmailError("");
        props.closeModal();
    }

    // handle add admin submit
    const _handleSubmit = () => {
        let is_valid = true;
        let validate_email = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;

        if (name.trim().length === 0) {
            setNameError("Name is required");
            is_valid = false;
        }

        if (!validate_email.test(email)) {
            setEmailError("Enter valid email");
            is_valid = false;
        }

        if (number.length === 0) {
            setNumberError("Number is required");
            is_valid = false;
        }

        if (is_valid === true) {
            setLoader(true);
            _addAdmin();
        }
    }

    //API - Invite country admin
    const _addAdmin = () => {
        let is_post = true;
        let url = 'country-admin/invite';
        let data = {
            "country_id": selectedCounty.value,
            "username": name,
            "email": email,
            "mobile": number
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success(response.message, {
                    type: "success",
                    className: "e-toast",
                });
                _handleCloseModal();
            } else {

                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setLoader(false);
        });
    };


    return (
        <div className="modal fade" id="add-admin-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="addAdminLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered e-border-radius-24">
                <div className="modal-content e-border-radius-8 w-412px margin-auto">
                    <div className="modal-header p-4 border-0">
                        <h4 className="mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-600 line-height-26px">
                            Add country admin
                        </h4>
                        <button id='add-admin-modal-close' type="button" className="btn-close d-none shadow-none" data-bs-dismiss="modal" aria-label="Close" ></button>

                        <Icon icon="close-icon"
                            className='cursor-pointer'
                            size="24px"
                            onPress={_handleCloseModal} />
                    </div>
                    <div className=" e-border-radius-8 e-bg-lotion p-4">

                        {/* Country name */}

                        <CustomSelectBox label="Country name"
                            value={selectedCounty}
                            options={countryOptions}
                            className="e-modal-select e-bg-white e-border-radius-12"
                            isSearchable={false}
                            selectChange={(id) => { setSelectedCountry(id); }}
                        />


                        {/* Admin name */}
                        <CustomTextInput label="Admin name"
                            placeholder="admin"
                            type="text"
                            require
                            value={name}
                            error={nameError}
                            className="w-100"
                            labelStyle="e-font-weight-500 e-text-raisin-black"
                            onKeyPress={_handleSubmit} 
                            handleChange={(e) => setName(e)} />

                        {/* Admin mail */}
                        <CustomTextInput label="Email"
                            placeholder="admin@gmail.com"
                            type="text"
                            require
                            value={email}
                            error={emailError}
                            className="w-100"
                            labelStyle="e-font-weight-500 e-text-raisin-black"
                            onKeyPress={_handleSubmit} 
                            handleChange={(e) => setEmail(e)} />

                        {/* Admin WhatsApp number */}
                        <CustomTextInput label="Phone no.(must have WhatsApp business account)"
                            placeholder="9989486986"
                            type="number"
                            require
                            value={number}
                            error={numberError}
                            className="w-100"
                            labelStyle="e-font-weight-500 e-text-raisin-black"
                            handleChange={(e) => {
                                if (e.length <= 11) {
                                    setNumber(e);
                                }
                            }}
                            onKeyPress={_handleSubmit} />

                        <div className='pt-2'>
                            <PrimaryButton name="Cancel"
                                className="e-bg-transparent e-border-1px-spanish-gray e-text-spanish-gray"
                                onPress={_handleCloseModal} />

                            <PrimaryButton name="Send invitation"
                                className="margin-12px-left w-136px"
                                status={loader}
                                onPress={_handleSubmit} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
});


AddAdminModal.defaultProps = {
    closeModal: () => { }
}

export default memo(AddAdminModal);