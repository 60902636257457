/*
 *   File : search-input.js
 *   Author : https://evoqins.com
 *   Description : Search input autocomplete component
 *   Integrations : react-search-autocomplete
 *   Version : 1.0.0
*/

import React, { memo } from 'react';
import Select from 'react-select';

const CustomSearchSuggestion = (props) => {

    return (
        <div className='w-100 h-56px position-relative'>
            <Select value={props.selected}
                options={props.options}
                placeholder={props.placeholder}
                className="e-search-suggestion"
                // menuIsOpen={true}
                styles={{
                    dropdownIndicator: (base, state) => ({
                        ...base,
                        transition: "all .2s ease",
                        transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
                    }),
                    control: (styles, state) => ({
                        ...styles,
                        borderColor: state.menuIsOpen ? '#213366' : '#E3E5E5',
                        boxShadow: state.menuIsOpen ? '#213366' : 'none',
                        '&:hover': {
                            borderColor: '##213366',
                        },
                        borderRadius: "0.5rem",
                        cursor: "text",
                        background: "transparent",
                        padding: "0.125rem 0 0.125rem 1.875rem",
                    }),
                    menuList: (styles) => ({
                        ...styles,
                        maxHeight: '180px',
                        '&::-webkit-scrollbar-thumb': {
                            background: "#213366",
                            borderRadius: "3rem",
                        },

                        '&::-webkit-scrollbar': {
                            width: "0.125rem",
                            display: "block",
                        }
                    }),
                }}
                onChange={props.handleSelect} />

            <img src={require("../../Assets/Image/search-icon-table.svg").default}
                draggable={false}
                className='position-absolute top-10px left-14px'
                alt='search' />

            {props.error &&
                <p className='mb-0 e-error-message e-alt-font-noto-sans e-font-12 line-height-12px padding-2-top'>
                    {props.error}
                </p>
            }
        </div>
    )
}

export default memo(CustomSearchSuggestion);