/*
 *   File : manage-flash-quiz.js
 *   Author : https://evoqins.com
 *   Description : Manage flash quiz page
 *   Integrations : react-toastify,js-cookie
 *   Version : 1.0.0
*/

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import Cookies from 'js-cookie';

// Custom component
import { CustomCheckbox, CustomDatePicker, CustomTextInput, TagSelector, TextArea } from '../../../Component/FormElements';
import { PrimaryButton } from '../../../Component/Buttons';
import { Icon } from '../../../Component/Icon';
import { APIService } from '../../../Service';
import { Loader, OverLay } from '../../../Component/Others';

const ANSWER_ERROR = {
    question: "",
    question_arabic: "",
    hint: "",
    hint_arabic: "",
    answers: [
        {
            answer: "",
            answer_arabic: "",
            is_answer: ""
        },
    ]
};

const ManageFlashQuiz = () => {

    const { state } = useLocation();
    const moduleRef = useRef([]);
    const navigate = useNavigate();

    const [adminType, setAdminType] = useState(Cookies.get("admin_type") === "1" ? "super_admin" : "country_admin");
    const [title, setTitle] = useState({ en: "", ar: "" });
    const [titleError, setTitleError] = useState({ en: "", ar: "" });
    const [description, setDescription] = useState({ en: "", ar: "" });
    const [descriptionError, setDescriptionError] = useState({ en: "", ar: "" });
    const [quizPoint, setQuizPoint] = useState("");
    const [quizPointError, setQuizPointError] = useState("");
    const [quizTime, setQuizTime] = useState({ en: "", ar: "" });
    const [quizTimeError, setQuizTimeError] = useState({ en: "", ar: "" });

    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [startDateError, setStartDateError] = useState("");
    const [startDateToggle, setStartDateToggle] = useState(false);

    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [endDateError, setEndDateError] = useState("");
    const [endDateToggle, setEndDateToggle] = useState(false);

    const [sectionData, setSectionData] = useState([]);
    const [createLoader, setCreateLoader] = useState(true);
    const [loading, setLoading] = useState(true);

    const [countries, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [countrySelect, setCountrySelect] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });

        setCreateLoader(false);
    }, []);

    useEffect(() => {
        if (state !== null) {
            if (state.type === "edit") {
                _getFlashQuizDetail();
            } else {
                setSectionData([{
                    question: "",
                    question_arabic: "",
                    hint: "",
                    hint_arabic: "",
                    answers: [
                        {
                            answer: "",
                            answer_arabic: "",
                            is_answer: true
                        },
                        {
                            answer: "",
                            answer_arabic: "",
                            is_answer: false
                        },
                    ],
                    errors: ANSWER_ERROR,
                    id: 1
                }]);
                setLoading(false);
            }
        } else {
            navigate("/manage-resource", {
                state: 3
            });
        }
    }, [state]);

    useEffect(() => {
        if (adminType === "super_admin") {
            _getCountries();
        }
    }, [adminType]);

    useEffect(() => {
        setQuizPointError("");
    }, [quizPoint]);

    useEffect(() => {
        setStartDateError("");
    }, [selectedStartDate]);

    useEffect(() => {
        setEndDateError("");
    }, [selectedEndDate]);

    // start date toggle show an hide
    const _handleStartDateToggle = () => {
        setStartDateToggle(!startDateToggle);

        window.scrollTo({
            top: 300, behavior: 'smooth'
        });
    };

    // handle change quiz title 
    const _handleChangeTitle = (lang, value) => {
        setTitle(prev => ({
            ...prev,
            [lang]: value
        }));

        setTitleError(prev => ({
            ...prev,
            [lang]: ""
        }))
    };

    // handle change quiz description 
    const _handleChangeDescription = (lang, value) => {
        setDescription(prev => ({
            ...prev,
            [lang]: value
        }));

        setDescriptionError(prev => ({
            ...prev,
            [lang]: ""
        }))
    };

    // handle quiz time change 
    const _handleTimeChange = (lang, value) => {
        setQuizTime(prev => ({
            ...prev,
            [lang]: value
        }));

        setQuizTimeError(prev => ({
            ...prev,
            [lang]: ""
        }))
    };

    // handle select start date
    const _handleSelectedStartDate = (day) => {
        setSelectedStartDate(day);
        setStartDateToggle(false);
    };

    // handle clear start date 
    const _handleClearStartDate = () => {
        setSelectedStartDate(null);
        setSelectedEndDate(null);
    };

    // end date toggle show an hide
    const _handleEndDateToggle = () => {
        setEndDateToggle(!endDateToggle);

        window.scrollTo({
            top: 300, behavior: 'smooth'
        });
    };

    // handle select end date
    const _handleSelectedEndDate = (day) => {
        setSelectedEndDate(day);
        setEndDateToggle(false);
    };

    // handle clear end date 
    const _handleClearEndDate = () => {
        setSelectedEndDate(null);
    };

    // handle change
    const _handleChange = useMemo(() => (index, field, value) => {
        const new_data = [...sectionData];
        new_data[index] = {
            ...sectionData[index],
            [field]: value,
            errors: {
                ...sectionData[index].errors,
                [field]: "" // Clear error for the edited field
            }
        };

        setSectionData(new_data);
    }, [sectionData]);

    // handle change for answers
    const _handleChangeAnswer = (index, ans_index, field, value) => {
        const new_data = [...sectionData];

        if (field === "is_answer") {
            new_data[index].answers = new_data[index].answers.map((ans, idx) => ({
                ...ans,
                is_answer: idx === ans_index ? true : false
            }));
        } else {
            new_data[index].answers[ans_index] = {
                ...new_data[index].answers[ans_index],
                [field]: value
            };
        }

        if (field === 'answer') {
            const error_key = `answer${ans_index}`;
            new_data[index].errors[error_key] = "";
        } else if (field === 'answer_arabic') {
            const error_key = `answer_arabic${ans_index}`;
            new_data[index].errors[error_key] = "";
        }

        setSectionData(new_data);
    };

    // handle add more section
    const _handleAddSection = () => {
        setSectionData(prev => [
            ...prev, {
                question: "",
                question_arabic: "",
                hint: "",
                hint_arabic: "",
                answers: [
                    {
                        answer: "",
                        answer_arabic: "",
                        is_answer: true
                    },
                    {
                        answer: "",
                        answer_arabic: "",
                        is_answer: false
                    },
                ],
                errors: ANSWER_ERROR,
                id: prev.length + 1
            }
        ]);
    };
    // handle delete each section
    const _handleDeleteSection = (index) => {
        const new_sections = [...sectionData];
        new_sections.splice(index, 1);
        setSectionData(new_sections);
    };

    // handle add more answers
    const _handleAddMoreAnswer = (index) => {
        const new_data = [...sectionData];
        if (new_data[index].answers.length < 4) { // Limiting to a maximum of 4 answers
            new_data[index].answers.push({
                answer: "",
                is_answer: false
            });
            setSectionData(new_data);
        } else { }
    };

    // handle delete answer
    const _handleDeleteAnswer = (sec_index, answer_index) => {
        const new_data = [...sectionData];
        const deleted_answer = new_data[sec_index].answers[answer_index];
        new_data[sec_index].answers.splice(answer_index, 1);

        if (deleted_answer.is_answer && new_data[sec_index].answers.length > 0) {
            new_data[sec_index].answers[0].is_answer = true;   //set up index 0 should be selected if deleted selected answer
        }

        setSectionData(new_data);
    };

    // handle Country change
    const _handleCountryChange = (value) => {
        setSelectedCountries(value);
    };

    // validate the form
    const _validate = () => {
        let is_valid = true;

        if (title.en.trim().length === 0) {
            setTitleError(prev => ({
                ...prev,
                en: "English title is required"
            }));
            is_valid = false;
        }

        if (title.ar.trim().length === 0) {
            setTitleError(prev => ({
                ...prev,
                ar: "Arabic title is required"
            }));
            is_valid = false;
        }

        if (description.en.trim().length === 0) {
            setDescriptionError(prev => ({
                ...prev,
                en: "English description is required"
            }));
            is_valid = false;
        }

        if (description.ar.trim().length === 0) {
            setDescriptionError(prev => ({
                ...prev,
                ar: "Arabic description is required"
            }));
            is_valid = false;
        }

        // Check reward point
        if (quizPoint.length === 0) {
            setQuizPointError("Question point is required");
            is_valid = false;
        } else {
            setQuizPointError("")
        }

        if (quizTime.en.trim().length === 0) {
            setQuizTimeError(prev => ({
                ...prev,
                en: "Quiz time english is required"
            }));
            is_valid = false;
        }

        if (quizTime.ar.trim().length === 0) {
            setQuizTimeError(prev => ({
                ...prev,
                ar: "Quiz time arabic is required"
            }));
            is_valid = false;
        }

        if (selectedStartDate === null) {
            setStartDateError("Select start date");
            is_valid = false;
        } else {
            setStartDateError("")
        }
        if (selectedStartDate !== null && selectedEndDate === null) {
            setEndDateError("Select end date");
            is_valid = false;
        } else {
            setEndDateError("")
        }

        // for error
        const new_section = sectionData.map((data) => {
            const error_object = {};

            // Check quiz question
            if (data.question.trim().length === 0) {
                error_object.question = "Quiz question english is required";
                is_valid = false;
            }

            if (data.question_arabic.trim().length === 0) {
                error_object.question_arabic = "Quiz question arabic is required";
                is_valid = false;
            }

            if (data.hint.trim().length === 0) {
                error_object.hint = "Hint english is required";
                is_valid = false;
            }

            if (data.hint_arabic.trim().length === 0) {
                error_object.hint_arabic = "Hint arabic is required";
                is_valid = false;
            }

            // Check answers
            data.answers.map((answer, index) => {
                if (answer.answer.trim().length === 0) {
                    error_object[`answer${index}`] = "Choice english is required";
                    is_valid = false;
                }

                if (answer.answer_arabic.trim().length === 0) {
                    error_object[`answer_arabic${index}`] = "Choice arabic is required";
                    is_valid = false;
                }
            });


            return { ...data, errors: error_object };
        });

        // focus error 
        if (state.type === "edit") {
            for (let i = 0; i < new_section.length; i++) {
                const section = new_section[i];
                if (section.errors && Object.values(section.errors).some(error => error.trim().length !== 0)) {
                    if (moduleRef.current[i]) {
                        moduleRef.current[i].scrollIntoView({ behavior: 'smooth', block: 'start', });
                        break;
                    }
                }
            }
        };

        setSectionData(new_section);

        return is_valid;
    };

    // handle save and update
    const _handleSubmit = () => {
        if (_validate()) {
            setCreateLoader(true);

            if (state.type === "edit") {
                _updateFlashQuiz();
            } else {
                _createFlashQuiz();
            }
        } else {
            console.log("error");
        }
    }

    // handle back
    const _handleBack = (id) => {
        navigate("/manage-resource", {
            state: id
        });
    };

    // API - Create flash quiz
    const _createFlashQuiz = () => {

        const updated_country_ids = selectedCountries.map(item => item.value);

        let is_post = true;
        let url = adminType === "super_admin" ? 'flash-quiz/create' : 'CA/flash-quiz/create';
        let data = {
            "title": title.en,
            "title_arabic": title.ar,
            "description": description.en,
            "description_arabic": description.ar,
            "time": quizTime.en,
            "time_arabic": quizTime.ar,
            "reward_points": quizPoint,
            "start_date": format(selectedStartDate, 'yyyy-MM-dd'),
            "end_date": format(selectedEndDate, 'yyyy-MM-dd'),
            "questions": sectionData,
            "country_ids": updated_country_ids,
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                navigate("/manage-resource", {
                    state: 3
                });

                toast.dismiss();
                toast.success("Flash quiz created successfully", {
                    type: "success",
                    className: "e-toast",
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setCreateLoader(false);
        });
    };

    // API - Update flash quiz
    const _updateFlashQuiz = () => {

        const updated_country_ids = selectedCountries?.map(item => item.value);

        let is_post = true;
        let url = adminType === "super_admin" ? 'flash-quiz/update' : 'CA/flash-quiz/update';
        let data = {
            "flash_quiz_id": state.flash_quiz_id,
            "title": title.en,
            "title_arabic": title.ar,
            "description": description.en,
            "description_arabic": description.ar,
            "time": quizTime.en,
            "time_arabic": quizTime.ar,
            "reward_points": quizPoint,
            "start_date": format(selectedStartDate, 'yyyy-MM-dd'),
            "end_date": format(selectedEndDate, 'yyyy-MM-dd'),
            "questions": sectionData,
            "country_ids": updated_country_ids,
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                navigate("/manage-resource", {
                    state: 3
                });

                toast.dismiss();
                toast.success("Flash quiz updated successfully", {
                    type: "success",
                    className: "e-toast",
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setCreateLoader(false);
        });
    };

    // API - Flash quiz detail
    const _getFlashQuizDetail = () => {
        let is_post = true;
        let url = adminType === "super_admin" ? 'flash-quiz/detail' : 'CA/flash-quiz/detail';
        let data = {
            "quiz_id": state.flash_quiz_id
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                let flash_quiz = response.data;
                setTitle({ en: flash_quiz.title, ar: flash_quiz.title_arabic });
                setDescription({ en: flash_quiz.description, ar: flash_quiz.description_arabic });
                setQuizPoint(flash_quiz.reward_points)
                setQuizTime({ en: flash_quiz.time, ar: flash_quiz.time_arabic });
                setSelectedStartDate(flash_quiz.start_date);
                setSelectedEndDate(flash_quiz.end_date);

                if (flash_quiz.question.length !== 0) {
                    const updated_question = flash_quiz.question.map(question => ({
                        ...question,
                        errors: ANSWER_ERROR,
                    }));
                    setSectionData(updated_question);
                }

                setSelectedCountries(flash_quiz.country_ids);

            } else {
                navigate("/manage-resource", {
                    state: 3
                });
            }
            setLoading(false);
        });
    };

    // API - Get countries
    const _getCountries = () => {
        let is_post = false;
        let url = 'general/list-countries';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCountries(response.data);
                setCountrySelect(true);
            } else {
                setCountrySelect(false);
            }
        });
    };

    return (
        <section className='pb-5 '>
            <div className={`${adminType === "super_admin" ? 'e-bg-azureish-white' : 'e-bg-lavender-blush-light'} pt-3 padding-98px-bottom padding-52px-lr`}>
                <div className='e-display-flex'>
                    <Icon icon="arrow-left"
                        className='cursor-pointer'
                        size="24px"
                        onPress={() => _handleBack(3)} />
                    <h1 className='ps-1 mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                        {state?.type === "edit" ? "Edit flash quiz" : "Add new flash quiz"}
                    </h1>
                </div>
            </div>

            <div className='margin-neg-66px-top padding-52px-lr'>
                {loading ?
                    // loader 
                    <Loader />
                    :

                    // details
                    <div className='e-bg-white e-admin-box-shadow p-4 e-border-radius-16 '>
                        <div className='d-flex gap-0-8 justify-content-space-between'>
                            <h3 className='mb-0 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px'>
                                Flash quiz details
                            </h3>
                            <PrimaryButton name="Save & update"
                                className={`${createLoader ? 'padding-6px-tb' : 'py-2'} e-border-radius-16 e-font-16 w-146px`}
                                status={createLoader}
                                onPress={_handleSubmit} />
                        </div>

                        {/* input section */}
                        <div className='row'>
                            <div className='col-xl-9 col-lg-9 col-md-10 col-12'>
                                {/* quiz title english */}
                                <CustomTextInput label="Quiz title (English)"
                                    placeholder="Color ful creations quiz challenge"
                                    type="text"
                                    require
                                    value={title.en}
                                    error={titleError.en}
                                    className="w-100 padding-10px-tb"
                                    labelStyle="e-font-weight-500 e-text-raisin-black"
                                    borderStyle="e-border-1px-platinum-light"
                                    handleChange={(e) => _handleChangeTitle("en", e)} />

                                {/* quiz title arabic */}
                                <CustomTextInput label="Quiz title (Arabic)"
                                    placeholder="Color ful creations quiz challenge"
                                    type="text"
                                    require
                                    value={title.ar}
                                    error={titleError.ar}
                                    className="w-100 padding-10px-tb"
                                    labelStyle="e-font-weight-500 e-text-raisin-black"
                                    borderStyle="e-border-1px-platinum-light"
                                    handleChange={(e) => _handleChangeTitle("ar", e)} />

                                {/* description english */}
                                <TextArea label="Quiz description (English)"
                                    placeholder="Enter your text..."
                                    type="text"
                                    rows="3"
                                    value={description.en}
                                    error={descriptionError.en}
                                    require
                                    className="w-100 px-3 padding-10px-tb"
                                    handleChange={(e) => _handleChangeDescription("en", e)} />

                                {/* description arabic */}
                                <TextArea label="Quiz description (Arabic)"
                                    placeholder="Enter your text..."
                                    type="text"
                                    rows="3"
                                    value={description.ar}
                                    error={descriptionError.ar}
                                    require
                                    className="w-100 px-3 padding-10px-tb"
                                    handleChange={(e) => _handleChangeDescription("ar", e)} />

                                <div className='row'>
                                    {/* Quiz time english */}
                                    <div className='col-6 ps-2'>
                                        <CustomTextInput label="Quiz time (English)"
                                            placeholder="1 min"
                                            type="text"
                                            require
                                            value={quizTime.en}
                                            error={quizTimeError.en}
                                            className="w-100 padding-10px-tb"
                                            labelStyle="e-font-weight-500 e-text-raisin-black"
                                            borderStyle="e-border-1px-platinum-light e-border-radius-8"
                                            handleChange={(e) => _handleTimeChange("en", e)} />
                                    </div>

                                    {/* Quiz time arabic*/}
                                    <div className='col-6 ps-2'>
                                        <CustomTextInput label="Quiz time (Arabic)"
                                            placeholder="1 min"
                                            type="text"
                                            require
                                            value={quizTime.ar}
                                            error={quizTimeError.ar}
                                            className="w-100 padding-10px-tb"
                                            labelStyle="e-font-weight-500 e-text-raisin-black"
                                            borderStyle="e-border-1px-platinum-light e-border-radius-8"
                                            handleChange={(e) => _handleTimeChange("ar", e)} />
                                    </div>
                                </div>

                                {/* date select */}
                                <div className='row'>
                                    <div className='col-6 pe-2'>
                                        <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                            Start date<span className='e-text-lust'>*</span>
                                        </p>
                                        {/* Pick date  */}
                                        <div className='pt-2 h-70px position-relative'>
                                            <div className={`${startDateToggle ? 'e-border-1px-indigo' : 'e-border-1px-platinum-light'} e-display-flex gap-0-4px e-border-radius-6 padding-14px-tb px-2 cursor-pointer`}
                                                onClick={_handleStartDateToggle}>
                                                <img src={require("../../../Assets/Image/CountryAdmin/Notification/calendar-icon.svg").default}
                                                    draggable={false}
                                                    alt='calender-icon' />
                                                {selectedStartDate !== null ?
                                                    <>
                                                        <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-16px'>
                                                            {format(selectedStartDate, 'PP')}
                                                        </p>

                                                    </>
                                                    :
                                                    <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-16px'>
                                                        Choose start date
                                                    </p>
                                                }

                                            </div>
                                            <p className='mb-0 e-error-message e-alt-font-noto-sans e-font-12 line-height-12px padding-2-top'>
                                                {startDateError}
                                            </p>

                                            {/* close icon */}
                                            {selectedStartDate !== null &&
                                                <Icon icon="close-icon"
                                                    className='position-absolute top-20px right-8px cursor-pointer'
                                                    size="20px"
                                                    onPress={_handleClearStartDate} />
                                            }
                                        </div>

                                        {/* date picker */}
                                        <>
                                            {startDateToggle &&
                                                <>
                                                    <OverLay className="e-bg-overlay " isOpen={startDateToggle} onClose={_handleStartDateToggle} />
                                                    <CustomDatePicker selected={selectedStartDate}
                                                        className='e-day-picker w-358px position-absolute e-bg-white e-border-radius-16 z-index-99'
                                                        onSelect={_handleSelectedStartDate} />
                                                </>
                                            }
                                        </>
                                    </div>

                                    <div className='col-6 ps-2'>
                                        <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                            End date<span className='e-text-lust'>*</span>
                                        </p>
                                        {/* Pick date  */}
                                        <div className='pt-2 h-70px position-relative'>
                                            <div className={`${endDateToggle ? 'e-border-1px-indigo' : 'e-border-1px-platinum-light'} ${selectedStartDate === null ? 'e-disable' : ''} e-display-flex gap-0-4px e-border-radius-6 padding-14px-tb px-2 cursor-pointer`}
                                                onClick={_handleEndDateToggle}>
                                                <img src={require("../../../Assets/Image/CountryAdmin/Notification/calendar-icon.svg").default}
                                                    draggable={false}
                                                    alt='calender-icon' />
                                                {selectedEndDate !== null ?
                                                    <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-16px'>
                                                        {format(selectedEndDate, 'PP')}
                                                    </p>
                                                    :
                                                    <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-16px'>
                                                        Choose end date
                                                    </p>
                                                }
                                            </div>
                                            <p className='mb-0 e-error-message e-alt-font-noto-sans e-font-12 line-height-12px padding-2-top'>
                                                {endDateError}
                                            </p>

                                            {/* clear icon */}
                                            {selectedEndDate !== null &&
                                                <Icon icon="close-icon"
                                                    className='position-absolute top-20px right-8px cursor-pointer'
                                                    size="20px"
                                                    onPress={_handleClearEndDate} />
                                            }
                                        </div>

                                        {/* date picker */}
                                        <>
                                            {endDateToggle &&
                                                <>
                                                    <OverLay className="e-bg-overlay " isOpen={endDateToggle} onClose={_handleEndDateToggle} />
                                                    <CustomDatePicker selected={selectedEndDate}
                                                        className='e-day-picker w-358px position-absolute e-bg-white e-border-radius-16 z-index-99'
                                                        minDate={selectedStartDate}
                                                        onSelect={_handleSelectedEndDate} />
                                                </>
                                            }
                                        </>
                                    </div>
                                </div>

                                <div className='row'>
                                    {/* Quiz point */}
                                    <div className='col-6 pe-2'>
                                        <CustomTextInput label="Quiz point"
                                            placeholder="100"
                                            type="number"
                                            require
                                            value={quizPoint}
                                            error={quizPointError}
                                            className="w-100 padding-10px-tb"
                                            labelStyle="e-font-weight-500 e-text-raisin-black"
                                            borderStyle="e-border-1px-platinum-light e-border-radius-8"
                                            handleChange={(e) => setQuizPoint(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Add sections */}
                        {sectionData.map((data, key) => (
                            <div key={key} ref={error => moduleRef.current[key] = error} className='row mb-2 pt-2'>
                                <div className='col-xl-9 col-lg-9 col-md-10 col-12'>
                                    <div className={`${key === 0 ? 'justify-content-between' : 'justify-content-end'} d-flex align-items-center`}>
                                        {key === 0 &&
                                            <h3 className='mb-3 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px'>
                                                {state?.type === "edit" ? "Edit modules" : "Add modules"}
                                            </h3>
                                        }
                                        {sectionData.length > 1 &&
                                            <p className='mb-2 text-end e-text-lust e-font-16 e-alt-font-noto-sans e-font-weight-600 line-height-22px cursor-pointer text-decoration-hover-underline'
                                                onClick={() => _handleDeleteSection(key)} >
                                                Delete
                                            </p>
                                        }
                                    </div>

                                    {/* Quiz question */}
                                    <div className='e-bg-ghost-white p-3 e-border-radius-16'>
                                        <CustomTextInput label="Quiz question (English)"
                                            placeholder="Successful negotiation essential strategies & skills"
                                            type="text"
                                            require
                                            value={data.question}
                                            error={data.errors.question}
                                            className="w-100 padding-10px-tb"
                                            labelStyle="e-font-weight-500 e-text-raisin-black"
                                            borderStyle="e-border-1px-platinum-light"
                                            handleChange={(e) => _handleChange(key, 'question', e)}
                                        />

                                        {/* Question arabic */}
                                        <CustomTextInput label="Quiz question (Arabic)"
                                            placeholder="Successful negotiation essential strategies & skills"
                                            type="text"
                                            require
                                            value={data.question_arabic}
                                            error={data.errors.question_arabic}
                                            className="w-100 padding-10px-tb"
                                            labelStyle="e-font-weight-500 e-text-raisin-black"
                                            borderStyle="e-border-1px-platinum-light"
                                            handleChange={(e) => _handleChange(key, 'question_arabic', e)}
                                        />

                                        {/* first option */}
                                        {data.answers.map((answer, index) => (
                                            <div key={index} className='row'>
                                                <div className='col-10'>
                                                    <CustomTextInput label={index === 0 && 'Add options'}
                                                        placeholder={`Option ${index + 1} (English)`}
                                                        type="text"
                                                        require
                                                        value={answer.answer}
                                                        error={data.errors[`answer${index}`]}
                                                        className="w-100 padding-10px-tb"
                                                        labelStyle="e-font-weight-500 e-text-raisin-black"
                                                        borderStyle="e-border-1px-platinum-light"
                                                        handleChange={(e) => _handleChangeAnswer(key, index, 'answer', e)}
                                                    />

                                                    <CustomTextInput placeholder={`Option ${index + 1} (Arabic)`}
                                                        type="text"
                                                        require
                                                        value={answer.answer_arabic}
                                                        error={data.errors[`answer_arabic${index}`]}
                                                        className="w-100 padding-10px-tb"
                                                        labelStyle="e-font-weight-500 e-text-raisin-black"
                                                        borderStyle="e-border-1px-platinum-light"
                                                        handleChange={(e) => _handleChangeAnswer(key, index, 'answer_arabic', e)}
                                                    />
                                                </div>

                                                <div className='col-2'>
                                                    <CustomCheckbox label={index === 0 ? "Answer" : ""}
                                                        require
                                                        checked={answer.is_answer}
                                                        className="w-100 padding-10px-tb"
                                                        labelStyle="e-font-weight-500 e-text-raisin-black"
                                                        borderStyle="e-border-1px-platinum-light"
                                                        handleChange={(e) => _handleChangeAnswer(key, index, 'is_answer', e)}
                                                    />

                                                    {data.answers.length > 2 &&
                                                        <div className={`text-end ${index === 0 ? 'pt-3' : 'pt-1'}`}>
                                                            <img src={require("../../../Assets/Image/CountryAdmin/delete.svg").default}
                                                                draggable={false}
                                                                className='cursor-pointer'
                                                                alt='delete'
                                                                onClick={() => _handleDeleteAnswer(key, index)} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        ))}

                                        <div className={`d-flex ${data.answers.length < 4 ? 'justify-content-between' : 'justify-content-end'}`}>

                                            {/* add more answer */}
                                            {data.answers.length < 4 &&
                                                <p className='mb-0 e-text-indigo e-font-14 e-alt-font-noto-sans e-font-weight-600 line-height-22px cursor-pointer'
                                                    onClick={() => _handleAddMoreAnswer(key)} >
                                                    + <span className='cursor-pointer text-decoration-hover-underline '>Add answer</span>
                                                </p>
                                            }

                                            {/* delete answer */}
                                            {/* {data.answers.length > 2 &&
                                            <p className='mb-2 text-end e-text-lust e-font-14 e-alt-font-noto-sans e-font-weight-600 line-height-22px cursor-pointer text-decoration-hover-underline'
                                                onClick={() => _handleDeleteAnswer(key)} >
                                                Delete
                                            </p>
                                        } */}
                                        </div>

                                        {/* Add hint */}
                                        <div className='pt-2'>
                                            <CustomTextInput label="Add hint (English)"
                                                placeholder="Enhances paints grip for a smooth and durable finish"
                                                type="text"
                                                require
                                                value={data.hint}
                                                error={data.errors.hint}
                                                className="w-100 padding-10px-tb"
                                                labelStyle="e-font-weight-500 e-text-raisin-black"
                                                borderStyle="e-border-1px-platinum-light"
                                                handleChange={(e) => _handleChange(key, 'hint', e)} />

                                            {/* Arabic hint */}
                                            <CustomTextInput label="Add hint (Arabic)"
                                                placeholder="Enhances paints grip for a smooth and durable finish"
                                                type="text"
                                                require
                                                value={data.hint_arabic}
                                                error={data.errors.hint_arabic}
                                                className="w-100 padding-10px-tb"
                                                labelStyle="e-font-weight-500 e-text-raisin-black"
                                                borderStyle="e-border-1px-platinum-light"
                                                handleChange={(e) => _handleChange(key, 'hint_arabic', e)} />
                                        </div>

                                    </div>
                                </div>

                                {/* Add more button */}
                                <div className='col-xl-3 col-lg-3 col-md-10 col-12 text-xl-center text-lg-center text-end pt-xl-0 pt-lg-0 pt-2 my-auto'>
                                    {sectionData.length === key + 1 && sectionData.length < 4 &&
                                        <p className='mb-0 e-text-indigo e-font-16 e-alt-font-noto-sans e-font-weight-600 line-height-22px cursor-pointer'
                                            onClick={_handleAddSection} >
                                            + <span className='cursor-pointer text-decoration-hover-underline '>Add new quiz</span>
                                        </p>
                                    }
                                </div>
                            </div>
                        ))}

                        {/* Countries select */}
                        {countrySelect === true &&
                            <div className='row pt-2'>
                                <div className='col-xl-9 col-lg-9 col-md-10 col-12'>
                                    <h3 className='mb-3 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px'>
                                        {state.type === "edit" ? "Manage Countries" : "Countries"}
                                    </h3>
                                    <div className='e-bg-ghost-white p-3 e-border-radius-16'>
                                        {/* Country select */}
                                        <TagSelector label="Country"
                                            value={selectedCountries}
                                            options={countries}
                                            className="e-bg-white e-border-radius-12"
                                            handleTagChange={_handleCountryChange} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        </section>
    )
}

export default ManageFlashQuiz;