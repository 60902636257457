/*
 *   File : normal-bar.js
 *   Author : https://evoqins.com
 *   Description : Normal bar component
 *   Integrations : react-chartjs-2,chart.js
 *   Version : 1.0.0
*/

import React, { memo } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const NormalBarChart = (props) => {
    const OPTIONS = {
        indexAxis: props.type === 1 ? 'y' : 'x',
        responsive: true,
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false
            },
            tooltip: {
                // displayColors: false,
                backgroundColor: '#000',
                bodyColor: '#fff',
                paddingRight: 12,
                paddingLeft: 12,
                paddingTop: 8,
                paddingBottom: 8,

                cornerRadius: 12,
                borderColor: '#7A7A7A',
                // callbacks: {
                //     label: (context) => {
                //         return context.raw.y; // Return value
                //     },
                //     title: (context) => {
                //         return ""; // remove title value
                //     }
                // }
            }
        },

        scales: {
            x: {
                grid: {
                    display: props.type === 2 ? false : true,
                },
                border: {
                    display: props.type === 1 ? true : false
                }
            },
            y: {
                stacked: true,
                grid: {
                    display: props.type === 1 ? false : true,
                },
                border: {
                    display: props.type === 1 ? false : true
                },
                ticks: {
                    display: props.type === 1 ? false : true,
                },

            },
        },
    };

    //labels
    const labels = props.label;

    const DATA = {
        labels,
        datasets: [
            {
                // categoryPercentage: 0.8,
                label: '',
                data: props.data,
                backgroundColor: props.type === 1 ? ['#213366', '#F5B01A', '#E02126'] : props.type === 2 ? '#E02126' :
                    '#213366',
                barThickness: props.type === 1 ? 26 : props.type === 2 ? 34 : 18,
                // barPercentage: 0.8,
            },
        ],
    };

    return (
        <div className={`${props.className} ${props.type === 2 ? 'h-256px' : ''} w-100`}>
            <Bar options={OPTIONS} data={DATA} />
        </div>
    )
}

export default memo(NormalBarChart);