/*
 *   File : upload.js
 *   Author : https://evoqins.com
 *   Description : Drag and drop file upload component
 *   Integrations : react-drag-drop-files
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useState } from 'react';

//Custom components
import { FileUploader } from 'react-drag-drop-files';
import { CustomVideoPlayer } from '../Video';

const CustomFileUpload = (props) => {

    const [fileError, setFileError] = useState("");

    useEffect(() => {
        setFileError(props.error);
    }, [props.error, props.errorUpdate]);

    // handle change file
    const _handleChangeFile = (url) => {
        if (props.resolution) {
            const img = new Image();
            img.src = URL.createObjectURL(url);

            img.onload = () => {
                const { width, height } = img;
                if (props.requiredWidth && props.requiredHeight) {
                    if (width !== props.requiredWidth || height !== props.requiredHeight) {
                        setFileError(`Please provide required dimensions ${props.requiredWidth}px * ${props.requiredHeight}px`);
                        return;
                    } else {
                        setFileError('');
                        props.handleChange(url);
                    }
                }
            };
        } else {
            props.handleChange(url);
        }
    };

    return (
        <div className={`${props.label ? `h-148px pt-1` : `h-118px`}`}>
            {props.label &&
                <label className={`${props.labelStyle} e-text-rich-black e-alt-font-noto-sans e-font-weight-500 line-height-18px e-font-14 pb-2`}>
                    {props.label}
                    {props.require &&
                        <span className='e-text-lust'>*</span>
                    }
                </label>
            }

            {props.file == null ?
                <>

                    {props.loader ?
                        <div className='text-center e-border-2px-dashed-royal-blue e-bg-lavender-blue e-border-radius-16 p-3 w-100'>
                            <img src={require("../../Assets/loader-dark.gif")}
                                width={32}
                                className='py-3'
                                alt='loader' />
                        </div>
                        :
                        <FileUploader name="file"
                            types={props.fileType}
                            classes="text-center cursor-pointer e-border-2px-dashed-royal-blue e-bg-lavender-blue e-border-radius-16 p-3 w-100"
                            children={

                                <>
                                    <img src={require("../../Assets/Image/CountryAdmin/upload-icon.svg").default}
                                        draggable={false}
                                        alt='upload' />
                                    <p className='mb-0 pt-2 e-text-black-chocolate e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-12px'>
                                        {props.placeholder}, or
                                        <a className='ps-1 e-text-indigo cursor-pointer e-font-weight-500 text-decoration-none text-decoration-hover-underline'>
                                            browse
                                        </a>
                                    </p>
                                    <p className='mb-0 padding-6px-top e-text-davys-grey e-alt-font-noto-sans e-font-8 e-font-weight-400 line-height-12px'>
                                        Supports: {props.support ? props.support : "PNG, JPG, JPEG"} {`${props.resolution ? `(${props.resolution})` : ''}`}
                                    </p>
                                </>

                            }
                            handleChange={_handleChangeFile} />

                    }
                </>
                :
                // uploaded file
                <>
                    {props.type === 2 ?
                        <CustomVideoPlayer url={props.file}
                            className="h-96px"
                            controls={true} />
                        :
                        props.type === 3 ?
                            <div className=' e-border-radius-16 overflow-hidden text-center  e-border-2px-dashed-royal-blue e-bg-lavender-blue e-border-radius-16'>
                                <p className='px-4 py-4 my-3 e-text-rich-black e-alt-font-noto-sans e-font-16 e-font-weight-400 line-height-20px text-truncate'>
                                    {props.file}
                                </p>
                            </div>
                            :
                            <div className='e-border-radius-16 overflow-hidden text-center e-border-2px-dashed-royal-blue e-bg-lavender-blue e-border-radius-16'>
                                <img src={props.file}
                                    draggable={false}
                                    height={96}
                                    alt='thumbnail' />
                            </div>
                    }
                </>
            }
            {fileError &&
                <p className='mb-0 e-error-message e-alt-font-noto-sans e-font-12 line-height-12px pt-1'>
                    {fileError}
                </p>
            }

        </div >
    )
}

export default memo(CustomFileUpload);