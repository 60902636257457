/*
 *   File : list.js
 *   Author : https://evoqins.com
 *   Description : Banner list page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';

// Custom components
import { BannerCard } from '../../Component/Cards';
import { PrimaryButton } from '../../Component/Buttons';
import { APIService } from '../../Service';
import { ManageBanner, ConfirmDeleteModal } from '../../Component/Modal';
import { CustomTab, Loader } from '../../Component/Others';
import { EmptyScreen } from '../../Component/EmptyComponent';
import ConfirmBannerActions from '../../Component/Modal/confirm-banner-actions';
import { useLocation, useNavigate } from 'react-router-dom';


// Custom data
// Tabs
const BANNER_TABS = [
    {
        id: 1,
        label: "Active banners"
    },
    {
        id: 2,
        label: "Hidden banners"
    },
    {
        id: 3,
        label: "Archived banners"
    },
];

// demo scheduled data
const TEMP_SCHEDULED = [
    {
        "banner_id": 28,
        "image": "https://jotun-dev.s3.ap-south-1.amazonaws.com/banner/banner.png",
        "type_id": 1,
        "type": "IN-APP",
        "resource_type": 2,
        "resource": "Course",
        "resource_id": 1,
        "resource_name": "Sales training: Practical sales techniques.",
        "scheduled_time": "Sent on 24 Feb 2024, 12.00 AM"
    },
    {
        "banner_id": 43,
        "image": "https://jotun-dev.s3.ap-south-1.amazonaws.com/public/admin/master/new-banner.png",
        "type_id": 1,
        "type": "IN-APP",
        "resource_type": 2,
        "resource": "Course",
        "resource_id": 2,
        "resource_name": "Successful negotiation: Essential strategies & skills",
        "scheduled_time": "Sent on 24 Feb 2024, 12.00 AM"
    }
];


const BannerList = () => {

    const navigate = useNavigate();
    const { state } = useLocation();

    const [tabIndex, setTabIndex] = useState(null);

    const [bannerList, setBannerList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [courseData, setCourseData] = useState([]);
    const [courseLoading, setCourseLoading] = useState(true);
    const [videoData, setVideoData] = useState([]);
    const [manageModal, setManageModal] = useState(null);
    const [editData, setEditData] = useState([]);
    const [deleteId, setDeleteId] = useState(null);
    const [deleteResponse, setDeleteResponse] = useState(false);

    // banner types
    const [activeBanners, setActiveBanners] = useState([]);
    const [hiddenBanners, setHiddenBanners] = useState([]);
    const [archiveBanners, setArchiveBanners] = useState([]);
    const [scheduledBanners, setScheduledBanners] = useState(TEMP_SCHEDULED);

    // Modal
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [tempData, setTempData] = useState("");
    const [actionType, setActionType] = useState("");

    const [resourceOptions, setResourceOptions] = useState([]);
    const [newsOptions, setNewsOptions] = useState([]);
    const [competitionOptions, setCompetitionOptions] = useState([]);

    const [resourceLoading, setResourceLoading] = useState(true);

    useEffect(() => {
        if (state !== null) {
            setTabIndex(state);
        } else {
            setTabIndex(1);
        }
        setManageModal(null);
        setShowConfirmModal(false);

        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    }, [state]);

    useEffect(() => {
        _getResource();
    }, []);

    useEffect(() => {
        if (tabIndex !== null) {
            _getBannerList();
        }
    }, [tabIndex]);

    useEffect(() => {
        if (deleteId !== null) {
            const delete_modal = new Modal(document.getElementById('confirm-delete-modal'));
            delete_modal.show();
        }
    }, [deleteId]);

    useEffect(() => {
        if (manageModal !== null) {
            const manage_banner = new Modal(document.getElementById('add-banner-modal'));
            manage_banner.show();

        }
    }, [manageModal]);

    useEffect(() => {

        if (showConfirmModal) {
            const confirm_banner = new Modal(document.getElementById('confirm-banner-action-modal'));
            confirm_banner.show();
        }

    }, [showConfirmModal]);

    // handle tab change 
    const _handleTabChange = (id) => {
        // setTabIndex(id);
        navigate("/banner-list", {
            state: id
        });
        setLoading(true);
    };

    // handle add new banner modal
    const _handleAddBanner = () => {
        setManageModal(true);
        setEditData([]);

        _getCourseList();
        _getVideoList();
        _getNewsList();
        _getCompetitionList();
    };

    // handle edit banner 
    const _handleEditBanner = (data, type) => {

        setManageModal(false);
        setEditData({ data, type });

        _getCourseList();
        _getVideoList();
        _getNewsList();
        _getCompetitionList();
    };

    // handle delete 
    const _handleDeleteBanner = (banner_id) => {
        setDeleteId(banner_id);
        setDeleteResponse(false);
    };


    // handle add banner to archive
    const _handleShowModal = (data, action_type) => {
        setShowConfirmModal(true);
        setTempData(data);
        setActionType(action_type);

        // if (action_type === "ARCHIVE" || action_type === "UN_ARCHIVE") {
        //     _manageBanner(data.banner_id, 2);
        // } else {
        //     _manageBanner(data.banner_id, 1);
        // }
    };

    // handle remove banner from archived
    const _handleRemoveArchived = (data, action_type) => {
        // const updated_list = archiveBanners.filter((banner => banner.banner_id !== selected_banner_id));
        // setArchiveBanners(updated_list);
        setShowConfirmModal(true);
        setActionType(action_type);
        setTempData(data);
        // _manageBanner(selected_banner_id, 2, "UN_ARCHIVE");
    };

    const _handleConfirm = (type) => {
        if (type === "ARCHIVE") {
            _manageBanner(tempData.banner_id, 2, type);
        } else if (type === "HIDE_BANNER") {
            _manageBanner(tempData.banner_id, 1, type);
        } else if (type === "SHOW_BANNER") {
            _manageBanner(tempData.banner_id, 1, type);
        } else if (type === "UN_ARCHIVE") {
            _manageBanner(tempData.banner_id, 2, type);
        }

        setShowConfirmModal(false);

    };

    // ======== API CALLS ============ //

    // API - Coupon list 
    const _getBannerList = () => {
        let is_post = true;
        let url = 'CA/banner/list';
        let data = {
            "page_num": 1,
            "filter": tabIndex === 4 ? null : tabIndex
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setBannerList(response.data);
            } else {
            }
            setLoading(false);
        });
    };

    // API - Course list 
    const _getCourseList = () => {
        let is_post = false;
        let url = 'general/course/list';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCourseData(response.data);
            } else { }
            setCourseLoading(false);
        });
    };

    // API - Video list 
    const _getVideoList = () => {
        let is_post = false;
        let url = 'general/video/list';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setVideoData(response.data);
            } else {
            }
        });
    };

    // API - Delete banner 
    const _deleteBanner = (delete_id) => {
        let is_post = true;
        let url = 'CA/banner/delete';
        let data = {
            "banner_id": delete_id
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setDeleteResponse(true);
            } else {
                setDeleteResponse(false);
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        });
    };

    // API - Manage banner active/inactive or archive/unarchive
    const _manageBanner = (banner_id, status, type) => {
        let is_post = true;
        let url = 'CA/banner/manage';
        let data = {
            "banner_id": banner_id,
            "status": status
        };

        APIService(is_post, url, data).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                if (type === "ARCHIVE") {
                    navigate("/banner-list", {
                        state: 3
                    });
                } else if (type === "HIDE_BANNER") {
                    navigate("/banner-list", {
                        state: 2
                    });
                } else if (type === "SHOW_BANNER") {
                    navigate("/banner-list", {
                        state: 1
                    });
                } else if (type === "UN_ARCHIVE") {
                    navigate("/banner-list", {
                        state: 1
                    });
                }

                toast.success(response.data.message, {
                    type: "success",
                    className: "e-toast",
                });
            } else {
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        });
    };

    // API - Get Resources
    const _getResource = () => {
        let is_post = false;
        let url = 'general/get-contents';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setResourceOptions(response.data);
            } else { }
            setResourceLoading(false);
        });
    };

    // API - Get News list
    const _getNewsList = () => {
        let is_post = false;
        let url = 'general/news/list';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setNewsOptions(response.data);
            } else { }
        });
    };

    // API - Get competition list
    const _getCompetitionList = () => {
        let is_post = false;
        let url = 'general/competition/list';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCompetitionOptions(response.data);
            } else { }
        });
    };

    return (
        <section className='pb-5 '>
            <div className='d-flex justify-content-between e-bg-lavender-blush-light pt-3 padding-20px-bottom padding-52px-lr'>
                {/*<section className='pb-5'>
            <div className='e-bg-lavender-blush-light pt-3 padding-52px-lr padding-32px-bottom'>*/}
                <h1 className='mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-raisin-black e-font-weight-600'>
                    Manage banners
                </h1>
            </div>
            <div className='d-flex justify-content-between flex-wrap e-bg-lavender-blush-light padding-52px-lr padding-26px-bottom'>
                {/* Tabs */}
                <div className='d-flex gap-3 overflow-auto pb-xl-0 pb-lg-0 pb-md-0 pb-1 e-tab-scroll'>
                    {
                        BANNER_TABS.map((tab) => (
                            <CustomTab key={tab.id}
                                data={tab}
                                tabIndex={tabIndex}
                                handleTabChange={_handleTabChange} />
                        ))
                    }
                </div>

                {/* Button */}
                <div className='text-end '>
                    {resourceLoading === false &&
                        <PrimaryButton name={"Add new banner"}
                            className="me-3 e-border-radius-16 padding-14px-tb line-height-12px e-font-16 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-2 mt-2"
                            onPress={_handleAddBanner} />
                    }
                </div>
            </div>

            <div className='padding-52px-lr'>

                {
                    loading ?
                        // loader 
                        <Loader />
                        :
                        <>
                            {
                                tabIndex == 1 &&
                                <>
                                    {
                                        bannerList.data.length !== 0 ?
                                            <div className='row pt-4 mt-2 row-gap-4'>
                                                {bannerList.data.map((data, key) => (
                                                    <div key={key} className='col-sm-11 col-12'>
                                                        <BannerCard data={data}
                                                            bannerType={1}
                                                            handleEdit={() => _handleEditBanner(data, "edit")}
                                                            handleAddToArchive={() => _handleShowModal(data, "ARCHIVE")}
                                                            handleHideAndShowBanner={() => _handleShowModal(data, "HIDE_BANNER")} />
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            // empty screen
                                            <div className='h-100vh pt-5 mt-5'>
                                                <EmptyScreen message="No banners found" />
                                            </div>
                                    }
                                </>
                            }
                            {
                                tabIndex == 2 &&
                                <>
                                    {
                                        bannerList.data.length !== 0 ?
                                            <div className='row pt-4 mt-2 row-gap-4'>
                                                {bannerList.data.map((data, key) => (
                                                    <div key={key} className='col-sm-11 col-12'>
                                                        <BannerCard data={data}
                                                            bannerType={2}
                                                            handleEdit={() => _handleEditBanner(data, "edit")}
                                                            handleAddToArchive={() => _handleShowModal(data, "ARCHIVE")}
                                                            handleHideAndShowBanner={() => _handleShowModal(data, "SHOW_BANNER")} />
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            // empty screen
                                            <div className='h-100vh pt-5 mt-5'>
                                                <EmptyScreen message="No banners found" />
                                            </div>
                                    }
                                </>
                            }
                            {
                                tabIndex == 3 &&
                                <>
                                    {
                                        bannerList.data.length !== 0 ?
                                            <div className='row pt-4 mt-2 row-gap-4'>
                                                {bannerList.data.map((data, key) => (
                                                    <div key={key} className='col-sm-11 col-12'>
                                                        <BannerCard data={data}
                                                            bannerType={3}
                                                            handleEdit={() => _handleEditBanner(data, "edit")}
                                                            handleRemoveArchived={() => _handleRemoveArchived(data, "UN_ARCHIVE")}
                                                            handleHideAndShowBanner={() => _handleShowModal(data, data.is_active ? "HIDE_BANNER" : "SHOW_BANNER")} />
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            // empty screen
                                            <div className='h-100vh pt-5 mt-5'>
                                                <EmptyScreen message="No banners found" />
                                            </div>
                                    }
                                </>
                            }
                            {/* {
                                tabIndex == 4 &&
                                <>
                                    {
                                        bannerList.data.length !== 0 ?
                                            <div className='row pt-4 mt-2 row-gap-4'>
                                                {bannerList.data.map((data, key) => (
                                                    <div key={key} className='col-sm-11 col-12'>
                                                        <BannerCard data={data}
                                                            bannerType={4}
                                                            handleAddToArchive={() => _handleShowModal(data, "ARCHIVE")}
                                                            handleEdit={() => _handleEditBanner(data, "edit")}
                                                            handleHideAndShowBanner={() => _handleShowModal(data, "HIDE_BANNER")} />
                                                    </div>
                                                ))}
                                            </div>
                                            :
                                            // empty screen
                                            <div className='h-100vh pt-5 mt-5'>
                                                <EmptyScreen message="No banners found" />
                                            </div>
                                    }
                                </>
                            } */}
                        </>
                }
            </div>

            {/* banner modal */}
            {manageModal !== null &&
                <ManageBanner courseList={courseData}
                    videoList={videoData}
                    editData={editData}
                    resourceOptions={resourceOptions}
                    newsOptions={newsOptions}
                    competitionOptions={competitionOptions}
                    handleClose={() => setManageModal(null)}
                    handleDelete={_handleDeleteBanner}
                    success={() => {
                        _getBannerList();
                        setManageModal(null);
                    }} />
            }

            {/* confirm delete modal */}
            {deleteId !== null &&
                <ConfirmDeleteModal label="banner"
                    deleteId={deleteId}
                    response={deleteResponse}
                    delete={(id) => _deleteBanner(id)}
                    handleClose={() => setDeleteId(null)}
                    success={() => _getBannerList()} />
            }

            {
                showConfirmModal &&
                <ConfirmBannerActions
                    // type={hideBanner ? 1 : 2}
                    actionType={actionType}
                    archive={tabIndex !== 3 ? true : false}
                    // hideBanner={hideBanner}
                    onClose={() => setShowConfirmModal(false)}
                    onConfirm={_handleConfirm} />
            }

        </section>
    )
}

export default BannerList;