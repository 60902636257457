/*
 *   File : video-player.js
 *   Author : https://evoqins.com
 *   Description : Video player component
 *   Integrations : react-player
 *   Version : 1.0.0
*/

import React, { memo } from 'react';
import ReactPlayer from 'react-player';

const CustomVideoPlayer = (props) => {

    return (
        <div className={`${props.className} e-border-radius-16 overflow-hidden e-bg-black `}>
            <ReactPlayer url={props.url}
                controls={props.controls}
                height="100%"
                playing={props.playing}
                width="100%"
                onEnded={props.onEnded}
            />
        </div>
    )
}

export default memo(CustomVideoPlayer);