/*
 *   File : dashboard.js
 *   Author : https://evoqins.com
 *   Description : Home page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//custom Component
import { StatisticCard } from '../../../Component/Cards';
import { TextButton } from '../../../Component/Buttons';
import { NormalBarChart, StackedBarChart } from '../../../Component/Chart';
import { DataTableComponent } from '../../../Component/DataTable';
import { CustomSelectBox } from '../../../Component/FormElements';
import { APIService } from '../../../Service';
import { Loader } from '../../../Component/Others';

//Custom style
import styles from "../../../Style/Component/dashboard.module.scss";
import { _amountInWords } from '../../../Helper/converter';

const SALES_PERFORMANCE = [
    {
        value: 1,
        label: 'Last week'
    },
    {
        value: 2,
        label: 'Last month'
    },
    {
        value: 3,
        label: 'Last year'
    },
    {
        value: 4,
        label: 'Last 5 year'
    },
];

const BUSINESS_STATS = [
    {
        value: 1,
        label: 'Last week',
    },
    {
        value: 2,
        label: 'Last month'
    },
    {
        value: 3,
        label: 'Last year'
    },
    {
        value: 4,
        label: 'Last 5 year'
    },
];


const USER_SIGNUP_FILTER = [
    {
        value: 1, label: 'Week',
    },
    {
        value: 2, label: 'Monthly'
    },
    {
        value: 3, label: 'Yearly'
    },
];

const Dashboard = () => {

    const navigate = useNavigate();

    const [selectedPerformance, setSelectedPerformance] = useState(SALES_PERFORMANCE[2]);

    const [selectedBusiness, setSelectedBusiness] = useState(BUSINESS_STATS[2]);
    const [selectedUserSignUp, setSelectedUserSignUp] = useState(USER_SIGNUP_FILTER[1]);

    const [dashboardData, setDashboardData] = useState({});
    const [loading, setLoading] = useState(true);

    const [userSignUpData, setUserSignUpData] = useState({});
    const [signupDataLoading, setSignUpDataLoading] = useState(true);

    const [businessStatsData, setBusinessStatsData] = useState({});
    const [businessStatsLoading, setBusinessStatsLoading] = useState(true);
    const [updatedBusinessStatsData, setUpdatedBusinessStatsData] = useState([]);

    const [salesPerformanceData, setSalesPerformanceData] = useState({});
    const [performanceDataLoading, setPerformanceDataLoading] = useState(true);

    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [countrySelect, setCountrySelect] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });

        _getCountries();
    }, []);

    useEffect(() => {
        _getDashboardData();
    }, [selectedCountry]);

    useEffect(() => {
        _salesPerformance();
    }, [selectedPerformance, selectedCountry]);

    useEffect(() => {
        _getBusinessStats();
    }, [selectedBusiness, selectedCountry]);

    useEffect(() => {
        _userSignupData();
    }, [selectedUserSignUp, selectedCountry]);

    useEffect(() => {
        if (!businessStatsLoading) {
            const max_value = Math.max(...businessStatsData.data_sale);
            const double_sale = max_value + 10;
            const updated_data = [...businessStatsData.data_sale, double_sale];
            setUpdatedBusinessStatsData(updated_data);
        }
    }, [businessStatsData, businessStatsLoading]);

    // handle sort sales performance 
    const _handleSortSalesPerformance = (value) => {
        setSelectedPerformance(value);
        setPerformanceDataLoading(true);
    };

    // handle sort business stats 
    const _handleSortBusinessStats = (value) => {
        setSelectedBusiness(value);
        setBusinessStatsLoading(true);
    };

    // handle sort of user signup 
    const _handleSortUserSignUp = (value) => {
        setSignUpDataLoading(true);
        setSelectedUserSignUp(value);
    };

    // handle select country 
    const _handleFilterCountry = (value) => {
        setSelectedCountry(value);
        setLoading(true);
    };

    // data table column 
    const TABLE_COLUMNS = [
        {
            name: 'Users name',
            selector: row => row.user_name,
            width: "160px"
        },
        {
            name: 'Shop',
            selector: row => row.shop_name,
            width: "160px"
        },
        {
            name: 'Country',
            selector: row => row.location,
        },
        {
            name: 'Points',
            selector: row => row.points,
        },
    ];

    // API - Get dashboard data
    const _getDashboardData = () => {
        let is_post = true;
        let url = 'dashboard/get';
        let data = {
            "country_id": selectedCountry.value
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setDashboardData(response.data);
            } else { }
            setLoading(false);
        });
    };

    // API - Get sales performance data
    const _salesPerformance = () => {
        let is_post = true;
        let url = 'dashboard/sales-statistics';
        let data = {
            "filter": selectedPerformance.value,
            "country_id": selectedCountry.value
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setSalesPerformanceData(response.data);
            } else {

            }
            setPerformanceDataLoading(false);
        });
    };

    // API - Get Business stats data
    const _getBusinessStats = () => {
        let is_post = true;
        let url = 'dashboard/business-statistics';
        let data = {
            "filter": selectedBusiness.value,
            "country_id": selectedCountry.value
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setBusinessStatsData(response.data);
            } else {

            }
            setBusinessStatsLoading(false);
        });
    };


    // API - Get Statistics data
    const _userSignupData = () => {
        let is_post = true;
        let url = 'dashboard/get-statistics';
        let data = {
            "filter": selectedUserSignUp.value,
            "country_id": selectedCountry.value
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setUserSignUpData(response.data);
            } else {

            }
            setSignUpDataLoading(false);
        });
    };

    // API - Get countries list
    const _getCountries = () => {

        const country_filter_options = [];

        let is_post = false;
        let url = 'general/list-countries';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                country_filter_options.push({
                    value: null,
                    label: "All"
                });

                response.data.map((country) => (
                    country_filter_options.push({
                        value: country.value,
                        label: country.label
                    })
                ));
                setCountrySelect(true);
                setSelectedCountry(country_filter_options[0]);
                setCountries(country_filter_options);

            } else {
                setCountrySelect(false);
            }
        });
    };

    return (
        <section>
            <div className='e-bg-azureish-white pt-3 padding-98px-bottom padding-52px-lr'>
                <div className='d-flex justify-content-between'>
                    <h1 className='mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                        Dashboard
                    </h1>

                    {/* select country */}
                    {countrySelect === true &&
                        <CustomSelectBox value={selectedCountry}
                            options={countries}
                            type={1}
                            className="e-header-select-box"
                            width="120px"
                            borderColor="#8696bb"
                            isSearchable={false}
                            selectChange={(value) => _handleFilterCountry(value)} />
                    }
                </div>
            </div>
            <div className='padding-52px-lr'>
                {loading ?
                    // loader 
                    <Loader />
                    :
                    <>
                        <div className='row margin-neg-66px-top '>
                            {/* Statistic Card  */}
                            <div className='col-xl-9 col-lg-8 col-md-8 col-12'>
                                <div className='row row-cols-xl-4 row-cols-lg-3 row-cols-md-3 row-cols-sm-3 row-cols-2 row-gap-4'>
                                    <div className='col pe-1'>
                                        <StatisticCard icon={require("../../../Assets/Image/Card/people.svg").default}
                                            quantity={dashboardData.register_count}
                                            title="Registered users" />
                                    </div>
                                    <div className='col pe-1'>
                                        <StatisticCard icon={require("../../../Assets/Image/Card/courses.svg").default}
                                            quantity={dashboardData.course_count}
                                            title="Courses" />
                                    </div>
                                    <div className='col pe-1'>
                                        <StatisticCard icon={require("../../../Assets/Image/Card/video.svg").default}
                                            quantity={dashboardData.video_count}
                                            title="Video snippets" />
                                    </div>
                                    <div className='col pe-1'>
                                        <StatisticCard icon={require("../../../Assets/Image/Card/quiz.svg").default}
                                            quantity={dashboardData.quiz_count}
                                            title="Quizzes" />
                                    </div>
                                    <div className='col pe-1'>
                                        <StatisticCard icon={require("../../../Assets/Image/Card/quick-quiz.svg").default}
                                            quantity={dashboardData.flash_quiz_count}
                                            title="Quick quizzes" />
                                    </div>
                                    <div className='col pe-1'>
                                        <StatisticCard icon={require("../../../Assets/Image/Card/shops.svg").default}
                                            quantity={dashboardData.shops_count}
                                            title="No of shops" />
                                    </div>
                                    <div className='col pe-1'>
                                        <StatisticCard icon={require("../../../Assets/Image/Card/country.svg").default}
                                            quantity={dashboardData.countries_count}
                                            title="No of countries" />
                                    </div>

                                </div>
                            </div>
                            {/* Admins by country */}
                            <div className='col-xl-3 col-lg-4 col-md-4 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-3'>
                                <div className='e-bg-white e-border-radius-12 p-3'>
                                    <h4 className='mb-4 e-text-indigo e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                                        Admins by country
                                    </h4>
                                    {dashboardData.admins_by_countries.map((data, key) => (
                                        <div key={key} className='e-bg-cultured p-2 e-border-radius-4 mb-2'>
                                            <div className='d-flex gap-0-8 align-items-baseline'>
                                                <div>
                                                    <img src={require("../../../Assets/Image/Dashboard/flag-icon.svg").default}
                                                        draggable={false}
                                                        alt='flag' />
                                                </div>
                                                <div>
                                                    <p className='mb-0 padding-6px-bottom e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-16px'>
                                                        {data.country}
                                                    </p>
                                                    <div className='d-flex flex-wrap'>
                                                        <p className='mb-0 pe-1 e-text-raisin-black e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                                                            {data.admin_name}
                                                        </p>
                                                        <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                                                            ({data.mobile})
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    {/* view all country admin */}
                                    <div className='pt-4'>
                                        <TextButton name="View all"
                                            onPress={() => navigate("/country-admin")} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row padding-38px-bottom pt-4 h-100'>
                            {/* Sales performance chart */}
                            <div className='col-xl-6 col-lg-6 col-12'>
                                <div className='d-flex flex-column h-100'>
                                    <div className={`${performanceDataLoading ? '' : 'overflow-auto'} flex-grow-1 e-bg-azureish-white p-4  e-border-radius-16 e-chart-scroll h-`}>
                                        <div className='d-flex justify-content-space-between flex-wrap'>
                                            <h4 className='mb-0 e-text-deep-violet e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                                                Sales performance
                                            </h4>
                                            <CustomSelectBox value={selectedPerformance}
                                                type={1}
                                                options={SALES_PERFORMANCE}
                                                isSearchable={false}
                                                selectChange={(value) => _handleSortSalesPerformance(value)} />
                                        </div>

                                        {/* StackedBarChart */}
                                        {performanceDataLoading ?
                                            // loader 
                                            <Loader className="h-30vh " />
                                            :
                                            <div className='row pt-4'>
                                                <div className='col-8 mt-auto'>
                                                    <div>
                                                        <p className='mb-0 padding-28px-bottom e-text-raisin-black e-alt-font-noto-sans e-font-48 e-font-weight-500 line-height-58px'>
                                                            {_amountInWords(salesPerformanceData.total)}
                                                        </p>
                                                        <StackedBarChart label={salesPerformanceData.data.label}
                                                            interior={salesPerformanceData.data.interior}
                                                            exterior={salesPerformanceData.data.exterior} />
                                                    </div>
                                                </div>

                                                <div className={`${styles.e_interior_paint} col-4`}>
                                                    <div className='padding-58px-bottom'>
                                                        <ul className='ps-3  mb-2 '>
                                                            <li className={`${styles.first} e-text-sonic-silver e-alt-font-noto-sans e-font-14 e-font-weight-600 line-height-20px`}>
                                                                Interior paints
                                                            </li>
                                                        </ul>
                                                        <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-24 e-font-weight-500 line-height-28px'>
                                                            {_amountInWords(salesPerformanceData.total_interior)}
                                                        </p>
                                                    </div>
                                                    <div className='padding-58px-bottom'>
                                                        <ul className='ps-3  mb-2 '>
                                                            <li className={`${styles.second} e-text-sonic-silver e-alt-font-noto-sans e-font-14 e-font-weight-600 line-height-20px`}>
                                                                Exterior paints
                                                            </li>
                                                        </ul>
                                                        <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-24 e-font-weight-500 line-height-28px'>
                                                            {_amountInWords(salesPerformanceData.total_exterior)}
                                                        </p>
                                                    </div>
                                                    {/* <div>
                                                <ul className='ps-3  mb-2 '>
                                                    <li className={`${styles.third} e-text-sonic-silver e-alt-font-noto-sans e-font-14 e-font-weight-600 line-height-20px`}>
                                                        Paint accessories
                                                    </li>
                                                </ul>
                                                <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-24 e-font-weight-500 line-height-28px'>
                                                    1.1K
                                                </p>
                                            </div> */}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            {/* Business stats chart */}
                            <div className='col-xl-6 col-lg-6 col-12 pt-xl-0 pt-lg-0 pt-3'>
                                <div className='d-flex flex-column h-100'>
                                    <div className={`${businessStatsLoading ? '' : 'overflow-auto'} flex-grow-1 e-bg-white h-100 p-4 e-border-radius-16 e-box-shadow-business-stats e-chart-scroll`}>
                                        <div className='d-flex justify-content-space-between'>
                                            <h4 className='mb-0 e-text-deep-violet e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                                                Business stats
                                            </h4>
                                            <CustomSelectBox value={selectedBusiness}
                                                type={1}
                                                options={BUSINESS_STATS}
                                                isSearchable={false}
                                                selectChange={(value) => _handleSortBusinessStats(value)} />
                                        </div>

                                        {businessStatsLoading ?
                                            // loader 
                                            <Loader className="h-100" />
                                            :
                                            <div className='row padding-58px-top'>
                                                <div className='col-8 mt-auto'>
                                                    <NormalBarChart label={businessStatsData.label}
                                                        data={updatedBusinessStatsData}
                                                        type={1} />
                                                </div>
                                                <div className={`${styles.e_business_stats} col-4`}>
                                                    <div className='pb-3'>
                                                        <ul className='ps-3 mb-2 '>
                                                            <li className={`${styles.first} e-text-sonic-silver e-alt-font-noto-sans e-font-16 e-font-weight-600 line-height-20px`}>
                                                                Total sales
                                                            </li>
                                                        </ul>
                                                        <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-18 e-font-weight-500 line-height-28px'>
                                                            {businessStatsData.data_sale[0]}
                                                        </p>
                                                    </div>
                                                    <div className='pb-3'>
                                                        <ul className='ps-3 mb-2 '>
                                                            <li className={`${styles.second} e-text-sonic-silver e-alt-font-noto-sans e-font-16 e-font-weight-600 line-height-20px`}>
                                                                Premium sales
                                                            </li>
                                                        </ul>
                                                        <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-18 e-font-weight-500 line-height-28px'>
                                                            {businessStatsData.data_sale[1]}
                                                        </p>
                                                    </div>
                                                    {/* <div>
                                                <ul className='ps-3 mb-2 '>
                                                    <li className={`${styles.third} e-text-sonic-silver e-alt-font-noto-sans e-font-16 e-font-weight-600 line-height-20px`}>
                                                        Painters visited
                                                    </li>
                                                </ul>
                                                <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-18 e-font-weight-500 line-height-28px'>
                                                    450
                                                </p>
                                            </div> */}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row pb-5'>
                            {/* Top rewarded users leader board */}
                            <div className='col-xl-6 col-lg-6 col-12'>
                                <div className='d-flex flex-column h-100'>
                                    <div className='flex-grow-1 e-bg-white e-leader-board-box-shadow py-4 e-border-radius-16'>
                                        <h4 className='mb-0 px-4 e-text-deep-violet e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                                            Leaderboard of top rewarded users
                                        </h4>

                                        <div className='pt-4 e-leader-board-table padding-54px-bottom'>
                                            <DataTableComponent columns={TABLE_COLUMNS}
                                                data={dashboardData.leader_board.data}
                                                rowClick={(row) => navigate("/country-admin/detail", {
                                                    state: {
                                                        admin_id: 3,
                                                        tabIndex: 1
                                                    }
                                                })} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* User signup metrics */}
                            <div className='col-xl-6 col-lg-6 col-12 pt-xl-0 pt-lg-0 pt-3'>
                                <div className='d-flex flex-column h-100'>
                                    <div className={`${signupDataLoading ? '' : ''} flex-grow-1 e-bg-white e-leader-board-box-shadow p-4 e-border-radius-16 `}>
                                        <div className='d-flex justify-content-space-between flex-wrap'>
                                            <h4 className='mb-0 e-text-deep-violet e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                                                User signups metrics
                                            </h4>
                                            <CustomSelectBox value={selectedUserSignUp}
                                                type={1}
                                                options={USER_SIGNUP_FILTER}
                                                isSearchable={false}
                                                selectChange={(value) => _handleSortUserSignUp(value)} />
                                        </div>

                                        {signupDataLoading ?
                                            // loader 
                                            <Loader className="h-100" />
                                            :
                                            <div className='pt-4'>
                                                {/* chart */}
                                                <NormalBarChart label={userSignUpData.label}
                                                    data={userSignUpData.data}
                                                    className="h-240px" />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div >
        </section >
    )
}

export default Dashboard;