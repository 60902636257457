/*
 *   File : header.js
 *   Author : https://evoqins.com
 *   Description : Header component
 *   Integrations : js-cookie,react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';

// Custom component
import { OverLay } from '../Others';
import LeftNavigationWidget from './left-navigation';
import { ResetPasswordModal } from '../Modal';
import { Icon } from '../Icon';
import { APIService } from '../../Service';

const CustomHeader = (props) => {

    const navigate = useNavigate();
    const location = useLocation();

    const [toggleMore, setToggleMore] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [notificationCount, setNotificationCount] = useState(0);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        const opened_modal = document.querySelector('.modal.show');
        if (opened_modal) {
            const modal = Modal.getInstance(opened_modal);
            modal.hide();

            setShowPassword(false);
        }
    }, [location]);

    useEffect(() => {
        setLoader(false);
        if (props.token === 'country_admin') {
            _getNotificationCount();
        }
    }, [props.token, location]);

    useEffect(() => {
        if (showPassword === true) {
            const reset_modal = new Modal(document.getElementById('reset-password-modal'));
            reset_modal.show();
        }
    }, [showPassword]);

    // handle logout
    const _handleLogout = () => {
        Cookies.remove("access_token", { path: '/', domain: process.env.REACT_APP_DOMAIN });
        Cookies.remove("admin_type", { path: '/', domain: process.env.REACT_APP_DOMAIN });
        setTimeout(() => {
            window.location.href = "/";
        }, 100);
    };

    // handle close offcanvas 
    const _handleCloseOffcanvas = () => {
        var button = document.getElementById("offcanvas-close");
        button.click();
    };

    // Reset password 
    const _handleChangePassword = () => {
        setShowPassword(true);
    };

    // API - Get notification count
    const _getNotificationCount = () => {
        let is_post = false;
        let url = 'CA/notifications/count';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setNotificationCount(response.data[0].notification_count);
            } else { }
        });
    };

    // API - Change password country admin
    const _changePasswordCountryAdmin = async (new_password, current_password) => {
        let valid = false;
        setLoader(true);

        let is_post = true;
        const url = "auth/change-password";
        const data = {
            "current_password": current_password,
            "new_password": new_password
        };

        await APIService(is_post, url, data).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                valid = true;
                toast.success("Password changed successfully", {
                    type: "success"
                });
                setShowPassword(false);
            } else {
                valid = false;
                toast.error(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        });
        return valid;
    };

    // API - Change password super admin
    const _changePasswordSuperAdmin = async (new_password, current_password) => {
        let valid = false;
        setLoader(true);

        let is_post = true;
        const url = "general/change-password";
        const data = {
            "current_password": current_password,
            "new_password": new_password
        };

        await APIService(is_post, url, data).then((response) => {
            toast.dismiss();
            if (response.status_code === 200) {
                valid = true;
                toast.success("Password changed successfully", {
                    type: "success"
                });
                setShowPassword(false);
            } else {
                valid = false;
                toast.error(response.message, {
                    type: "error"
                });
            }
            setLoader(false);
        });
        return valid;
    };

    return (
        <div className={`${props.token === 'super_admin' ? `e-bg-azureish-white` : `e-bg-lavender-blush-light`} padding-36px-tb`}>
            <div className='d-flex justify-content-space-between'>
                <div className='ps-3'>
                    <Icon icon="hamburger"
                        size={"24px"}
                        className='cursor-pointer d-xl-block d-lg-block d-md-none d-none'
                        onPress={props.handleToggle} />

                    <Icon icon="hamburger"
                        size={"24px"}
                        className='cursor-pointer d-xl-none d-lg-none d-md-block d-block'
                        data-bs-toggle="offcanvas"
                        data-bs-target="#left-navigation-offcanvas" />
                </div>

                {/* offcanvas */}
                <div className="offcanvas offcanvas-start w-auto" data-bs-backdrop="static" data-bs-scroll="false"
                    tabIndex="-1"
                    id="left-navigation-offcanvas"
                    aria-labelledby="offcanvasWithBothOptionsLabel">
                    <div className="overflow-auto e-offcanvas-wrapper">
                        <div className="text-end pe-3">
                            <button id="offcanvas-close"
                                type="button"
                                className="btn-close mt-2 d-none"
                                data-bs-dismiss="offcanvas"
                                aria-label="Close"></button>
                        </div>
                        <LeftNavigationWidget type="offcanvas"
                            token={props.token}
                            handleCloseOffCanvas={_handleCloseOffcanvas} />
                    </div>
                </div>

                <div className='d-flex justify-content-end pe-4 align-items-center'>
                    {props.token === 'country_admin' &&
                        <div className='position-relative cursor-pointer me-4'
                            onClick={() => navigate("/notification")}>
                            <Icon icon="notification"
                                size={"24px"} />
                            {notificationCount !== 0 &&
                                <span className=" e-alt-font-noto-sans e-font-10 e-font-weight-700 line-height-12px position-absolute top-4px left-18px translate-middle badge rounded-pill e-bg-fire-engine-red padding-tb-3px-6px">
                                    {notificationCount < 100 ? notificationCount : "99+"}
                                </span>
                            }
                        </div>
                    }

                    <div className='e-dropdown-more-wrapper position-relative'>
                        <Icon icon="menu"
                            size={"24px"}
                            className="cursor-pointer"
                            onPress={() => setToggleMore(!toggleMore)} />

                        {toggleMore &&
                            <>
                                <OverLay isOpen={toggleMore} onClose={() => setToggleMore(!toggleMore)} />
                                <div className="e-dropdown-more e-bg-white text-start top-24px right-neg-14px z-index-99"
                                    onClick={() => setToggleMore(!toggleMore)}>
                                    <p className='mb-4 e-text-rich-black-light e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px cursor-pointer text-decoration-hover-underline'
                                        onClick={_handleChangePassword} >
                                        Change password
                                    </p>
                                    <p className='mb-0 e-text-rich-black-light e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px cursor-pointer text-decoration-hover-underline'
                                        onClick={_handleLogout} >
                                        Logout
                                    </p>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>

            {/* Reset Password modal */}
            {showPassword &&
                <ResetPasswordModal loading={loader}
                    closeModal={() => {
                        setShowPassword(false);
                    }}
                    reset={props.token === 'super_admin' ?
                        _changePasswordSuperAdmin :
                        _changePasswordCountryAdmin} />
            }
        </div>
    )
}

export default CustomHeader;