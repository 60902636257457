/*
 *   File : configure-push-notification.js
 *   Author : https://evoqins.com
 *   Description :  Configure push notification
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useRef, useState } from 'react';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Modal } from 'bootstrap';
import { useNavigate } from 'react-router-dom';

// Custom components 
import { CustomTextInput, CustomFileUpload, TextArea, CustomTimePicker, CustomDatePicker } from '../../../../Component/FormElements';
import { PrimaryButton, TextButton } from '../../../../Component/Buttons';
import { Loader, OverLay } from '../../../../Component/Others';
import { Icon } from '../../../../Component/Icon';
import { APIService, AWSService } from '../../../../Service';
import { ScheduledNotifications } from '../../../../Component/Cards';
import { EmptyScreen } from '../../../../Component/EmptyComponent';
import { Pagination } from '../../../../Component/pagination';
import { ResendPushNotification } from '../../../../Component/Modal';

// Style
import styles from "../../../../Style/Component/notification.module.scss";

const TAB = [
    {
        id: 1,
        label: "Send push notification",
    },
    {
        id: 2,
        label: "Schedule push notification",
    },
];

const REPEAT_TAB = [
    {
        id: 1,
        name: "Weekly"
    },
    {
        id: 2,
        name: "Monthly"
    },
    {
        id: 3,
        name: "Yearly"
    },
];

const ITEMS_PER_PAGE = 12;

const ConfigurePushNotification = (props) => {
    const scrollRef = useRef(null);
    const errorRef = useRef({});
    const cardRef = useRef(null);

    const navigate = useNavigate();

    const [tabIndex, setTabIndex] = useState(1);
    const [title, setTitle] = useState({ en: "", ar: "" });
    const [titleError, setTitleError] = useState({ en: "", ar: "" });
    const [content, setContent] = useState({ en: "", ar: "" });
    const [contentError, setContentError] = useState({ en: "", ar: "" });
    const [image, setImage] = useState(null);
    const [imageError, setImageError] = useState("");
    const [imageLoader, setImageLoader] = useState(false);
    const [scheduleTitle, setScheduleTitle] = useState({ en: "", ar: "" });
    const [scheduleTitleError, setScheduleTitleError] = useState({ en: "", ar: "" });
    const [scheduleContent, setScheduleContent] = useState({ en: "", ar: "" });
    const [scheduleContentError, setScheduleContentError] = useState({ en: "", ar: "" });
    const [scheduledImage, setScheduledImage] = useState(null);
    const [scheduledImageLoader, setScheduledImageLoader] = useState(false);
    const [scheduledImageError, setScheduledImageError] = useState("");
    const [repeatTab, setRepeatTab] = useState(1);

    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedDayError, setSelectedDayError] = useState("");
    const [dateToggle, setDateToggle] = useState(false);

    const [showTimePicker, setShowTimePicker] = useState(false);
    const [selectedTime, setSelectedTime] = useState(null);
    const [timeError, setTimeError] = useState("");
    const [repeatToggle, setRepeatToggle] = useState(false);
    const [sendCreateLoader, setSendCreateLoader] = useState(false);
    const [notificationId, setNotificationId] = useState(null);

    const [notificationHistoryData, setNotificationHistoryData] = useState({});
    const [loading, setLoading] = useState(true);

    const [totalPageCount, setTotalPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    // Modal state
    const [showResendNotificationModal, setShowResendNotificationModal] = useState(false);
    const [resendNotificationData, setResendNotificationData] = useState("");
    const [errorUpdate, setErrorUpdate] = useState(false);

    useEffect(() => {
        _pushNotificationHistory();
    }, [currentPage]);

    useEffect(() => {

        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });

        if (Object.keys(props.editData).length !== 0) {
            setTabIndex(2);

            setSelectedDay(props.editData.date);

            const updated_time = moment(props.editData.time, 'h:mm a');
            setSelectedTime(updated_time);

            setScheduleTitle({ en: props.editData.title, ar: props.editData.title_arabic });
            setScheduleContent({ en: props.editData.description, ar: props.editData.description_arabic });
            setScheduledImage(props.editData.image_url);
            setNotificationId(props.editData.notification_id);

            if (props.editData.repetition_type !== null) {
                setRepeatToggle(true);
                setRepeatTab(props.editData.repetition_type);
            }
        }
    }, [props.editData]);


    useEffect(() => {
        setSelectedDayError("");
    }, [selectedDay]);

    useEffect(() => {
        setTimeError("");
    }, [selectedTime]);

    useEffect(() => {
        setImageError("");
    }, [image]);

    useEffect(() => {
        setScheduledImageError("");
    }, [scheduledImage]);

    useEffect(() => {
        if (showResendNotificationModal) {
            const resend_push = new Modal(document.getElementById('resend-push-notification-modal'));
            resend_push.show();
        }
    }, [showResendNotificationModal]);

    // handle tab change 
    const _handleTabChange = (id) => {
        setTabIndex(id);
    };

    // handle title change 
    const _handleChangeTitle = (lang, value) => {
        setTitle(prev => ({
            ...prev,
            [lang]: value
        }));
        setTitleError(prev => ({
            ...prev,
            [lang]: ""
        }));
    };

    // handle content change 
    const _handleChangeContent = (lang, value) => {
        setContent(prev => ({
            ...prev,
            [lang]: value
        }));
        setContentError(prev => ({
            ...prev,
            [lang]: ""
        }));
    };

    // handle upload image
    const _uploadedImage = (url) => {
        setImageLoader(true);
        _getPresignedUrl(url, 1);
    };

    // handle Image change 
    const _handleChangeImage = () => {
        setImage(null);
    };

    // handle send push notification
    const _handleSendPushNotification = () => {
        let is_valid = true;
        if (title.en.trim().length === 0) {
            setTitleError(prev => ({
                ...prev,
                en: "Required english notification title"
            }));
            is_valid = false;
        }

        if (title.ar.trim().length === 0) {
            setTitleError(prev => ({
                ...prev,
                ar: "Required arabic notification title"
            }));
            is_valid = false;
        }

        if (content.en.trim().length === 0) {
            setContentError(prev => ({
                ...prev,
                en: "Push notification english message is required"
            }));
            is_valid = false;
        }

        if (content.ar.trim().length === 0) {
            setContentError(prev => ({
                ...prev,
                ar: "Push notification arabic message is required"
            }));
            is_valid = false;
        }

        if (image === null) {
            setImageError("Image is required");
            is_valid = false;
        }

        setErrorUpdate(prev => !prev);

        if (is_valid === true) {
            setSendCreateLoader(true);
            _sendPushNotification();
        }
    };

    // date toggle show an hide
    const _handleDateToggle = () => {
        setDateToggle(!dateToggle);

        window.scrollTo({
            top: 150, behavior: 'smooth'
        });
    };

    // handle scheduled title change 
    const _handleChangeScheduledTitle = (lang, value) => {
        setScheduleTitle(prev => ({
            ...prev,
            [lang]: value
        }));
        setScheduleTitleError(prev => ({
            ...prev,
            [lang]: ""
        }));
    };

    // handle scheduled content change 
    const _handleChangeScheduledContent = (lang, value) => {
        setScheduleContent(prev => ({
            ...prev,
            [lang]: value
        }));
        setScheduleContentError(prev => ({
            ...prev,
            [lang]: ""
        }));
    };

    // handle upload scheduled image
    const _uploadedScheduledImage = (url) => {
        setScheduledImageLoader(true);
        _getPresignedUrl(url, 2);
    };

    // handle scheduled Image change 
    const _handleChangeScheduledImage = () => {
        setScheduledImage(null);
    };

    // handle tab change 
    const _handleRepeatTabChange = (id) => {
        setRepeatTab(id);
    };

    // handle select date
    const _handleDayClick = (day) => {
        setSelectedDay(day);
        setDateToggle(false);
    };

    // handle time change 
    const _handleTimeChange = (value) => {
        setSelectedTime(value);
    };

    // handle show time picker 
    const _handleShowTimePicker = () => {
        setShowTimePicker(!showTimePicker)
    }

    // handle clear the date 
    const _handleClearSelectedDate = () => {
        setSelectedDay(null);
    };

    // handle repeat toggle 
    const _handleRepeatToggle = () => {
        setRepeatToggle(!repeatToggle);

        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    };

    // handle focus error
    const _focusError = (field) => {
        if (errorRef.current[field]) {
            errorRef.current[field].scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    // handle schedule submit 
    const _handleSchedulePushNotification = () => {
        let is_valid = true;
        let error_field = [];

        if (selectedDay === null) {
            setSelectedDayError("Date is required");
            is_valid = false;
            error_field.push('date');
        }

        if (selectedTime === null) {
            setTimeError("Time is required");
            is_valid = false;
            error_field.push('time');
        }

        if (scheduleTitle.en.length === 0) {
            setScheduleTitleError(prev => ({
                ...prev,
                en: "Title english is required"
            }));
            is_valid = false;
            error_field.push('title');
        }

        if (scheduleTitle.ar.length === 0) {
            setScheduleTitleError(prev => ({
                ...prev,
                ar: "Title arabic is required"
            }));
            is_valid = false;
            error_field.push('title_arabic');
        }

        if (scheduleContent.en.length === 0) {
            setScheduleContentError(prev => ({
                ...prev,
                en: "Message english is required"
            }));
            is_valid = false;
            error_field.push('content');
        }

        if (scheduleContent.ar.length === 0) {
            setScheduleContentError(prev => ({
                ...prev,
                ar: "Message arabic is required"
            }));
            is_valid = false;
            error_field.push('content_arabic');
        }

        if (scheduledImage === null) {
            setScheduledImageError("Image is required");
            is_valid = false;
            error_field.push('image');
        }

        setErrorUpdate(prev => !prev);

        if (is_valid === true) {
            setSendCreateLoader(true)

            if (notificationId == null) {
                _schedulePushNotification();
            } else {
                _updateSchedulePushNotification();
            }
        } else {
            if (error_field.length > 0) {
                _focusError(error_field[0]);
            }
        }
    };

    //handle pagination
    const _handlePagination = (new_start_limit) => {
        const selected_page = Math.floor(new_start_limit / ITEMS_PER_PAGE) + 1;
        setCurrentPage(selected_page);

        if (cardRef.current) {
            cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    // handle open resend notification modal
    const _handleResendNotification = (data) => {
        setResendNotificationData(data);
        setShowResendNotificationModal(true);
    };

    // get presigned url
    async function _getPresignedUrl(file, type) {
        let is_post = true;
        let url = 'general/file-upload';
        let data = {
            "file": file.name,
            "is_public": true
        };
        try {
            let response = await APIService(is_post, url, data);
            if (response.status_code == 200) {
                let aws_fields = response.data.upload_info.fields;
                let aws_url = response.data.upload_info.url;

                try {
                    let aws_response = await AWSService(aws_fields, file, aws_url);
                    if (aws_response.status == 'ok') {
                        if (type === 1) {
                            setImage(response.data.view_info);
                        } else {
                            setScheduledImage(response.data.view_info);
                        }
                    } else {
                        toast.dismiss();
                        toast.error(aws_response.message, {
                            type: "error",
                            className: "e-toast",
                        });
                    }
                } catch (err) {
                    console.log("error")
                }
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        } catch (err) {
        }
        setScheduledImageLoader(false);
        setImageLoader(false);
    };

    // API - Send push notification
    const _sendPushNotification = () => {
        let is_post = true;
        let url = 'CA/notifications/send';
        let data = {
            "title": title.en,
            "title_arabic": title.ar,
            "description": content.en,
            "description_arabic": content.ar,
            "image_url": image
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                toast.dismiss();
                toast.success("Push notification send successfully", {
                    type: "success",
                    className: "e-toast",
                });

                setTitle({ en: "", ar: "" });
                setContent({ en: "", ar: "" });
                setImage(null);
                _pushNotificationHistory();
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                })
            }
            setSendCreateLoader(false);
        });
    };

    // API - Schedule push notification
    const _schedulePushNotification = () => {
        let is_post = true;
        let url = 'CA/notifications/schedule-notification';
        let data = {
            "title": scheduleTitle.en,
            "title_arabic": scheduleTitle.ar,
            "description": scheduleContent.en,
            "description_arabic": scheduleContent.ar,
            "image_url": scheduledImage,
            "date": format(selectedDay, 'yyyy-MM-dd'),
            "time": selectedTime.format('HH:mm:ss'),
            "repetition": repeatToggle === false ? null : repeatTab
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                toast.dismiss();
                toast.success("Push notification scheduled successfully", {
                    type: "success",
                    className: "e-toast",
                });


                navigate("/manage-notification", {
                    state: 3
                });

                setSelectedDay(null);
                setSelectedTime(null);
                setScheduleTitle({ en: "", ar: "" });
                setScheduleContent({ en: "", ar: "" });
                setScheduledImage(null);
                setRepeatToggle(false);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                })
            }
            setSendCreateLoader(false);
        });
    };

    // API - Update schedule push notification
    const _updateSchedulePushNotification = () => {
        let is_post = true;
        let url = 'CA/notifications/update-scheduled-notification';
        let data = {
            "notification_id": notificationId,
            "title": scheduleTitle.en,
            "title_arabic": scheduleTitle.ar,
            "description": scheduleContent.en,
            "description_arabic": scheduleContent.ar,
            "image_url": scheduledImage,
            "date": format(selectedDay, 'yyyy-MM-dd'),
            "time": selectedTime.format('HH:mm:ss'),
            "repetition_type": repeatToggle === false ? null : repeatTab
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                toast.dismiss();
                toast.success("Push notification updated successfully", {
                    type: "success",
                    className: "e-toast",
                });

                navigate("/manage-notification", {
                    state: 3
                });

                setSelectedDay(null);
                setSelectedTime(null);
                setScheduleTitle({ en: "", ar: "" });
                setScheduleContent({ en: "", ar: "" });
                setScheduledImage(null);
                setRepeatToggle(false);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                })
            }
            setSendCreateLoader(false);
        });
    };

    //API - Get push notification history
    const _pushNotificationHistory = () => {
        let is_post = true;
        let url = 'CA/notifications/push-notifications';
        let data = {
            "page_num": currentPage
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setNotificationHistoryData(response.data);
                setTotalPageCount(Math.ceil(response.data.total_entries / ITEMS_PER_PAGE));
            } else { }
            setLoading(false);
        });
    };

    return (
        <div className='row'>
            <div className='col-xl-6 col-lg-7 col-md-8 col-sm-12 col-12'>
                <div className='e-border-1px-platinum-light py-4 ps-4 e-bg-white e-admin-box-shadow e-border-radius-16'>
                    <div className='overflow-auto pb-xl-0 pb-lg-0 pb-md-0 pb-1'>
                        <div className='d-flex gap-0-20px w-max-content'>
                            {TAB.map((data, key) => (
                                <p key={key} className={`${tabIndex === data.id ? `e-text-lust cursor-default e-border-bottom-2px-lust` : `e-text-raisin-black cursor-pointer`}  e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-28px mb-0`}
                                    onClick={() => _handleTabChange(data.id)} >
                                    {data.label}
                                </p>
                            ))}
                        </div>
                    </div>

                    {tabIndex === 1 ?
                        // Send push notification
                        <div className='pt-3 pe-3'>
                            <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                Send push notification
                            </p>

                            <div className='pt-3 pe-2'>
                                {/* Title english  */}
                                <CustomTextInput label="Title (English)"
                                    placeholder="Notification title"
                                    type="text"
                                    require
                                    value={title.en}
                                    error={titleError.en}
                                    className="w-100"
                                    labelStyle="e-font-weight-500 e-text-raisin-black"
                                    handleChange={(e) => _handleChangeTitle("en", e)} />

                                {/* TItle arabic */}
                                <CustomTextInput label="Title (Arabic)"
                                    placeholder="Notification title"
                                    type="text"
                                    require
                                    value={title.ar}
                                    error={titleError.ar}
                                    className="w-100"
                                    labelStyle="e-font-weight-500 e-text-raisin-black"
                                    handleChange={(e) => _handleChangeTitle("ar", e)} />

                                {/* content English*/}
                                <TextArea label="Content (English)"
                                    placeholder="Enter your text..."
                                    type="text"
                                    rows="3"
                                    require
                                    value={content.en}
                                    error={contentError.en}
                                    className=" px-3 padding-12px-tb w-100"
                                    handleChange={(e) => _handleChangeContent("en", e)} />

                                {/* Content arabic */}
                                <TextArea label="Content (Arabic)"
                                    placeholder="Enter your text..."
                                    type="text"
                                    rows="3"
                                    require
                                    value={content.ar}
                                    error={contentError.ar}
                                    className=" px-3 padding-12px-tb w-100"
                                    handleChange={(e) => _handleChangeContent("ar", e)} />

                                {/* file upload */}
                                <div >
                                    <CustomFileUpload label="Upload image"
                                        placeholder="Drop your image here"
                                        require
                                        file={image}
                                        fileType={["JPG", "PNG", "JPEG"]}
                                        resolution="328px * 150px"
                                        requiredWidth={328}
                                        requiredHeight={150}
                                        errorUpdate={errorUpdate}
                                        error={imageError}
                                        loader={imageLoader}
                                        handleChange={_uploadedImage} />

                                    {/* change image button */}
                                    {image !== null &&
                                        <div className='mb-2 margin-neg-20px-top'>
                                            <TextButton name="Change image"
                                                onPress={_handleChangeImage} />
                                        </div>
                                    }
                                </div>
                                {/* send button */}
                                <div className='pt-4 '>
                                    <PrimaryButton name="Send now"
                                        className="w-100"
                                        status={sendCreateLoader}
                                        onPress={_handleSendPushNotification} />
                                </div>
                            </div>
                        </div>
                        :
                        // Schedule push notification
                        <div className='pt-3 pe-2'>
                            <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                Scheduled notifications
                            </p>

                            <div className={`${styles.e_schedule} ${showTimePicker ? 'overflow-hidden' : 'overflow-y-auto'} pt-3 pe-3 h-358px  overflow-x-hidden`}>
                                <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                    Pick start date<span className='e-text-lust'>*</span>
                                </p>
                                {/* Pick date  */}
                                <div className='pt-2 row'>
                                    <div ref={(ref) => errorRef.current['date'] = ref} className='col-4 '>
                                        <div className='position-relative h-60px'>
                                            <div className={`${dateToggle ? 'e-border-1px-indigo' : 'e-border-1px-platinum-light'} e-display-flex gap-0-4px e-border-radius-6 padding-14px-tb px-2 cursor-pointer`}
                                                onClick={_handleDateToggle}>
                                                <img src={require("../../../../Assets/Image/CountryAdmin/Notification/calendar-icon.svg").default}
                                                    draggable={false}
                                                    alt='calender-icon' />
                                                {selectedDay !== null ?
                                                    <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-16px'>
                                                        {format(selectedDay, 'PP')}
                                                    </p>
                                                    :
                                                    <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-16px'>
                                                        Choose date
                                                    </p>
                                                }
                                            </div>

                                            {/* error message */}
                                            <p className='mb-0 e-error-message e-alt-font-noto-sans e-font-12 line-height-12px padding-2-top'>
                                                {selectedDayError}
                                            </p>

                                            {/* clear icon */}
                                            {selectedDay !== null &&
                                                <Icon icon="close-icon"
                                                    className='position-absolute top-14px right-8px cursor-pointer'
                                                    size="20px"
                                                    onPress={_handleClearSelectedDate} />
                                            }
                                        </div>
                                    </div>
                                </div>

                                {/* date picker */}
                                {dateToggle &&
                                    <>
                                        <OverLay className="e-bg-overlay " isOpen={dateToggle} onClose={_handleDateToggle} />
                                        <CustomDatePicker selected={selectedDay}
                                            className='e-day-picker w-358px position-absolute e-bg-white e-border-radius-16 z-index-99'
                                            onSelect={_handleDayClick} />
                                    </>
                                }

                                {/* time picker */}
                                <div className='row'>
                                    <div ref={(ref) => errorRef.current['time'] = ref} className='col-5'>
                                        <CustomTimePicker label="Set time"
                                            showSecond={false}
                                            placeholder='Choose a time'
                                            value={selectedTime}
                                            open={showTimePicker}
                                            error={timeError}
                                            handleClose={_handleShowTimePicker}
                                            handleOpen={_handleShowTimePicker}
                                            handleChange={_handleTimeChange} />
                                    </div>
                                </div>

                                {/* Scheduled title english */}
                                <div ref={(ref) => errorRef.current['title'] = ref}>
                                    <CustomTextInput label="Title (English)"
                                        placeholder="Message title"
                                        type="text"
                                        require
                                        value={scheduleTitle.en}
                                        error={scheduleTitleError.en}
                                        className="w-100"
                                        labelStyle="e-font-weight-500 e-text-raisin-black"
                                        handleChange={(e) => _handleChangeScheduledTitle("en", e)} />
                                </div>

                                {/* Scheduled title arabic */}
                                <div ref={(ref) => errorRef.current['title_arabic'] = ref}>
                                    <CustomTextInput label="Title (Arabic)"
                                        placeholder="Message title"
                                        type="text"
                                        require
                                        value={scheduleTitle.ar}
                                        error={scheduleTitleError.ar}
                                        className="w-100"
                                        labelStyle="e-font-weight-500 e-text-raisin-black"
                                        handleChange={(e) => _handleChangeScheduledTitle("ar", e)} />
                                </div>

                                {/* content */}
                                <div ref={(ref) => errorRef.current['content'] = ref}>
                                    <TextArea label="Content (English)"
                                        placeholder="Enter your text..."
                                        type="text"
                                        rows="3"
                                        require
                                        value={scheduleContent.en}
                                        error={scheduleContentError.en}
                                        className=" px-3 padding-12px-tb w-100"
                                        handleChange={(e) => _handleChangeScheduledContent("en", e)} />
                                </div>

                                {/* content arabic */}
                                <div ref={(ref) => errorRef.current['content_arabic'] = ref}>
                                    <TextArea label="Content (Arabic)"
                                        placeholder="Enter your text..."
                                        type="text"
                                        rows="3"
                                        require
                                        value={scheduleContent.ar}
                                        error={scheduleContentError.ar}
                                        className=" px-3 padding-12px-tb w-100"
                                        handleChange={(e) => _handleChangeScheduledContent("ar", e)} />
                                </div>

                                {/* upload thumbnail */}
                                <div ref={(ref) => errorRef.current['image'] = ref} >
                                    <CustomFileUpload label="Upload image"
                                        placeholder="Drop your image here"
                                        require
                                        file={scheduledImage}
                                        fileType={["JPG", "PNG", "JPEG"]}
                                        resolution="328px * 150px"
                                        requiredWidth={328}
                                        requiredHeight={150}
                                        errorUpdate={errorUpdate}
                                        error={scheduledImageError}
                                        loader={scheduledImageLoader}
                                        handleChange={_uploadedScheduledImage} />
                                    {/* change thumbnail button */}
                                    {scheduledImage !== null &&
                                        <div className='mb-2 margin-neg-20px-top'>
                                            <TextButton name="Change image"
                                                onPress={_handleChangeScheduledImage} />
                                        </div>
                                    }
                                </div>

                                {/* Repeat notification */}
                                <div className='e-display-flex justify-content-space-between pt-3'>
                                    <p className='mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-16 e-font-weight-400 line-height-22px'>
                                        Repeat this notification
                                    </p>
                                    <label className={`${styles.e_repeatnotification} `} >
                                        <input type="checkbox"
                                            checked={repeatToggle}
                                            onChange={_handleRepeatToggle} />
                                        <span className={`${styles.e_slider}`}></span>
                                    </label>
                                </div>
                                <div >
                                    {/* Repeat tab  */}
                                    {repeatToggle &&
                                        <div className='d-flex gap-0-10 pt-4 pb-3'>
                                            {REPEAT_TAB.map((data, key) => (
                                                <div key={key} className={`${repeatTab === data.id ? 'e-border-1px-lust cursor-default' : 'e-border-1px-platinum-light cursor-pointer'} e-text-raisin-black e-border-radius-8 r`}
                                                    onClick={() => _handleRepeatTabChange(data.id)} >
                                                    <p className='mb-0 px-4 py-2 e-alt-font-noto-sans e-font-16 e-font-weight-500 line-height-22px'>
                                                        {data.name}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </div>
                            {/* Custom button */}
                            <div className='pt-4 mt-2 pe-3'>
                                <PrimaryButton name={notificationId === null ? "Schedule & send later" : "Update scheduled & send later "}
                                    className="w-100"
                                    status={sendCreateLoader}
                                    onPress={_handleSchedulePushNotification} />
                            </div>
                        </div>
                    }

                </div>
            </div>

            {/*  Push notification history card */}
            <div ref={cardRef} className='pt-4'>
                <h4 className='mb-0 e-text-dark-jungle-green e-primary-font-playfair e-font-18 e-font-weight-500 line-height-28px'>
                    Push notification history
                </h4>

                {loading ?
                    <Loader className="h-30vh" />  //loader
                    :
                    <>
                        {notificationHistoryData.data.length !== 0 ?
                            <div className='pt-2'>
                                {notificationHistoryData.data.map((data, key) => (
                                    <ScheduledNotifications key={key}
                                        notification={data}
                                        handleResend={() => _handleResendNotification(data)}
                                        type={1} />
                                ))}

                                {/* pagination */}
                                {totalPageCount > 1 &&
                                    <div className="d-flex justify-content-center e-gap-0-16 mt-4 pt-3">
                                        <Pagination itemsPerPage={ITEMS_PER_PAGE}
                                            totalPage={totalPageCount}
                                            showFirstLastPages={true}
                                            onChange={_handlePagination} />
                                    </div>
                                }
                            </div>
                            :
                            // empty screen 
                            <div className='min-h-30vh pt-5 mt-4'>
                                <EmptyScreen message="No history found" />
                            </div>
                        }
                    </>
                }
            </div>

            {
                showResendNotificationModal &&
                <ResendPushNotification onCloseModal={() => setShowResendNotificationModal(false)}
                    data={resendNotificationData} />
            }

        </div>
    )
}

export default memo(ConfigurePushNotification);