/*
 *   File : forgot-password.js
 *   Author : https://evoqins.com
 *   Description : Forgot password page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

//Custom components
import { CustomPasswordInput, CustomTextInput, OTPInput } from '../../Component/FormElements';
import { PrimaryButton } from '../../Component/Buttons';
import { APIService } from '../../Service';
import Cookies from 'js-cookie';

const ForgotPassword = () => {

    const inputRef = useRef(null);
    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [usernameError, setUserNameError] = useState("");

    const [step, setStep] = useState(0);

    const [otpValue, setOtpValue] = useState("");
    const [focusedInput, setFocusedInput] = useState(2);
    const [confirmOTP, setConfirmOTP] = useState([]);
    const [otpError, setOtpError] = useState("");

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [passwordError, setPasswordError] = useState("");

    const [tempToken, setTempToken] = useState("");

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    }, []);

    useEffect(() => {
        setUserNameError("");
    }, [username]);

    useEffect(() => {
        setOtpError("");
    }, [otpValue]);

    useEffect(() => {
        setPasswordError("");
    }, [password, confirmPassword]);

    //handle login
    const _handleGetOTP = () => {
        // let validate_email = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;

        if (username.trim().length === 0) {
            setUserNameError("Enter your username");
        } else {
            _getOTP(false);  //API - getOTP 
        }
    }

    // handle OTP 
    const _handleOTP = (e) => {
        if (loading !== true) {
            const input_value = e.target.value;
            if (input_value.length <= 4) {
                setOtpValue(input_value);
                const characters = [];

                for (let i = 0; i < input_value.length; i++) {
                    const character = input_value.charAt(i);
                    characters.push(character);
                }
                setConfirmOTP(characters)
            }
        }
    };

    // handle submit OTP 
    const _handleSubmitOTP = () => {
        if (otpValue.length < 4) {
            setOtpError("Enter received OTP");
        }

        if (otpValue.length === 4) {
            _verifyOTP();
        }
    };

    // handle resend OTP
    const _handleResendOTP = () => {
        setOtpValue("");
        setConfirmOTP([]);

        _getOTP(true);
    };

    // handle submit new password
    const _handleConfirmPassword = () => {
        let is_valid = true;
        if (password !== confirmPassword) {
            setPasswordError("PIN does not match");
            is_valid = false;
        } else if (password.length <= 0 && confirmPassword.length <= 0) {
            setPasswordError("PIN does not match");
            is_valid = false;
        }

        if (is_valid === true) {
            _updatePassword();
        }

    }

    // handle back flow
    const _handleBack = () => {
        if (step === 0) {
            navigate("/login");
        } else if (step === 1) {
            setStep(0);
        }
    }

    // API - get OTP 
    const _getOTP = (resend) => {
        let is_post = true;
        let url = 'auth/get-otp';
        let data = {
            "type": "FORGET_PASSWORD",
            "username": username,
            "is_resend": resend
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setStep(1);
                toast.dismiss();
                toast.success("OTP send", {
                    type: "success",
                    className: "e-toast",
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        });
    };

    // API - Verify OTP 
    const _verifyOTP = () => {
        let is_post = true;
        let url = 'auth/verify-otp';
        let data = {
            "username": username,
            "otp": otpValue,
            "type": "FORGET_PASSWORD"
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setStep(2);

                setTempToken(response.data.temp_token);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        });
    };

    // API - change password 
    const _updatePassword = () => {
        let is_post = true;
        let url = 'auth/update-password';
        let data = {
            "token": tempToken,
            "password": confirmPassword,
            "type": "FORGET_PASSWORD"
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                navigate("/");
                toast.dismiss();
                toast.success("Password updated", {
                    type: "success",
                    className: "e-toast",
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        });
    };

    return (
        <>
            <div className={`${step === 0 ? "padding-200px-bottom" : step === 1 ? "padding-176px-bottom" : 'padding-200px-bottom'} e-bg-indigo  padding-32px-top`}>
                <div className='container'>
                    <img src={require("../../Assets/jotun-icon.svg").default}
                        draggable={false}
                        className='cursor-pointer'
                        alt='jotun-icon'
                        onClick={() => navigate("/")} />

                    {/* User authentication */}
                    <div className='row padding-80px-top'>
                        <div className='offset-xl-4 offset-lg-3 offset-md-2 offset-sm-1 col-xl-4 col-lg-6 col-md-8 col-sm-10 '>
                            <div className='px-4 pt-3 pb-4 e-bg-white e-border-radius-16'>
                                {step !== 2 &&
                                    <img src={require("../../Assets/Image/left-back-arrow.svg").default}
                                        draggable={false}
                                        className='cursor-pointer'
                                        alt='back-arrow'
                                        onClick={_handleBack} />
                                }
                                {step === 0 ?
                                    <div className='px-2 padding-18px-top'>
                                        <h3 className='mb-4 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                                            User authentication
                                        </h3>
                                        <CustomTextInput label="Enter username"
                                            placeholder="Jotun123"
                                            type="text"
                                            require
                                            value={username}
                                            error={usernameError}
                                            autoFocus={true}
                                            className="w-100"
                                            labelStyle="e-font-weight-500"
                                            onKeyPress={_handleGetOTP}
                                            handleChange={(e) => setUsername(e)} />

                                        <div className='pt-2'>
                                            <PrimaryButton name="Confirm"
                                                className="e-bg-indigo"
                                                onPress={_handleGetOTP} />
                                        </div>
                                    </div>
                                    :
                                    step === 1 ?
                                        <div className='px-2 padding-18px-top'>
                                            <h3 className='mb-4 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                                                User authentication
                                            </h3>
                                            {/* OTP Input */}
                                            <OTPInput label="Enter the otp sent to the registered email"
                                                ref={inputRef}
                                                id="otp"
                                                value={otpValue}
                                                spellcheck="false"
                                                error={otpError}
                                                focused_input={focusedInput}
                                                confirm_pin={confirmOTP}
                                                onFocus={() => setFocusedInput(2)}
                                                onBlur={() => setFocusedInput(0)}
                                                onKeyPress={_handleSubmitOTP}
                                                onChange={_handleOTP} />
                                            <p className=' mb-0 e-text-rich-black e-alt-font-noto-sans e-font-weight-500 line-height-18px e-font-14'>
                                                Don’t receive the otp?
                                                <span className='ps-1 e-text-lust cursor-pointer text-decoration-hover-underline'
                                                    onClick={_handleResendOTP} >
                                                    Resend now
                                                </span>
                                            </p>

                                            <div className='pt-4'>
                                                <PrimaryButton name="Confirm"
                                                    className="e-bg-indigo"
                                                    onPress={_handleSubmitOTP} />
                                            </div>
                                        </div>
                                        :
                                        // Change password
                                        <div className='padding-18px-top'>
                                            <h3 className='mb-4 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                                                Change password
                                            </h3>

                                            {/* new password */}
                                            <CustomPasswordInput label="Enter new password"
                                                placeholder="*****"
                                                type="text"
                                                require
                                                value={password}
                                                autoFocus={true}
                                                className="w-100"
                                                labelStyle="e-font-weight-500"
                                                onKeyPress={_handleConfirmPassword}
                                                handleChange={(e) => setPassword(e)} />

                                            {/* confirm password */}
                                            <CustomPasswordInput label="Confirm password"
                                                placeholder="*****"
                                                type="text"
                                                require
                                                value={confirmPassword}
                                                error={passwordError}
                                                className="w-100"
                                                labelStyle="e-font-weight-500"
                                                onKeyPress={_handleConfirmPassword}
                                                handleChange={(e) => setConfirmPassword(e)} />
                                            <p className='mb-0 pt-1 e-text-davys-grey e-alt-font-noto-sans e-font-weight-500 line-height-18px e-font-14'>
                                                The password must be 8 characters or longer, must contain one uppercase and lowercase letter and must need a special character(e.g @#*)
                                            </p>

                                            {/* submit otp button */}
                                            <div className='pt-4'>
                                                {password.length > 0 && confirmPassword.length > 0 ?
                                                    <PrimaryButton name="Confirm"
                                                        className="e-bg-indigo"
                                                        onPress={_handleConfirmPassword} />
                                                    :
                                                    <PrimaryButton name="Confirm"
                                                        className="e-bg-indigo e-disable" />
                                                }
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;