/*
 *   File : country-admin.js
 *   Author : https://evoqins.com
 *   Description : Country admin listing page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';

//Custom component
import { DataTableComponent } from '../../../Component/DataTable';
import { PrimaryButton } from '../../../Component/Buttons';
import { CustomSearchInput, CustomSelectBox } from '../../../Component/FormElements';
import { AddAdminModal } from '../../../Component/Modal';
import { Icon } from '../../../Component/Icon';
import { EmptyScreen } from '../../../Component/EmptyComponent';
import { Loader } from '../../../Component/Others';

// Services
import { APIService } from '../../../Service';

const WEEK_OPTIONS = [
    {
        value: 1,
        label: 'All',
    },
    {
        value: 2,
        label: 'Last Week'
    },
    {
        value: 3,
        label: 'Last Month'
    },
];

const CountryAdmin = React.memo(() => {
    const initialRef = useRef(false);
    const navigate = useNavigate();

    const [toggleFilter, setToggleFilter] = useState(false);
    const [addAdmin, setAddAdmin] = useState(false);
    const [apiLoader, setApiLoader] = useState(true);

    const [searchValue, setSearchValue] = useState("");
    const [selected, setSelected] = useState(WEEK_OPTIONS[0]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const [countryOptions, setCountryOptions] = useState([]);
    const [countryAdminOptions, setCountryAdminOptions] = useState([]);
    const [adminData, setAdminData] = useState({});

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
        if (!initialRef.current) {
            _getCountries();
            initialRef.current = true
        }
    }, []);

    useEffect(() => {
        if (selectedCountry !== null) {
            _getCountryAdmins(searchValue);
        }
    }, [selectedCountry, pageNumber])

    useEffect(() => {
        if (addAdmin === true) {
            const admin_modal = new Modal(document.getElementById('add-admin-modal'));
            admin_modal.show();
        }
    }, [addAdmin]);

    //handle add admin
    const _handleAddAdmin = () => {
        setAddAdmin(true);
    }

    // handle navigate detail page 
    const _handleNavigate = (row) => {
        navigate('detail', {
            state: {
                tabIndex: 1,
                admin_id: row.admin_id
            }
        });
    }

    // handle page change 
    const _handlePageChange = (page_num) => {
        setPageNumber(page_num);

        window.scrollTo({
            top: 0, behavior: 'smooth'
        });
    };

    // API - country list
    const _getCountries = async () => {
        const url = 'country-admin/filter-data';

        APIService(false, url).then((response) => {
            if (response.status_code === 200) {
                const country_options = [];
                const country_admin_options = [];
                country_options.push({
                    label: "All",
                    value: null
                })
                response.data.map((item) => {
                    return (
                        country_options.push({
                            label: item.label,
                            value: item.value
                        })

                    )
                })
                response.data.map((item) => {
                    return (
                        country_admin_options.push({
                            label: item.label,
                            value: item.value
                        })

                    )
                })
                setSelectedCountry(country_options[0]);
                setCountryAdminOptions(country_admin_options);
                setCountryOptions(country_options)
            }
        })
    }

    // API - admin list
    const _getCountryAdmins = (query) => {
        const url = 'country-admin/list';

        const request = {
            "page_num": pageNumber,
            "query": query,
            "country_id": selectedCountry.value
        }
        APIService(true, url, request).then((response) => {
            if (response.status_code === 200) {
                setAdminData(response.data);
            } else {
                setAdminData(response.data);
            }
            setApiLoader(false);
        });
    }

    const TABLE_COLUMNS = [
        {
            name: 'Admin name',
            selector: row => row.username,
            width: "240px"
        },
        {
            name: 'Email',
            cell: row => <span onClick={() => _handleNavigate(row)}>{row.email}</span>,
            width: "240px"
        },
        {
            name: 'Contact no',
            cell: row => <span onClick={() => _handleNavigate(row)}>{row.contact_no}</span>,
            width: "200px"
        },
        {
            name: 'Role',
            selector: row => row.role,
            width: "200px"
        },
        {
            name: 'Country',
            selector: row => row.country,
            width: "200px"
        }

    ];

    return (
        <section className='pb-5 min-h-70vh'>
            <div className='e-bg-azureish-white pt-3 padding-98px-bottom padding-52px-lr'>
                <h1 className='mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                    Manage country admins
                </h1>
            </div>
            <div className='margin-neg-66px-top padding-52px-lr'>
                <div className='e-bg-white e-admin-box-shadow py-4 e-border-radius-16 '>
                    <div className='row px-4 pb-2'>
                        <div className='col-xl-5 col-lg-3 col-md-12 col-12'>
                            <h4 className='mb-2 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-600 line-height-28px w-max-content'>
                                Country admins
                            </h4>
                        </div>
                        <div className='col-xl-7 col-lg-9 col-md-12 col-12 text-end'>
                            <div className='row'>
                                <div className='col-xl-9 col-lg-9 col-md-9 col-sm-8 pe-3'>
                                    <div className='position-relative overflow-hidden '>
                                        <CustomSearchInput placeholder="Search with country name or admin name"
                                            type="text"
                                            id="search"
                                            require
                                            search
                                            value={searchValue}
                                            className="w-100 padding-10px-tb pe-3"
                                            // borderStyle="border-transparent"
                                            labelStyle="e-font-weight-500"
                                            handleChange={(e) => {
                                                setSearchValue(e);
                                                if (e === "") {
                                                    _getCountryAdmins("");
                                                }
                                            }}
                                            onKeyPress={() => {
                                                _getCountryAdmins(searchValue)
                                            }} />

                                        {searchValue.length !== 0 &&
                                            <span className='position-absolute h-100 top-0 px-3 d-flex align-items-center justify-content-center e-bg-indigo end-0 cursor-pointer transform-180deg e-border-radius-8px-0-0-8px'
                                                onClick={() => _getCountryAdmins(searchValue)}>
                                                <Icon icon="arrow-left"
                                                    className=''
                                                    size="24px"
                                                    color="#ffffff"
                                                    onPress={() => {
                                                    }} />
                                            </span>}

                                        {searchValue.length !== 0 &&
                                            <Icon icon="close-icon"
                                                className='position-absolute top-11px right-64px cursor-pointer'
                                                size="20px"
                                                onPress={() => {
                                                    setSearchValue("");
                                                    _getCountryAdmins("");
                                                }} />
                                        }
                                    </div>
                                </div>
                                <div className='col-xl-3 col-lg-3 col-md-3 col-sm-4 ps-xl-0 ps-lg-0 ps-md-0 pt-xl-0 pt-lg-0 pt-md-0 pt-sm-0 pt-2 pe-0 my-auto'>
                                    <div className='d-flex justify-content-center align-items-center gap-0-24px e-res-justify-content-start'>
                                        <div>
                                            <img src={toggleFilter ? require("../../../Assets/Image/filter-icon-white.svg").default : require("../../../Assets/Image/filter-icon.svg").default}
                                                draggable={false}
                                                className={`${toggleFilter ? `e-bg-indigo` : ``} transition e-border-1px-anti-flash-white  e-border-radius-8 px-2 padding-10px-tb cursor-pointer`}
                                                alt='filter'
                                                onClick={() => setToggleFilter(!toggleFilter)} />
                                        </div>
                                        <div>
                                            <PrimaryButton name="Add"
                                                className="padding-10px-tb padding-28px-lr"
                                                onPress={_handleAddAdmin} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* filter */}
                    {toggleFilter &&
                        <div className='e-bg-anti-flash-white-dark py-3 px-4'>
                            <div className='row'>
                                {false && <div className='col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6'>
                                    <CustomSelectBox label="Select type"
                                        value={selected}
                                        options={WEEK_OPTIONS}
                                        type={2}
                                        className="e-bg-white e-border-radius-8 e-table-select"
                                        isSearchable={false}
                                        selectChange={(temp) => { setSelected(temp); }}
                                    />
                                </div>}
                                <div className='col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6'>
                                    <CustomSelectBox label="Select country"
                                        value={selectedCountry}
                                        options={countryOptions}
                                        type={2}
                                        className="e-bg-white e-border-radius-8 e-table-select"
                                        isSearchable={false}
                                        selectChange={(temp) => { setSelectedCountry(temp); }}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    {/* country admin list data table */}
                    {
                        apiLoader ?
                            // loader 
                            <Loader />
                            :
                            adminData.data.length !== 0 ?
                                <div className='e-country-admin-table'>
                                    <DataTableComponent columns={TABLE_COLUMNS}
                                        data={adminData.data}
                                        pagination={true}
                                        paginationServer={true}
                                        paginationTotalRows={adminData.total_count}
                                        onChangePage={_handlePageChange}
                                        rowClick={(row) => _handleNavigate(row)} />
                                </div>
                                :
                                <div className='h-100 py-5 my-5'>
                                    <EmptyScreen message="No admins found" />
                                </div>
                    }
                </div>
            </div>

            {/* Add admin modal */}
            {addAdmin === true &&
                <AddAdminModal
                    countries={countryAdminOptions}
                    closeModal={() => {
                        setAddAdmin(false);
                    }} />
            }
        </section>
    )
})

export default CountryAdmin;