/*
 *   File : configure-password.js
 *   Author : https://evoqins.com
 *   Description : Password configuration page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/


import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

//Custom components
import { CustomPasswordInput, CustomTextInput } from '../../Component/FormElements';
import { PrimaryButton } from '../../Component/Buttons';
import { APIService } from '../../Service';

const ConfigurePassword = () => {

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const search = new URLSearchParams(window.location.search);
        const email = search.get("email");

        if (email !== null) {
            setEmail(email);
        } else {
            navigate("/");
        }
    }, []);

    useEffect(() => {
        setOtpError("");
    }, [otp]);

    useEffect(() => {
        setPasswordError("");
    }, [password]);

    useEffect(() => {
        setConfirmPasswordError("");
    }, [confirmPassword]);

    // handle submit password 
    const _handleSubmit = () => {
        let is_valid = true;

        if (otp.trim().length < 5) {
            setOtpError("Enter received otp");
            is_valid = false;
        }
        if (password.trim().length === 0) {
            setPasswordError("Enter new password");
            is_valid = false;
        }

        if (confirmPassword.trim().length === 0) {
            setConfirmPasswordError("Re-enter password");
            is_valid = false;
        } else if (confirmPassword !== password) {
            setConfirmPasswordError("Password dows not match");
            is_valid = false;
        }

        if (is_valid === true) {
            setLoading(true);
            _setPassword();
        }
    };

    // API - Set password 
    const _setPassword = () => {
        let is_post = true;
        let url = 'auth/set-password';
        let data = {
            "email": email,
            "otp": otp,
            "password": password
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                navigate("/");
                toast.dismiss();
                toast.success(response.message, {
                    type: "success",
                    className: "e-toast",
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setLoading(false);
        });
    };

    return (
        <div className='e-bg-indigo padding-32px-top'>
            <div className='container '>
                <img src={require("../../Assets/jotun-icon.svg").default}
                    draggable={false}
                    alt='jotun-icon' />

                {/* Login section */}
                <div className='row padding-32px-top padding-200px-bottom'>
                    <div className='offset-xl-4 offset-lg-3 offset-md-2 offset-sm-1 col-xl-4 col-lg-6 col-md-8 col-sm-10 '>
                        <div className='p-4 e-bg-white e-border-radius-16'>
                            {/* Email */}
                            <CustomTextInput label="Email"
                                placeholder="admin@gmail.com"
                                type="text"
                                require
                                readOnly={true}
                                value={email}
                                className="w-100"
                                labelStyle="e-font-weight-500"
                                onKeyPress={_handleSubmit}
                                handleChange={(e) => setEmail(e)} />

                            <div className='pt-3'>
                                <CustomTextInput label="OTP"
                                    placeholder="Enter otp"
                                    type="text"
                                    require
                                    value={otp}
                                    error={otpError}
                                    autoFocus={true}
                                    className="w-100"
                                    labelStyle="e-font-weight-500"
                                    onKeyPress={_handleSubmit}
                                    handleChange={(e) => {
                                        if (e.length <= 5) {
                                            setOtp(e.toUpperCase());
                                        }
                                    }} />
                            </div>

                            {/* new password */}
                            <CustomPasswordInput label="New password"
                                placeholder="********"
                                require
                                value={password}
                                error={passwordError}
                                className="w-100"
                                labelStyle="e-font-weight-500"
                                onKeyPress={_handleSubmit}
                                handleChange={(e) => setPassword(e)} />

                            {/* re-enter password */}
                            <CustomPasswordInput label="Re-enter password"
                                placeholder="********"
                                require
                                value={confirmPassword}
                                error={confirmPasswordError}
                                className="w-100"
                                labelStyle="e-font-weight-500"
                                handleChange={(e) => setConfirmPassword(e)}
                                onKeyPress={_handleSubmit} />

                            {/* Set pw button */}
                            <PrimaryButton name="Submit"
                                className="e-bg-indigo mt-2"
                                status={loading}
                                onPress={_handleSubmit} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfigurePassword;
