/*
 *   File : employee.js
 *   Author : https://evoqins.com
 *   Description : Employee listing page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';

// Custom components 
import { DataTableComponent } from '../../../Component/DataTable';
import { CustomSelectBox } from '../../../Component/FormElements';
import { APIService } from '../../../Service';
import { Loader } from '../../../Component/Others';
import { EmptyScreen } from '../../../Component/EmptyComponent';
import { PrimaryButton } from '../../../Component/Buttons';
import { ManageAssignModal } from '../../../Component/Modal';
import { Icon } from '../../../Component/Icon';

const USER_FILTER_OPTION = [
    {
        value: 0,
        label: 'All',
    },
    {
        value: 1,
        label: 'Latest user registered'
    },
    {
        value: 2,
        label: 'Top earned'
    },
    {
        value: 3,
        label: 'Sales performance'
    },
];

const ITEMS_PER_PAGE = 16;

const SalesSpecialist = () => {

    const navigate = useNavigate();

    const [selected, setSelected] = useState(USER_FILTER_OPTION[0]);
    const [toggleFilter, setToggleFilter] = useState(false);

    const [registeredUserData, setRegisteredUserData] = useState({});
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);

    const [assignModal, setAssignModal] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);

    const [countryAdmins, setCountryAdmins] = useState([]);
    const [adminDataLoading, setAdminDataLoading] = useState(true);
    const [cityOptions, setCityOptions] = useState([]);
    const [cityOptionsLoading, setCityOptionsLoading] = useState(true);
    const [selectedCity, setSelectedCity] = useState({ value: null, label: "All" });

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });

        _getCity();
    }, []);

    useEffect(() => {
        _getRegisteredUserData();
    }, [selected, selectedCity, currentPage]);

    useEffect(() => {
        if (assignModal === true) {
            const assign_modal = new Modal(document.getElementById('manage-assign-modal'));
            assign_modal.show();
        }
    }, [assignModal]);

    // handle filter registered user 
    const _handleFilter = (value) => {
        setSelected(value);
        setLoading(true);
        setCurrentPage(1);
    };

    // handle filter city 
    const _handleFilterCity = (value) => {
        setSelectedCity(value);
        setLoading(true);
        setCurrentPage(1);
    };

    // handle navigate detail page 
    const _handleNavigate = (id) => {
        navigate("detail", {
            state: {
                customer_id: id,
                tabIndex: 1
            }
        });
    };

    // handle page change 
    const _handlePageChange = (page_num) => {
        setCurrentPage(page_num);
        setSelectedIds([]);

        window.scrollTo({
            top: 0, behavior: 'smooth'
        });
    };

    // handle select employee ids
    const _handleSelectIds = (id) => {
        setSelectedIds((prev) => {
            if (prev.includes(id)) {
                return prev.filter((selectedId) => selectedId !== id);
            } else {
                return [...prev, id];
            }
        });
    };

    // handle assign modal 
    const _handleAssign = () => {
        setAssignModal(true);

        _getCountryAdminList(); //API admin list
    };

    // handle select all rows
    const _handleSelectAll = () => {
        if (selectedIds.length === registeredUserData.data.length) {
            setSelectedIds([]);
        } else {
            setSelectedIds(registeredUserData.data.map((row) => row.customer_id));
        }
    };

    // registered members table 
    const TABLE_COLUMNS = [
        {
            name: (
                <Icon icon={selectedIds.length === registeredUserData?.data?.length ? "checkbox-active" : "checkbox"}
                    className='cursor-pointer'
                    size="16px"
                    onPress={_handleSelectAll} />
            ),
            cell: (row) => <div >
                <Icon icon={selectedIds.includes(row.customer_id) ? "checkbox-active" : "checkbox"}
                    className='cursor-pointer'
                    size="16px"
                    onPress={() => _handleSelectIds(row.customer_id)} />
            </div>,
            width: "40px"
        },
        {
            name: 'Name',
            selector: row => row.customer_name,
            width: "180px"
        },
        {
            name: 'Joined on',
            selector: row => row.joined_on,
            width: "120px"
        },
        {
            name: 'Email',
            selector: row => row.email === null ? "-" : row.email,
            width: "200px"
        },
        {
            name: 'Phone no.',
            selector: row => row.contact_no,
            width: "160px"
        },
        {
            name: 'Store name',
            selector: row => row.store_name,
            width: "260px"
        },
        {
            name: 'Units sold',
            selector: row => row.total_sales,
            width: "140px"
        },
        {
            name: 'Reward points',
            selector: row => row.reward,
            width: "140px"
        },
        {
            name: 'Revenue generated',
            selector: row => row.revenue,
            width: "180px"
        },
        {
            name: 'Country admin',
            selector: row => row.country_admin ? row.country_admin : '-',
            width: "180px"
        },

    ];

    // API - Get sales specialist data
    const _getRegisteredUserData = () => {
        let is_post = true;
        let url = 'CA/sales-specialist/list';
        let data = {
            "page_num": currentPage,
            "filter": selected.value,
            "limit": ITEMS_PER_PAGE,
            "city_id": selectedCity.value
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setRegisteredUserData(response.data);
            } else { }
            setLoading(false);
        });
    };

    // API - Get country admin list
    const _getCountryAdminList = () => {
        let is_post = false;
        let url = 'general/country-admin';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCountryAdmins(response.data);
            } else { }
            setAdminDataLoading(false);
        });
    };

    // API - City list
    const _getCity = () => {
        let city_filter_options = [];

        let is_post = false;
        let url = "general/city";
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                city_filter_options.push({
                    value: null,
                    label: "All"
                });

                response.data.map((city) => (
                    city_filter_options.push({
                        value: city.value,
                        label: city.label
                    })
                ));

                setCityOptions(city_filter_options);

            } else { }
            setCityOptionsLoading(false);
        });
    }

    return (
        <section className='pb-5 '>
            <div className='e-bg-lavender-blush-light pt-3 padding-98px-bottom padding-52px-lr'>
                <h1 className='mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-raisin-black e-font-weight-600'>
                    Sales specialists
                </h1>
            </div>
            <div className='margin-neg-66px-top padding-52px-lr '>
                <div className='e-bg-white e-admin-box-shadow py-4 e-border-radius-16 '>
                    <div className='d-flex justify-content-space-between px-4'>
                        <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-18 e-font-weight-500 line-height-28px'>
                            All registered members
                        </h4>
                        <div className='d-flex align-items-center gap-3'>

                            <PrimaryButton name="Assign"
                                className={`${selectedIds.length > 0 ? "" : "e-disable"} py-2`}
                                onPress={_handleAssign} />

                            <img src={toggleFilter ? require("../../../Assets/Image/filter-icon-white.svg").default : require("../../../Assets/Image/filter-icon.svg").default}
                                draggable={false}
                                className={`${cityOptionsLoading ? 'e-disable' : ''} ${toggleFilter ? `e-bg-indigo` : ``} transition e-border-1px-anti-flash-white e-border-radius-8 p-2 cursor-pointer`}
                                alt='filter'
                                onClick={() => setToggleFilter(!toggleFilter)} />
                        </div>
                    </div>

                    <div className='d-flex gap-1 align-items-center justify-content-end padding-12px-top px-4'>
                        <img src={require("../../../Assets/Image/CountryAdmin/scroll-animation.gif")}
                            draggable={false}
                            className='transform-rotate-270'
                            width={24}
                            alt='scroll' />
                        <p className='mb-0 text-end e-text-outer-space e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px'>
                            Scroll horizontally for view more  information
                        </p>
                    </div>

                    {/* filter */}
                    {toggleFilter &&
                        <div className='e-bg-anti-flash-white-dark py-3 px-4 mt-3'>
                            <div className='row'>
                                <div className='col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6'>
                                    <CustomSelectBox label="Select type"
                                        value={selected}
                                        options={USER_FILTER_OPTION}
                                        type={2}
                                        className="e-bg-white e-border-radius-8 e-table-select"
                                        isSearchable={false}
                                        selectChange={(value) => _handleFilter(value)}
                                    />
                                </div>
                                {/* City filter */}
                                <div className='col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6'>
                                    <CustomSelectBox label="City"
                                        value={selectedCity}
                                        options={cityOptions}
                                        type={2}
                                        className="e-bg-white e-border-radius-8 e-table-select"
                                        isSearchable={false}
                                        selectChange={(value) => _handleFilterCity(value)}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    {loading ?
                        // loader 
                        <Loader />
                        :
                        <>
                            {/* Registered members list table */}
                            {registeredUserData.data.length !== 0 ?
                                <div className='e-members-table'>
                                    <DataTableComponent columns={TABLE_COLUMNS}
                                        data={registeredUserData.data}
                                        pagination={true}
                                        paginationServer={true}
                                        paginationTotalRows={registeredUserData.total_count}
                                        paginationPerPage={ITEMS_PER_PAGE}
                                        // selectableRows={true}
                                        onChangePage={_handlePageChange}
                                        rowClick={(row) => _handleNavigate(row.customer_id)} />
                                </div>
                                :
                                // empty screen
                                <div className='h-100vh pt-5 mt-5'>
                                    <EmptyScreen message="No sales specialist found" />
                                </div>
                            }
                        </>
                    }
                </div>
            </div>

            {/* assign modal */}
            {assignModal === true &&
                <ManageAssignModal options={countryAdmins}
                    loading={adminDataLoading}
                    customerIds={selectedIds}
                    handleClose={() => setAssignModal(false)}
                    handleSuccess={() => {
                        setAssignModal(false);
                        _getRegisteredUserData();
                        setSelectedIds([]);
                    }} />
            }
        </section>
    )
}

export default SalesSpecialist;