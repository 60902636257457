/*
 *   File : confirm-delete.js
 *   Author : https://evoqins.com
 *   Description :Delete confirm modal
 *   Integrations : js-cookie
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react'

// Custom components
import { PrimaryButton, SecondaryButton } from '../Buttons';
import { Icon } from '../Icon';

const BlockAdminModal = (props) => {
    const [apiLoader, setApiLoader] = useState(false);
    const [status, setStatus] = useState(false);

    useEffect(() => {
        var delete_modal = document.getElementById("block-admin-modal");
        delete_modal.addEventListener("hidden.bs.modal", () => {
            setStatus(false);
            props.closeModal()
        });
    }, []);

    useEffect(() => {
        setApiLoader(props.loading);
    }, [props.loading]);

    // handle close Modal 
    const _handleCloseModal = () => {
        var button = document.getElementById("confirm-delete-modal-close");
        button.click();
        setStatus(false);

        props.success();
    };

    // handle confirm delete 
    async function _blockAdmin() {
        try {
            const result = await props.block();
            if (result === true) {
                setStatus(true);
            }
        } catch (error) {

        }
    }

    return (
        <div className="modal fade" id="block-admin-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="confirmDeleteModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content e-border-radius-12 w-400px mx-auto ">
                    <img src={require("../../Assets/Image/CountryAdmin/close-icon.svg").default}
                        draggable={false}
                        className='d-none'
                        id='confirm-delete-modal-close'
                        alt='close'
                        data-bs-dismiss="modal" />
                    <div className="modal-header px-4 pt-4 pb-3 border-0 align-items-start">
                        <img src={require("../../Assets/Image/CountryAdmin/delete-icon.svg").default}
                            draggable={false}
                            alt='delete' />
                        <Icon icon="close-icon"
                            className='cursor-pointer'
                            size="24px"
                            onPress={_handleCloseModal} />
                    </div>
                    <div className="modal-body px-4 pt-1 pb-4">
                        {!status ?
                            <>
                                <h4 className='mb-2 e-primary-font-playfair e-text-raisin-black e-font-18 e-font-weight-500 line-height-28px'>
                                    {props.blocked ? "Un-block" : "Block"} {props.label}
                                </h4>
                                <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                    Are you sure you want to {props.blocked ? "un-block" : "block"} this {props.label}
                                </p>

                                {/* Custom button  */}
                                <div className='row row-cols-2 pt-4 mt-2'>
                                    <div className='col'>
                                        <SecondaryButton name="Cancel"
                                            className="w-100"
                                            dismiss="modal" />
                                    </div>
                                    <div className='col'>
                                        <PrimaryButton name="Submit"
                                            className="w-100"
                                            status={props.response}
                                            onPress={() => _blockAdmin()} />
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <h4 className='mb-2 e-primary-font-playfair e-text-raisin-black e-font-18 e-font-weight-500 line-height-28px'>
                                    {props.blocked ? "Un-blocked" : "Blocked"} successfully
                                </h4>
                                <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                                    {props.label === "admin" ? 'Admin' : 'User'}  has been {props.blocked ? "un-blocked" : "blocked"} successfully.
                                </p>

                                {/* Custom button  */}
                                <div className='pt-4 mt-2'>
                                    <PrimaryButton name="Ok"
                                        className="w-100"
                                        onPress={_handleCloseModal} />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}

BlockAdminModal.defaultProps = {
    delete: () => { },
    closeModal: () => { }
}


export default BlockAdminModal;