/*
 *   File : tab.js
 *   Author : https://evoqins.com
 *   Description : Custom tab component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { memo } from "react";

const CustomTab = (props) => {
    return (
        <div className={`${props.tabIndex === props.data.id ? 'pe-none e-bg-lust e-text-white border-transparent' : 'e-border-1px-cool-grey e-text-rich-black cursor-pointer'}  e-border-radius-24 `}
            onClick={() => props.handleTabChange(props.data.id)} >
            <p className='mb-0 px-4 padding-12px-tb e-alt-font-noto-sans  e-font-14 e-font-weight-500 line-height-14px w-max-content'>
                {props.data.label}
            </p>
        </div>
    )
};

export default memo(CustomTab);