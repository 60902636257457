/*
 *   File : redemption-history.js
 *   Author : https://evoqins.com
 *   Description : Redemption history page
 *   Integrations : NA
 *   Version : 1.0.0
*/


import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Custom components 
import { DataTableComponent } from '../../../Component/DataTable';
import { CustomSearchInput, CustomSelectBox } from '../../../Component/FormElements';
import { APIService } from '../../../Service';
import Icon from '../../../Component/Icon/icon';
import Loader from '../../../Component/Others/loader';
import { EmptyScreen } from '../../../Component/EmptyComponent';

const WEEK_OPTIONS = [
    {
        value: 1,
        label: 'All',
    },
    {
        value: 2,
        label: 'Last Week'
    },
    {
        value: 3,
        label: 'Last Month'
    },
];

const RedemptionHistory = () => {

    const navigate = useNavigate();

    const [searchValue, setSearchValue] = useState("");
    const [historyData, setHistoryData] = useState({});
    const [toggleFilter, setToggleFilter] = useState(false);
    const [selected, setSelected] = useState(WEEK_OPTIONS[0]);
    const [currentPage, setCurrentPage] = useState(1);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    }, []);

    useEffect(() => {
        _getRedemptionHistory();
    }, [searchValue, currentPage]);

    // handle back to offer list 
    const _handleBack = () => {
        navigate("/redeem-offer",
            { state: 2 }
        );
    };

    // handle search history data 
    const _handleSearch = (value) => {
        setSearchValue(value);
        setLoading(true);
    };

    // handle table page change 
    const _handlePageChange = (page_num) => {
        setCurrentPage(page_num);
        window.scrollTo({
            top: 0, behavior: 'smooth'
        });
    };

    // history table data
    const TABLE_COLUMNS = [
        {
            name: 'Emp. ID',
            selector: row => row.employee_id,
            width: "80px"
        },
        {
            name: 'Name',
            // selector: row => row.employee_name,
            cell: (row) =>
                <span className='text-decoration-hover-underline cursor-pointer'
                    onClick={() => navigate("/sales-specialist/detail", {
                        state: {
                            customer_id: row.employee_id,
                            tabIndex: 1
                        }
                    })}>
                    {row.employee_name}
                </span>,
            width: "150px"
        },
        {
            name: 'Requested on',
            selector: row => row.created,
            width: "140px"
        },
        {
            name: 'Redeem point',
            selector: row => row.redemption_points,
            width: "140px"
        },
        {
            name: 'Coupon name',
            selector: row => row.coupon_title,
            width: "160px"
        },
        {
            name: 'Store name',
            selector: row => row.store_name,
            width: "260px"
        },
        {
            name: 'Status',
            // selector: row => row.status,
            cell: row =>
                <>
                    {row.status ?
                        <div className={`${row.status === "Approved" ? `e-bg-tea-green padding-12px-lr` :
                            row.status === "Rejected" ? `e-bg-light-red padding-12px-lr` : ``} py-1 e-border-radius-16 e-font-weight-500 e-font-12`}>
                            {row.status}
                        </div>
                        :
                        "--"
                    }
                </>,
            width: "120px"
        },
    ];

    // API - get registration list 
    const _getRedemptionHistory = () => {
        let is_post = true;
        let url = 'CA/coupon/redemption-history';
        let data = {
            "page_num": currentPage,
            "query": searchValue
        };
        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setHistoryData(response.data);
                setLoading(false);
            } else {
                setLoading(false);
                setHistoryData({});
            }
        });
    };

    return (
        <section className='pb-5'>
            <div className='e-bg-lavender-blush-light pt-3 padding-98px-bottom padding-52px-lr'>
                <div className='d-flex gap-0-8 align-items-center'>
                    <img src={require("../../../Assets/Image/left-back-arrow.svg").default}
                        draggable={false}
                        className='cursor-pointer'
                        alt='arrow'
                        onClick={_handleBack} />
                    <h1 className='mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                        Request RSVP history
                    </h1>
                </div>
            </div>
            <div className='margin-neg-66px-top padding-52px-lr'>
                <div className='e-bg-white e-admin-box-shadow py-4 e-border-radius-16 '>
                    <div className='row px-4'>
                        <div className='col-7'>
                            <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-600 line-height-28px' >
                                Requests
                            </h4>
                        </div>
                        <div className='col-5 text-end'>
                            <div className='row'>
                                <div className='col-xl-12 col-lg-12 col-md-12 col-sm-11 pe-2 ps-2'>

                                    {/* search input */}
                                    <div className='position-relative'>
                                        <CustomSearchInput placeholder="Search by reward name or user name"
                                            type="text"
                                            require
                                            search={true}
                                            value={searchValue}
                                            className="w-100 padding-10px-tb"
                                            labelStyle="e-font-weight-500"
                                            handleChange={(e) => _handleSearch(e)} />
                                        {searchValue.length !== 0 &&
                                            <Icon icon="close-icon"
                                                className='position-absolute top-12px right-12px cursor-pointer'
                                                size="20px"
                                                onPress={() => setSearchValue("")} />
                                        }
                                    </div>
                                </div>

                                {/* toggle filter button */}
                                {/* <div className='col-xl-1 col-lg-1 col-md-1 col-sm-2 ps-2 my-auto'>
                                    <img src={toggleFilter ? require("../../../Assets/Image/filter-icon-white.svg").default : require("../../../Assets/Image/filter-icon.svg").default}
                                        draggable={false}
                                        className={`${toggleFilter ? `e-bg-indigo` : ``} transition e-border-1px-anti-flash-white  e-border-radius-8 p-2 cursor-pointer`}
                                        alt='filter'
                                        onClick={() => setToggleFilter(!toggleFilter)} />
                                </div> */}
                            </div>
                        </div>
                    </div>

                    {/* filter */}
                    {toggleFilter &&
                        <div className='e-bg-anti-flash-white-dark py-3 px-4 mt-3'>
                            <div className='row'>
                                <div className='col-2'>
                                    <CustomSelectBox label="Category"
                                        value={selected}
                                        options={WEEK_OPTIONS}
                                        type={2}
                                        className="e-bg-white e-border-radius-8 e-table-select"
                                        isSearchable={false}
                                        selectChange={(temp) => { setSelected(temp); }}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    <>
                        {loading ?
                            // loader 
                            <Loader />
                            :
                            <>
                                {/* request history data table */}
                                {historyData.data.length !== 0 ?
                                    <div className='e-table pt-2'>
                                        <DataTableComponent columns={TABLE_COLUMNS}
                                            data={historyData.data}
                                            pagination={true}
                                            paginationServer={true}
                                            paginationTotalRows={historyData.total_items}
                                            onChangePage={_handlePageChange} />
                                    </div>
                                    :
                                    // empty screen
                                    <div className='h-100vh pt-5 mt-5'>
                                        <EmptyScreen message="No requests found" />
                                    </div>
                                }
                            </>

                        }
                    </>
                </div>
            </div>
        </section>
    )
}

export default RedemptionHistory;