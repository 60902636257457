
/*
 *   File : pagination.js
 *   Author URI : https://evoqins.com
 *   Description : Index page for blog listing
 *   Integrations : ReactPaginate
 *   Version : v1.0
 */

import { memo } from 'react';
import ReactPaginate from 'react-paginate';

//Assets
import NextIcon from "../../Assets/Image/next-icon.svg";

const Pagination = (props) => {

    // handle page click 
    const _handlePageClick = (event) => {
        let new_start_limit = event.selected * props.itemsPerPage;
        let new_end_limit = new_start_limit + props.itemsPerPage;
        props.onChange(new_start_limit, new_end_limit);
    }

    return (
        <ReactPaginate
            breakLabel="..."
            // nextLabel={props.nextButtonLabel}
            pageRangeDisplayed={2}
            // marginPagesDisplayed={0}
            pageCount={props.totalPage}
            // previousLabel={props.prevButtonLabel}
            renderOnZeroPageCount={null}
            pageClassName="e-page-item"
            previousClassName="e-prev-button"
            nextClassName="e-next-button"
            forcePage={props.forcePage}
            containerClassName={`${props.className} e-pagination`}
            activeClassName="e-active"
            marginPagesDisplayed={0}
            nextLabel={<img src={NextIcon} width={32} draggable={false} alt="Next" />}
            previousLabel={<img src={NextIcon} width={32} draggable={false} alt="Next" />}
            onPageChange={_handlePageClick}
        />
    )
};

export default memo(Pagination);