/*
 *   File : aws-service.js
 *   Author Author URI : https://evoqins.com
 *   Description : aws file upload service
 *   Integrations : axios
 *   Version : v1.1
 */

import axios from 'axios';

// AWS service 
const AWSService = async function (params, file, url) {

    try {

        let AWS_BASE_URL = url;
        var FormData = require('form-data');
        var formData = new FormData();        

        // // append form data
        Object.entries(params).map(item => { formData.append(item[0], item[1]); });
        formData.append('file', file, file.name);
       
        //config
        var config = {
            method: 'post',
            url: AWS_BASE_URL,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept': "application/json",
                'cache-control': "no-cache",
            },
            data: formData,
            timeout: 1800000, // Set a timeout of 30 minutes
            transformRequest: formData => formData,
        };

        return await axios(config)
            .then(function (response) {
                return ({ status: 'ok' }); // when successful
            })
            .catch(function (error) {
                return ({
                    status: false,
                    message: 'We are facing issues with our third-party service providers. Please try later.'
                });
            });

    } catch (err) {
        console.error("AWS upload error:", err);
        return ({
            status: false,
            message: 'We are facing issues with our third-party service providers. Please try later.'
        })
    }
};
export default AWSService;