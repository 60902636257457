/*
 *   File : video-detail.js
 *   Author : https://evoqins.com
 *   Description : Video detail modal component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// style 
import styles from "../../Style/Component/modal.module.scss";
import style from "../../Style/Component/input.module.scss";

//custom components
import { TextButton } from '../Buttons';
import { Loader } from '../Others';
import { Icon } from '../Icon';

const VideoDetailModal = (props) => {

    const navigate = useNavigate();

    const [toggleLanguage, setToggleLanguage] = useState(false);

    useEffect(() => {
        if (props.response === true) {
            _handleCloseModal();
        }
    }, [props.response]);

    // handle close Modal 
    const _handleCloseModal = () => {
        var button = document.getElementById("video-detail-modal-close");
        button.click();

        props.handleClose();
    };

    // handle edit quiz 
    const _handleEditVideo = (id) => {
        _handleCloseModal();
        navigate('/manage-resource/manage-video', {
            state: {
                video_id: id,
                type: "edit"
            }
        });
    };

    // handle toggle language
    const _handleToggleLanguage = (toggle) => {
        setToggleLanguage(toggle);
    };

    return (
        <div className="modal fade" id="video-detail-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="videoDetailModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content e-border-radius-12 w-480px mx-auto overflow-hidden">
                    <img src={require("../../Assets/Image/CountryAdmin/close-icon.svg").default}
                        id='video-detail-modal-close'
                        className='d-none'
                        alt='close'
                        data-bs-dismiss="modal" />
                    {props.loading ?
                        <Loader className="h-30vh py-4 my-0 justify-content-center d-flex align-items-center " /> //loader
                        :
                        <>
                            <div className="modal-header px-3 pt-3 pb-0 border-0">
                                <div>
                                    <h4 className="mb-0 e-primary-font-playfair e-text-dark-jungle-green e-font-18 e-font-weight-500 line-height-28px">
                                        {toggleLanguage ? props.video.name_arabic : props.video.name}
                                    </h4>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <div className='e-display-flex justify-content-space-between '>
                                        <p className='pe-2 mb-0 e-text-raisin-black e-alt-font-noto-sans e-font-16 e-font-weight-400 line-height-24px'>
                                            {toggleLanguage ? "Arabic" : "English"}
                                        </p>
                                        <label className={`${style.e_resource} `} >
                                            <input type="checkbox"
                                                checked={toggleLanguage}
                                                onChange={(e) => _handleToggleLanguage(e.target.checked)} />
                                            <span className={`${style.e_slider}`}></span>
                                        </label>
                                    </div>
                                    <Icon icon="close-icon"
                                        className='cursor-pointer d-flex'
                                        size="24px"
                                        onPress={_handleCloseModal} />
                                </div>
                            </div>

                            <div className={`${styles.e_modal_body_wrapper} py-3 modal-body px-3 overflow-auto e-modal-body-wrapper`}>
                                <div className='position-relative'>
                                    <img src={props.video.thumbnail_url}
                                        draggable={false}
                                        className='w-100 e-border-radius-16 '
                                        alt='thumbnail' />
                                    <img src={require("../../Assets/Image/Card/video-play.svg").default}
                                        draggable={false}
                                        className='position-absolute top-44 left-46'
                                        alt='play' />
                                </div>

                                {/* Tags */}
                                <div className='d-flex flex-wrap gap-2 pt-2'>
                                    {toggleLanguage ?
                                        <>
                                            {props.video.tags_arabic?.map((tag, key) => (
                                                <span key={key} className='padding-2px-tb e-bg-periwinkle e-border-radius-20 px-2 e-alt-font-noto-sans e-text-raisin-black e-font-14 e-font-weight-400 line-height-22px'>
                                                    {tag}
                                                </span>
                                            ))}
                                        </>
                                        :
                                        <>
                                            {props.video.tags?.map((tag, key) => (
                                                <span key={key} className='padding-2px-tb e-bg-periwinkle e-border-radius-20 px-2 e-alt-font-noto-sans e-text-raisin-black e-font-14 e-font-weight-400 line-height-22px'>
                                                    {tag}
                                                </span>
                                            ))}
                                        </>
                                    }
                                </div>

                                <div className='e-display-flex padding-22px-top gap-3'>
                                    <p className='mb-0 e-alt-font-noto-sans e-text-davys-grey e-font-12 e-font-weight-400 line-height-12px'>
                                        Reward points
                                        <span className='ps-1 e-text-raisin-black e-font-14 e-font-weight-600 line-height-14px'>
                                            {props.video.points}
                                        </span>
                                    </p>

                                    <p className='mb-0 e-alt-font-noto-sans e-text-davys-grey e-font-12 e-font-weight-400 line-height-12px'>
                                        Watch time {toggleLanguage ? props.video.duration_text_arabic : props.video.duration_text}
                                    </p>
                                </div>

                                <div className='text-end pb-2'>
                                    <div className='e-inline-flex justify-content-end gap-0-20px margin-neg-8px-top'>
                                        <TextButton name="Edit"
                                            className="e-font-16"
                                            onPress={() => _handleEditVideo(props.video.video_id)} />
                                        <TextButton name="Delete"
                                            className="e-text-lust e-font-16"
                                            onPress={() => props.handleDelete(props.video.video_id)} />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default memo(VideoDetailModal);
