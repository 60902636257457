/*
 *   File : confirm-banner-action.js
 *   Author : https://evoqins.com
 *   Description : Modal banner archive and unarchive, hide confirm component
 *   Integrations :
 *   Version : 1.0.0
*/

import { memo } from "react";
import { Icon } from "../Icon";
import { PrimaryButton, SecondaryButton } from "../Buttons";

// Assets
import ArchiveBanner from "../../Assets/Image/CountryAdmin/ManageBanner/archive-banner.svg";
import HideBanner from "../../Assets/Image/CountryAdmin/ManageBanner/hide-banner.svg";
import ShowBanner from "../../Assets/Image/CountryAdmin/ManageBanner/show-banner.svg";

function confirmBannerAction(props) {

  return (
    <div className="modal fade"
      id="confirm-banner-action-modal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="confirmBannerAction"
      aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content e-border-radius-12">
          <div className="modal-header px-4 pt-4 pb-3 border-0 align-items-start">
            <img src={props.actionType === "ARCHIVE" ? ArchiveBanner : props.actionType === "HIDE_BANNER" ? HideBanner : ShowBanner}
              draggable={false}
              id='confirm-delete-modal-close'
              alt='close'
              data-bs-dismiss="modal"
              onClick={props.onClose} />
            <Icon icon="close-icon"
              className='cursor-pointer'
              size="24px"
              data-bs-dismiss="modal"
              onClick={props.onClose} />

          </div>
          <div className="modal-body px-4 pt-1 pb-4">
            {
              props.actionType === "ARCHIVE" || props.actionType === "UN_ARCHIVE" ?

                <>
                  <h4 className='mb-2 e-primary-font-playfair e-text-raisin-black e-font-18 e-font-weight-500 line-height-28px'>
                    {props.archive ? "Archive banner" : "Banner unarchived"}
                  </h4>
                  <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>

                    {props.archive ? "Do you want to archive banner?" : "Your banner is unarchived successfully "}
                  </p>

                  {/* Custom button  */}
                  <div className='row row-cols-2 pt-4 mt-2'>
                    <div className='col'>
                      <SecondaryButton name="Cancel"
                        className="w-100"
                        dismiss="modal"
                        onPress={props.onClose} />
                    </div>
                    <div className='col'>
                      <PrimaryButton name={props.archive ? "Archive" : "View"}
                        className="w-100"
                        onPress={() => props.onConfirm(props.actionType)}
                        dismiss="modal" />
                    </div>
                  </div>
                </>
                :

                <>
                  <h4 className='mb-2 e-primary-font-playfair e-text-raisin-black e-font-18 e-font-weight-500 line-height-28px'>
                    {props.actionType === "HIDE_BANNER" ? "Hide banner" : "Show banner"}
                  </h4>
                  <p className='mb-0 e-text-davys-grey e-alt-font-noto-sans e-font-14 e-font-weight-400 line-height-20px'>
                    {props.actionType === "HIDE_BANNER" ? "Do you want to hide created banner?" : "Do you want to unhide created banner?"}
                  </p>

                  {/* Custom button  */}
                  <div className='row row-cols-2 pt-4 mt-2'>
                    <div className='col'>
                      <SecondaryButton name="Cancel"
                        className="w-100"
                        dismiss="modal"
                        onPress={props.onClose} />
                    </div>
                    <div className='col'>
                      <PrimaryButton name={props.actionType === "HIDE_BANNER" ? "Hide" : "Show"}
                        className="w-100"
                        onPress={() => props.onConfirm(props.actionType)}
                        dismiss="modal" />
                    </div>
                  </div>
                </>
            }
          </div>
        </div>
      </div>
    </div>

  )
};

export default memo(confirmBannerAction);