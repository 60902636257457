/*
 *   File : competition-detail.js
 *   Author : https://evoqins.com
 *   Description : Competition detail page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

// Package
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Modal } from 'bootstrap'
import { toast } from "react-toastify";

// Custom components
import { CompetitionCard } from "../../../Component/Cards";
import { ConfirmDeleteModal, ManageCompetitionModal } from "../../../Component/Modal";
import { APIService } from "../../../Service";
import { Loader } from "../../../Component/Others";
import { EmptyScreen } from "../../../Component/EmptyComponent";
import { SecondaryButton } from "../../../Component/Buttons";

const CompetitionDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [competitionId, setCompetitionId] = useState(null);
    const [competitionDetail, setCompetitionDetail] = useState({});
    const [loading, setLoading] = useState(true);
    const [deleted, setDeleted] = useState(false);

    const [productList, setProductList] = useState([]);
    const [productLoading, setProductLoading] = useState(true);
    const [competitionModal, setCompetitionModal] = useState(false);

    const [leaderBoardData, setLeaderBoardData] = useState({});
    const [leaderBoardLoading, setLeaderBoardLoading] = useState(true);
    const [btnLoader, setBtnLoader] = useState(true);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
        setBtnLoader(false);
    }, []);

    useEffect(() => {
        if (confirmDelete === true) {
            const modal = new Modal(document.getElementById("confirm-delete-modal"));
            modal.show()
        }
    }, [confirmDelete]);

    useEffect(() => {
        if (location.state !== null) {
            setCompetitionId(location.state.competition_id);
        } else {
            navigate("/competition");
        }
    }, [location]);

    useEffect(() => {
        if (competitionId !== null) {
            _competitionDetail();
            _leaderBoardData();
        }
    }, [competitionId]);

    useEffect(() => {
        if (competitionModal === true) {
            const manage_modal = new Modal(document.getElementById('manage-competition-modal'));
            manage_modal.show();
        }
    }, [competitionModal]);

    // handle edit competition modal 
    const _handleEdit = () => {
        setCompetitionModal(true);
        _getProducts();
    };

    // handle delete competition
    const _handleDelete = () => {
        setConfirmDelete(true);
    };

    // handle success edit 
    const _handleSuccessEdit = () => {
        setLoading(true);
        _competitionDetail();
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    };

    // handle send notification 
    const _handleSendNotification = (id) => {
        setBtnLoader(true);
        _sendNotification();
    };

    // API - Get competition detail
    const _competitionDetail = () => {
        let is_post = true;
        let url = 'CA/competition/detail';
        let data = {
            "competition_id": competitionId
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCompetitionDetail(response.data);
            } else {

            }
            setLoading(false);
        });
    };

    // API - Delete competition
    const _deleteCompetition = () => {
        let is_post = true;
        let url = 'CA/competition/delete';
        let data = {
            "competition_id": competitionId
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setDeleted(true);
            } else {
                setDeleted(false);
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
        });
    };

    // API - get products
    const _getProducts = () => {
        let is_post = true;
        let url = 'general/products';
        let data = {
            "query": "",
            "page_num": 1
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setProductList(response.data);
            } else {
            }
            setProductLoading(false);
        });
    };

    // API - Get leader board data
    const _leaderBoardData = () => {
        let is_post = true;
        let url = 'CA/competition/leaderboard';
        let data = {
            "competition_id": competitionId,
            "page_num": 1
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setLeaderBoardData(response.data);
            } else {
            }
            setLeaderBoardLoading(false);
        });
    };

    // API - Send notification
    const _sendNotification = () => {
        let is_post = true;
        let url = 'CA/competition/send-notification';
        let data = {
            "competition_id": competitionId
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Notification send successfully", {
                    type: "success",
                    className: "e-toast",
                });
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
            }
            setBtnLoader(false);
        });
    };

    return (
        <Fragment>
            <section className='pb-5 '>
                <div className='e-bg-lavender-blush-light pt-3 padding-88px-bottom padding-52px-lr'>
                    <div className='d-flex justify-content-space-between flex-wrap align-items-center'>
                        <div className='e-display-flex'>
                            <img src={require("../../../Assets/Image/CountryAdmin/arrow-left.svg").default}
                                draggable={false}
                                className='cursor-pointer'
                                alt='arrow'
                                onClick={() => navigate("/competition")} />
                            <h1 className='ps-1 mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                                Competition detail
                            </h1>
                        </div>
                        {/* send notification button  */}
                        <SecondaryButton name="Send notification"
                            className="py-2 e-border-radius-16 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0 mt-2 w-148px"
                            status={btnLoader}
                            onPress={_handleSendNotification} />
                    </div>

                </div>
                <div className="padding-52px-lr margin-neg-56px-top">
                    {loading ?
                        <Loader />  //loader
                        :
                        <>
                            <div className="col-lg-6 padding-32px-bottom" >
                                <CompetitionCard data={competitionDetail}
                                    detail={true}
                                    handleEdit={_handleEdit}
                                    handleConfirm={_handleDelete} />
                            </div>
                            <div className="col-lg-6">
                                <h2 className=' mb-0 e-primary-font-playfair e-font-18 line-height-28px e-text-dark-jungle-green e-font-weight-500 mb-3'>
                                    Live Leaderboard
                                </h2>
                                <div className="bg-white e-border-radius-16 pb-1">
                                    <div className="row px-4 py-3">
                                        <div className="col-3">
                                            <h6 className="mb-0 e-font-12 e-text-raisin-black e-font-weight-500 line-height-16px e-alt-font-noto-sans">Name</h6>
                                        </div>
                                        <div className="col-2 text-center">
                                            <h6 className="mb-0 e-font-12 e-text-raisin-black e-font-weight-500 line-height-16px e-alt-font-noto-sans">Sales unit</h6>
                                        </div>
                                        <div className="col-3 text-center">
                                            <h6 className="mb-0 e-font-12 e-text-raisin-black e-font-weight-500 line-height-16px e-alt-font-noto-sans">Sales amount</h6>
                                        </div>
                                        <div className="col-3 text-center">
                                            <h6 className="mb-0 e-font-12 e-text-raisin-black e-font-weight-500 line-height-16px e-alt-font-noto-sans">Points awarded</h6>
                                        </div>
                                    </div>
                                    {leaderBoardLoading ?
                                        <Loader className="h-40vh" />  //loader
                                        :
                                        <>
                                            {/* leader board data */}
                                            {leaderBoardData.data.length !== 0 ?
                                                <>
                                                    {leaderBoardData.data.map((item, key) => {
                                                        return (
                                                            <div key={key} className="e-border-top-1px-light-steel-blue px-4 py-2">
                                                                <div className="row">
                                                                    <div className="col-3">
                                                                        <span className="e-font-12 e-text-raisin-black e-font-weight-500 line-height-16px e-alt-font-noto-sans">
                                                                            {item.name}
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-2 text-center">
                                                                        <span className="e-font-12 e-text-raisin-black e-font-weight-500 line-height-16px e-alt-font-noto-sans ">
                                                                            {item.sales_unit}
                                                                        </span>
                                                                    </div>
                                                                    <div className="col-3 text-center">
                                                                        <span className="e-font-12 e-text-raisin-black e-font-weight-500 line-height-16px e-alt-font-noto-sans">
                                                                            {item.sales_amount}                                        </span>
                                                                    </div>
                                                                    <div className="col-3 text-center">
                                                                        <span className="e-font-12 e-text-raisin-black e-font-weight-500 line-height-16px e-alt-font-noto-sans">
                                                                            {item.points}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </>
                                                :
                                                <div className='h-60vh pt-5 mt-5'>
                                                    {/* empty screen */}
                                                    <EmptyScreen message="No list found" />
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
            </section>

            {/* Manage competition modal */}
            {competitionModal === true &&
                <ManageCompetitionModal product={productList.data}
                    edit={true}
                    detailData={competitionDetail}
                    loading={productLoading}
                    success={() => _handleSuccessEdit()}
                    handleClose={() => setCompetitionModal(false)} />
            }

            {/* Delete confirm modal */}
            {confirmDelete === true &&
                <ConfirmDeleteModal label="competition"
                    handleClose={() => setConfirmDelete(false)}
                    response={deleted}
                    delete={() => _deleteCompetition()}
                    success={() => {
                        setConfirmDelete(false);
                        navigate("/competition")
                    }} />}
        </Fragment>
    )
}

export default CompetitionDetail;