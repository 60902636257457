/*
 *   File : request-history.js
 *   Author : https://evoqins.com
 *   Description : Request history page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Custom components 
import { DataTableComponent } from '../../../Component/DataTable';
import { CustomSearchInput } from '../../../Component/FormElements';
import { APIService } from '../../../Service';
import { Loader } from '../../../Component/Others';
import { EmptyScreen } from '../../../Component/EmptyComponent';
import { Icon } from '../../../Component/Icon';

const RequestHistory = () => {

    const navigate = useNavigate();

    const [searchValue, setSearchValue] = useState("");
    const [historyData, setHistoryData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    }, []);

    useEffect(() => {
        _getHistoryData();
    }, [searchValue, currentPage]);

    // Request history table columns
    const TABLE_COLUMNS = [
        {
            name: 'Name',
            selector: row => row.name,
            width: "190px"
        },
        {
            name: 'Email',
            selector: row => row.email !== null ? row.email : '-',
            width: "200px"
        },
        {
            name: 'Phone no.',
            // selector: row => row.phone,
            cell: row =>
                <a href={`tel:${row.phone}`} className='text-decoration-none text-decoration-hover-underline e-text-rich-black' >
                    {row.phone}
                </a>,
            width: "160px"
        },
        {
            name: 'Dob',
            selector: row => row.dob,
            width: "180px"
        },
        {
            name: 'Store name',
            selector: row => row.store_name,
            width: "260px"
        },
        {
            name: 'Status',
            // selector: row => row.status,
            cell: row =>
                <>
                    {row.status ?
                        <div className={`${row.status === 1 ? `e-bg-tea-green padding-12px-lr` :
                            row.status === 2 ? `e-bg-light-red padding-12px-lr` : ``} py-1 e-border-radius-16 e-font-weight-500 e-font-12`}>
                            {row.status === 1 ? "Approved" : "Rejected"}
                        </div>
                        :
                        "--"
                    }
                </>,
            width: "120px"
        },
        {
            name: 'Status updated on',
            selector: row => row.updated,
            width: "180px",
            sortable: true,
            sortFunction: (a, b) => {
                let first_date = new Date(a.updated);
                let second_date = new Date(b.updated);
                return first_date - second_date;
            }
        }
    ];

    // handle page change 
    const _handlePageChange = (page_num) => {
        setCurrentPage(page_num);

        window.scrollTo({
            top: 0, behavior: 'smooth'
        });
    };

    // handle clear searched value 
    const _handleClearSearchValue = () => {
        setSearchValue("");
        setLoading(true);
    }

    // API - get registration history 
    const _getHistoryData = () => {
        let is_post = true;
        let url = 'CA/registration/history';
        let data = {
            "page_num": currentPage,
            "query": searchValue
        };
        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setHistoryData(response.data)
            } else {
            }
            setLoading(false);
        });
    };

    return (
        <section className='pb-5 '>
            <div className='e-bg-lavender-blush-light pt-3 padding-98px-bottom padding-52px-lr'>
                <div className='d-flex gap-0-8 align-items-center'>
                    <img src={require("../../../Assets/Image/left-back-arrow.svg").default}
                        draggable={false}
                        className='cursor-pointer'
                        alt='arrow'
                        onClick={() => navigate("/pending-request")} />
                    <h1 className='mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                        Request RSVP history
                    </h1>
                </div>
            </div>
            <div className='margin-neg-66px-top padding-52px-lr'>
                <div className='e-bg-white e-admin-box-shadow py-4 e-border-radius-16 '>
                    <div className='row px-4'>
                        <div className='col-8'>
                            <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-600 line-height-28px' >
                                Requests
                            </h4>
                        </div>
                        <div className='col-4 text-end'>
                            {/* search input */}
                            <div className='position-relative'>
                                <CustomSearchInput placeholder="Search by name, id & store"
                                    type="text"
                                    require
                                    value={searchValue}
                                    className="w-100 padding-10px-tb"
                                    labelStyle="e-font-weight-500"
                                    handleChange={(e) => {
                                        setSearchValue(e);
                                        setCurrentPage(1);
                                    }} />
                                {searchValue.length !== 0 &&
                                    <Icon icon="close-icon"
                                        className='cursor-pointer position-absolute top-12px right-12px'
                                        size="20px"
                                        onPress={_handleClearSearchValue} />
                                }
                            </div>
                        </div>
                    </div>

                    {loading ?
                        // loader 
                        <Loader />
                        :
                        <>
                            {/* request history data table */}
                            {historyData.data.length !== 0 ?
                                <div className='e-table'>
                                    <DataTableComponent columns={TABLE_COLUMNS}
                                        data={historyData.data}
                                        pagination={true}
                                        paginationServer={true}
                                        paginationTotalRows={historyData.total_records}
                                        onChangePage={_handlePageChange} />
                                </div>
                                :
                                <div className='h-100vh pt-5 mt-5'>
                                    <EmptyScreen message="No requests found" />
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
        </section>
    )
}

export default RequestHistory;