/*
 *   File : overlay.js
 *   Author : https://evoqins.com
 *   Description :  Modal open overlay
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo } from 'react';

const OverLay = ({ isOpen, onClose, children, className, ref, dismiss }) => {
    return (
        isOpen &&
        <div ref={ref} className={`${className} overlay-background`} onClick={onClose} data-bs-dismiss={dismiss}>
            <div className='overlay-container' >
                {children}
            </div >
        </div >
    );
};

export default memo(OverLay);