/*
 *   File : App.js
 *   Author : https://evoqins.com
 *   Description :
 *   Integrations : js-cookie,react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Cookies from 'js-cookie';
import { ToastContainer } from 'react-toastify';

//Custom component
import { ConfigurePassword, ForgotPassword, Login } from './Container/Auth';
import { Dashboard as SuperAdminDashboard } from './Container/SuperAdmin/Home';
import { Dashboard as CountryAdminDashboard } from "./Container/CountryAdmin/Home";
import { CustomHeader, LeftNavigationWidget } from './Component/Navigation';
import { Footer } from './Component/Footer';
import { CountryAdmin, CountryAdminsDetail } from './Container/SuperAdmin/CountryAdmins';
import { DobChangeHistory, PendingRequest, RequestHistory } from './Container/CountryAdmin/Requests';
import { EmployeeDetail, SalesSpecialist } from './Container/CountryAdmin/Employee';
import { StoreListing, StoreDetail } from './Container/CountryAdmin/Store';
import { CourseDetail, LearningResources, ManageCourse, ManageDownload, ManageFlashQuiz, ManageQuiz, ManageVideo } from './Container/CountryAdmin/Learn';
import { ListRewardPoints } from './Container/CountryAdmin/Rewards';
import { OfferListing, RedemptionHistory } from './Container/CountryAdmin/Offers';
import { NotificationList as CountryAdminNotification } from './Container/CountryAdmin/Notification';
import { NotificationList as SuperAdminNotification } from './Container/SuperAdmin/Notifications';
import { ManageNotification } from './Container/CountryAdmin/InAppNotifications';
import { BannerList } from './Container/Banner';
import { CompetitionDetail, ListCompetitions } from './Container/CountryAdmin/Competition';
import { ManageNews } from './Container/CountryAdmin/News';

function App() {

    const [toggleSidebar, setToggleSidebar] = useState(true);
    const [token, setToken] = useState(Cookies.get("access_token"));
    const [adminType, setAdminType] = useState(Cookies.get("admin_type") === "1" ? "super_admin" : Cookies.get("admin_type") === "2" ? "country_admin" : null);
    const [browserZoom, setBrowserZoom] = useState(100);

    // useEffect(() => {
    //     setToken(Cookies.get("access_token"));
    // }, [Cookies.get("access_token")]);

    useEffect(() => {
        if (adminType === 'super_admin') {
            document.title = 'Jotun Way Super Admin';
        } else if (adminType === 'country_admin') {
            document.title = 'Jotun Way Country Admin';
        }
    }, [adminType]);

    useEffect(() => {
        // Removing modal backdrop for all modals when use gesture
        window.addEventListener('popstate', function () {
            if (document.querySelector(".modal-backdrop")) {
                document.querySelector(".modal-backdrop").remove();
                document.body.style.overflow = 'unset';
            };

        });
    }, [window.location.pathname]);

    useEffect(() => {
        // Update browser zoom level
        function updateZoom() {
            const newZoom = ((window.outerWidth - 10) / window.innerWidth) * 100;
            const roundedZoom = Math.ceil(newZoom / 5) * 5;
            setBrowserZoom(roundedZoom);
        }
        updateZoom();
        window.addEventListener("resize", updateZoom);
        return () => {
            window.removeEventListener("resize", updateZoom);
        };
    }, []);

    useEffect(() => {
        // Calculate the default zoom level based on the device pixel ratio
        const default_zoom = window.devicePixelRatio;

        const initial_value = `${default_zoom * 100}%`;

        if (initial_value === "150%") {
            document.body.style.zoom = "80%";
        } else {
            document.body.style.zoom = "100%";
        }
    }, []);

    // handle sidebar toggle
    const _handleToggleSidebar = () => {
        setToggleSidebar(!toggleSidebar);
    };

    return (
        <div className={` ${browserZoom >= 150 ? 'e-app-zoom-level-7' :
            browserZoom === 125 ? 'e-app-zoom-level-8' :
                browserZoom === 110 ? 'e-app-zoom-level-9' : null
            }`}>
            {token ?
                <Router>
                    <div className='container-fluid min-h-100vh'>
                        <div className='row'>
                            <div className={`${toggleSidebar ? `col-2` : `col-1`} px-0 transition d-xl-block d-lg-block d-md-none d-none`}>
                                <LeftNavigationWidget toggle={toggleSidebar} token={adminType} />
                            </div>
                            <div className={`${toggleSidebar ? `col-xl-10 col-lg-10 col-md-12` : `col-xl-11 col-lg-11 col-md-12`} px-0 e-bg-ghost-white transition`}>
                                <CustomHeader handleToggle={_handleToggleSidebar} token={adminType} />
                                {adminType === "super_admin" ?
                                    <>
                                        <div className='min-h-100vh'>
                                            <Routes>
                                                <Route exact path="/" element={<SuperAdminDashboard />} />
                                                <Route path="country-admin" element={<CountryAdmin />} />
                                                <Route path="country-admin/detail" element={<CountryAdminsDetail />} />
                                                {/* <Route path="notification" element={<SuperAdminNotification />} /> */}

                                                {/* New Features */}
                                                <Route path='/manage-resource'>
                                                    <Route index element={<LearningResources />} />
                                                    <Route path='course-detail' element={<CourseDetail />} />
                                                    <Route path='manage-course' element={<ManageCourse />} />
                                                    <Route path='manage-video' element={<ManageVideo />} />
                                                    <Route path='manage-quiz' element={<ManageQuiz />} />
                                                    <Route path='manage-flash-quiz' element={<ManageFlashQuiz />} />
                                                    <Route path='manage-download' element={<ManageDownload />} />
                                                </Route>
                                                <Route path='/manage-news' element={<ManageNews />} />

                                                <Route path="*" element={<p>404</p>} />
                                            </Routes>
                                        </div>
                                        <Footer type={2} token={adminType} />
                                    </>
                                    :
                                    <>
                                        <div className='min-h-100vh'>
                                            <Routes>
                                                <Route path="/" element={<CountryAdminDashboard />} />

                                                <Route path="/pending-request">
                                                    <Route index element={<PendingRequest />} />
                                                    <Route path='request-history' element={<RequestHistory />} />
                                                    <Route path='dob-history' element={<DobChangeHistory />} />
                                                </Route>


                                                <Route path="/sales-specialist">
                                                    <Route index element={< SalesSpecialist />} />
                                                    <Route path="detail" element={< EmployeeDetail />} />
                                                </Route>

                                                <Route path='/store-listing'>
                                                    <Route index element={<StoreListing />} />
                                                    <Route path='detail' element={<StoreDetail />} />
                                                </Route>

                                                <Route path='/manage-resource'>
                                                    <Route index element={<LearningResources />} />
                                                    <Route path='course-detail' element={<CourseDetail />} />
                                                    <Route path='manage-course' element={<ManageCourse />} />
                                                    <Route path='manage-video' element={<ManageVideo />} />
                                                    <Route path='manage-quiz' element={<ManageQuiz />} />
                                                    <Route path='manage-flash-quiz' element={<ManageFlashQuiz />} />
                                                    <Route path='manage-download' element={<ManageDownload />} />
                                                </Route>

                                                <Route path='/competition'>
                                                    <Route index element={<ListCompetitions />} />
                                                    <Route path='competition-detail' element={<CompetitionDetail />} />
                                                </Route>

                                                <Route path='/redeem-offer' element={<OfferListing />} />
                                                <Route path='/redemption-history' element={<RedemptionHistory />} />
                                                <Route path='/reward-point' element={<ListRewardPoints />} />
                                                <Route path='/notification' element={<CountryAdminNotification />} />
                                                <Route path='/manage-notification' element={<ManageNotification />} />
                                                <Route path='/manage-news' element={<ManageNews />} />
                                                <Route path='/banner-list' element={<BannerList />} />
                                                <Route path="*" element={<p>404</p>} />
                                            </Routes>
                                        </div>
                                        <Footer type={2} token={adminType} />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </Router>
                :
                <Router>
                    <div className='position-relative min-h-100vh e-bg-indigo'>
                        <Routes>
                            <Route exact path="/" element={<Login />} />
                            <Route exact path="/login" element={<Login />} />
                            <Route path="/forgot-password" element={<ForgotPassword />} />
                            <Route path="/set-password" element={<ConfigurePassword />} />
                            <Route path="*" element={<Login />} />
                        </Routes>
                        {/* footer */}
                        <Footer />
                    </div>
                </Router>
            }

            <ToastContainer
                autoClose={3000}
                pauseOnHover={false}
                position="bottom-right" />
        </div>
    );
}

export default App;
