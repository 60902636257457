/*
 *   File : service.js
 *   Author URI : https://evoqins.com
 *   Description : Global function for API
 *   Integrations : axios
 *   Version : v1.1
*/

import axios from "axios";
import Cookies from "js-cookie";

export default async function APIService(is_post, url, data, token = null) {
    let API_URL = (Cookies.get("admin_type") === "1" ? process.env.REACT_APP_SUPER_ADMIN_URL : process.env.REACT_APP_API_URL) + url;
    // let API_URL = base_api_url ? base_api_url  : (Cookies.get("admin_type") == "1" ? process.env.REACT_APP_SUPER_ADMIN_URL : process.env.REACT_APP_API_URL) + url;

    let auth_token = null;

    if (token === null) {
        auth_token = Cookies.get("access_token");
    } else {
        auth_token = token;
    }

    if (is_post === true) {
        return await axios
            .post(API_URL, data, {
                headers: {
                    method: "post",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth_token}`,
                },
            })
            .then(function (response) {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.status_code === 401) {
                        Cookies.remove("access_token", { path: '/', domain: process.env.REACT_APP_DOMAIN });
                        Cookies.remove("admin_type", { path: '/', domain: process.env.REACT_APP_DOMAIN });
                        window.location.href = "/";
                    }
                    return error.response.data;
                } else if (error.request) {

                }

            });
    } else {
        return await axios
            .get(API_URL, {
                headers: {
                    method: "get",
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${auth_token}`,
                },
            })
            .then(function (response) {
                return response.data;
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.data.status_code === 401) {
                        Cookies.remove("access_token", { path: '/', domain: process.env.REACT_APP_DOMAIN });
                        Cookies.remove("admin_type", { path: '/', domain: process.env.REACT_APP_DOMAIN });
                        window.location.href = "/";
                    }
                    return error.response.data;
                }
            });
    }
}
