/*
 *   File : user-registration.js
 *   Author : https://evoqins.com
 *   Description : User register component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useRef, useState } from 'react';

//Custom Component
import { DataTableComponent } from '../../../Component/DataTable';
import { CustomSelectBox } from '../../../Component/FormElements';
import { SummaryCard } from '../../../Component/Cards';
import { APIService } from '../../../Service';
import { Loader } from '../../../Component/Others';
import { EmptyScreen } from '../../../Component/EmptyComponent';

const USER_REG_FILTER = [
    {
        value: 1,
        label: 'Latest user registered',
    },
    {
        value: 2,
        label: 'By top earned'
    },
    {
        value: 3,
        label: 'By sales performance'
    },
];

const ITEMS_PER_PAGE = 15;

const UserRegistered = (props) => {

    const tableRef = useRef(null);

    const [selected, setSelected] = useState(USER_REG_FILTER[0]);
    const [toggleFilter, setToggleFilter] = useState(false);

    const [userRegisteredData, setUserRegisteredData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);

    const [selectedCity, setSelectedCity] = useState({ value: null, label: "All" });

    useEffect(() => {
        _userRegisteredData();
    }, [selected, selectedCity, currentPage]);

    // handle filter registered user 
    const _handleFilterRegister = (value) => {
        setSelected(value);
        setLoading(true);
        setCurrentPage(1);
    };

    // handle filter city 
    const _handleFilterCity = (value) => {
        setSelectedCity(value);
        setLoading(true);
        setCurrentPage(1);
    };

    // handle page change of table
    const _handlePageChange = (page_num) => {
        setCurrentPage(page_num);

        if (tableRef.current) {
            tableRef.current.scrollIntoView({ behavior: 'smooth', block: "start" });
        }
    };

    // user registered table 
    const TABLE_COLUMNS = [
        {
            name: 'Users name',
            selector: row => row.customer_name,
            width: "220px"
        },
        {
            name: 'Shop',
            selector: row => row.store_name,
            width: "260px"
        },
        {
            name: 'Email',
            cell: (row) =>
                row.email === null ?
                    "-"
                    :
                    <a href={`mailto:${row.email}`} className='e-text-rich-black text-decoration-hover-underline text-decoration-none cursor-pointer'>
                        {row.email}
                    </a>,
            width: "200px"
        },
        {
            name: 'Contact no',
            cell: (row) =>
                row.contact_no === null ?
                    "-"
                    :
                    <a href={`tel:${row.contact_no}`} className='e-text-rich-black text-decoration-hover-underline text-decoration-none cursor-pointer'>
                        {row.contact_no}
                    </a>,
            width: "160px"
        },
        {
            name: 'Reward points earned',
            selector: row => row.reward,
            width: "200px"
        },
        {
            name: (
                <div className='d-flex'>
                    <span className='pe-1'>Total sales</span>
                    <div className='e-tooltip'>
                        <img src={require("../../../Assets/Image/note.svg").default}
                            draggable={false}
                            alt='tooltip' />
                        <p className="mb-0 px-2 padding-12px-tb e-tooltip-content e-box-tooltip-box-shadow e-text-rich-black e-alt-font-noto-sans e-font-12 e-font-weight-400 line-height-16px">
                            Both premium and normal sales
                        </p>
                    </div>
                </div>
            ),
            selector: row => row.total_sales,
            width: "160px"
        }
    ];

    // API - Get registered user data
    const _userRegisteredData = () => {
        let is_post = true;
        let url = 'country-admin/user-registered';
        let data = {
            "country_admin_id": props.adminId,
            "city_id": selectedCity.value,
            "page_num": currentPage,
            "filter": selected.value,
            "limit": ITEMS_PER_PAGE
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setUserRegisteredData(response.data);
            } else { }
            setLoading(false);
        });
    };

    return (
        <>
            {loading ?
                <div className='margin-20px-top e-bg-white e-admin-box-shadow py-4 e-border-radius-16 '>
                    {/*loader */}
                    <Loader />
                </div>
                :
                <>
                    {/* Summary */}
                    <div className='e-admin-box-shadow p-4 e-bg-white e-border-radius-16'>
                        <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-500 line-height-28px'>
                            Summary
                        </h4>

                        {/* Summary card */}
                        <div className='d-flex padding-10px-top gap-0-24px'>
                            <SummaryCard title="Registered sales specialists"
                                quantity={userRegisteredData.registered_user} />
                            <SummaryCard title="Blocked sales specialists"
                                quantity={userRegisteredData.blocked_count} />
                        </div>
                    </div>

                    {/* Sales specialists table */}
                    <div ref={tableRef} className='margin-20px-top e-bg-white e-admin-box-shadow py-4 e-border-radius-16 '>
                        <div className='d-flex px-4 justify-content-space-between'>
                            <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-20 e-font-weight-600 line-height-28px'>
                                Sales specialists
                            </h4>
                            <img src={toggleFilter ? require("../../../Assets/Image/filter-icon-white.svg").default : require("../../../Assets/Image/filter-icon.svg").default}
                                draggable={false}
                                className={`${toggleFilter ? `e-bg-indigo` : ``} transition e-border-1px-anti-flash-white  e-border-radius-8 p-2 cursor-pointer`}
                                alt='filter'
                                onClick={() => setToggleFilter(!toggleFilter)} />
                        </div>

                        {/* filter */}
                        {toggleFilter &&
                            <div className='e-bg-anti-flash-white-dark py-3 px-4 mt-2'>
                                <div className='row'>
                                    <div className='col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6'>
                                        <CustomSelectBox label="Filter by"
                                            value={selected}
                                            options={USER_REG_FILTER}
                                            type={2}
                                            className="e-bg-white e-border-radius-8 e-table-select"
                                            isSearchable={false}
                                            selectChange={(value) => _handleFilterRegister(value)}
                                        />
                                    </div>

                                    {/* City filter */}
                                    <div className='col-xl-2 col-lg-2 col-md-3 col-sm-4 col-6'>
                                        <CustomSelectBox label="City"
                                            value={selectedCity}
                                            options={props.cityData}
                                            type={2}
                                            className="e-bg-white e-border-radius-8 e-table-select"
                                            isSearchable={false}
                                            selectChange={(value) => _handleFilterCity(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        }

                        {/* Data table */}
                        {userRegisteredData.data.length !== 0 ?
                            <div className='e-table'>
                                <DataTableComponent columns={TABLE_COLUMNS}
                                    pagination={true}
                                    data={userRegisteredData.data}
                                    paginationServer={true}
                                    paginationTotalRows={userRegisteredData.total_count}
                                    onChangePage={_handlePageChange} />
                            </div>
                            :
                            // empty screen
                            <div className='h-100vh pt-5 mt-5'>
                                <EmptyScreen message="No user found" />
                            </div>
                        }
                    </div>
                </>
            }
        </>
    )
}

export default UserRegistered;