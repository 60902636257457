/*
 *   File : downloadable.js
 *   Author : https://evoqins.com
 *   Description : Download card component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo } from 'react';

const DownloadCard = (props) => {

    return (
        <div className='e-admin-box-shadow e-border-radius-16 p-4 e-bg-white'>
            <div className='row'>
                <div className='col-12'>
                    <div className='d-flex align-items-center' >
                        <h6 className='mb-0 e-primary-font-playfair e-text-raisin-black e-font-16 e-font-weight-700 line-height-18px'>
                            <span className='e-font-weight-400'>
                                {props.toggleLanguage ? props.download.name_arabic : props.download.name}
                            </span>
                        </h6>
                        <span className='px-1 e-font-16 '>•</span>
                        <p className='mb-0 e-alt-font-noto-sans e-text-raisin-black e-font-14 e-font-weight-400 line-height-20px'>
                            {props.toggleLanguage ? props.download.duration_in_text_arabic : props.download.duration_in_text} read
                        </p>
                    </div>
                    <p className='mb-0 padding-12px-top e-alt-font-noto-sans e-text-davys-grey e-font-14 e-font-weight-400 line-height-20px h-94px e-ellipse-4-line'>
                        {props.toggleLanguage ? props.download.description_arabic : props.download.description}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default memo(DownloadCard);