/*
 *   File : list-reward-points.js
 *   Author : https://evoqins.com
 *   Description : Reward points listing page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { Modal } from 'bootstrap';

// Custom components
import { PrimaryButton } from '../../../Component/Buttons';
import { CustomSelectBox } from '../../../Component/FormElements';
import { DataTableComponent } from '../../../Component/DataTable';
import { AddRewardsModal, ConfirmDeleteModal } from '../../../Component/Modal';
import { APIService } from '../../../Service';
import { Loader } from '../../../Component/Others';
import { EmptyScreen } from '../../../Component/EmptyComponent';
import { toast } from 'react-toastify';


const ListRewardPoints = () => {

    const [toggleFilter, setToggleFilter] = useState(false);

    const [milestoneFilterData, setMilestoneFilterData] = useState({});
    const [milestoneModal, setMilestoneModal] = useState(false);
    const [milestoneData, setMilestoneData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [deleteResponse, setDeleteResponse] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [milestoneOptions, setMilestoneOptions] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });

        _getMilestoneFilter();

    }, []);

    useEffect(() => {
        if (selected !== null) {
            _getMilestoneList();
        }
    }, [currentPage, selected]);

    useEffect(() => {
        if (milestoneModal === true) {
            const reward_modal = new Modal(document.getElementById('add-reward-modal'));
            reward_modal.show();
        }
    }, [milestoneModal]);

    useEffect(() => {
        if (deleteId !== null) {
            const delete_modal = new Modal(document.getElementById('confirm-delete-modal'));
            delete_modal.show();
        }
    }, [deleteId]);

    // handle open add reward modal 
    const _handleRewardModal = () => {
        setMilestoneModal(true);
    };

    // handle page change 
    const _handlePageChange = (page_num) => {
        setCurrentPage(page_num);

        window.scrollTo({
            top: 0, behavior: 'smooth'
        });
    };

    // handle delete milestone 
    const _handleDelete = (id) => {
        setDeleteId(id);
        setDeleteResponse(false);
    };

    // milestone list table columns
    const TABLE_COLUMNS = [
        {
            name: 'Created on',
            selector: row => row.created_on,
            width: "120px"
        },
        {
            name: 'Milestone type',
            selector: row => row.milestone_type,
            width: "160px"
        },
        {
            name: 'Sales type',
            selector: row => row.sales_type !== null ? row.sales_type : '-',
            width: "160px"
        },
        {
            name: (
                <>
                    <p className='mb-0'>Sales<span className='e-font-weight-400 ps-1'>(Per units)</span></p>
                </>
            ),
            selector: row => row.sales_per_unit !== null ? row.sales_per_unit : '-',
            width: "160px"
        },
        {
            name: 'Painters visited',
            selector: row => row.visited_painters !== null ? row.visited_painters : '-',
            width: "150px"
        },
        {
            name: 'Reward points',
            selector: row => row.reward_points !== null ? row.reward_points : '-',
            width: "180px"
        },
        {
            name: '',
            cell: (row) =>
                <div className="d-flex">
                    <PrimaryButton name="Delete"
                        className="padding-6px-tb padding-14px-lr e-reject"
                        onPress={() => _handleDelete(row.reward_id)} />
                </div>,
        }
    ];

    // API - Get milestone list 
    const _getMilestoneList = () => {
        let is_post = true;
        let url = 'CA/milestone/list';
        let data = {
            "page_num": currentPage,
            "type": selected.value,
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setMilestoneData(response.data);
            } else {

            }
            setLoading(false);
        });
    };

    // API - Create milestone 
    const _getMilestoneFilter = () => {
        let is_post = false;
        let url = 'CA/milestone/filter';
        let data = {};

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setMilestoneFilterData(response.data);
                // setMilestoneModal(true);
                const milestone_options = []
                milestone_options.push({ label: "All", value: null })
                response.data.milestone_types.map((item) => {
                    return (
                        milestone_options.push(item)
                    )
                })
                setMilestoneOptions(milestone_options);
                setSelected(milestone_options[0]);
            } else {

            }
        });
    };

    // API - Delete milestone 
    const _deleteMilestone = (id) => {
        let is_post = true;
        let url = 'CA/milestone/delete';
        let data = {
            "milestone_id": id
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setDeleteResponse(true);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    type: "error",
                    className: "e-toast",
                });
                setDeleteResponse(false);
            }
        });
    };

    return (
        <section className='pb-5 '>
            <div className='e-bg-lavender-blush-light pt-3 padding-98px-bottom padding-52px-lr'>
                <div className='d-flex justify-content-space-between flex-wrap'>
                    <h1 className='mb-0 e-primary-font-playfair e-font-24 line-height-32px e-text-rich-black e-font-weight-600'>
                        Manage reward point
                    </h1>

                    {/* add milestone button  */}
                    <PrimaryButton name="Add new milestone"
                        className="py-2 e-border-radius-16 mt-xl-0 mt-lg-0 mt-md-0 mt-sm-0 mt-2"
                        onPress={_handleRewardModal} />
                </div>
            </div>
            <div className='margin-neg-66px-top padding-52px-lr'>
                <div className='e-bg-white e-admin-box-shadow py-4 e-border-radius-16 '>
                    <div className='d-flex justify-content-space-between px-4'>
                        <h4 className='mb-0 e-text-rich-black e-alt-font-noto-sans e-font-18 e-font-weight-500 line-height-18px'>
                            Track milestones
                        </h4>
                        <img src={toggleFilter ? require("../../../Assets/Image/filter-icon-white.svg").default : require("../../../Assets/Image/filter-icon.svg").default}
                            draggable={false}
                            className={`${toggleFilter ? `e-bg-indigo` : ``} transition e-border-1px-anti-flash-white  e-border-radius-8 p-2 cursor-pointer`}
                            alt='filter'
                            onClick={() => setToggleFilter(!toggleFilter)} />
                    </div>

                    {/* filter */}
                    {toggleFilter &&
                        <div className='e-bg-anti-flash-white-dark py-3 px-4 mt-3'>
                            <div className='row'>
                                <div className='col-xl-2 col-lg-3 col-md-3 col-sm-4 col-8'>
                                    <CustomSelectBox label="Select milestone type"
                                        value={selected}
                                        options={milestoneOptions}
                                        type={2}
                                        className="e-bg-white e-border-radius-8 e-table-select"
                                        isSearchable={false}
                                        selectChange={(temp) => { setSelected(temp); }}
                                    />
                                </div>
                            </div>
                        </div>
                    }

                    {/* country admin list data table */}
                    {loading ?
                        // loader 
                        <Loader />
                        :
                        <>
                            {milestoneData.data.length !== 0 ?
                                <div className='e-table'>
                                    <DataTableComponent columns={TABLE_COLUMNS}
                                        data={milestoneData.data}
                                        pagination={true}
                                        paginationServer={true}
                                        paginationTotalRows={milestoneData.total_rows}
                                        onChangePage={_handlePageChange} />
                                </div>
                                :
                                // empty screen
                                <div className='h-100vh pt-5 mt-5'>
                                    <EmptyScreen message="No milestones found" />
                                </div>
                            }
                        </>
                    }

                </div>
            </div>

            {/* add reward modal */}
            {milestoneModal === true &&
                <AddRewardsModal data={milestoneFilterData}
                    handleClose={() => setMilestoneModal(false)}
                    success={() => _getMilestoneList()} />
            }

            {/* delete modal  */}
            {deleteId !== null &&
                <ConfirmDeleteModal label="milestone"
                    deleteId={deleteId}
                    delete={(id) => _deleteMilestone(id)}
                    handleClose={() => setDeleteId(null)}
                    success={() => {
                        if (currentPage > 1 && milestoneData.data.length === 1) {
                            setCurrentPage(currentPage - 1)
                        } else {
                            _getMilestoneList()
                        }
                    }}
                    response={deleteResponse} />
            }

            {/* {deleteId !== null &&
                <ConfirmDeleteModal deleteId={deleteId}
                    delete={(id) => _deleteMilestone(id)}
                    handleClose={() => setDeleteId(null)}
                    success={() => _getMilestoneList()}
                    response={deleteResponse} />
            } */}

        </section >
    )
}

export default ListRewardPoints;