/*
 *   File : loader.js
 *   Author : https://evoqins.com
 *   Description :  Loader component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo } from 'react';

const Loader = (props) => {
    return (
        <div className={`${props.className} h-100vh text-center padding-48px-top margin-48px-top`}>
            <img src={require("../../Assets/loader-dark.gif")}
                width={32}
                alt='loader' />
        </div>
    )
}

export default memo(Loader);
